import { ContentLayout } from '@/components/Layout';
// import { Authorization, ROLES } from '@/lib/authorization';

import { UpdateUser } from '../components/UpdateUser';

export const User = () => {
  return (
    <ContentLayout title="Edit User" back={'/app/users'}>
      <UpdateUser />
    </ContentLayout>
  );
};
