import { SignInFooterProps, SignInHeaderProps } from '../types';

export const SignInHeader = ({ errors }: SignInHeaderProps) => {
  return (
    <>
      <div className="auth-header">
        <h1 className="auth-title">Welcome back!</h1>
        <p>Your learning journey continues</p>
      </div>
      {errors?.length && (
        <ul className="mb-4 text-red-500">
          {errors.map((error, i) => (
            <li key={i} className="text-base">
              {error}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export const SignInFooter = ({ toResetPassword }: SignInFooterProps) => (
  <div className="auth-footer">
    <p className="auth-password-reset">
      Forgot your password?
      <button onClick={toResetPassword}>Reset your password</button>
    </p>
  </div>
);
