import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  placeholder?: string;
  horizontal?: boolean;
  bold?: boolean;
  icons?: Array<React.ReactNode>;
  defaultValue?: string;
  size?: string;
  grid?: boolean;
  disabled?: boolean;
  maxLength?: number;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = 'text',
    label,
    className,
    registration,
    error,
    placeholder,
    horizontal,
    bold,
    icons,
    defaultValue,
    size,
    grid,
    disabled,
    maxLength = 9000,
  } = props;
  return (
    <FieldWrapper label={label} error={error} horizontal={horizontal} size={size} grid={grid}>
      <input
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        type={type}
        maxLength={maxLength}
        className={clsx('input-field', error ? 'error' : '', bold ? 'bold-input' : '', className)}
        {...registration}
      />
      <div
        style={{ color: !error ? '#cecece' : '' }}
        className={clsx('input-field__icons', error && 'input-field__icons-error')}
      >
        {icons && icons.map((icon) => icon)}
      </div>
    </FieldWrapper>
  );
};
