import clsx from 'clsx';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import {
  useTakeAssessments,
  GeneralSettingsType,
  AssessmentResponseType,
  ASSESSMENT_PHASE,
} from '@/features/assessments';
import { formatDateFull } from '@/utils/format';

export enum STATUS {
  NEW = 'New',
  DUE = 'Due Soon',
  OVERDUE = 'Overdue',
  COMPLETED = 'Completed',
}

type AssessmentItemProps = {
  name?: string;
  updatedAt?: string;
  selected?: boolean;
  general_settings?: GeneralSettingsType;
  assessment_name: string;
  onClick?: () => void;
};

export const AssessmentItem = ({
  general_settings,
  selected,
  assessment_name,
  onClick,
}: AssessmentItemProps) => {
  const dueDate = useMemo(() => {
    const date = general_settings && general_settings.due_date;
    return date && date.substring(0, 10);
  }, [general_settings]);
  return (
    <button
      onClick={onClick}
      className={`!flex assessment-list__item${selected ? ' selected' : ''}`}
    >
      <div className="assessment-list__item-header">
        <p className="bold">{assessment_name}</p>
      </div>
      <div className="assessment-list__item-content">
        <p>
          {dueDate && (
            <>
              <span className="bold">Due</span>
              {formatDateFull(dueDate)}
            </>
          )}
        </p>
      </div>
    </button>
  );
};

export const AssessmentList = ({
  label = '',
  step,
}: {
  label?: string;
  step: ASSESSMENT_PHASE;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const { isLoading, data } = useTakeAssessments(step, {
    config: { cacheTime: 0, staleTime: 0 },
  });

  if (isLoading)
    return (
      <div className="assessment-list__spinner">
        <Spinner />
      </div>
    );

  return (
    <>
      <div
        className={clsx('assessment-list__main-container', isLoading && 'assessment-list__loading')}
      >
        <span className="assessment-list-header">{`${label} (${
          (data && data?.length) || 0
        })`}</span>
        <div className="assessment-list-container">
          {data !== undefined &&
            data?.map((assessment: AssessmentResponseType, i: number) => {
              const { assessment_id } = assessment;
              return (
                <AssessmentItem
                  key={i}
                  selected={params.assessmentId === assessment_id}
                  onClick={() =>
                    navigate(
                      params.assessmentId && assessment_id
                        ? `../${assessment_id}`
                        : `${assessment_id}`
                    )
                  }
                  {...assessment}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};
