import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as zod from 'zod';

import { Button, Dialog, DialogTitle } from '@/components/Elements';
import { InputField, SelectField, TextAreaField } from '@/components/Form';
import { useNotificationStore } from '@/stores/notifications';

type ContactUsDialogType = {
  isOpen: boolean;
  close: () => void;
};
const formSchema = zod.object({
  fullName: zod.string().nonempty('Please enter a name.'),
  email: zod.string().email('This is not a valid email address').nonempty('Email is required'),
  contactReason: zod.string().nonempty('Please make a selection'),
});

export const ContactUsDialog = ({ isOpen, close }: ContactUsDialogType) => {
  const { addNotification } = useNotificationStore();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: zodResolver(formSchema) });

  const onSubmit = (data: any) => {
    if (data.contactReason === 'report-bug') {
      window.open(
        'https://docs.google.com/forms/d/e/1FAIpQLSd4PAbqNMNvmR-HQzSy8-jbNEhiZvv_g_hUXF00rV4nmIwiTQ/viewform',
        '_blank'
      );
      close();
      reset();
    } else {
      //The API should be called to trigger email sending here
      console.log(data);
      close();
      addNotification({
        type: 'success',
        title: 'Thank you! Your message has been received. We will get back to you shortly!',
      });
      reset();
    }
  };

  return (
    <Dialog isOpen={isOpen} onClose={close}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="modal overflow-hidden transform transition-all contact-us"
      >
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Get in Touch with Us
          </DialogTitle>
          <div className="modal__info">
            <p className="modal__info__text">
              Fill out the form below and someone from our team will get back to you in 24 hours!
            </p>
          </div>
          <div className="modal__body">
            <InputField
              className="input-field"
              label="Full Name"
              type="text"
              registration={register('fullName', { required: true })}
              error={errors.fullName}
            />

            <InputField
              className="input-field"
              label="Email"
              type="email"
              registration={register('email', { required: true })}
              error={errors.email}
            />

            <SelectField
              size="full"
              className="select-field"
              placeholder="Select a reason"
              label="Why are you contacting us?"
              registration={register('contactReason', { required: true })}
              error={errors.contactReason}
              options={[
                { label: 'General Inquiry', value: 'general-inquiry' },
                { label: 'I would like to schedule a product demo', value: 'product-demo' },
                { label: 'Request access to GrowthOS app', value: 'request-access' },
                { label: 'Report a bug', value: 'report-bug' },
              ]}
            />
            <TextAreaField
              containerSize="lg"
              className="text-area-field"
              label="Is there anything you'd like us to know? (optional)"
              placeholder="Start writing your message here"
              registration={register('additionalInfo')}
              error={errors.additionalInfo}
            />
          </div>
          <div className="modal__footer modal-row justify-end">
            <Button type="button" variant="inverse" onClick={close}>
              Cancel
            </Button>
            <Button type="submit" variant="danger">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
