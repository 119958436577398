import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as FileIcon } from '@/assets/icons/fileIcon.svg';
import { ReactComponent as UserIcon } from '@/assets/icons/userIcon.svg';
import { IOption } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useDisclosure } from '@/hooks/useDisclosure';

import { BulkUploadModal } from '../components/BulkUploadModal';
import { UsersList } from '../components/UserList/UsersList';
import { useBulkUsersStore } from '../stores/bulkUsers';

export const Users = () => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { setUsers } = useBulkUsersStore();
  const { close, isOpen, open } = useDisclosure();

  const options: IOption[] = [
    {
      label: 'Add new user',
      icon: <UserIcon />,
      onClick: () => navigate('create-admin'),
    },
    {
      label: 'Import users from CSV',
      icon: <FileIcon />,
      onClick: () => open(),
    },
  ];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      //parsing CSV file to JSON
      reader.onload = (e) => {
        if (e.target) {
          const csvData = e.target.result as string;
          const lines = csvData.split('\n').slice(1);
          const jsonData = lines.map((line) => {
            const sanitizedLine = line.replace('\r', '');
            const [name, email, role] = sanitizedLine.split(',');
            return { name, email, role };
          });

          console.log(jsonData);
          setUsers(jsonData);
          navigate('create-admin');
        }
      };

      reader.readAsText(file);
    }
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  return (
    <ContentLayout title="Manage Users" addNavigateLabel="Add new user" options={options}>
      <BulkUploadModal isOpen={isOpen} close={close} onClick={handleUploadClick} />
      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={handleFileChange}
      />
      <UsersList />
    </ContentLayout>
  );
};
