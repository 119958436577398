import clsx from 'clsx';

type SectionPropsType = {
  index: number;
  step: number;
  children: React.ReactNode;
  section_description: string;
  section_name: string;
};

export const Section = ({
  index,
  step,
  section_description,
  section_name,
  children,
}: SectionPropsType) => {
  return (
    <div className={clsx('section-take', step === index && 'section-take__active')}>
      <div className="section-take__info">
        <p className="bold section-take__title">{section_name}</p>
        <p className="section-take__desc">{section_description}</p>
      </div>
      {children}
    </div>
  );
};
