import { useAuthenticator } from '@aws-amplify/ui-react';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import { Button, Spinner, ConfirmationDialog, ConfirmChangesModal } from '@/components/Elements';
import { InputField, SelectField } from '@/components/Form';
// import { useResetPassword } from '@/features/users';
// import { useDisclosure } from '@/hooks/useDisclosure';
import { useOrganizations } from '@/hooks/useOrganizations';
import { ROLES, ROLES_SELECT } from '@/lib/authorization';
import { useClientStore } from '@/stores/client';
import { useLeavePageStore } from '@/stores/leavePage';
import { formatDateFull } from '@/utils/format';

import { useDeleteUser } from '../api/deleteUser';
import { useUser } from '../api/getUserByEmail';
import { useUsers } from '../api/getUsers';
import { useUpdateUser } from '../api/updateUser';

// import { ResetPasswordModal } from './ResetPasswordModal';

export const UpdateUser = () => {
  const { userEmail } = useParams();
  const navigate = useNavigate();
  const userInfo = useAuthenticator((context) => [context.user]);
  const userEmailAttribute = userInfo?.user?.attributes?.email;

  // const { close: closeDelete, isOpen: isOpenDelete, open: openDelete } = useDisclosure();

  // const { mutateAsync: mutateResetPassword } = useResetPassword();

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useUser({ email: userEmail });

  const { data: orgData, isLoading: isLoadingOrg } = useOrganizations({});

  const { refetch: refetchUsers, isRefetching: refetchingUsers } = useUsers();

  const {
    mutateAsync: mutateAsyncUser,
    isSuccess: mutateAsyncUserSuccess,
    isLoading: isLoadingUpdateUser,
  } = useUpdateUser();

  const { mutateAsync: mutateDeleteAsyncUser, isLoading: isLoadingDeleteUser } = useDeleteUser();

  const { setBlocker, removeBlocker } = useLeavePageStore();

  const {
    handleSubmit,
    formState: { isDirty, errors, isValid, dirtyFields },
    getValues,
    reset,
    register,
  } = useForm({ mode: 'all' });

  // filter by active options
  const orgDataMemo = useMemo(
    () =>
      orgData?.length
        ? orgData.map(({ name: label, organization_id: value, ...rest }) => ({
            label,
            value,
            ...rest,
          }))
        : [],
    [orgData]
  );

  const initValues = useMemo(() => {
    if (userData) {
      return {
        ...userData,
        custom_role: userData.custom_role ? Number(userData.custom_role) : Number(userData.profile),
      };
    }
    return userData;
  }, [userData]);

  useEffect(() => {
    reset(initValues);
  }, [initValues, reset]);

  useEffect(() => {
    if (isDirty) setBlocker();
  }, [isDirty, setBlocker]);

  const checkChanges = (objChange: any, key: string) => {
    if (key === 'custom_role') return ROLES[objChange[key]];
    if (key === 'custom_organization')
      return orgDataMemo.filter((x: any) => x.value === objChange[key])?.[0]?.label;
    return objChange[key];
  };

  const labels: { [x: string]: string } = {
    name: 'Name',
    email: 'Email Address',
    custom_role: 'Role',
    custom_organization: 'Organization',
  };

  const adminRoleOptions = ROLES_SELECT.filter(
    (role) =>
      role.value === ROLES.ADMIN || role.value === ROLES.CLIENT_ADMIN || role.value === ROLES.USER
  );
  const clientAdminRoleOptions = ROLES_SELECT.filter(
    (role) => role.value === ROLES.CLIENT_ADMIN || role.value === ROLES.USER
  );
  const userRoleOptions = ROLES_SELECT.filter((role) => role.value === ROLES.USER);

  const [roleOptions, setRoleOptions] = useState(
    ROLES_SELECT.filter((role) => role.value === ROLES.USER)
  );
  const { clients } = useClientStore();
  const selectedClient = clients.filter((client) => client.client_selected);

  useEffect(() => {
    if (selectedClient[0].role_id === '5') {
      setRoleOptions(adminRoleOptions);
    } else if (selectedClient[0].role_id === '0') {
      setRoleOptions(clientAdminRoleOptions);
    } else if (selectedClient[0].role_id === '8') {
      setRoleOptions(userRoleOptions);
    }
  }, [clients]);

  const onUpdateUser = useCallback(
    (data) => {
      const { custom_role, name, custom_organization } = data;
      mutateAsyncUser({
        email: userEmail,
        custom_role,
        name,
        custom_organization,
      }).then(() => {
        refetchUser();
        removeBlocker();
      });
    },
    [userEmail, mutateAsyncUser, refetchUser, removeBlocker]
  );

  const onDeleteUser = useCallback(() => {
    removeBlocker();
    mutateDeleteAsyncUser(userEmail)
      .then(() => {
        refetchUsers().then(() => {
          navigate('/app/users');
        });
      })
      .catch(() => setBlocker());
  }, [removeBlocker, mutateDeleteAsyncUser, userEmail, refetchUsers, navigate, setBlocker]);

  // const onResetPassword = useCallback(() => {
  //   if (!isOpenDelete) {
  //     openDelete();
  //   } else {
  //     removeBlocker();
  //     const email = userEmail;
  //     mutateResetPassword({ email })
  //       .then(() => {
  //         closeDelete();
  //       })
  //       .catch((error) => {
  //         console.error('Error sending password reset email:', error);
  //       });
  //   }
  // }, [closeDelete, isOpenDelete, openDelete, removeBlocker, userEmail, mutateResetPassword]);

  if (isLoadingUser || isLoadingOrg) return <Spinner />;

  return (
    <div className="update-user container mb-40">
      {/* <ResetPasswordModal isOpen={isOpenDelete} close={closeDelete} onClick={onResetPassword} /> */}
      <span className="user-photo"></span>

      <form onSubmit={handleSubmit(onUpdateUser)}>
        <InputField
          registration={register('name', { required: "Please fill in user's full name" })}
          error={errors.name}
          label="Name"
          horizontal
          bold
          size="full"
          grid
        />
        <p className="bold email">
          <span className="label">Email Address</span>
          <span className="value">{userEmail}</span>
        </p>

        {/* <div className="last-login my-[30px]">
          <p>
            Password
            <span className="password">*************</span>
            <button className="reset-password" onClick={onResetPassword}>
              RESET PASSWORD
            </button>
          </p>
        </div> */}
        <SelectField
          label="Permissions"
          registration={register('custom_role', { required: false })}
          options={roleOptions}
          horizontal
          size="full"
          grid
        />
        <div className="last-login  my-[30px]">
          <p>
            Last Login <span>{userData && formatDateFull(userData.UserLastModifiedDate)}</span>
          </p>
        </div>

        <div className="update-user-actions">
          {userEmailAttribute && userEmailAttribute !== userEmail && (
            <ConfirmationDialog
              title="Delete User?"
              cancelButtonText="Go back"
              body="If you proceed with deleting this user profile, all data from this account will be deleted from the GrowthCatalyst database and cannot be restored. You will have 24 hours to contact the system administrator to reactivate this account, if you change your mind. Please confirm you understand and agree to the removal of this account."
              triggerButton={
                <Button type="button" variant="danger">
                  Delete user
                </Button>
              }
              confirmButton={
                <Button
                  type="button"
                  variant="danger"
                  onClick={onDeleteUser}
                  disabled={isLoadingDeleteUser}
                  isLoading={isLoadingDeleteUser || refetchingUsers}
                >
                  I understand, delete this user
                </Button>
              }
            />
          )}
          <ConfirmChangesModal
            initValues={initValues}
            currentValues={getValues()}
            dirtyFields={dirtyFields}
            labels={labels}
            triggerDisabled={!isDirty}
            confirmOnClick={handleSubmit(onUpdateUser)}
            confirmDisabled={!isValid || isLoadingUpdateUser}
            confirmIsLoading={isLoadingUpdateUser}
            isDone={mutateAsyncUserSuccess}
            handle={handleSubmit}
            customChanges={checkChanges}
          />
        </div>
      </form>
    </div>
  );
};
