import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { ITeamAssessments, TeamAssessmentType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getTeamAssessments = () => {
  return axios
    .get('/stats/dropdowns/teams')
    .then((data: AxiosResponse | ITeamAssessments): TeamAssessmentType[] => {
      return (data as ITeamAssessments)?.responseObj || [];
    });
};

type QueryFnType = typeof getTeamAssessments;

export const useTeamAssessments = (config?: QueryConfig<QueryFnType>) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['teamAssessments'],
    queryFn: () => getTeamAssessments(),
  });
};
