import { useMemo, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Spinner, LabelState } from '@/components/Elements';
import { SelectField } from '@/components/Form';
import {
  CALENDAR_TYPES,
  ASSESSMENT_PHASE,
  GeneralSettingsType,
  AssessmentTakeBaseType,
  useAdminCloseAssessment,
} from '@/features/assessments';
import { useTeams } from '@/features/teams';
import { useDisclosure } from '@/hooks/useDisclosure';
import { formatSelectData } from '@/utils/format';

import { AdminCloseDialog } from '../dialogs/AdminCloseDialog';

import { CalendarSettings } from './CalendarSettings';
import { MemberList } from './MemberList';

interface IGeneralSettings extends UseFormReturn<FieldValues> {
  generalSettings?: GeneralSettingsType;
  assessmentTakes?: AssessmentTakeBaseType[];
  assessmentName?: string;
  assessmentID?: string;
  editAssessmentPublished?: boolean;
  launchDisabled?: boolean;
  dueDisabled?: boolean;
  isBlank?: boolean;
  adminClosed?: boolean;
}
export const GeneralSettings = (props: IGeneralSettings) => {
  const { data: teamsData, isLoading: teamsLoading } = useTeams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const phaseParam = searchParams.get('phase') as ASSESSMENT_PHASE;
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    editAssessmentPublished,
    assessmentName,
    assessmentID,
    generalSettings,
    assessmentTakes,
    isBlank,
    adminClosed,
  } = props;
  const [showMarkAsComplete, setShowMarkAsComplete] = useState(false);
  const { mutate: closeAssessment, isLoading: isClosing } = useAdminCloseAssessment();

  const {
    open: openAdminCloseDialog,
    close: closeAdminCloseDialog,
    isOpen: isAdminCloseDialogOpen,
  } = useDisclosure();

  //  ----------------------- calendar logic starts ---------------------------------------
  const fixInvalidDates = (field?: string) => {
    if (field && field !== 'Invalid Date') {
      return new Date(field);
    }
    return new Date();
  };

  const watchFields = watch(['general_settings.launch_date', 'general_settings.due_date']);
  const { launchDate, dueDate } = useMemo((): { [key: string]: Date } => {
    const launchDateResult = fixInvalidDates(watchFields[0]);
    const dueDateResult = fixInvalidDates(watchFields[1]);

    return {
      launchDate: launchDateResult,
      dueDate: launchDateResult > dueDateResult ? launchDateResult : dueDateResult,
    };
  }, [watchFields]);
  //  ----------------------- calendar logic ends ---------------------------------------

  const teamsOptions = useMemo(() => {
    return formatSelectData(teamsData, 'team_name', 'team_id');
  }, [teamsData]);

  const teamName = useMemo(() => {
    const teamLabel = teamsOptions.filter((team) => team.value === generalSettings?.team_id);

    if (teamLabel.length > 0) {
      return teamLabel[0].label;
    } else {
      return '';
    }
  }, [generalSettings?.team_id, teamsOptions]);

  const handleMoreButtonClick = () => {
    setShowMarkAsComplete(!showMarkAsComplete);
  };

  const handleMarkAsCompleteClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    openAdminCloseDialog();
  };

  const handleAdminCloseConfirm = () => {
    if (assessmentID) {
      closeAssessment(assessmentID, {
        onSuccess: () => {
          closeAdminCloseDialog();
          navigate(-1);
        },
        onError: (error) => {
          console.error(error);
        },
      });
    }
  };

  return (
    <div className="settings__container">
      {editAssessmentPublished && assessmentName && phaseParam && (
        <div className="settings__header">
          <p>{assessmentName}</p>
          <LabelState phase={phaseParam} adminClosed={adminClosed} />
          {!adminClosed && phaseParam !== 'CLOSED' && (
            <button type="button" className="more-button" onClick={handleMoreButtonClick}>
              ...
            </button>
          )}
          {showMarkAsComplete && (
            <button
              className="mark-complete-button"
              onClick={handleMarkAsCompleteClick}
              disabled={isClosing}
            >
              Mark as Complete
            </button>
          )}
        </div>
      )}
      <div className="settings__options">
        <div className="settings__calendar">
          <CalendarSettings
            label={'Launch Date'}
            name={CALENDAR_TYPES.LAUNCH_DATE}
            registration={register(`general_settings.launch_date`, {
              required: `Please select a date`,
            })}
            setValue={setValue}
            value={launchDate}
          />
          <CalendarSettings
            label={'Due Date'}
            name={CALENDAR_TYPES.DUE_DATE}
            registration={register(`general_settings.due_date`, {
              required: `Please select a date`,
            })}
            value={dueDate}
            setValue={setValue}
            minDate={launchDate}
          />
        </div>
        <div className="settings__dropdowns">
          {!editAssessmentPublished &&
            (teamsLoading ? (
              <Spinner />
            ) : (
              <SelectField
                label="Team"
                registration={register('general_settings.team_id', {
                  required: 'Please select a team',
                })}
                horizontal={true}
                size="md"
                error={errors && errors.general_settings?.team}
                options={teamsOptions}
              />
            ))}
          {editAssessmentPublished && (
            <>
              <p className="bold option-label">
                Team <span>{teamName}</span>
              </p>

              <input hidden {...register(`general_settings.team_id`)} />
            </>
          )}
        </div>
      </div>
      <div className="settings__members">
        {(!isBlank ? generalSettings : !!teamsOptions?.[0]?.value) && (
          <MemberList
            {...props}
            editPublished={editAssessmentPublished}
            assessmentTakes={assessmentTakes}
            defaultTeamID={generalSettings?.team_id || teamsOptions?.[0]?.value}
          />
        )}
      </div>
      <AdminCloseDialog
        isOpen={isAdminCloseDialogOpen}
        close={closeAdminCloseDialog}
        onAdminClose={handleAdminCloseConfirm}
        adminCloseLoading={isClosing}
        adminClose={false}
      />
    </div>
  );
};
