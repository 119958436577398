import { zodResolver } from '@hookform/resolvers/zod';
import { useState, useEffect, useContext, useCallback } from 'react';
import { useForm, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import * as zod from 'zod';

import { DropdownRole, Spinner, IOptionRole } from '@/components/Elements';
import { ListContext, MemberAdd, MemberType } from '@/features/teams';
// eslint-disable-next-line no-restricted-imports
import { useUpdateUserInTeam } from '@/features/teams/api/updateUserInTeam';
import { useUser, useUsers } from '@/features/users';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useTeamMemberListReturnType } from '@/hooks/useTeamMemberList';
import { getRoleLabel } from '@/lib/authorization';
import { useLeavePageStore } from '@/stores/leavePage';
import { useNotificationStore } from '@/stores/notifications';

import { useTeamRolesStore } from '../../stores/teamRoles';
import { AddNewRoleDialog } from '../dialogs/AddNewRole';
import { RemoveMemberDialog } from '../dialogs/RemoveMemberDialog';

export const MemberList = ({
  members,
  deleteMember,
  addMember,
  teamID,
  teamData,
}: Pick<
  useTeamMemberListReturnType,
  'members' | 'deleteMember' | 'addMember' | 'teamID' | 'teamData'
> & {
  showHeading?: boolean;
}) => {
  const { data: usersData } = useUsers();
  const { editState } = useContext(ListContext);
  const [regexEmails, setRegexEmails] = useState<string>(' ');
  const [stateEmail, setStateEmail] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stateName, setStateName] = useState<string>('');
  const [stateRole, setStateRole] = useState<string>();
  const [newRoleTitle, setNewRoleTitle] = useState('');
  const [addingMember, setAddingMember] = useState(false);
  const { addNotification } = useNotificationStore();
  const { setBlocker } = useLeavePageStore();
  const [dropdownOptions] = useState<IOptionRole[]>([]);
  const { teamRoles, getRoles, addRole } = useTeamRolesStore();
  const [selectedValues, setSelectedValues] = useState<Record<string, IOptionRole | undefined>>({});

  const [dropdownStates, setDropdownStates] = useState<Record<string, boolean>>({});

  const [editInputDisabled, setEditInputDisabled] = useState(false);
  const { mutateAsync: mutateAsyncUser } = useUpdateUserInTeam();

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    setEditInputDisabled(editState !== undefined ? !editState : false);
  }, [editState]);

  const addMembersValidations = zod.object({
    name: zod.string().nonempty(),
    email: zod
      .string()
      .email()
      .refine(
        (email) => {
          return !members.some((member) => member.email === email);
        },
        {
          message: 'This email already exists',
        }
      ),
    role: zod.string().refine((role) => role !== 'Select Role', {
      message: 'Please Select Role',
    }),
  });

  const {
    handleSubmit,
    formState: { isDirty, errors },
    reset,
    register,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(addMembersValidations),
  });

  const { refetch, isLoading, isFetching } = useUser({
    email: stateEmail,
    config: { enabled: false },
  });

  const {
    open: openRemoveModal,
    close: closeRemoveModal,
    isOpen: isOpenRemoveModal,
  } = useDisclosure();

  const {
    open: openAddNewRoleModal,
    close: closeAddNewRoleModal,
    isOpen: isOpenAddNewRoleModal,
  } = useDisclosure();

  const setRole = useCallback(
    (role: string) => {
      setValue('role', role, { shouldValidate: true, shouldDirty: true });
    },
    [setValue]
  );

  const setSelected = useCallback((email: string, value: IOptionRole) => {
    setSelectedValues((prevValues) => ({ ...prevValues, [email]: value }));
  }, []);

  const getDropdownOptionsForEmail = useCallback(
    (email: string) => {
      const newDropdownOptions: IOptionRole[] = [...dropdownOptions];

      teamRoles.forEach((role) => {
        const exists = newDropdownOptions.find((option) => option.selectedLabel === role.value);

        if (!exists) {
          const label = role.name || '';
          newDropdownOptions.push({
            label,
            selectedLabel: role.value,
            //system set to true for all roles in MemeberList, so the delete icon won't show
            system: true,
            onClick: () => {
              setSelected(email, {
                label,
              });
              setRole(label);
            },
          });
        }
      });

      return newDropdownOptions;
    },
    [teamRoles, dropdownOptions, newRoleTitle]
  );

  /*
   * set the restriction to ask user before take him to another page
   */
  useEffect(() => {
    if (isDirty) setBlocker();
  }, [isDirty, setBlocker]);

  /*
   * set the initial value for 'regexEmails' state to know the 'already exist' emails
   */
  useEffect(() => {
    let tempRegex = ' ';
    if (members.length > 0 && regexEmails.length === 1) {
      members.map(({ email }) => {
        tempRegex = `${tempRegex}|${email}`;
      });
      setRegexEmails(tempRegex);
    }
  }, [members, regexEmails]);

  const onRemoveMember = useCallback(
    (role, email) => {
      setStateRole(role);
      setStateEmail(email);
      setRegexEmails(regexEmails.replace(`|${email}`, ''));
      openRemoveModal();
    },
    [openRemoveModal, regexEmails]
  );

  const removeMember = useCallback(() => {
    setRegexEmails(regexEmails.replace(`|${stateEmail}`, ''));
    deleteMember(stateEmail, String(stateRole));
    clearErrors();
    closeRemoveModal();
  }, [regexEmails, stateEmail, deleteMember, stateRole, clearErrors, closeRemoveModal]);

  const addNewRole = useCallback(
    (newRoleTitle) => {
      setNewRoleTitle(newRoleTitle);
      setStateRole(newRoleTitle);
      addRole({ name: newRoleTitle });
      closeAddNewRoleModal();
    },
    [closeAddNewRoleModal]
  );

  const setOpen = useCallback((email: string) => {
    setDropdownStates((prevStates) => ({ ...prevStates, [email]: !prevStates[email] }));
  }, []);

  const onSubmit = useCallback(
    ({ email, role, name }: { email: string; role: string; name: string }) => {
      //Prevent adding to the team without selecting a proper role
      if (!role || role === 'Select Role') {
        addNotification({
          type: 'error',
          title: 'Invalid Role',
          message: 'Please select a valid role before submitting.',
        });
        return;
      }

      setAddingMember(true);
      new Promise((resolve: any) => {
        setStateEmail(email);
        setStateName(name);
        setStateRole(role);
        resolve();
      })
        .then(() => {
          const emailExists = usersData?.some((user) => user.email === email);
          if (!emailExists) {
            addNotification({
              type: 'error',
              title: 'User not found',
              message: 'Please invite them to create an account through Manage All Users',
            });
            setAddingMember(false);
            return;
          }
          addMember({ email, role, name });
        })
        .then(() => {
          refetch();
        })
        .then(() => {
          setAddingMember(false);
          reset();
          clearErrors();
        })
        .catch(() => setAddingMember(false));
    },
    [clearErrors, refetch, reset, usersData, addNotification, addMember]
  );

  const handleRoleUpdate = async (email: string, newRole: string) => {
    if (teamID && teamData) {
      try {
        mutateAsyncUser({
          teamID: teamID,
          userEmail: email,
          data: { team_role: newRole },
        });
      } catch (error) {
        console.error('Error updating user role:', error);
      }
    }
  };

  return (
    <>
      <RemoveMemberDialog
        isOpen={isOpenRemoveModal}
        close={closeRemoveModal}
        onRemove={removeMember}
      />
      <AddNewRoleDialog
        isOpen={isOpenAddNewRoleModal}
        close={closeAddNewRoleModal}
        onAdd={addNewRole}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h5>Team Members</h5>
          <table className="team-members-table">
            <thead className="">
              <tr>
                <th className="body-4 bold" colSpan={2}>
                  Full Name
                </th>
                <th className="body-4 bold">Email</th>
                <th className="body-4 bold">Role</th>
              </tr>
            </thead>
            <tbody>
              {editState && (
                <MemberAdd
                  newRoleTitle={newRoleTitle}
                  register={
                    register as unknown as UseFormRegister<{
                      name: string;
                      email: string;
                      role: string;
                      roleId: string;
                    }>
                  }
                  loading={addingMember}
                  errors={errors}
                  watch={
                    watch as UseFormWatch<{
                      name: string;
                      email: string;
                      role: string;
                      roleId: string;
                    }>
                  }
                  setValue={
                    setValue as unknown as UseFormSetValue<{
                      name: string;
                      email: string;
                      role: string;
                      roleId: string;
                    }>
                  }
                  openNewRoleModal={openAddNewRoleModal}
                />
              )}
              {members &&
                members.map(({ email, role, name }: MemberType, key: number) => {
                  const optionsForThisEmail = getDropdownOptionsForEmail(email);
                  return (
                    <tr key={key} className="team-members">
                      <td className="team-members__photo-container">
                        <span className="team-members__photo"></span>
                      </td>
                      <td className="team-members__info">
                        <span className="body-3-italic body-3">{name}</span>
                      </td>
                      <td>
                        <span className="team-members__email">{email}</span>
                      </td>
                      {editState ? (
                        <td>
                          <DropdownRole
                            options={optionsForThisEmail}
                            open={dropdownStates[email] || false}
                            disabled={editInputDisabled}
                            setOpen={() => setOpen(email)}
                            selectedValue={selectedValues[email]}
                            placeholder={role || ''}
                            registration={register('role')}
                            onChange={(option) => handleRoleUpdate(email, option.label!)}
                          />{' '}
                        </td>
                      ) : (
                        <td>{role && getRoleLabel(role)}</td>
                      )}
                      <td>
                        <button
                          className="team-members__remove body-3"
                          type="button"
                          disabled={editState !== undefined ? !editState : false}
                          onClick={() => onRemoveMember(role, email)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="member-list__loading">{(isFetching || isLoading) && <Spinner />}</div>
        </div>
      </form>
    </>
  );
};
