import { ChatModel } from '@/features/chat';

export interface PostMentorChatPayloadType {
  message: string;
}

export type PostMentorChatResponseType = ChatModel;

export interface PostMentorChatStore {
  chatPost?: ChatModel;
  isLoading?: boolean;
  isError?: boolean;
  setPostChatSession: (props: PostMentorChatPayloadType) => void;
}

export const POST_MENTOR_CHAT_API = 'mentor/chats';
