import create from 'zustand';

import {
  useDeleteMentorChat,
  DeleteMentorChatPayloadType,
  DeleteMentorChatStore,
  useChatMessagesStore,
} from '@/features/chat';

export const useDeleteMentorChatStore = () => {
  const { mutateAsync, isLoading, isError } = useDeleteMentorChat();
  const { chatHistories, setChatHistory, setChatMessages } = useChatMessagesStore();

  return create<DeleteMentorChatStore>(() => ({
    isLoading,
    isError,
    setDeleteChatSession: async (payload: DeleteMentorChatPayloadType) => {
      await mutateAsync({ ...payload });
      const newChatHistories = chatHistories?.filter(
        (item) => item.session_id !== payload.session_id
      );
      if (newChatHistories) {
        setChatHistory(newChatHistories);
        setChatMessages([]);
        localStorage.removeItem('chat-active-session-id');
        localStorage.removeItem('chat-active-session-name');
        localStorage.removeItem('chat-error-last-action');
      }
    },
  }));
};
