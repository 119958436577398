import { Button, Dialog, DialogTitle } from '@/components/Elements';

interface IResetPasswordModal {
  isOpen: boolean;
  onClick: () => void;
  close: () => void;
  loadingDelete?: boolean;
}

export const ResetPasswordModal = ({
  isOpen,
  onClick,
  close,
  loadingDelete,
}: IResetPasswordModal) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Reset Password?
          </DialogTitle>
          <div className="modal__info">
            <p>
              The user will be sent an email with a temporary one-time password, which expires in 24
              hours, and instructions to create a new password.
            </p>
          </div>
          <div className="builder__publish-modal-buttons">
            <Button type="button" onClick={close} variant="inverse">
              Go Back
            </Button>
            <Button onClick={onClick} isLoading={loadingDelete}>
              I understand, reset password
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
