import {
  CheckboxField,
  TextField,
  PhoneNumberField,
  PasswordField,
  FieldGroupIcon,
  Button as AmpButton,
} from '@aws-amplify/ui-react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useCallback } from 'react';

import { Button } from '@/components/Elements';
import { TopBanner } from '@/features/auth';
import { TermsConditions, Policy } from '@/features/misc/';
import { axios } from '@/lib/axios';
import { validEmail } from '@/utils/format';

import {
  useForceNewPassword,
  ONLY_NUMBERS_REGEX,
  PASSWORD_REGEX,
  NAME_REGEX,
} from '../validations/useForceNewPassword';

interface IForceNewPasswordViewProps {
  isPending: boolean;
  errorsObj: {
    confirm_password?: string;
  };
  userInfo: any;
  AlreadyHaveAccount: React.ReactChild;
}

export const ForceNewPasswordView = ({
  errorsObj,
  isPending,
  userInfo,
  AlreadyHaveAccount,
}: IForceNewPasswordViewProps) => {
  const { confirm_password } = errorsObj;
  const [openTerms, setOpenTerms] = useState<boolean>(false);
  const [openPolicy, setOpenPolicy] = useState<boolean>(false);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('+1');
  const [phoneNumberVerified, setPhoneNumberVerified] = useState<boolean>(false);
  const [smsCode, setSmsCode] = useState<string>('');
  const [sendBlock, setSendBlock] = useState<boolean>(false);
  const [smsCodeSent, setSmsCodeSent] = useState<boolean>(false);
  const [smsDisabled, setSmsDisabled] = useState<boolean>(false);
  const [smsCodeVerifiedFailed, setSmsCodeVerifiedFailed] = useState<boolean>(false);
  const [ERRORS, formErrors, validateField, modifyFormErrorState] = useForceNewPassword();

  const onOpenTerms = useCallback((open: boolean) => {
    open && document.body.classList.add('fixed');
    !open && document.body.classList.remove('fixed');
    setOpenTerms(open);
  }, []);

  const onOpenPolicy = useCallback((open: boolean) => {
    open && document.body.classList.add('fixed');
    !open && document.body.classList.remove('fixed');
    setOpenPolicy(open);
  }, []);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    validateField('name', new RegExp(NAME_REGEX).test(name) && name !== '', ERRORS.name);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    validateField(
      'password',
      new RegExp(PASSWORD_REGEX).test(target.value) && target.value.length > 7,
      ERRORS.password
    );
  };

  return (
    <>
      {openTerms && (
        <div className="auth-force-terms-container">
          <TopBanner />
          <div className="auth-force-terms-content">
            <TermsConditions
              bArrowProps={{
                onClick: () => {
                  onOpenTerms(false);
                },
              }}
              onAcceptTerms={(accept: boolean, open: boolean) => {
                setTermsChecked(accept);
                validateField('custom:acknowledgement', accept, ERRORS.required);
                onOpenTerms(open);
              }}
            />
          </div>
        </div>
      )}
      {openPolicy && (
        <div className="auth-force-terms-container">
          <TopBanner />
          <div className="auth-force-terms-content">
            <Policy
              bArrowProps={{
                onClick: () => {
                  onOpenPolicy(false);
                },
              }}
              onAccept={(accept: boolean, open: boolean) => {
                setTermsChecked(accept);
                validateField('custom:acknowledgement', accept, ERRORS.required);
                onOpenPolicy(open);
              }}
            />
          </div>
        </div>
      )}
      <div className="force-new-password-form">
        <div className="force-new-password-header">
          <h3>Welcome!</h3>
          <p>Your learning journey starts here</p>
        </div>
        <TextField
          name="name"
          label="Full Name"
          required={true}
          errorMessage={formErrors.name?.message}
          hasError={!!formErrors.name?.message}
          onBlur={onNameChange}
          onChange={onNameChange}
        />
        <PasswordField
          className="password-field"
          name="password"
          label="Password"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          autocomplete="off"
          required={true}
          errorMessage={formErrors.password?.message}
          hasError={!!formErrors.password?.message}
          pattern={PASSWORD_REGEX}
          min={8}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onPasswordChange(e);
          }}
          onBlur={onPasswordChange}
        ></PasswordField>
        <p className="password-disclaimer">
          Must be at least 8 characters, and contain at least 1 lowercase letter, 1 uppercase
          letter, 1 number, and 1 special character. <b>Spaces are not allowed.</b>
        </p>
        <PasswordField
          name="confirm_password"
          label="Confirm Password"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          autocomplete="off"
          required={true}
          errorMessage={confirm_password as string}
          hasError={!!confirm_password}
          onChange={() => {
            modifyFormErrorState('remove', 'confirm_password');
          }}
          onBlur={() => {
            modifyFormErrorState('remove', 'confirm_password');
          }}
        />
        <PhoneNumberField
          className="phone-number-field"
          name="phone_number"
          label="Phone Number"
          required={true}
          onCountryCodeChange={(e) => {
            setCountryCode(e.target.value);
          }}
          defaultCountryCode={countryCode}
          countryCodeName="country_code"
          pattern="[0-9]+"
          value={phoneNumber}
          errorMessage={formErrors.phone_number?.message}
          hasError={!!formErrors.phone_number?.message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const number = e.target.value;

            setPhoneNumberVerified(false);
            setSmsCode('');
            setSmsCodeVerifiedFailed(false);
            validateField('custom:verification_code', true, ERRORS.sms_code);

            if (number === '' || ONLY_NUMBERS_REGEX.test(number)) {
              setPhoneNumber(number);
              validateField('phone_number', number !== '', ERRORS.required);
              setPhoneNumberVerified(false);
            }

            setTimeout(() => {
              if (number.length >= 7) {
                setSmsDisabled(false);
                setSmsCodeSent(false);
              } else {
                setSmsDisabled(true);
                setSmsCodeSent(false);
              }
            }, 200);
          }}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            validateField('phone_number', e.target.value !== '', ERRORS.required);
            validateField('phone_number', e.target.value.length >= 7, ERRORS.phone_number_length);
          }}
          outerEndComponent={
            <AmpButton
              disabled={phoneNumber.length < 7 || sendBlock || phoneNumberVerified}
              className="amplify-button-send"
              onClick={() => {
                const body = {
                  email: userInfo.challengeParam.userAttributes.email,
                  Username: userInfo.username,
                  phone_number: countryCode + phoneNumber,
                };
                setSmsCode('');
                setPhoneNumberVerified(false);
                validateField('custom:verification_code', true, ERRORS.sms_code);
                axios
                  .post(`/users/${body.email}/sendcode`, body)
                  .then(() => {
                    console.log('CODE SENT');
                    setSendBlock(true);
                    setTimeout(() => {
                      setSendBlock(false);
                    }, 5000);
                    setSmsCodeSent(true);
                  })
                  .catch((e) => {
                    console.log(e);
                    console.log('CODE SENT FAILED');
                  });
              }}
            >
              SEND CODE
            </AmpButton>
          }
        />
        <p className="phone-number-disclaimer">
          We will send you an SMS code. SMS Msg &amp; Data rates may apply.
        </p>
        <div>
          {smsCodeSent && (
            <p className="sms-code-sent-text">
              An access code has been sent to the your phone number.{' '}
              <span className="sms-bold-notice"> It will expire in 10 minutes.</span>
            </p>
          )}
        </div>

        <TextField
          name="custom:verification_code"
          label="SMS Code"
          labelHidden
          placeholder="SMS Code"
          errorMessage={formErrors['custom:verification_code']?.message}
          hasError={!!formErrors['custom:verification_code']?.message}
          required={true}
          disabled={phoneNumber.length < 7 || phoneNumberVerified || smsDisabled || !smsCodeSent}
          value={smsCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const sms = e.target.value;

            if (sms === '' || ONLY_NUMBERS_REGEX.test(sms)) {
              setSmsCode(sms);
              validateField('custom:verification_code', true, ERRORS.sms_code);
            }

            setTimeout(() => {
              if (sms.length === 6) {
                const body = {
                  email: userInfo.challengeParam.userAttributes.email,
                  Username: userInfo.username,
                  code: sms,
                  phone_number: countryCode + phoneNumber,
                };
                setSmsDisabled(true);
                axios
                  .post(`/users/${body.email}/confirmcode`, body)
                  .then(() => {
                    console.log('CODE CONFIRMED SUCCESS');
                    setPhoneNumberVerified(true);
                    setSmsDisabled(false);
                    setSmsCodeVerifiedFailed(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    console.log('CODE CONFIRM FAILED');
                    validateField('custom:verification_code', false, ERRORS.sms_code);
                    setPhoneNumberVerified(false);
                    setSmsCodeVerifiedFailed(true);
                    setSmsDisabled(false);
                  });
              } else {
                setSmsCodeVerifiedFailed(false);
              }
            }, 200);
          }}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            const sms = e.target.value;
            if (sms.length === 6) {
              setSmsCodeSent(false);
              setSmsCodeVerifiedFailed(false);
            }
          }}
          innerEndComponent={
            <FieldGroupIcon ariaLabel="">
              {phoneNumberVerified && <FontAwesomeIcon icon={faCheckCircle} color="green" />}
              {smsCode.length === 6 && smsCodeVerifiedFailed && !phoneNumberVerified && (
                <FontAwesomeIcon icon={faTimesCircle} color="red" />
              )}
            </FieldGroupIcon>
          }
        />

        <TextField
          name="custom:alternate_email"
          label="Alternate Email (optional)"
          type="email"
          className={'display-none'}
          hasError={!!formErrors['custom:alternate_email']?.message}
          errorMessage={formErrors['custom:alternate_email']?.message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const email = e.target.value;
            validateField(
              'custom:alternate_email',
              validEmail(email) || email === '',
              ERRORS.email
            );
          }}
        />
        <CheckboxField
          name="custom:acknowledgement"
          value={'true'}
          checked={termsChecked}
          required={true}
          hasError={!!formErrors['custom:acknowledgement']?.message}
          errorMessage={formErrors['custom:acknowledgement']?.message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTermsChecked(e.target.checked);
            validateField('custom:acknowledgement', termsChecked === false, ERRORS.required);
          }}
          label={
            <p className="auth-checkbox-terms">
              I agree to the
              <button
                type="button"
                onClick={() => {
                  onOpenTerms(true);
                }}
              >
                Terms of Service
              </button>
              and
              <button
                type="button"
                onClick={() => {
                  setOpenPolicy(true);
                }}
              >
                Privacy Policy
              </button>
            </p>
          }
        />
        <Button
          isLoading={isPending}
          disabled={
            Object.keys(formErrors).length === 0 && Object.keys(errorsObj).length === 0
              ? false || !phoneNumberVerified
              : true
          }
          onClick={() => {}}
          type="submit"
        >
          Create account
        </Button>
        {AlreadyHaveAccount}
      </div>
    </>
  );
};
