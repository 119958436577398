import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { IndustryResponseType } from '../types';

interface IIndustries {
  responseObj: IndustryResponseType[];
}

export const getIndustries = () => {
  return axios
    .get<IIndustries>(`/industries`)
    .then((data: AxiosResponse | IIndustries): IndustryResponseType[] => {
      return (data as IIndustries)?.responseObj || [];
    });
};

type QueryFnType = typeof getIndustries;

type UseIndustriesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useIndustries = ({ config }: UseIndustriesOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['industries'],
    queryFn: () => getIndustries(),
  });
};
