import { Link, useNavigate } from 'react-router-dom';
import './backArrow.scss';

export interface IBackArrow {
  onClick?: () => void;
  toUrl?: string | boolean;
}

export const BackArrow = ({ onClick, toUrl }: IBackArrow) => {
  const navigate = useNavigate();

  if (typeof toUrl === 'string') {
    return <Link to={toUrl} className="back-arrow" replace />;
  }

  if (onClick) {
    return <button type="button" onClick={onClick} className="back-arrow" />;
  }

  if (toUrl) return <button type="button" onClick={() => navigate(-1)} className="back-arrow" />;

  return null;
};
