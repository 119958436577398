import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { IconType } from './textarea.type';

function countLines(textarea: HTMLTextAreaElement) {
  let buffer = null;

  if (buffer == null) {
    buffer = document.createElement('textarea');
    buffer.style.border = 'none';
    buffer.style.height = '0';
    buffer.style.overflow = 'hidden';
    buffer.style.padding = '0';
    buffer.style.position = 'absolute';
    buffer.style.left = '0';
    buffer.style.top = '0';
    buffer.style.zIndex = '-1';
    document.body.appendChild(buffer);
  }

  const cs = window.getComputedStyle(textarea);
  const pl = parseInt(cs.paddingLeft);
  const pr = parseInt(cs.paddingRight);
  let lh = parseInt(cs.lineHeight);

  // [cs.lineHeight] may return 'normal', which means line height = font size.
  if (isNaN(lh)) lh = parseInt(cs.fontSize);

  // Copy content width.
  buffer.style.width = textarea.clientWidth - pl - pr + 'px';

  // Copy text properties.
  buffer.style.font = cs.font;
  buffer.style.letterSpacing = cs.letterSpacing;
  buffer.style.whiteSpace = cs.whiteSpace;
  buffer.style.wordBreak = cs.wordBreak;
  buffer.style.wordSpacing = cs.wordSpacing;
  buffer.style.flexWrap = cs.flexWrap;

  // Copy value.
  buffer.value = textarea.value;

  let result = Math.floor(buffer.scrollHeight / lh);
  if (result == 0) result = 1;

  return result;
}

export interface TextAreaProps {
  placeholder?: string;
  value?: string;
  maxRows?: number;
  PreIcon?: IconType;
  PostIcon?: IconType;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
}

const defaultProps: TextAreaProps = {
  maxRows: 4,
};

export const TextArea = ({ PreIcon, PostIcon, maxRows, ...props }: TextAreaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [value, setValue] = useState<string>();
  const [numberOfRows, setNumberOfRows] = useState<number>(1);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (textareaRef && textareaRef.current && maxRows) {
      textareaRef.current.style.height = '0';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    props.onChange && props.onChange(e);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey && !e.ctrlKey) {
        e.preventDefault();
      }
    }
    props.onKeyDown && props.onKeyDown(e);
  };

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const lines = countLines(e.target);
    setNumberOfRows(lines);
  };

  return (
    <div className="textarea-container">
      {PreIcon && (
        <span className="pre-icon flex whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200">
          <PreIcon />
        </span>
      )}
      <textarea
        {...props}
        ref={textareaRef}
        className={`${maxRows && numberOfRows >= maxRows ? ' with-y-scroll' : ''} ${
          numberOfRows > 1 ? ' with-normal-styling' : ''
        } ${PreIcon ? ' with-pre-icon' : ''} ${PostIcon ? ' with-post-icon' : ''} ${
          PreIcon && !PostIcon ? 'right-radius' : ''
        } ${!PreIcon && PostIcon ? 'left-radius' : ''}
        relative m-0 block w-[1px] min-w-0 flex-auto border border-solid border-neutral-300 rounded bg-transparent text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200`}
        value={value}
        style={{ maxHeight: maxRows && maxRows * 25 }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onInput={handleInput}
      />
      {PostIcon && (
        <span className="post-icon flex whitespace-nowrap rounded-l border border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200">
          <PostIcon />
        </span>
      )}
    </div>
  );
};

TextArea.defaultProps = defaultProps;

export default TextArea;
