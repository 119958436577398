import { useMutation } from 'react-query';

import { TeamResponseType } from '@/features/teams';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export const deleteTeam = (teamID: string): Promise<TeamResponseType> => {
  return axios.delete(`/teams/${teamID}`).then((data: any) => data.responseObj);
};

type UseDeleteTeamOptions = {
  config?: MutationConfig<typeof deleteTeam>;
};

export const useDeleteTeam = ({ config }: UseDeleteTeamOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      console.log('context', context);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('teams');
      addNotification({
        type: 'success',
        title: 'Team successfully deleted!',
      });
    },
    ...config,
    mutationFn: deleteTeam,
  });
};
