import ventureverseCardImageWidget from '@/assets/dashboard/ventureverse-card-image-widget.png';
import { Card } from '@/components';
import CardContent from '@/components/card/card-content';
import { Button } from '@/components/Elements';

export interface VentureverseWidgetProps {
  className?: string;
}

export const VentureverseWidget = (props: VentureverseWidgetProps) => {
  return (
    <Card
      {...props}
      outerHeader={`Recommended for you`}
      image={{ file: <img src={ventureverseCardImageWidget} alt="" /> }}
      actions={
        <Button type="button" size="sm" className="disabled">
          Coming soon
        </Button>
      }
    >
      <CardContent>
        <div className="widget-wrapper">
          <div className="title">
            VentureVerse: Sharpen your entrepreneurial skills in the startup battleground
          </div>
          <p>
            VentureVerse invites you to an AI-generated realm where entrepreneurial skills are king.
            Face a variety of market challenges, adapt to shifting customer needs, and steer your
            virtual venture to product-market fit. Every game is a unique expedition, putting your
            strategies to the test in an unpredictable world of business.
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default VentureverseWidget;
