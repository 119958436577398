import { useNavigate } from 'react-router-dom';

import { ReactComponent as RobotGradientIcon } from '@/assets/icons/robot-gradient.icon.svg';
import { Card } from '@/components';
import CardContent from '@/components/card/card-content';
import { Button } from '@/components/Elements';

export const GrowthMentorWidget = () => {
  const navigate = useNavigate();

  return (
    <Card
      icon={<RobotGradientIcon className="robot-icon" />}
      actions={
        <Button type="button" size="sm" onClick={() => navigate('/app/growth-mentor')}>
          Start Your Growth Session
        </Button>
      }
    >
      <CardContent>
        <div className="widget-wrapper">
          <div className="title">
            GrowthMentor: Your digital ally in the entrepreneurial journey
          </div>
          <p>
            Looking for expert advice on your next startup decision? GrowthMentor is here to guide
            you with the collective intelligence, frameworks, and experiences of successful
            entrepreneurs and VCs. Get instant, tailored advice on everything from business strategy
            to product development to real-world startup learnings.
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default GrowthMentorWidget;
