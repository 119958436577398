import { Navigate } from 'react-router-dom';

import { Login, Demo, ConfirmationEmail } from '@/features/auth';

export const commonRoutes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/request-demo',
    element: <Demo />,
  },
  {
    path: '/conf-email',
    element: <ConfirmationEmail />,
  },
  { path: '*', element: <Navigate to="/app" /> },
];
