import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { SideMenuLink, ISideMenuLink } from './SideMenuLink';

export interface SideMenuCollapsibleProps {
  label: string;
  children?: ISideMenuLink[];
  to?: string;
  allowed?: boolean;
  active?: string;
  index?: number;
  pattern?: string;
  sectionTitle?: boolean;
  addPreSeparator?: boolean;
  Icon?: React.ElementType;
  setActive?: (x: string) => void;
}

export const SideMenuCollapsible = (props: SideMenuCollapsibleProps) => {
  const [expand, setExpand] = useState<boolean>(false);
  const onExpand = () => {
    setExpand(!expand);
  };

  return props.to ? (
    <>
      {props.addPreSeparator && <hr className="pre-separator" />}
      <NavLink
        key={props.label}
        to={props.to}
        className={clsx(!props.allowed && 'disabled', 'menu-item__link menu-item__label body-4')}
        activeClassName="active-link"
        onClick={() => props.setActive && props.setActive(props.label)}
      >
        {props.Icon && <props.Icon className="main-icon" />}
        {props.label}
      </NavLink>
    </>
  ) : props.sectionTitle ? (
    <>
      {props.addPreSeparator && <hr className="pre-separator" />}
      <div className="menu-item__section-title">
        <div className="menu-item__label body-4">
          {props.Icon && <props.Icon className="main-icon" />}
          <p>{props.label}</p>
        </div>
      </div>
    </>
  ) : (
    <div
      className={clsx(
        'menu-item',
        props.active === props.label && 'menu-item__active menu-item__expand',
        expand && 'menu-item__expand'
      )}
    >
      <div
        role={'button'}
        tabIndex={0}
        key={props.label}
        className={clsx(
          'menu-item__label body-4',
          !props.allowed && 'disabled',
          props.active === props.label && 'menu-item__active'
        )}
        onClick={onExpand}
        onKeyDown={onExpand}
      >
        {props.Icon && <props.Icon className="main-icon" />}
        <p>{props.label}</p>
        <div className="menu-item__label-icons">
          <ChevronDownIcon className="icon-chevron__down" />
          <ChevronUpIcon className="icon-chevron__up" />
        </div>
      </div>
      <div className="menu-item__content">
        {props.children &&
          props.children.map((item: ISideMenuLink) => (
            <SideMenuLink
              key={item.label}
              allowedParent={props.allowed}
              labelParent={props.label}
              setActive={props.setActive}
              siblings={props.children}
              to={item.to}
              label={item.label}
              allowed={item.allowed}
              isDefault={item.isDefault}
              active={item.active}
              setExpand={setExpand}
            />
          ))}
      </div>
    </div>
  );
};
