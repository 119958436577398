import { useAuthenticator } from '@aws-amplify/ui-react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import logo from '@/assets/sparkLogo.svg';
import { SelectField } from '@/components/Form';
import { SideNavigation, SideNavigationHeader } from '@/components/SideNavigation';
import { BaselineModal } from '@/features/assessments';
import { LeavePage } from '@/features/misc/';
import { useClientStore } from '@/stores/client';

import { useAssessmentsStore } from '../../features/assessments/stores/assessmentsStore';
import { useAssessmentTemplatesStore } from '../../features/assessments/stores/assessmentTemplateStore';
import { useClients } from '../../features/clients/api/getClients';
import { useUpdateUser } from '../../features/users/api/updateUser';
import { Header } from '../Header';

type MobileSidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }: MobileSidebarProps) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div
            className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white"
            style={{ marginBottom: '10px' }}
          >
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <SideNavigationHeader containerClasses={'flex-shrink-0 flex items-center px-4'} />
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                <SideNavigation />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

const Sidebar = () => {
  const { clients, addClients, selectClient, setWorkspaceChanging } = useClientStore();
  const { user } = useAuthenticator((context) => [context.user]);
  const { data: clientsData } = useClients();
  const { mutate: updateUser } = useUpdateUser();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<any>();
  const [clientOptions, setClientOptions] = useState<any>([]);
  const [adminOptions, setAdminOptions] = useState<any>();
  const [defaultClient, setDefaultClient] = useState<any>(null);
  const isAdmin = user?.attributes?.['custom:role'] === '5';
  const { getAssessments } = useAssessmentsStore();
  const { getAssessmentTemplates } = useAssessmentTemplatesStore();

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
      setUserInfo(user);
    });
  }, []);

  useEffect(() => {
    if (clientsData) {
      const formattedClients = clientsData.map((client) => ({
        client_id: client?.client_id || '',
        client_name: client?.client_name || '',
        business_area: client?.business_area || '',
        createdBy: client?.createdBy || '',
        createdOn: client?.createdOn || undefined,
        description: client?.description || '',
        industry_name: client?.industry_name || '',
        industry_id: client?.industry_id || '',
        modifiedBy: client?.modifiedBy || '',
        modifiedOn: client?.modifiedOn || undefined,
        organization_id: client.organization_id || '',
        role_id: client?.role_id || userInfo?.attributes['custom:role'],
        ...(client.client_selected && { client_selected: true }),
      }));

      addClients(formattedClients);
    }
  }, [clientsData, addClients]);

  const handleUpdateUser = async (email: string, clientId: string) =>
    updateUser({
      email: email,
      custom_client_id: clientId,
    });

  useEffect(() => {
    if (clients) {
      const options = clients?.map((client) => ({
        label: client.client_name,
        value: client.client_id || '',
        selected: client.client_selected || '',
      }));

      setClientOptions(options);

      const adminOpts = [
        ...options,
        {
          label: '+ Add a new client',
          value: 'addNewClient',
          selected: '',
        },
      ];

      setAdminOptions(adminOpts);
    }
  }, [clients]);

  const handleChangeClient = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const clientId = event.target.value;
    if (event.target.value === 'addNewClient') {
      navigate('/app/create-client');
    } else if (user?.attributes?.email) {
      setWorkspaceChanging(true);
      handleUpdateUser(user?.attributes?.email, clientId).then(() => {
        setTimeout(() => {
          selectClient({ client_id: clientId, client_selected: true });
          navigate('.', { replace: true, state: { refresh: true } });
          getAssessments().then(() => {
            setWorkspaceChanging(false);
            getAssessmentTemplates().then();
          });
        }, 500);
      });
    }
  };

  useEffect(() => {
    const defaultClientValue = clientOptions?.find(
      (option: { selected: any }) => option.selected
    )?.value;
    setDefaultClient(defaultClientValue);
  }, [clientOptions]);

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64 side-menu">
        <div className="flex flex-col h-0 flex-1">
          <SideNavigationHeader
            addRule={true}
            containerClasses={
              'flex gap-1 justify-center items-center flex-shrink-0 brand-container'
            }
          />
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 menu mt-4">
              <p className="client-workspace-heading">Client Workspace:</p>
              {defaultClient && (
                <SelectField
                  className="client-select"
                  label=""
                  registration={{}}
                  defaultValue={defaultClient}
                  options={isAdmin ? adminOptions : clientOptions}
                  placeholder="Select a client"
                  size="md"
                  onChange={handleChangeClient}
                />
              )}
              <SideNavigation />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <div className="h-screen flex overflow-hidden">
      <BaselineModal />
      <LeavePage />
      <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar />
      <div className="flex flex-col w-0 flex-1 overflow-hidden main-container">
        <Header />
        <main className="flex-1 relative px-16 overflow-y-auto focus:outline-none">{children}</main>
      </div>
    </div>
  );
};
