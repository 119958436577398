import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { UserResponseType } from '@/features/users';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

interface IMembersByTeam {
  responseObj: UserResponseType[];
}

export const getMembersByTeam = (teamID: string) => {
  return axios
    .get<IMembersByTeam>(`/teams/${teamID}/members`)
    .then((data: AxiosResponse | IMembersByTeam): UserResponseType[] => {
      return (data as IMembersByTeam).responseObj;
    })
    .catch(() => []);
};

type QueryFnType = typeof getMembersByTeam;

type useMembersByTeamOptions = {
  config?: QueryConfig<QueryFnType>;
  teamID: string;
};

export const useMembersByTeam = ({ config, teamID }: useMembersByTeamOptions = { teamID: '' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    onError: () => {},
    queryKey: ['getMembersByTeam'],
    queryFn: () => getMembersByTeam(teamID),
  });
};
