import { useCallback, useEffect, useMemo } from 'react';
import { FieldValues, UseFormReturn, useWatch } from 'react-hook-form';

import { ReactComponent as InfoIcon } from '@/assets/icons/infoIcon.svg';
import { Checkbox, Spinner } from '@/components/Elements';
import { ASSESSMENT_PHASE } from '@/features/assessments';
import { useMembersByTeam } from '@/features/teams';
import { UserResponseType } from '@/features/users';
import { getRoleLabel } from '@/lib/authorization';

import { AssessmentTakeBaseType, GeneralSettingsType } from '../../types';

type MemberOption = {
  email: string;
  role: number;
};

export type MemberItem = MemberOption & {
  name: string;
  observer?: boolean;
  reciever?: boolean;
};

interface IMemberList extends UseFormReturn<FieldValues> {
  generalSettings?: GeneralSettingsType;
  editPublished?: boolean;
  assessmentTakes?: AssessmentTakeBaseType[];
  defaultTeamID?: string;
}

//completation status
const assessmentStateConversion = (str: string) => {
  switch (str) {
    case ASSESSMENT_PHASE.NEW:
      return 'Not Started';
    case ASSESSMENT_PHASE.COMPLETED:
      return 'Finished';
    case ASSESSMENT_PHASE.IN_PROGRESS:
      return 'Started';
    case ASSESSMENT_PHASE.NOT_ASSIGNED:
      return 'Not Assigned';
    default:
      '';
  }
};

export const MemberList = (props: IMemberList) => {
  const {
    register,
    setValue,
    // setError,
    // clearErrors,
    control,
    formState: { errors },
    generalSettings,
    editPublished,
    assessmentTakes,
    defaultTeamID,
  } = props;

  const teamIDWatch = useWatch({
    control,
    name: 'general_settings.team_id',
  });

  const membersObserversWatch = useWatch({
    control,
    name: 'observers',
  });

  const membersRecieversWatch = useWatch({
    control,
    name: 'recievers',
  });
  // const watchMembers = useWatch({
  //   control,
  //   name: 'general_settings.members',
  // });

  const teamID = useMemo(() => {
    return teamIDWatch || defaultTeamID;
  }, [teamIDWatch, defaultTeamID]);

  const {
    data: membersData,
    refetch: refetchMembers,
    isRefetching: isRefetchingMembers,
  } = useMembersByTeam({
    teamID,
    config: {
      enabled: false,
    },
  });

  //return all the members to be populated under team member list
  const members = useMemo(() => {
    if (generalSettings?.team_id === teamIDWatch && generalSettings?.members_with_roles) {
      setValue(
        'observers',
        generalSettings?.members_with_roles.filter((m) => m.observer).map((m) => m.email)
      );
      setValue(
        'recievers',
        generalSettings?.members_with_roles.filter((m) => m.reciever).map((m) => m.email)
      );
    } else {
      setValue('observers', []);
      setValue('recievers', []);
    }
    if (assessmentTakes?.length) {
      return membersData?.map((member) => ({
        ...member,
        assessmentStatus: assessmentStateConversion(
          assessmentTakes.filter((take) => take.assigned_user_id === member.email)?.[0]
            ?.assessment_take_user_phase || ASSESSMENT_PHASE.NOT_ASSIGNED
        ),
      }));
    }

    return membersData;
  }, [assessmentTakes, membersData, teamID]);

  // const showNoCofoundersError = useCallback(
  //   (roleToAssesst: string) => {
  //     setError('general_settings.noCofounder', {
  //       message: `You must select at least one ${getRoleLabel(
  //         +roleToAssesst
  //       )} member to publish an assessment`,
  //     });
  //   },
  //   [setError]
  // );

  // const isCheckboxObserverDisabled = useCallback(
  //   (x: UserResponseType): boolean => {
  //     const existMember = generalSettings?.members_with_roles?.filter(
  //       (member) => member.email === x.email && member.observer
  //     );
  //     return existMember && existMember.length > 0 ? true : false;
  //   },
  //   [generalSettings?.members]
  // );

  // const isCheckboxReceiverDisabled = useCallback(
  //   (x: UserResponseType): boolean => {
  //     const existMember = generalSettings?.members_with_roles?.filter(
  //       (member) => member.email === x.email && member.reciever
  //     );
  //     return existMember && existMember.length > 0 ? true : false;
  //   },
  //   [generalSettings?.members]
  // );

  const isObserverChecked = useCallback(
    (x: UserResponseType): boolean => {
      const existMember =
        membersObserversWatch && membersObserversWatch?.filter((member: any) => member === x.email);
      return existMember && existMember.length > 0 ? true : false;
    },
    [membersObserversWatch]
  );

  const isReceiverChecked = useCallback(
    (x: UserResponseType): boolean => {
      const existMember =
        membersRecieversWatch && membersRecieversWatch?.filter((member: any) => member === x.email);
      return existMember && existMember.length > 0 ? true : false;
    },
    [membersRecieversWatch]
  );

  //refetch the options when teamID changes
  useEffect(() => {
    teamID && refetchMembers();
  }, [teamID, refetchMembers]);

  useEffect(() => {
    if (members && members?.length > 0) {
      setValue(
        'general_settings.members_with_roles',
        members?.map((mem) => {
          return {
            email: mem.email,
            role: mem.team_role,
            name: mem.name,
            observer:
              Array.isArray(membersObserversWatch) && membersObserversWatch?.includes(mem.email),
            reciever:
              Array.isArray(membersRecieversWatch) && membersRecieversWatch?.includes(mem.email),
          };
        })
      );
    }
  }, [members, membersRecieversWatch, membersObserversWatch, setValue]);

  //if the teamID is the default one we set the default members
  //if not we set an empty array
  useEffect(() => {
    if (teamID && teamID !== generalSettings?.team_id) {
      setValue('general_settings.members', []);
    } else {
      setValue('general_settings.members', generalSettings?.members);
    }
  }, [generalSettings?.members, generalSettings?.team_id, setValue, teamID]);

  //No assesst role validation on publish action
  // useEffect(() => {
  //   if (!editPublished && generalSettings) {
  //     const roleToAssesst = generalSettings.role_to_assesst;
  //     // if (!watchMembers || (watchMembers && watchMembers.length === 0 && roleToAssesst)) {
  //     //   showNoCofoundersError(roleToAssesst);
  //     // } else if (membersData && watchMembers) {
  //     //   const selectedFormatted = watchMembers.map((x: string) => {
  //     //     return membersData.filter((y) => y.email === x)[0];
  //     //   });

  //       // const hasCofounders = selectedFormatted.some((x: UserResponseType) => {
  //       //   if (x && x.team_role && roleToAssesst) {
  //       //     return Number(x.team_role) === +roleToAssesst;
  //       //   }
  //       // });

  //       // if (!hasCofounders) {
  //       //   showNoCofoundersError(roleToAssesst);
  //       // } else if (hasCofounders) clearErrors(['general_settings.noCofounder']);
  //     // }
  //   }
  // }, [
  //   clearErrors,
  //   editPublished,
  //   generalSettings,
  //   membersData,
  //   showNoCofoundersError,
  //   watchMembers,
  // ]);

  if (isRefetchingMembers)
    return (
      <div className="team-members__empty">
        <Spinner />
      </div>
    );

  return (
    <div className="member-list">
      <p className="member-list-title">Team Members</p>
      {errors?.publish && errors?.general_settings?.members?.message && (
        <p className="error">{errors.general_settings.members.message}</p>
      )}
      {errors?.publish && errors?.general_settings?.noCofounder?.message && (
        <p className="error">{errors.general_settings.noCofounder.message}</p>
      )}
      {members?.length ? (
        <div className="member-list__content">
          <table className="team-members-table">
            <thead className="">
              <tr>
                <th className="body-4 bold" colSpan={1}>
                  Observer
                </th>
                <th className="body-4 bold" colSpan={1}>
                  Receiver
                </th>
                <th className="body-4 bold" colSpan={1}>
                  {' '}
                  <div className="tooltip">
                    <InfoIcon />
                    <span className="tooltiptext">
                      <strong>Observer</strong> <br />
                      Team members providing input and not doing a self-reflection <br />
                      <br />
                      <strong>Receiver</strong> <br />
                      Team members who are doing a self-reflection and are the focus of the
                      reflection
                    </span>
                  </div>
                </th>
                <th className="body-4 bold">Role</th>
                {!!assessmentTakes?.length && <th className="body-4 bold">Completion Status</th>}
              </tr>
            </thead>
            <tbody>
              {members.map((x) => (
                <tr key={x.email} className="team-members">
                  <td>
                    <Checkbox
                      disabled={isReceiverChecked(x)}
                      value={x.email}
                      registration={register(`observers`, {
                        required: false,
                      })}
                    />
                    {members.length === 1 && (
                      <Checkbox
                        hidden={true}
                        value={''}
                        registration={register(`observers`, {
                          required: false,
                        })}
                      />
                    )}
                  </td>
                  <td>
                    <Checkbox
                      disabled={isObserverChecked(x)}
                      value={x.email}
                      registration={register(`recievers`, {
                        required: editPublished
                          ? false
                          : 'You must select at least one member to publish an assessment',
                      })}
                    />
                    {members.length === 1 && (
                      <Checkbox
                        hidden={true}
                        value={''}
                        registration={register(`recievers`, {
                          required: false,
                        })}
                      />
                    )}
                  </td>
                  <td className="team-members__info">
                    <p className="body-3-italic body-3">{x.name}</p>
                    <p className="team-members__email">{x.email}</p>
                  </td>
                  <td>{x.team_role && getRoleLabel && getRoleLabel(x.team_role)}</td>
                  {assessmentTakes?.length && !!x?.assessmentStatus && (
                    <td>{x.assessmentStatus}</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="team-members__empty">No members in this team</div>
      )}
    </div>
  );
};
