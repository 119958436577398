import { Fragment } from 'react';

type SkillsType = {
  data: string[];
  setSkill: (x: string) => void;
  defaultValue: string;
};
const SkillsSelector = (props: SkillsType) => {
  const { data, setSkill, defaultValue } = props;
  return (
    <div className="radio-level-container">
      <div id="level-slider-skills">
        {data.map((s, index) => {
          return (
            <Fragment key={`${s}-${index}`}>
              <input
                name="skill"
                type="radio"
                id={`${s}-${index}`}
                value={s}
                onClick={() => setSkill(s)}
                defaultChecked={s === defaultValue}
              />
              <label htmlFor={`${s}-${index}`} data-level-label={s}>
                <span></span>
              </label>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default SkillsSelector;
