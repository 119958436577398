import { useCallback } from 'react';
import { DeepMap, FieldValues, UseFormHandleSubmit } from 'react-hook-form';

import { Button, ConfirmationDialog } from '@/components/Elements';

interface IValues {
  [x: string]: string | number | boolean | string[];
}

type ConfirmModalProps = {
  customChanges?: (objChange: IValues, key: string) => string | number | boolean | string[];
  initValues?: IValues;
  currentValues: FieldValues;
  dirtyFields: DeepMap<FieldValues, true>;
  labels: { [x: string]: string };
  handle?: UseFormHandleSubmit<FieldValues>;
  triggerDisabled: boolean;
  confirmOnClick: () => void;
  confirmDisabled: boolean;
  confirmIsLoading: boolean;
  isDone: boolean;
};

export const ConfirmChangesModal = ({
  customChanges,
  initValues,
  currentValues,
  dirtyFields,
  labels,
  triggerDisabled,
  confirmDisabled,
  confirmIsLoading,
  confirmOnClick,
  isDone,
  handle,
}: ConfirmModalProps) => {
  const checkChanges = useCallback(
    (objChange: IValues, key: string): string | number | boolean | string[] => {
      if (customChanges) return customChanges(objChange, key);
      return objChange[key];
    },
    [customChanges]
  );

  const ConfirmChanges = () => {
    return (
      <div>
        {Object.keys(dirtyFields).map((key) => {
          return (
            <div className="confirm-changes values mb-5 !w-full" key={key}>
              <span className="body-4 bold">{labels[key]}: </span>
              <span className="flex gap-1">
                {initValues && !!checkChanges(initValues, key) && (
                  <>
                    {Array.isArray(initValues[key]) ? (
                      <ul>
                        {(checkChanges(initValues, key) as string[]).map((x: string, i: number) => (
                          <li key={i} className="whitespace-nowrap">
                            {x}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span className="whitespace-nowrap">{checkChanges(initValues, key)}</span>
                    )}
                    <span className="!min-w-[2rem] text-center whitespace-nowrap">{'—>'}</span>
                  </>
                )}
                {Array.isArray(currentValues[key]) ? (
                  <ul>
                    {(checkChanges(currentValues, key) as string[]).map((x: string, i: number) => (
                      <li key={i} className="bold modal-value whitespace-nowrap">
                        {x}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span className="bold modal-value whitespace-nowrap">
                    {checkChanges(currentValues, key)}
                  </span>
                )}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <ConfirmationDialog
      title="Confirm Changes"
      cancelButtonText="Go back"
      className="confirm-changes"
      body={<ConfirmChanges />}
      handle={handle}
      isDone={isDone}
      triggerButton={
        <Button type="button" variant="primary" disabled={triggerDisabled}>
          Save
        </Button>
      }
      confirmButton={
        <Button
          type="button"
          variant="primary"
          onClick={confirmOnClick}
          disabled={confirmDisabled}
          isLoading={confirmIsLoading}
        >
          Confirm
        </Button>
      }
    />
  );
};
