import { useEffect, useMemo, useState } from 'react';

import { LabelState } from '@/components/Elements';
import { ASSESSMENT_PHASE } from '@/features/assessments';
import { formatDateFull, getDateDiff } from '@/utils/format';

import { DisplayOption, InformationProps } from './types';

interface TakeDisplayProps extends InformationProps {
  complete?: boolean;
}
export const TakeDisplay = ({
  data,
  selected,
  isAssessmentLoading,
  navigate,
  teamsMap,
  complete = false,
}: TakeDisplayProps) => {
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<DisplayOption[]>([]);

  const handleClickCard = () => {
    if (!complete) {
      navigate(`/app/assessment/take/${data.assessment_id}`, {
        replace: true,
      });
    } else {
      navigate(`/app/assessment/take/${data.assessment_id}`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    const options: DisplayOption[] = [];
    if (complete) {
      // Add options
      options.push({
        title: 'See results',
        onClick: () => {
          navigate(`/app/assessment/results/${data.assessment_id}`, {
            replace: true,
          });
        },
      });
    }
    setOptions(options);
  }, [data]);

  const phase = useMemo(() => {
    if (data.assessment_status) {
      return data.assessment_status as ASSESSMENT_PHASE;
    }
    if (data.assessment_take_user_phase !== ASSESSMENT_PHASE.COMPLETED) {
      const difference = getDateDiff(new Date(data.general_settings.due_date), new Date(), 'hours');
      if (difference < 48 && difference > 0) {
        return ASSESSMENT_PHASE.DUE_SOON;
      }
      if (new Date() > new Date(data.general_settings.due_date)) {
        return ASSESSMENT_PHASE.OVERDUE;
      }
    }

    if (data.assessment_take_user_phase) {
      return data.assessment_take_user_phase as ASSESSMENT_PHASE;
    }
    return undefined;
  }, [data]);

  return (
    <div className={`assessment-section ${selected ? 'selected' : ''}`}>
      <button
        onClick={() => {
          handleClickCard();
        }}
        className={`assessment-section-button`}
        disabled={isAssessmentLoading}
      >
        <div className={'assessment-section-content'}>
          {/*@TODO Then change this to assessment category*/}
          <h4 className="assessment-section-category">REFLECTION</h4>
          <h3 className="assessment-section-title">{data.assessment_name}</h3>
        </div>
        <div className={'assessment-section-content'}>
          {teamsMap.has(data.general_settings.team_id) && (
            <sub className="assessment-section-sub">
              Assigned to {teamsMap.get(data.general_settings.team_id)?.team_name}
            </sub>
          )}
          <sub className="assessment-section-sub">
            Due {formatDateFull(data.general_settings.due_date)}
          </sub>
          {phase && <LabelState phase={phase} noMargin={true} />}
        </div>
      </button>
      {options && options.length > 0 && (
        <button
          onClick={() => setDisplayOptions(!displayOptions)}
          className={'assessment-section-options-button'}
        >
          ...
        </button>
      )}
      {displayOptions && (
        <ul className={'assessment-section-options-list'}>
          {options?.map((opt, index) => {
            return (
              <li key={index}>
                <button
                  onClick={() => {
                    opt.onClick();
                    setDisplayOptions(false);
                  }}
                >
                  {opt.title}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
