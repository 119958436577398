/* eslint-disable @typescript-eslint/no-unused-vars */
import create from 'zustand';

import { QuestionBaseType, getQuestions } from '@/features/assessments';

type AssessmentQuestionStore = {
  questions: QuestionBaseType[];

  getAssessmentQuestions: () => Promise<true | undefined>;
};

export const useAssessmentQuestionStore = create<AssessmentQuestionStore>((set, get) => ({
  questions: [],

  getAssessmentQuestions: async () => {
    const questions = await getQuestions();
    set({
      questions: questions,
    });
    return true;
  },
}));
