import { useMutation } from 'react-query';

import { DELETE_MENTOR_CHAT_API, DeleteMentorChatPayloadType } from '@/features/chat';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export const deleteMentorChat = ({ session_id }: DeleteMentorChatPayloadType): Promise<void> => {
  return axios.delete(DELETE_MENTOR_CHAT_API.replace('{session_id}', session_id));
};

interface DeleteMentorChatOptions {
  config?: MutationConfig<typeof deleteMentorChat>;
}

export const useDeleteMentorChat = ({ config }: DeleteMentorChatOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    ...config,
    mutationFn: deleteMentorChat,
    onError: () => {
      localStorage.setItem('chat-error-last-action', 'delete');
      addNotification({
        type: 'error',
        title: 'Chat session deletion failed!',
      });
    },
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Chat session successfully deleted!',
      });
    },
  });
};
