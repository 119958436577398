import { useAuthenticator } from '@aws-amplify/ui-react';
import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';

import { ChatContext } from '@/contexts/chat.context';
import {
  ChatBox,
  usePostMentorChatStore,
  ChatMode,
  useChatMessagesStore,
  usePutMentorChatStore,
  ChatErrorAction,
  useGetMentorChatStore,
  CHAT_SUGGESTIONS_KEYWORD,
  ChatModel,
  useDeleteMentorChatStore,
  useRenameMentorChatStore,
  MessageType,
  MessageReact,
  useUserLikeChatStore,
  useUserUnlikeChatStore,
} from '@/features/chat';

export interface ChatProviderProps {
  children?: ReactNode;
  chatMode?: ChatMode;
  fullPagePath?: string;
  portalId?: string;
  excludeThisPath?: string[];
  isVentureverse?: boolean;
}

const ChatProvider = (props: ChatProviderProps) => {
  const [chatMode, setChatMode] = useState<ChatMode>(props.chatMode ?? 'init');
  const [isPageRefreshed, setIsPageRefreshed] = useState<boolean>();
  const [chatPortal, setChatPortal] = useState<HTMLElement>();

  const { user } = useAuthenticator((context) => [context.user]);
  const location = useLocation();

  const {
    chatMessages,
    chatHistories,
    incoming,
    activeChatSession,
    selectedItemMaxMode,
    setInitialChatMessage,
    setChatMessages,
    setSelectedItemMaxMode,
    setChatHistory,
    setIncoming,
    invokeNewChatSession,
  } = useChatMessagesStore();
  const {
    isLoading: putLoading,
    isError: putError,
    chatPut,
    setPutChatSession,
  } = usePutMentorChatStore()();
  const {
    isLoading: postLoading,
    isError: postError,
    chatPost,
    setPostChatSession,
  } = usePostMentorChatStore()();
  const {
    isLoading: getLoading,
    isError: getError,
    chatGet,
    setGetChatSession,
  } = useGetMentorChatStore()();

  const { isLoading: isHistoryDeleting, setDeleteChatSession } = useDeleteMentorChatStore()();
  const { setRenameChatSession } = useRenameMentorChatStore()();
  const { setUserLikeChatSession } = useUserLikeChatStore()();
  const { setUserUnlikeChatSession } = useUserUnlikeChatStore()();

  useEffect(() => {
    if (isPageRefreshed === undefined) {
      setIsPageRefreshed && setIsPageRefreshed(true);
      // if there is no mentor chat id and session chat id stored in local storage, then ignore this.
      // else get that
      if (activeChatSession?.id) {
        setGetChatSession({ session_id: activeChatSession.id });
      }
    }
  }, []);

  useEffect(() => {
    if (location.pathname === props.fullPagePath) {
      setChatMode('max');
      return;
    }
    if (props.isVentureverse) {
      setChatMode('normal');
    } else {
      setChatMode('init');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (chatMode === 'max') {
      const chatPortal = document.getElementById('chat-portal');

      if (!chatPortal) {
        return;
      }
      setChatPortal(chatPortal);
      setChatMessages([]); // reset chat messages
      setChatHistory([]); // reset chat history
      setGetChatSession({});
    } else {
      setChatPortal(undefined);
    }
  }, [chatMode]);

  useEffect(() => {
    if (chatGet && user.attributes?.name) {
      chatGet
        .filter((item) => item.session_id === activeChatSession?.id)
        .sort((a, b) => {
          if (!a.createdOn) return 1;
          if (!b.createdOn) return -1;
          return a.createdOn - b.createdOn;
        })
        .map((item) => {
          setChatMessages(item, true);
        });
      setChatHistory(chatGet);
    }
  }, [chatGet]);

  useEffect(() => {
    if (chatPost) {
      setChatMessages(chatPost, postLoading);
      setSelectedItemMaxMode && setSelectedItemMaxMode(chatPost.session_id);
      if (chatHistories) {
        setChatHistory([
          ...chatHistories,
          ...chatMessages.map((item) => ({
            ...item,
            session_id: chatPost.session_id,
          })),
          { ...chatPost },
        ]);
      }
    }
  }, [chatPost]);

  useEffect(() => {
    if (chatPut) {
      setChatMessages(chatPut, putLoading, false);
      if (chatHistories) {
        setChatHistory([
          ...chatHistories,
          ...chatMessages.filter((item) => item.session_id === chatPut.session_id),
          { ...chatPut },
        ]);
      }
    }
  }, [chatPut]);

  useEffect(() => {
    if (user?.attributes) {
      const userFirstName = user.attributes?.name.split(' ')[0];
      localStorage.setItem('chat-user-first-name', userFirstName);
      setInitialChatMessage();
    }
  }, [user?.attributes]);

  const handleChatSession = (message: string, _type: MessageType, scroller?: () => void) => {
    // this message is a string only and use for indicate message from a user.
    setChatMessages({ message });
    setTimeout(() => {
      scroller && scroller();
    }, 400);
    const { length } = chatMessages;
    if (length >= 2) {
      if (activeChatSession?.id) {
        // take last item from chatmessages then search for the key in the suggesstions
        let newMessage = message;
        if (chatMessages) {
          const index = CHAT_SUGGESTIONS_KEYWORD.findIndex(
            (item) => item.toLowerCase() === message.toLowerCase()
          );
          if (index > -1) {
            const lastMessage = chatMessages[chatMessages.length - 1];
            const { suggestions } = lastMessage;
            if (Array.isArray(suggestions)) {
              //
            } else {
              suggestions
                ?.split('\n')
                .filter((item) => item)
                .map((item) => {
                  if (item.toLowerCase().trim().startsWith(message.toLowerCase())) {
                    const splitted = item.split(`${message.toUpperCase()}.`);
                    if (splitted.length > 1) {
                      newMessage = splitted[1];
                    }
                    return;
                  }
                });
            }
          }
        }
        setPutChatSession({
          session_id: activeChatSession?.id,
          message: newMessage,
        });
      }
    } else {
      setPostChatSession({ message });
    }
  };

  const handleChatError = (lastAction: ChatErrorAction) => {
    const { message } = chatMessages[chatMessages.length - 1];
    if (message) {
      switch (lastAction) {
        case 'get':
          break;
        case 'post':
          setPostChatSession({ message });
          break;
        case 'put':
          if (activeChatSession?.id) {
            setPutChatSession({
              session_id: activeChatSession.id,
              message,
            });
          }
          break;
        default:
          break;
      }
    }
  };

  const handleStartNewChat = () => {
    invokeNewChatSession && invokeNewChatSession();
  };

  const handleloadDataBySession = (data?: ChatModel[]) => {
    if (data) {
      data
        .sort((a, b) => {
          if (!a.createdOn) return 1;
          if (!b.createdOn) return -1;
          return a.createdOn - b.createdOn;
        })
        .map((item) => {
          setChatMessages(item, undefined, true, true);
        });
    }
  };

  const handleChatDelete = (sessionId: string) => {
    setDeleteChatSession({ session_id: sessionId });
  };

  const handleChatRename = (sessionId: string, sessionName: string) => {
    setRenameChatSession({ session_id: sessionId, session_name: sessionName });
  };

  const handleMessageReact = (messageReact: MessageReact, mentorMessageId: string) => {
    if (activeChatSession?.id) {
      if (messageReact === 'thumbs-down') {
        setUserUnlikeChatSession({ session_id: activeChatSession.id, message_id: mentorMessageId });
        return;
      }
      if (messageReact === 'thumbs-up') {
        setUserLikeChatSession({ session_id: activeChatSession.id, message_id: mentorMessageId });
        return;
      }
    }
  };

  const handleIncomingMessage = () => {
    setIncoming(false);
  };

  return (
    <ChatContext.Provider
      value={{
        fullPagePath: props.fullPagePath,
        chatMode,
        isPageRefreshed,
        isVentureverse: props.isVentureverse,
        setChatMode,
        setIsPageRefreshed,
      }}
    >
      {props.children}
      {user &&
        !props.excludeThisPath?.some((item: string) => location.pathname.startsWith(item)) &&
        (chatMode === 'max' ? (
          chatPortal &&
          createPortal(
            <ChatBox
              data={chatMessages}
              chatHistory={chatHistories}
              isTyping={postLoading || putLoading}
              isError={putError || postError || getError}
              isHistoryLoading={getLoading}
              isHistoryDeleting={isHistoryDeleting}
              incoming={incoming}
              selectedItemMaxMode={selectedItemMaxMode}
              setSelectedItemMaxMode={setSelectedItemMaxMode}
              onChatSession={handleChatSession}
              onChatError={handleChatError}
              onLoadDataBySession={handleloadDataBySession}
              onChatDelete={handleChatDelete}
              onChatRename={handleChatRename}
              onMessageReact={handleMessageReact}
              onIncomingMessage={handleIncomingMessage}
            />,
            chatPortal
          )
        ) : (
          <ChatBox
            data={chatMessages}
            isTyping={postLoading || putLoading}
            isError={putError || postError || getError}
            isHistoryLoading={getLoading}
            isVentureverse={props.isVentureverse}
            incoming={incoming}
            onChatSession={handleChatSession}
            onChatError={handleChatError}
            onStartNewChat={handleStartNewChat}
            onMessageReact={handleMessageReact}
            onIncomingMessage={handleIncomingMessage}
          />
        ))}
    </ChatContext.Provider>
  );
};
export default ChatProvider;
