import { Auth } from 'aws-amplify';
import Axios, { AxiosRequestConfig } from 'axios';

// import { useNotificationStore } from '@/stores/notifications';

async function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});

axios.interceptors.request.use((config: any) => {
  if (config.url.includes('confirmcode') || config.url.includes('sendcode')) {
    // Modify the config object here if the request is going to 'https://api.example.com'
    return config;
  }
  return authRequestInterceptor(config);
});
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // const message = error.response?.data?.message || error.message;
    // useNotificationStore.getState().addNotification({
    //   type: 'error',
    //   title: 'Error',
    //   message,
    // });

    return Promise.reject(error);
  }
);
