import clsx from 'clsx';
import * as React from 'react';
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';

import { Button } from '@/components/Elements/Button';
import { Dialog, DialogTitle } from '@/components/Elements/Dialog';
import { useDisclosure } from '@/hooks/useDisclosure';

export type ConfirmationDialogProps = {
  triggerButton: React.ReactElement;
  confirmButton: React.ReactElement;
  title: string;
  body?: string | React.ReactElement;
  cancelButtonText?: string;
  icon?: 'danger' | 'info';
  isDone?: boolean;
  className?: string;
  handle?: UseFormHandleSubmit<FieldValues>;
};

export const ConfirmationDialog = ({
  triggerButton,
  confirmButton,
  title,
  body = '',
  cancelButtonText = 'Cancel',
  isDone = false,
  className,
  handle,
}: ConfirmationDialogProps) => {
  const { close, open, isOpen } = useDisclosure();

  const cancelButtonRef = React.useRef(null);

  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  const trigger = React.cloneElement(triggerButton, {
    onClick: handle ? handle(open) : open,
  });

  return (
    <>
      {trigger}
      <Dialog isOpen={isOpen} onClose={close} initialFocus={cancelButtonRef}>
        <div className={clsx('modal overflow-hidden transform transition-all', className)}>
          <div className="modal-container">
            <div className="">
              <DialogTitle as="h3" className="modal__title">
                {title}
              </DialogTitle>
              {body && (
                <div className="modal__info">
                  <div className="inline-block">{body}</div>
                </div>
              )}
            </div>
          </div>
          <div className="modal__btn">
            <Button
              type="button"
              variant="inverse"
              className="back-btn"
              onClick={close}
              ref={cancelButtonRef}
            >
              {cancelButtonText}
            </Button>
            {confirmButton}
          </div>
        </div>
      </Dialog>
    </>
  );
};
