import { useMutation } from 'react-query';

import {
  POST_MENTOR_CHAT_API,
  PostMentorChatPayloadType,
  PostMentorChatResponseType,
} from '@/features/chat';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { getFineName } from '@/utils/string';

export const postMentorChat = ({
  message,
}: PostMentorChatPayloadType): Promise<{ responseObj: PostMentorChatResponseType }> => {
  return axios.post(POST_MENTOR_CHAT_API, { message });
};

interface PostMentorChatOptions {
  config?: MutationConfig<typeof postMentorChat>;
}

export const usePostMentorChat = ({ config }: PostMentorChatOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: postMentorChat,
    onError: () => {
      localStorage.setItem('chat-error-last-action', 'post');
    },
    onSuccess: (response) => {
      const data = response.responseObj;
      queryClient.invalidateQueries('postMentorChat');
      // store session id to indicate an active session chat. This might be useful to detect that a chat is active or not. only 1 active chat session allowed.
      if (data.session_id) {
        localStorage.setItem('chat-active-session-id', data.session_id);
      }
      if (data.session_name) {
        localStorage.setItem('chat-active-session-name', getFineName(data.session_name));
        return;
      }
      if (data.message) {
        localStorage.setItem('chat-active-session-name', getFineName(data.message));
      }
    },
  });
};
