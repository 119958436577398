import { default as dayjs, OpUnitType, QUnitType } from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(RelativeTime);

export const formatDateMMDDYYUnix = (date: number) => dayjs.unix(date).format('MM/DD/YY');
export const formatDateMMDDYY = (date: number) => dayjs(date).format('MM/DD/YY');
export const formatDateFull = (date: number | string) => dayjs(date).format('MMMM DD, YYYY');
export const formatDateShort = (date: number | string) => dayjs(date).format('MMM D');
export const formatDateFromNow = (date: number | string) =>
  dayjs().diff(date, 'hours') < 24 ? 'today' : dayjs(date).fromNow();
export const formatDateIsoString = (date: number | string | Date) =>
  dayjs(date).format('YYYY-MM-DD HH:mm:ss');
export const formatDateMMMMDDYYYYHHmmA = (date: number | string | Date) =>
  dayjs(date).format('MMMM DD YYYY, HH:mm A');

export const getDateDiff = (
  date1: number | string | Date,
  date2: number | string | Date,
  unit: QUnitType | OpUnitType
) => {
  return dayjs(date1).diff(date2, unit);
};

export const RegexSelect2 = /^[a-zA-Z0-9_ ]*$/; // no empty

export const validEmail = (email: string) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};

export const formatSelectData = (
  data: any[] | undefined,
  optionalLabel = 'name',
  optionalValue = 'value'
) => {
  if (!data) return [];

  return data
    ? data.map(({ [optionalLabel]: label, [optionalValue]: value, ...rest }: any) => ({
        label,
        value,
        ...rest,
      }))
    : [];
};

export const toEpoch = (dateTime?: string) => {
  if (!dateTime) {
    return Math.floor(new Date().getTime() / 1000);
  }
  return Math.floor(new Date(dateTime).getTime() / 1000);
};
