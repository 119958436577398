import { Button, Dialog, DialogTitle } from '@/components/Elements';

type RemoveMemberProps = {
  isOpen: boolean;
  close: () => void;
  onRemove: () => void;
};

export const RemoveMemberDialog = ({ isOpen, close, onRemove }: RemoveMemberProps) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Remove member?
          </DialogTitle>
          <div className="modal__info">
            <p>Are you sure you want to remove the user from this team?</p>
          </div>
          <div className="modal__footer modal-row justify-end">
            <Button type="button" variant="inverse" onClick={close}>
              Go back
            </Button>
            <Button variant="danger" onClick={onRemove}>
              Yes, remove user
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
