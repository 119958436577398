import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { ASSESSMENT_PHASE, AssessmentResponseType, PHASE_LABELS } from '@/features/assessments';
import { useAssessmentsPerTeam } from '@/features/teams';

import { useTeam } from '../../api/getTeam';

import { ListPhase } from './ListPhase';

export type ListData = {
  label: string;
  data: AssessmentResponseType[];
};

const returnDataStructured = (
  data: any,
  phase:
    | ASSESSMENT_PHASE.UPCOMING
    | ASSESSMENT_PHASE.IN_PROGRESS
    | ASSESSMENT_PHASE.PAST_DUE
    | ASSESSMENT_PHASE.CLOSED
    | ASSESSMENT_PHASE.COMPLETED
) => {
  let tempObj: ListData = { label: '', data: [] };

  if (data[phase]) {
    tempObj.label = PHASE_LABELS[phase];
    tempObj = { ...tempObj, data: data[phase] };
  }

  return tempObj;
};

export const ManageAssessment = () => {
  const { teamID } = useParams();
  const { data, isLoading } = useAssessmentsPerTeam({
    teamID,
  });
  const { data: teamName } = useTeam({ teamID: teamID });

  const upcomingData = useMemo(() => {
    if (data) return returnDataStructured(data, ASSESSMENT_PHASE.UPCOMING);
  }, [data]);

  const closedData = useMemo(() => {
    if (data) {
      return returnDataStructured(data, ASSESSMENT_PHASE.CLOSED);
    }
  }, [data]);

  const inProgressData = useMemo(() => {
    if (data) return returnDataStructured(data, ASSESSMENT_PHASE.IN_PROGRESS);
  }, [data]);

  const pastDueData = useMemo(() => {
    if (data) return returnDataStructured(data, ASSESSMENT_PHASE.PAST_DUE);
  }, [data]);

  if (isLoading) return <Spinner />;

  return (
    <div className="assessment-lists">
      <h3>{teamName?.team_name}</h3>
      <ListPhase listData={upcomingData} phase={ASSESSMENT_PHASE.UPCOMING} />
      <ListPhase listData={inProgressData} phase={ASSESSMENT_PHASE.IN_PROGRESS} />
      <ListPhase listData={pastDueData} phase={ASSESSMENT_PHASE.PAST_DUE} />
      <ListPhase listData={closedData} phase={ASSESSMENT_PHASE.CLOSED} />
    </div>
  );
};
