import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
};

type SelectFieldProps = FieldWrapperPassThroughProps & {
  size?: keyof typeof sizes;
  options?: Option[];
  className?: string;
  defaultValue?: string | number;
  placeholder?: string;
  registration?: Partial<UseFormRegisterReturn>;
  horizontal?: boolean;
  disabled?: boolean;
  grid?: boolean;
  hidden?: boolean;
  value?: string;
  clearable?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

const baseClaseName = 'select';

const sizes = {
  sm: `${baseClaseName}-sm`,
  md: `${baseClaseName}-md`,
  full: `${baseClaseName}-full`,
};

export const SelectField = (props: SelectFieldProps) => {
  const {
    label,
    size = 'sm',
    options,
    error,
    className,
    defaultValue,
    registration,
    placeholder,
    horizontal,
    disabled,
    grid,
    hidden,
    // clearable,
  } = props;
  return (
    <FieldWrapper
      label={label}
      error={error}
      horizontal={horizontal}
      size={size}
      grid={grid}
      hidden={hidden}
    >
      {!!options?.length && (
        // eslint-disable-next-line jsx-a11y/no-onchange
        <select
          disabled={!options.length || disabled}
          placeholder={placeholder}
          name={'location'}
          className={clsx(baseClaseName, sizes[size], className)}
          defaultValue={defaultValue || ''}
          onChange={props.onChange}
          {...registration}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}

          {/* {placeholder && (
            <option
              value="default"
              // selected={defaultValue == 'default' || defaultValue == undefined ? true : false}
              disabled={!clearable}
              hidden={!clearable}
              selected={true}
            >
              {placeholder}
            </option>
          )} */}
          {options.map(({ label, value }, key) => (
            <option key={key} value={value}>
              {label}
            </option>
          ))}
        </select>
      )}
    </FieldWrapper>
  );
};
