import { PencilIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as zod from 'zod';

import { ReactComponent as TrashIcon } from '@/assets/icons/trashIcon.svg';
import { Button, Spinner } from '@/components/Elements';
import { InputField, SelectField, TextAreaField } from '@/components/Form';
import { useCreateClient, ClientBaseType, ClientResponseType } from '@/features/clients';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useLeavePageStore } from '@/stores/leavePage';

import { DeleteClientDialog } from './dialogs/DeleteClientDialog';

const createClientValidations = zod.object({
  client_name: zod.string().nonempty('Please fill in this field.'),
  description: zod.string().nonempty('Please fill in this field.'),
  industry: zod.string().nonempty('Select an option.'),
});

type Option = {
  label: string;
  value: string | number;
};

type IndustryOption = {
  subIndustries?: string[];
} & Option;

type CreateProps = {
  editObj?: ClientResponseType;
  editState?: boolean;
  deleteClientLoading?: boolean;
  setEditState?: (edit: boolean) => void;
  isUpdatingClient?: boolean;
  industriesOptions?: IndustryOption[];
  clientsOptions?: Option[];
  loading?: boolean;
};

export const Create = ({
  editObj,
  editState,
  deleteClientLoading,
  industriesOptions,
  loading,
}: CreateProps) => {
  const navigate = useNavigate();
  const { open } = useDisclosure();
  const { setBlocker, removeBlocker } = useLeavePageStore();

  const {
    mutate: createClientMutate,
    isSuccess: isSuccessClient,
    error: errorClient,
    isLoading: isLoadingClient,
  } = useCreateClient();

  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();

  const {
    handleSubmit,
    reset,
    register,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(createClientValidations),
  });

  const onDeleteClient = useCallback(() => {
    closeDeleteModal();
    removeBlocker();
    navigate('../dashboard', {
      replace: true,
      state: { refresh: true },
    });
    window.location.reload();
  }, [closeDeleteModal, removeBlocker, navigate]);

  useEffect(() => {
    if (editObj) reset(editObj);
  }, [editObj, reset]);

  useEffect(() => {
    if (isSuccessClient) {
      reset();
      open();
      removeBlocker();
    }
  }, [isSuccessClient, open, removeBlocker, reset]);

  const onCreateClient = useCallback(
    (data: ClientBaseType) => {
      createClientMutate(data);
      navigate('/', {
        replace: true,
        state: { refresh: true },
      });
    },
    [navigate]
  );

  useEffect(() => {
    if (!onCreateClient && isDirty) {
      setBlocker();
    }
    return () => {
      removeBlocker();
    };
  }, [isDirty, setBlocker, removeBlocker, onCreateClient]);
  if (loading) return <Spinner />;

  if (errorClient) return <p>Error while creating a new client!...</p>;

  return (
    <div className={clsx('create-team container', loading && 'flex justify-center')}>
      <form onSubmit={handleSubmit(onCreateClient)}>
        <DeleteClientDialog
          isOpen={isOpenDeleteModal}
          close={closeDeleteModal}
          deleteTeam={!!editObj}
          onDelete={onDeleteClient}
          deleteClientLoading={deleteClientLoading}
        />

        {(!editObj || editState) && (
          <>
            <InputField
              registration={register('client_name', { required: true })}
              error={errors.team_name}
              placeholder="Type a client name"
              className="special-input"
              bold={true}
              horizontal={true}
              icons={[<PencilIcon key={'pencilIcon'} />]}
            />
          </>
        )}
        {(!editObj || editState) && (
          <div className="create-team__info">
            <TextAreaField
              className="test"
              label="Client Description"
              registration={register('description')}
              size="md"
              error={errors.description}
              placeholder="Describe the client objectives in two or three sentences"
            />
            <div className="create-team__options">
              <SelectField
                label="Industry"
                registration={register('industry', { required: true })}
                horizontal={true}
                size="md"
                placeholder="Select industry"
                error={errors.industry}
                options={industriesOptions || []}
              />
            </div>
          </div>
        )}
        <div className="create-team__actions">
          {!editObj && (
            <>
              <Button
                className="create-team__button"
                isLoading={isLoadingClient}
                type="submit"
                disabled={!isValid}
              >
                Create Client
              </Button>
              <button type="button" onClick={openDeleteModal}>
                <TrashIcon className="!mb-[5px]" color="C80404" />
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
