import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation, Navigate } from 'react-router-dom';

interface IRequiereAuth {
  children: React.ReactElement;
}
export function RequireAuth({ children }: IRequiereAuth) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== 'authenticated' && route !== 'idle') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (route !== 'idle') {
    return children;
  }

  return null;
}
