import { CognitoUser } from '@aws-amplify/auth';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { Button, Spinner } from '@/components/Elements';
import { Notifications } from '@/components/Notifications/Notifications';
import { AppContext } from '@/contexts/app.context';
import { queryClient } from '@/lib/react-query';

const ErrorFallback = () => {
  Auth.currentAuthenticatedUser({ bypassCache: true }).catch((e) => {
    if (
      (e === 'The user is not authenticated' || e === 'User is disabled.') &&
      window.location.href.includes('/app')
    ) {
      Auth.signOut();
      window.location.href = '/login';
    }
  });

  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [loginInfo, setLoginInfo] = useState<CognitoUser>();
  const [generalErrors, setGeneralErrors] = useState<any>();

  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <Authenticator.Provider>
              <AppContext.Provider
                value={{
                  generalErrors,
                  setGeneralErrors,
                  loginInfo,
                  setLoginInfo,
                }}
              >
                <Router>{children}</Router>
              </AppContext.Provider>
            </Authenticator.Provider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
export { AppContext };
