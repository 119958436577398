import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { UserResponseType } from '../types';

interface IUser {
  responseObj: UserResponseType[];
}

export const getUsers = (): Promise<UserResponseType[]> => {
  return axios.get('/users').then((data: AxiosResponse | IUser): UserResponseType[] => {
    return (data as IUser)?.responseObj || [];
  });
};

type QueryFnType = typeof getUsers;

type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useUsers = ({ config }: UseUsersOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['users'],
    queryFn: () => getUsers(),
  });
};
