import { useCallback, useState } from 'react';

import { ContentLayout } from '@/components/Layout';

import GameScreen from '../components/game-screen';
import HowToPlayScreen from '../components/how-to-play-screen';
import StartedScreen from '../components/welcome-screen';

export const Ventureverse = () => {
  const [page, pageSet] = useState(0);

  const nextPage = useCallback(() => {
    pageSet(page + 1);
  }, [page]);

  return (
    <ContentLayout title="VentureVerse" customClassName="vv-content">
      <>
        {page === 0 && <StartedScreen onClick={nextPage} />}
        {page === 1 && <HowToPlayScreen onClick={nextPage} />}
        {page === 2 && <GameScreen />}
      </>
    </ContentLayout>
  );
};

export default Ventureverse;
