// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import './styles/app.scss';

import { Amplify } from 'aws-amplify';

import { AppProvider } from '@/providers/app.provider';
import { AppRoutes } from '@/routes';

import awsExports from './aws';
import { FULL_PAGE_CHAT_PATH } from './features/chat';
import ChatProvider from './providers/chat.provider';

Amplify.configure(awsExports);

function App() {
  return (
    <AppProvider>
      <ChatProvider
        fullPagePath={FULL_PAGE_CHAT_PATH}
        portalId="chat-portal"
        excludeThisPath={['/app/ventureverse']}
      >
        <AppRoutes />
      </ChatProvider>
    </AppProvider>
  );
}

export default App;
