import { PencilIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as zod from 'zod';

import { Button, Dialog, DialogTitle } from '@/components/Elements';
import { InputField } from '@/components/Form';

type AddNewRoleDialogProps = {
  isOpen: boolean;
  close: () => void;
  onAdd: (newRoleTitle: string) => void;
};
const addNewRoleValidation = zod.object({
  new_role: zod.string().nonempty('Please fill in this field.'),
});
export const AddNewRoleDialog = ({ isOpen, close, onAdd }: AddNewRoleDialogProps) => {
  const {
    handleSubmit,
    register,

    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(addNewRoleValidation),
  });

  const handleSaveRole = (data: { new_role: string }) => {
    onAdd(data.new_role);
    close();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        {/* <form onSubmit={handleSubmit(onCreateClient)}> */}
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Add new role
          </DialogTitle>
          <div className="modal__info">
            <p>
              <InputField
                registration={register('new_role', { required: true })}
                error={errors.new_role}
                placeholder="Role title"
                className="special-input"
                bold={true}
                horizontal={true}
                icons={[<PencilIcon key={'pencilIcon'} />]}
              />
            </p>
          </div>
          <div className="modal__footer modal-row justify-end">
            <Button type="button" variant="inverse" onClick={close}>
              Cancel
            </Button>
            {/* <Button onClick={onAdd} isLoading={addRoleLoading} variant="danger"> */}
            <Button onClick={handleSubmit(handleSaveRole)} variant="primary">
              Save role
            </Button>
          </div>
        </div>
        {/* </form> */}
      </div>
    </Dialog>
  );
};
