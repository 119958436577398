export const formFields = {
  resetPassword: {
    username: {
      order: 1,
      label: 'Email',
      labelHidden: false,
      placeholder: 'name@mail.com',
    },
  },
  signIn: {
    username: {
      order: 1,
      label: 'Email',
      labelHidden: false,
      placeholder: 'name@mail.com',
      required: true,
    },
    password: {
      order: 2,
      label: 'Password',
      labelHidden: false,
      placeholder: 'Min. 6 characters',
      required: true,
      autocomplete: 'off',
    },
  },
  signUp: {
    name: {
      order: 1,
      label: 'Full Name',
      labelHidden: false,
      hasError: true,
    },
    email: {
      order: 3,
      label: 'Email',
      labelHidden: false,
    },
    password: {
      order: 4,
      label: 'Password',
      labelHidden: false,
    },
    confirm_password: {
      order: 5,
      label: 'Confirm Password',
      labelHidden: false,
    },
    phone_number: {
      order: 6,
      label: 'Phone Number',
      labelHidden: false,
    },
    'custom:verification_code': {
      order: 7,
      label: 'SMS Code',
      labelHidden: true,
    },
    'custom:alternate_email': {
      type: 'custom:business-name',
      label: 'Alternate Email (optional)',
      required: false,
      placeholder: 'name@mail.com',
    },
  },
};
