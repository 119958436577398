export interface IIconListElement {
  type?: 'Button' | 'Anchor';
  icon: string;
  title: string;
  onClick?: () => void;
  url?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

interface IIconLinkList {
  elements: IIconListElement[];
}

export const IconLinkList = ({ elements }: IIconLinkList) => {
  return (
    <div className={'icon-link-list-container'}>
      <ul className={'icon-link-list-container--list'}>
        {elements.map((item, index) => (
          <li key={index}>
            {!item.type || item.type === 'Button' ? (
              <button onClick={item.onClick}>
                <img alt={item.title} src={item.icon} />
                {item.title}
              </button>
            ) : (
              <a href={item.url} target={item.target}>
                <img alt={item.title} src={item.icon} />
                {item.title}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
