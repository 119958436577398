import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { AssessmentResponseType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

interface IRenameAssessmentResponse {
  // Backend response is an empty object
  responseObj: {};
}

const renamePublishedAssessment = (data: {
  assessmentId: string;
  assessment_name: string;
}): Promise<any> => {
  return axios
    .patch<AssessmentResponseType>(`/assessments/${data.assessmentId}/publish`, {
      assessment_name: data.assessment_name,
    })
    .then((data: AxiosResponse | IRenameAssessmentResponse): {} => {
      return (data as IRenameAssessmentResponse)?.responseObj;
    });
};

type UseRenamePublishedAssessmentOptions = {
  config?: MutationConfig<typeof renamePublishedAssessment>;
};

export const useRenamePublishedAssessment = ({
  config,
}: UseRenamePublishedAssessmentOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onError: (_, __, context: any) => {
      addNotification({
        type: 'error',
        title: context,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries('assessments');
      addNotification({
        type: 'success',
        title: 'Assessment successfully saved!',
      });
    },
    ...config,
    mutationFn: renamePublishedAssessment,
  });
};
