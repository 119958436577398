export const EmptyState = () => {
  return (
    <div className="container assessment__main">
      <h1 className="font-style-rainbow">
        Welcome to your Results Hub!&nbsp;
        <span role="img" aria-label="emoji">
          📈
        </span>
      </h1>

      <h3 className="text-center">
        No results yet.{' '}
        <span role="img" aria-label="emoji">
          😞
        </span>{' '}
        Come back later.
      </h3>
    </div>
  );
};
