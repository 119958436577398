import { useMutation } from 'react-query';

// import { useAuth } from '@/lib/auth';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type UpdateProfile = {
  data: any;
  email: string;
};

export const updateProfile = ({ data, email }: UpdateProfile) => {
  return axios.put(`/users/${email}`, data);
};

type UseUpdateProfileOptions = {
  config?: MutationConfig<typeof updateProfile>;
};

export const useUpdateProfile = ({ config }: UseUpdateProfileOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'User Updated',
      });
    },
    ...config,
    mutationFn: updateProfile,
  });
};
