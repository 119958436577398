import { AxiosResponse } from 'axios';

import { AssessmentBaseType, AssessmentResponseType } from '@/features/assessments';
import { axios } from '@/lib/axios';

const BASE_URL = '/assessments/templates';
interface IAssessment {
  responseObj: AssessmentResponseType[];
}

interface ISaveAssessment {
  responseObj: AssessmentResponseType;
}

export const getOneAssessmentTemplate = (assessment_id: string) => {
  return axios
    .get<AssessmentResponseType[]>(`${BASE_URL}/${assessment_id}`)
    .then((data: AxiosResponse | ISaveAssessment): AssessmentResponseType => {
      return (data as ISaveAssessment)?.responseObj;
    });
};

export const getQueryAssessmentTemplate = (
  query: string | string[][] | Record<string, string> | URLSearchParams | undefined
) => {
  const queryString = new URLSearchParams(query).toString();
  return axios
    .get<AssessmentResponseType[]>(queryString ? `${BASE_URL}?${queryString}` : BASE_URL)
    .then((data: AxiosResponse | IAssessment): AssessmentResponseType[] => {
      return (data as IAssessment)?.responseObj || [];
    });
};

export const updateAssessmentTemplate = (data: AssessmentBaseType) => {
  return axios
    .put<AssessmentResponseType>(`${BASE_URL}/${data.assessment_id}`, data)
    .then((data: AxiosResponse | ISaveAssessment): AssessmentResponseType => {
      return (data as ISaveAssessment)?.responseObj;
    });
};

export const createAssessmentTemplate = (data: AssessmentBaseType) => {
  return axios
    .post<AssessmentResponseType>(BASE_URL, data)
    .then((data: AxiosResponse | ISaveAssessment): AssessmentResponseType => {
      return (data as ISaveAssessment)?.responseObj;
    });
};

export const deleteAssessmentTemplate = (
  assessmentID: string
): Promise<{ responseObj: AssessmentResponseType[] }> => {
  return axios.delete(`${BASE_URL}/${assessmentID}`);
};
