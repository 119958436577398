import { useNavigate } from 'react-router-dom';

import PublishedImage from '@/assets/ventureverse/rocket.svg';
import { Button } from '@/components/Elements';

export const AssessmentPublished = ({ setPublish }: { setPublish?: (flag: boolean) => void }) => {
  const navigate = useNavigate();
  return (
    <div className="container assessment__main published-container">
      <p className="published-title">Reflection published</p>
      <p className="published-content">
        Your reflection is now live and can be accessed by team members for evaluation.
      </p>
      <p className="published-content">
        An email has sent to each team member inbox notifying them of the new reflection.
      </p>
      <Button
        size="sm"
        variant="primary"
        className="published-button"
        onClick={() => {
          navigate(`../..`, { replace: true });
          if (setPublish) setPublish(false);
        }}
      >
        Create new reflection
      </Button>
      <img className="published-image" src={PublishedImage} alt="confirmation page" />
    </div>
  );
};
