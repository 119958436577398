import * as React from 'react';

import { useClientStore } from '@/stores/client';

export enum ROLES {
  CLIENT_ADMIN = 0,
  OTHER = 1,
  COFOUNDER = 2,
  SPONSOR = 3,
  LEAD = 4,
  ADMIN = 5,
  VENTURE = 6,
  ENTREPRENEUR = 7,
  USER = 8,
  OS_TEAM_MEMBER = 9,
  EIR = 10,
}

// this roles are being used in selects or element components
export const ROLES_SELECT = [
  {
    label: 'Client Administrator',
    value: ROLES.CLIENT_ADMIN,
  },
  {
    label: 'Global Administrator',
    value: ROLES.ADMIN,
  },
  {
    label: 'EIR',
    value: ROLES.EIR,
  },
  {
    label: 'OS Lead',
    value: ROLES.LEAD,
  },
  {
    label: 'OS Team Member',
    value: ROLES.OS_TEAM_MEMBER,
  },
  {
    label: 'Executive Sponsor',
    value: ROLES.SPONSOR,
  },
  {
    label: 'Cofounder',
    value: ROLES.COFOUNDER,
  },
  {
    label: 'Venture Partner',
    value: ROLES.VENTURE,
  },
  {
    label: 'Entrepreneur',
    value: ROLES.ENTREPRENEUR,
  },
  {
    label: 'Other',
    value: ROLES.OTHER,
  },
  {
    label: 'User',
    value: ROLES.USER,
  },
];

export const POLICIES = {
  'team:create': (role: any) => {
    role = parseInt(role);
    if (role <= ROLES.COFOUNDER) {
      return true;
    }
    if (role === ROLES.ADMIN) {
      return true;
    }
    return false;
  },
};

export const getRoleLabel = (role: number | string) => {
  const roleInfo = ROLES_SELECT.filter(({ value }) => {
    return +role === value;
  });

  if (roleInfo.length > 0) {
    return roleInfo[0].label;
  } else {
    return role;
  }
};

export const useAuthorization = () => {
  const { clients } = useClientStore();
  const selectedClient = clients?.filter((client) => client.client_selected);

  const role = selectedClient[0]?.role_id;

  const checkAccess = React.useCallback(
    ({ allowedRoles }: { allowedRoles: number[] }) => {
      if (allowedRoles && allowedRoles.length > 0 && role) {
        return Object.values(allowedRoles).includes(+role);
      }
      return false;
    },
    [role]
  );
  return { checkAccess, role };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
} & (
  | {
      allowedRoles: number[];
      policyCheck?: never;
    }
  | {
      allowedRoles?: never;
      policyCheck: boolean;
    }
);

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
