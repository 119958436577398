import { useAuthenticator } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronRightIcon } from '@/assets/icons/arrowRight.svg';
import { Card } from '@/components';
import CardContent from '@/components/card/card-content';

// this mock data will be remove once BE API endpoint is ready.
const itemList = [
  {
    name: 'Complete reflection for Team Spaghettis',
    dueDate: '10/06/2024',
    linkName: 'GrowthCatalyst',
    link: '/app/assessment/take',
  },
  {
    name: 'View results of Team Baseline Reflection',
    linkName: 'Results Hub',
    link: '/app/assessment/results',
  },
  {
    name: 'Complete CultureDNA for Team Blue Bazooka',
    dueDate: '10/06/2024',
    linkName: 'GrowthCatalyst',
    link: '/app/assessment/take',
  },
];

export const TodoList = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  return (
    <Card
      innerHeader={`You have ${itemList.length} tasks awaiting your action!`}
      outerHeader={`${user.attributes?.name}'s to-do list`}
    >
      <CardContent>
        <div className="widget-wrapper">
          {itemList.map((item, i) => (
            <div key={i} className={`item-row${itemList.length - 1 === i ? ' no-border' : ''}`}>
              <span className="column-1">{item.name}</span>
              <span className="column-2">{item.dueDate}</span>
              <span className="column-3">
                <Link to={item.link}>Go To {item.linkName}</Link>
                <ChevronRightIcon width={20} height={10} />
              </span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default TodoList;
