import * as zod from 'zod';

export const validations = zod.object({
  fullname: zod.string().nonempty('This is a required field.'),
  email: zod
    .string()
    .nonempty('This is a required field.')
    .email('This is not a valid email address.'),
  company: zod.string().nonempty('This is a required field.'),
  reason: zod.string(),
});
