import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type UserType = {
  name?: string;
  email: string;
  custom_role?: number;
  client?: string;
};

const createUser = (data: UserType[]): Promise<any> => {
  return axios.post('/users', data);
};

type UseCreateUserOptions = {
  config?: MutationConfig<typeof createUser>;
};

export const useCreateUser = ({ config }: UseCreateUserOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      addNotification({
        type: 'error',
        title: context,
      });
    },
    onSuccess: (data) => {
      const response = data[0];

      if (response?.status === 'rejected') {
        addNotification({
          type: 'error',
          title: response?.reason?.message,
        });
      }
    },
    ...config,
    mutationFn: createUser,
  });
};
