import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
  Legend,
  PolarRadiusAxis,
  Text,
  Dot,
} from 'recharts';

import { Slider, Spinner } from '@/components/Elements';
import { useSlider } from '@/hooks/useSlider';

import { useStatHubStore } from '../../stores/resultsHubTeamDropdown';

import { EmptyState } from './EmptyState';
import Nav from './Nav';
import SkillsSelector from './SkillsSelector';

const skills = ['Validate', 'Build'];

export const ResultsHub = React.memo(() => {
  const { assessmentId } = useParams();
  const { register, watch } = useForm({
    mode: 'all',
    defaultValues: {
      main: 'MY_TEAM_SELF_ASSESSMENT',
      second: 'NO_COMPARISON',
      teamMain: 'MY_TEAM_AVERAGE',
      teamSecond: 'NO_COMPARISON',
    },
  });

  const [categorySelected, categorySelectedSet] = useState('');
  const [legendSelected, legendSelectedSet] = useState('');
  const [chartType, chartTypeSet] = useState('MINDSET');
  const [skill, skillSet] = useState('Build');
  const [hideScore, hideScoreSet] = useState(false);

  const mainSelect = watch('main');
  const secondSelect = watch('second');
  const teamMainSelect = watch('teamMain');
  const teamSecondSelect = watch('teamSecond');
  const [historicalSelect, setHistoricalSelect] = useState(assessmentId || '');

  const [isLoading, setIsLoading] = useState(false);
  const [isError] = useState(false);
  const {
    assessmentName,
    assessmentDate,
    isCompleted,
    dataKeys,
    colors,
    colorsData,
    mappedChartData,
    chartResultsData,
    teamAssessmentDropdown,
    fetchTeamAssessmentDropdown,
    fetchChart,
  } = useStatHubStore();

  useEffect(() => {
    setHistoricalSelect(assessmentId || '');
    chartTypeSet('MINDSET');
  }, [assessmentId]);

  useEffect(() => {
    if (!teamAssessmentDropdown) {
      fetchTeamAssessmentDropdown();
    }
  }, [teamAssessmentDropdown, fetchTeamAssessmentDropdown]);

  useEffect(() => {
    if (mainSelect && teamMainSelect) {
      setIsLoading(true);
      fetchChart(
        chartType,
        chartType === 'TEAM' ? teamMainSelect : mainSelect,
        chartType === 'TEAM' ? teamSecondSelect : secondSelect,
        historicalSelect
      ).then(() => {
        setIsLoading(false);
      });
    }
  }, [chartType, mainSelect, secondSelect, teamMainSelect, teamSecondSelect, historicalSelect]);

  const slider = useSlider((chartResultsData as any)?.chart);
  const { setLabelActive, setChartActive, labelActive } = slider;

  useEffect(() => {
    if (labelActive !== categorySelected) {
      categorySelectedSet(labelActive);
    }
  }, [categorySelected, labelActive]);

  useMemo(() => {
    if (chartResultsData) {
      const chartData = (chartResultsData as any)?.chart || [];
      if (chartData[0]) {
        categorySelectedSet(chartData[0].question_name || '');
        setLabelActive(chartData[0].question_name || '');
      }

      legendSelectedSet(dataKeys[0] || '');
      setChartActive(dataKeys[0] || '');
    }

    return {};
  }, [chartResultsData, dataKeys, setChartActive, setLabelActive]);

  const CustomizedLegend = (props: any) => {
    const { payload } = props;
    const payload_tmp = payload.sort((a: any, b: any) => b.value - a.value);

    const scoreTitle = hideScore ? (
      <ChevronDownIcon className="icon-chevron__down" />
    ) : (
      <ChevronUpIcon className="icon-chevron__up" />
    );

    return (
      <ul className="results-text">
        <li>
          {
            <ol>
              <li
                className="results-text bold"
                style={{
                  marginBottom: '11px',
                  marginTop: '25px',
                  marginLeft: '20px',
                }}
              >
                <span>Legend</span>
              </li>
              <li
                style={{
                  maxHeight: '125px',
                  overflowX: 'auto',
                  marginBottom: '15px',
                }}
              >
                <ul>
                  {payload_tmp.map((entry: any, index: number) => (
                    <li
                      key={`item-${index}`}
                      className="recharts-legend-item legend-item-0"
                      style={{
                        display: 'block',
                        marginTop: 5,
                        marginRight: 5,
                        marginBottom: 5,
                        marginLeft: 5,
                      }}
                    >
                      <button
                        onClick={() => {
                          legendSelectedSet(entry.value);
                        }}
                      >
                        <svg
                          className="recharts-surface"
                          width="14"
                          height="14"
                          viewBox="0 0 32 32"
                          version="1.1"
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginRight: '4px',
                          }}
                        >
                          <title></title>
                          <desc></desc>
                          <path
                            fill={colorsData[entry.value]}
                            cx="16"
                            cy="16"
                            type="circle"
                            className="recharts-symbols"
                            transform="translate(16, 16)"
                            d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
                          ></path>
                        </svg>
                        <span
                          className="recharts-legend-item-text"
                          style={{
                            color: 'black',
                            fontWeight: legendSelected === entry.value ? 'bolder' : 'initial',
                            fontSize: '15px',
                          }}
                        >
                          {entry.value}
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
              <li
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <ul style={{ width: '150px' }}>
                  <li style={{ fontWeight: 'bold', marginLeft: '14px', marginBottom: '11px' }}>
                    <button
                      className="score-definitions flex w-full whitespace-nowrap"
                      onClick={() => hideScoreSet(!hideScore)}
                    >
                      Score Definitions &nbsp;{scoreTitle}
                    </button>
                  </li>
                  {!hideScore && (
                    <>
                      {(chartResultsData as any).score_definition.map((x: any, i: number) => {
                        return (
                          <li key={`score-${i}`} style={{ marginBottom: '11px' }}>
                            <span style={{ whiteSpace: 'nowrap' }}>{i} =&nbsp;</span>
                            <span>{x.tooltip}</span>
                          </li>
                        );
                      })}
                    </>
                  )}
                </ul>
              </li>
            </ol>
          }
        </li>
      </ul>
    );
  };

  const renderPolarAngleAxis = useCallback(
    ({ payload, ...props }) => {
      return (
        <Text
          {...props}
          fill={categorySelected === payload.value ? colorsData[legendSelected] : ''}
          fontWeight={categorySelected === payload.value ? 'bold' : ''}
          verticalAnchor="middle"
          style={{ width: '10px' }}
          fontSize="12px"
          onClick={() => {
            categorySelectedSet(payload.value);
            setLabelActive(payload.value);
          }}
          width={30}
        >
          {payload.value}
        </Text>
      );
    },
    [categorySelected, colors, dataKeys, legendSelected, setLabelActive]
  );

  const chartTypes: { onclick: () => void; label: string; selected: boolean }[] = useMemo(() => {
    return [
      {
        onclick: () => chartTypeSet('MINDSET'),
        label: 'Mindset',
        selected: chartType === 'MINDSET',
      },
      {
        onclick: () => chartTypeSet('SKILL'),
        label: 'Skills',
        selected: chartType === 'SKILL',
      },
      {
        onclick: () => chartTypeSet('TEAM'),
        label: 'Team Dynamics',
        selected: chartType === 'TEAM',
      },
    ];
  }, [chartType]);

  const chartTypeSelected = useMemo(() => {
    return chartTypes.filter((x) => x.selected)?.[0]?.label || '';
  }, [chartTypes]);

  const CustomDot = useCallback(
    ({ dataKey, payload, fill, ...rest }) => {
      let overrideLegend = legendSelected;
      if (
        dataKey !== legendSelected &&
        payload.name === categorySelected &&
        payload.payload[dataKey] === payload.payload[legendSelected]
      ) {
        overrideLegend = dataKey;
        rest.stroke = colorsData[legendSelected];
        fill = colorsData[legendSelected];
      }

      return (
        <Dot
          {...rest}
          r={7}
          fillOpacity={1}
          fill={dataKey === overrideLegend && payload.name === categorySelected ? fill : '#FFFFFF'}
          onClick={() => {
            categorySelectedSet(payload.name);
            setLabelActive(payload.name);
          }}
        />
      );
    },
    [categorySelected, legendSelected, setLabelActive, categorySelectedSet, dataKeys, colors]
  );

  return (
    <div className="container">
      {isError ? (
        <>
          <Nav
            chartTypes={chartTypes}
            chartTypeSelected={chartTypeSelected}
            register={register}
            mainSelect={mainSelect}
            teamMainSelect={teamMainSelect}
            setHistoricalSelect={setHistoricalSelect}
            historicalSelect={historicalSelect}
            historicalOptions={teamAssessmentDropdown}
          />
          <EmptyState />
        </>
      ) : (
        <>
          {isLoading ? (
            <div
              className="h-[425px] flex"
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Spinner />
            </div>
          ) : mappedChartData.length === 0 ? (
            <>
              <Nav
                chartTypes={chartTypes}
                chartTypeSelected={chartTypeSelected}
                register={register}
                mainSelect={mainSelect}
                teamMainSelect={teamMainSelect}
                setHistoricalSelect={setHistoricalSelect}
                historicalSelect={historicalSelect}
                historicalOptions={teamAssessmentDropdown}
              />
              <EmptyState />
            </>
          ) : (
            <>
              <Nav
                chartTypes={chartTypes}
                chartTypeSelected={chartTypeSelected}
                register={register}
                mainSelect={mainSelect}
                teamMainSelect={teamMainSelect}
                setHistoricalSelect={setHistoricalSelect}
                historicalSelect={historicalSelect}
                historicalOptions={teamAssessmentDropdown}
              />
              <div
                style={{
                  marginTop: '25px',
                  height: '575px',
                  marginBottom: chartTypeSelected === 'Skills - future' ? '65px' : 'none',
                }}
              >
                <div className="header-wrapper">
                  <h3 className="header">{chartTypeSelected}</h3>
                  {isCompleted ? (
                    <p className="completed">Completed</p>
                  ) : (
                    <p className="in-progress">In Progress</p>
                  )}
                </div>
                <h4 className="w-full text-center font-normal py-1">
                  {assessmentName} ({assessmentDate})
                </h4>
                {chartTypeSelected === 'Skills - future' && (
                  <SkillsSelector data={skills} defaultValue={skill} setSkill={skillSet} />
                )}
                {!!mappedChartData?.length && (
                  <>
                    <ResponsiveContainer minWidth={'425px'}>
                      <RadarChart
                        cx="35%"
                        innerRadius="30"
                        outerRadius="70%"
                        data={mappedChartData}
                        title={chartTypeSelected}
                      >
                        <Legend
                          verticalAlign="top"
                          align="right"
                          layout="vertical"
                          content={<CustomizedLegend />}
                        />
                        <PolarRadiusAxis
                          domain={[0, 4]}
                          tickFormatter={(value: number) => String(Math.ceil(value))}
                          angle={90}
                        />
                        <PolarGrid />
                        <PolarAngleAxis dataKey="label" tick={renderPolarAngleAxis} />
                        <div
                          className="w-full text-center"
                          style={{
                            fontFamily: 'Graphik',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '29px',
                            textAlign: 'center',
                            letterSpacing: '-0.05em',
                          }}
                        >
                          <span>{chartTypeSelected}</span>
                        </div>
                        {dataKeys.map((x: string) => {
                          return (
                            <Radar
                              key={x}
                              dataKey={x}
                              stroke={colorsData[x]}
                              fill={colorsData[x]}
                              fillOpacity={0}
                              strokeWidth={legendSelected === x ? 2.5 : 1.7}
                              isAnimationActive={false}
                              dot={CustomDot}
                            />
                          );
                        })}
                      </RadarChart>
                    </ResponsiveContainer>
                  </>
                )}
              </div>
              <div className="team-list__divider" />
              <div className="w-full mt-5">
                {slider && (
                  <Slider
                    className="mt-4"
                    onArrowClick={slider.setLabel}
                    labelActive={labelActive}
                    labelColor={colorsData[legendSelected]}
                    chartType={chartTypeSelected === 'Skills' ? 'Skill' : chartTypeSelected}
                    {...slider.dataFiltered}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
});
