import { useEffect } from 'react';
import { useNavigate, useBlocker } from 'react-router-dom';

import { Button, Dialog, DialogTitle } from '@/components/Elements/';
import { useLeavePageStore } from '@/stores/leavePage';

export const LeavePage = () => {
  const { isOpen, close, open, URL, isBlocker, removeBlocker, setURL } = useLeavePageStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isBlocker && isOpen) {
      close();
      navigate(URL);
    }
  }, [URL, close, isBlocker, isOpen, navigate]);

  useBlocker((data) => {
    const { pathname } = data.location;

    if (!isOpen) {
      setURL(pathname);
      open();
    }
  }, isBlocker);

  const onLeave = () => {
    removeBlocker();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="leave-page modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Leave page?
          </DialogTitle>
          <div className="modal__info">
            <p>
              Are you sure you want to navigate away from this page? Any changes you have made on
              this page will not be saved.
            </p>
          </div>
          <div className="leave-page__actions">
            <Button onClick={onLeave} variant="inverse">
              Leave
            </Button>
            <Button onClick={close}>Stay</Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
