import clsx from 'clsx';
import { useCallback, useState, useMemo } from 'react';

import { AddButton, DropdownMenu } from '@/components/Elements';

export type MethodDropdownOptionsType = {
  options?: {
    label: string;
    onClick?: () => void;
    icon?: React.ReactNode;
  }[];
};

type IMethodDropdownType = {
  open?: boolean;
  setOpen?: () => void;
  className?: string;
} & MethodDropdownOptionsType;

export const MethodDropdown = ({ options = [], setOpen, open, className }: IMethodDropdownType) => {
  const [openDefault, openDefaultSet] = useState(false);

  const openDropdown = useCallback(() => {
    setOpen ? setOpen() : openDefaultSet(!openDefault);
  }, [openDefault, setOpen]);

  const openFlag = useMemo(() => {
    return open !== undefined ? open : openDefault;
  }, [open, openDefault]);

  return (
    <div className={clsx('dropdown', className)}>
      <AddButton
        onClick={openDropdown}
        className={clsx(openFlag ? 'add-button-close' : '')}
        size="sm"
      />
      <DropdownMenu options={options} open={openFlag} setOpen={openDropdown} />
    </div>
  );
};
