import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

interface IMembersByTeam {
  responseObj: any;
}

export const getAssessmentsPerTeam = (teamID: string | null) => {
  if (teamID) {
    return axios
      .get<IMembersByTeam>(`/teams/${teamID}/assessments`)
      .then((data: any) => data.responseObj)
      .catch(() => []);
  }
};

type QueryFnType = typeof getAssessmentsPerTeam;

type useAssessmentsPerTeamOptions = {
  config?: QueryConfig<QueryFnType>;
  teamID: string | null;
};

export const useAssessmentsPerTeam = (
  { config, teamID }: useAssessmentsPerTeamOptions = { teamID: '' }
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    onError: () => {},
    queryKey: ['getAssessmentsPerTeam', teamID],
    queryFn: () => getAssessmentsPerTeam(teamID),
  });
};
