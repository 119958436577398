import { Link } from 'react-router-dom';

import { ASSESSMENT_PHASE, AssessmentResponseType } from '@/features/assessments';
import { formatDateFull } from '@/utils/format';

import { ListData } from './ManageAssessment';

const returnDate = (
  general_settings: any,
  phase:
    | ASSESSMENT_PHASE.UPCOMING
    | ASSESSMENT_PHASE.IN_PROGRESS
    | ASSESSMENT_PHASE.PAST_DUE
    | ASSESSMENT_PHASE.CLOSED
) => {
  let date = '';
  if (phase === ASSESSMENT_PHASE.UPCOMING)
    date = `— Launch ${formatDateFull(general_settings.launch_date)}`;
  else if (phase === ASSESSMENT_PHASE.CLOSED)
    date = `— Complete ${formatDateFull(general_settings.due_date)}`;
  else if (phase === ASSESSMENT_PHASE.IN_PROGRESS || phase === ASSESSMENT_PHASE.PAST_DUE)
    date = `— Due ${formatDateFull(general_settings.due_date)}`;
  return <span>{date}</span>;
};

const returnPhase = (
  phase:
    | ASSESSMENT_PHASE.UPCOMING
    | ASSESSMENT_PHASE.IN_PROGRESS
    | ASSESSMENT_PHASE.PAST_DUE
    | ASSESSMENT_PHASE.CLOSED,
  admin_closed: boolean | undefined
) => {
  let phaseText = '';
  let phaseClass = '';

  switch (phase) {
    case ASSESSMENT_PHASE.UPCOMING:
      phaseText = 'Upcoming';
      phaseClass = 'upcoming';
      break;
    case ASSESSMENT_PHASE.CLOSED:
      if (!admin_closed) {
        phaseText = 'Complete';
        phaseClass = 'closed';
      } else if (admin_closed) {
        phaseText = 'Admin Closed';
        phaseClass = 'admin-closed';
      }
      break;
    case ASSESSMENT_PHASE.IN_PROGRESS:
      phaseText = 'In Progress';
      phaseClass = 'in-progress';
      break;
    case ASSESSMENT_PHASE.PAST_DUE:
      phaseText = 'Past Due';
      phaseClass = 'past-due';
      break;
  }

  return <span className={`bold phase ${phaseClass}`}>{phaseText}</span>;
};

export const ListPhase = ({
  listData,
  phase,
}: {
  listData?: ListData;
  phase:
    | ASSESSMENT_PHASE.UPCOMING
    | ASSESSMENT_PHASE.IN_PROGRESS
    | ASSESSMENT_PHASE.PAST_DUE
    | ASSESSMENT_PHASE.CLOSED;
}) => {
  if (!listData) return null;

  const { data } = listData;

  return (
    <ul>
      {data &&
        data.map(
          (
            {
              assessment_name,
              general_settings,
              assessment_id,
              admin_closed,
            }: AssessmentResponseType,
            key: number
          ) => {
            return (
              <li key={`${assessment_name}-${key}`} className="container">
                <p className="bold">
                  {assessment_name} {returnDate(general_settings, phase)}
                  {returnPhase(phase, admin_closed)}
                </p>
                <Link
                  to={`/app/teams/edit-assessment/${assessment_id}?phase=${phase}`}
                  className="item-arrow"
                ></Link>
              </li>
            );
          }
        )}
    </ul>
  );
};
