import { ReactComponent as RobotIcon } from '@/assets/icons/robot.icon.svg';

export const ChatTyping = () => {
  return (
    <div className="message">
      <div className="wrapper">
        <RobotIcon className="icon" />
        <div className="mentor">
          <div className="typing-indicator">
            <ul>
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatTyping;
