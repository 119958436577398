import { useMemo } from 'react';

import { ContentLayout } from '@/components/Layout';

import { useClients } from '../../clients/api/getClients';
import { useStages } from '../api/getStages';
import { Create } from '../components/Create';

export const CreateTeam = () => {
  const { isLoading: stagesLoading, data: stagesData } = useStages();
  const { isLoading: clientsLoading, data: clientsData } = useClients();

  const stages = useMemo(
    () => stagesData?.map((x) => ({ label: x.stage_name, value: x.stage_id })) || [],
    [stagesData]
  );

  const clients = useMemo(
    () => clientsData?.map((x) => ({ label: x.client_name || '', value: x.client_id || '' })) || [],
    [clientsData]
  );

  return (
    <ContentLayout title="Create a new team" back={'teams'}>
      <Create
        loading={stagesLoading || clientsLoading}
        stagesOptions={stages}
        clientsOptions={clients}
      />
    </ContentLayout>
  );
};
