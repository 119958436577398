import { Button, Dialog, DialogTitle } from '@/components/Elements';

type DeleteTeamDialogProps = {
  isOpen: boolean;
  close: () => void;
  deleteTeam: boolean;
  onDelete: () => void;
  deleteTeamLoading: boolean;
};

export const DeleteTeamDialog = ({
  isOpen,
  close,
  onDelete,
  deleteTeamLoading,
}: DeleteTeamDialogProps) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Delete team
          </DialogTitle>
          <div className="modal__info">
            <p>
              Please confirm that you would like to delete this team profile. Team profiles sent to
              the trash can cannot be recovered.
            </p>
          </div>
          <div className="modal__footer modal-row justify-end">
            <Button type="button" variant="inverse" onClick={close}>
              Cancel
            </Button>
            <Button onClick={onDelete} isLoading={deleteTeamLoading} variant="danger">
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
