import { useNavigate } from 'react-router-dom';

import growthCatalystCardImageWidget from '@/assets/dashboard/growthcatalyst-card-image-widget.png';
import { Card } from '@/components';
import CardContent from '@/components/card/card-content';
import { Button } from '@/components/Elements';
// import { useDisclosure } from '@/hooks/useDisclosure';

// import { ContactUsDialog } from '../contact-us-modal';

export interface GrowthCatalystWidgetProps {
  className?: string;
}

export const GrowthCatalystWidget = (props: GrowthCatalystWidgetProps) => {
  // const { isOpen, close, open } = useDisclosure();
  const navigate = useNavigate();
  return (
    <>
      {/* <ContactUsDialog isOpen={isOpen} close={close} /> */}
      <Card
        {...props}
        outerHeader=""
        image={{ file: <img src={growthCatalystCardImageWidget} alt="" /> }}
        actions={
          <div>
            <Button type="button" size="sm" onClick={() => navigate('/app/assessment/results')}>
              See your Insights
            </Button>
            {/* <Button type="button" size="sm" variant="inverse" onClick={open}> */}
            <Button type="button" size="sm" className="disabled">
              Request more info
            </Button>
          </div>
        }
      >
        <CardContent>
          <div className="widget-wrapper">
            <div className="title">
              GrowthCatalyst: Where self-awareness meets professional development{' '}
            </div>
            <p>
              {`Dive deep into your behaviors, skills, and team's dynamics that are crucial for growth.
            Engage in meaningful reflections, gather comprehensive feedback, and crystallize
            insights into your collective strengths.`}
            </p>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default GrowthCatalystWidget;
