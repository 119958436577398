import { useMutation } from 'react-query';

import { SectionBaseType, AssessmentResponseType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type PostAnswers = {
  data?: SectionBaseType[];
  email: string;
};

export const postCode = ({
  data,
  email,
}: PostAnswers): Promise<{ responseObj: AssessmentResponseType[] }> => {
  return axios.post(`users/${email}/sendcode`, data);
};

type UseSendCode = {
  config?: MutationConfig<typeof postCode>;
};

export const useSendCode = ({ config }: UseSendCode = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      console.log('context', context);
    },
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Code successfully send!',
      });
    },
    ...config,
    mutationFn: postCode,
  });
};
