import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { IQuestions, QuestionResponseType } from '../types';

export const getQuestions = () => {
  return axios
    .get('/assessments/questions')
    .then(
      (data: AxiosResponse | IQuestions): QuestionResponseType[] =>
        (data as IQuestions)?.responseObj
    );
};

type QueryFnType = typeof getQuestions;

type UseQuestionsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useQuestions = ({ config }: UseQuestionsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['questions'],
    queryFn: () => getQuestions(),
  });
};
