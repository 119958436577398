import clsx from 'clsx';
import { useMemo } from 'react';

export interface IStepProgressBar {
  stepsTotal?: number;
  selectedTotal?: number;
}

export const StepProgressBar = ({ stepsTotal = 0, selectedTotal = 0 }: IStepProgressBar) => {
  const barStepsPogress = useMemo((): boolean[] => {
    const result: boolean[] = [];
    for (let i = 0, j = selectedTotal; i < stepsTotal; i++, j--) {
      result.push(j > 0);
    }
    return result;
  }, [stepsTotal, selectedTotal]);

  return (
    <div className="steps-wrapper" style={{ gridTemplateColumns: `repeat(${stepsTotal}, 1fr)` }}>
      {barStepsPogress.map((x, i) => (
        <div className={clsx('steps-step', x ? 'steps-selected' : '')} key={i}>
          {i}
        </div>
      ))}
    </div>
  );
};
