import clsx from 'clsx';
import { useCallback, useContext, useMemo, useState, useEffect, useRef } from 'react';
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { DropdownRole, IOptionRole, Spinner } from '@/components/Elements';
import { ListContext, useTeamRolesStore } from '@/features/teams';
import { useUsers } from '@/features/users';
import { useDisclosure } from '@/hooks/useDisclosure';

import { DeleteRoleDialog } from '../dialogs/DeleteRole';

interface IMemberAdd {
  loading: boolean;
  register: UseFormRegister<{
    name: string;
    email: string;
    role: string;
    roleId: string;
  }>;
  setValue: UseFormSetValue<{
    name: string;
    email: string;
    role: string;
    roleId: string;
  }>;
  errors: DeepMap<FieldValues, FieldError>;
  watch: UseFormWatch<{
    name: string;
    email: string;
    role: string;
    roleId: string;
  }>;
  openNewRoleModal: () => void;
  newRoleTitle?: string;
}

export const MemberAdd: React.FC<IMemberAdd> = ({
  errors,
  loading,
  watch,
  setValue,
  register,
  openNewRoleModal,
  newRoleTitle,
}) => {
  const { editState } = useContext(ListContext);
  const [listUserSelected, setListUserSelected] = useState(-1);
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailMouseEnter, setEmailMouseEnter] = useState(false);
  const { isLoading: usersLoading, data: usersData } = useUsers();
  const [editInputDisabled, setEditInputDisabled] = useState(false);
  const [openDefault, openDefaultSet] = useState(true);
  const [open, openSet] = useState(false);
  const [selectedValue, setSelectedValue] = useState<IOptionRole | undefined>(undefined);
  const [dropdownOptions, setDropdownOptions] = useState<IOptionRole[]>([]);

  const { teamRoles, getRoles, deleteRole, replaceRole } = useTeamRolesStore();
  const [deleteRoleDetails, setDeleteRoleDetails] = useState<{ option?: IOptionRole }>({});

  useEffect(() => {
    getRoles();
  }, []);

  const setEmail = useCallback(
    (email: string) => {
      setValue('email', email, { shouldValidate: true, shouldDirty: true });
    },
    [setValue]
  );
  const setName = useCallback(
    (name: string) => {
      setValue('name', name, { shouldValidate: true, shouldDirty: true });
    },
    [setValue]
  );

  const setRole = useCallback(
    (role: string) => {
      setValue('role', role, { shouldValidate: true, shouldDirty: true });
    },
    [setValue]
  );

  const {
    open: openDeleteRoleModal,
    close: closeDeleteRoleModal,
    isOpen: isOpenDeleteRoleModal,
  } = useDisclosure();

  //defining dropdown role options
  useEffect(() => {
    const newDropdownOptions = teamRoles.map((role) => ({
      label: role.name || '',
      selectedLabel: role.value,
      system: role.system,
      onClick: () => {
        setSelectedValue({ label: role.name || '' });
        setRole(role.name || '');
      },
    }));

    // Add the "+Add New Role" option
    if (teamRoles.length > 0) {
      newDropdownOptions.push({
        label: '+Add New Role',
        selectedLabel: 'new_role',
        system: true,
        onClick: () => {
          openNewRoleModal();
          setSelectedValue({ label: newRoleTitle || '' });
        },
      });
      //added as a placeholder option, otherwise it was selecting the 1st value 'other' each time when dropdown clicked
      newDropdownOptions.unshift({
        label: 'Select Role',
        selectedLabel: 'Select Role',
        system: true,
        onClick: () => {
          setSelectedValue({ label: 'Select Role' });
          setRole('Select Role');
        },
      });
    }

    setDropdownOptions(newDropdownOptions);
  }, [teamRoles, openNewRoleModal, newRoleTitle, setRole]);

  const filteredMembers = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setEditInputDisabled(editState !== undefined ? !editState : false);
  }, [editState]);

  const userEmails = useMemo(() => {
    const usersResult: { [x: string]: string }[] = [];
    usersData?.forEach((x) => {
      if (x.Enabled === 'true') {
        usersResult.push({ email: x.email, name: x.name });
      }
    });
    return usersResult;
  }, [usersData]);

  const emailWatch = watch('email');

  const filterEmails = useMemo(() => {
    return emailWatch?.length
      ? userEmails.filter((x) => {
          let flag = true;
          if (x.email === emailWatch) {
            return true;
          }
          for (let i = 0; i < emailWatch.length && flag; i++) {
            flag = flag && emailWatch[i] === x.email[i];
          }
          !flag ? setListUserSelected(-1) : setListUserSelected(0);
          return flag;
        })
      : [];
  }, [emailWatch, userEmails]);

  const handleKeyDown = useCallback(
    (e) => {
      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && listUserSelected > 0) {
        if (filteredMembers?.current && listUserSelected >= 3) {
          const offset = filteredMembers.current.offsetTop - 0.1;
          filteredMembers.current.scrollBy(0, offset);
        }
        setListUserSelected(listUserSelected - 1);
      } else if (e.keyCode === 40 && listUserSelected < filterEmails.length - 1) {
        if (filteredMembers?.current && listUserSelected >= 3) {
          const offset = filteredMembers.current.offsetTop + 0.1;
          filteredMembers.current.scrollBy(0, offset);
        }
        setListUserSelected(listUserSelected + 1);
      } else if (e.keyCode === 13) {
        e.preventDefault();
        filterEmails[listUserSelected]?.email && setEmail(filterEmails[listUserSelected].email);
        filterEmails[listUserSelected]?.name && setName(filterEmails[listUserSelected].name);
      }
    },
    [filterEmails, listUserSelected, setEmail, setName]
  );

  const handleClick = useCallback(
    (e, email: undefined | string = undefined, name: undefined | string = undefined) => {
      e.preventDefault();
      email && setEmail(email);
      name && setName(name);
    },
    [setEmail, setName]
  );

  const handleDelete = (option: IOptionRole) => {
    setDeleteRoleDetails({ option });
    openDeleteRoleModal();
  };

  const confirmDelete = async (roleToAssign: string) => {
    if (deleteRoleDetails.option) {
      const teamRoleId = deleteRoleDetails.option.selectedLabel?.toString();
      if (teamRoleId) {
        const roleData = { team_role_id: teamRoleId, label: deleteRoleDetails.option.label };
        await replaceRole(roleData, roleToAssign);
        await deleteRole(roleData);
        getRoles();
      }
      closeDeleteRoleModal();
      window.location.reload();
    }
  };

  const openFlag = useMemo(() => {
    return open !== undefined ? open : openDefault;
  }, [open, openDefault]);

  const setOpen = useCallback(() => {
    openSet(!open);
  }, [open]);

  const openDropdown = useCallback(() => {
    setOpen ? setOpen() : openDefaultSet(!openDefault);
  }, [openDefault, setOpen]);

  useEffect(() => {
    setEditInputDisabled(editState !== undefined ? !editState : false);
    // Check if there is a user with the current email in filterEmails
    const currentUser = filterEmails.find((user) => user.email === emailWatch);
    if (currentUser) {
      // Autofill the 'name' field
      setValue('name', currentUser.name, { shouldValidate: true, shouldDirty: true });
    }
  }, [editState, emailWatch, filterEmails, setValue]);

  return (
    <>
      {usersLoading ? (
        <tr>
          <td colSpan={4}>
            <Spinner />
          </td>
        </tr>
      ) : (
        <>
          <tr className="member-add">
            <td className="align-baseline" colSpan={2}>
              <div className="member-add__input">
                <div className="member-add__input-wrapper">
                  <input
                    id="name"
                    {...register('name')}
                    className={errors?.name ? 'error' : ''}
                    disabled={editInputDisabled}
                    maxLength={10000}
                  />
                </div>
              </div>
            </td>
            <td className="align-baseline">
              <div className="member-add__input">
                <div className="member-add__input-wrapper">
                  <input
                    id="email"
                    placeholder="Search for existing users"
                    disabled={editInputDisabled}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    onFocus={() => setEmailFocus(true)}
                    onPaste={() => setValue('email', '')}
                    {...register('email')}
                    onBlur={() => setEmailFocus(false)}
                    maxLength={10000}
                  />
                  {(!!filterEmails?.length || !!emailWatch?.length) && (
                    <ul
                      className="member-add__list top-[2.3rem]"
                      hidden={!(emailFocus || emailMouseEnter)}
                      ref={filteredMembers}
                    >
                      {filterEmails?.length ? (
                        <>
                          {filterEmails.map((x, i) => (
                            <button
                              key={i}
                              tabIndex={i}
                              className={clsx(
                                'w-full flex gap-4 px-4 member-add__list-option',
                                listUserSelected === i && 'hover'
                              )}
                              onClick={(e) => handleClick(e, x.email, x.name)}
                              onMouseEnter={() => setEmailMouseEnter(true)}
                              onMouseLeave={() => setEmailMouseEnter(false)}
                              onKeyDown={handleKeyDown}
                              onKeyPress={handleKeyDown}
                            >
                              <span className="bold">{x.name || 'Pending Login'}</span>
                              <span>{x.email}</span>
                            </button>
                          ))}
                        </>
                      ) : (
                        <button
                          tabIndex={-1}
                          className="w-full flex gap-4 px-4 member-add__list-option"
                          onMouseEnter={() => setEmailMouseEnter(true)}
                          onMouseLeave={() => setEmailMouseEnter(false)}
                          onClick={handleClick}
                        >
                          <span>No existing users found</span>
                        </button>
                      )}
                    </ul>
                  )}
                  <span className="error-icon"></span>
                </div>
              </div>
            </td>
            <td className="align-baseline__role-select">
              <DropdownRole
                options={dropdownOptions}
                open={openFlag}
                disabled={editInputDisabled}
                setOpen={openDropdown}
                selectedValue={selectedValue}
                placeholder="Select Role"
                registration={register('role')}
                handleDelete={handleDelete}
              />
            </td>
            <td className="align-baseline">
              <button
                disabled={editInputDisabled || loading}
                className="member-add__button"
                type="submit"
              >
                + ADD
              </button>
            </td>
          </tr>
          <tr className="member-add">
            {errors.name ? (
              <td className="align-baseline__error" colSpan={2}>
                {errors.name.message}
              </td>
            ) : (
              <td className="align-baseline__error" colSpan={2}></td>
            )}
            {errors.email ? (
              <td className="align-baseline__error">{errors.email.message}</td>
            ) : (
              <td className="align-baseline__error"></td>
            )}
            {errors.role ? (
              <td className="align-baseline__error">{errors.role.message}</td>
            ) : (
              <td className="align-baseline__error"></td>
            )}
          </tr>
        </>
      )}
      <DeleteRoleDialog
        isOpen={isOpenDeleteRoleModal}
        close={closeDeleteRoleModal}
        onDelete={(roleToAssign) => confirmDelete(roleToAssign)}
        roleToDelete={deleteRoleDetails?.option?.label}
        teamRoles={teamRoles}
      />
    </>
  );
};
