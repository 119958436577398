import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import {
  ChatModel,
  SideNavMax,
  ChatHeader,
  ChatMessage,
  ChatScroller,
  MessageType,
  ChatTyping,
  ChatError,
  ChatErrorAction,
  MessageReact,
} from '@/features/chat';
import { useChat } from '@/hooks/useChat';

export interface ChatBoxProps {
  data?: ChatModel[];
  chatHistory?: ChatModel[];
  isTyping?: boolean;
  isError?: boolean;
  isHistoryLoading?: boolean;
  isHistoryDeleting?: boolean;
  isVentureverse?: boolean;
  incoming?: boolean;
  selectedItemMaxMode?: Record<string, boolean>;
  containerId?: string;
  setSelectedItemMaxMode?: (sessionId?: string) => void;
  onChatSession?: (message: string, type: MessageType, scroller?: () => void) => void;
  onChatError?: (lastAction: ChatErrorAction) => void;
  onStartNewChat?: () => void;
  onLoadDataBySession?: (data?: ChatModel[]) => void;
  onChatDelete?: (sessionId: string) => void;
  onChatRename?: (sessionId: string, sessionName: string) => void;
  onMessageReact?: (messageReact: MessageReact, mentorMessageId: string) => void;
  onIncomingMessage?: () => void;
}

export const ChatBox = (props: ChatBoxProps) => {
  const { fullPagePath, chatMode, setChatMode } = useChat();
  const navigate = useNavigate();

  const [openSideNav, setOpenSideNav] = useState<boolean>(true);

  const handleOpenChatBoxFirstTime = () => {
    setChatMode('normal');
  };

  const handleChatMaximize = () => {
    setChatMode('max');
    if (fullPagePath) {
      navigate(fullPagePath);
    }
  };

  const handleChatMinimize = () => {
    if (chatMode === 'init') {
      setChatMode('normal');
    } else {
      setChatMode('init');
    }
  };

  const handleStartNewChat = () => {
    props.onStartNewChat && props.onStartNewChat();
  };

  const handleOpenSideNav = () => {
    setOpenSideNav(!openSideNav);
  };

  return (
    <>
      {chatMode === 'max' && (
        <SideNavMax
          open={openSideNav}
          data={props.chatHistory}
          chatMessages={props.data}
          isLoading={props.isHistoryLoading}
          isDeleting={props.isHistoryDeleting}
          selectedItemMaxMode={props.selectedItemMaxMode}
          setSelectedItemMaxMode={props.setSelectedItemMaxMode}
          onLoadDataBySession={props.onLoadDataBySession}
          onChatDelete={props.onChatDelete}
          onChatRename={props.onChatRename}
        />
      )}
      <div
        className={`chat-box ${chatMode}-mode${
          chatMode !== 'max' ? ' fixed right-0 bottom-0' : ''
        }${chatMode === 'max' && openSideNav ? ' with-sidenav' : ''}${
          props.isVentureverse ? ' ventureverse' : ''
        }`}
      >
        {!props.isVentureverse && (
          <div
            aria-hidden="true"
            onClick={chatMode === 'init' ? handleOpenChatBoxFirstTime : undefined}
          >
            <ChatHeader
              chatMode={chatMode}
              title={chatMode !== 'init' ? 'GrowthMentor' : ''}
              maxModeTitle={localStorage.getItem('chat-active-session-name') ?? ''}
              openSideNav={openSideNav}
              isHistoryLoading={props.isHistoryLoading}
              onChatMinimize={handleChatMinimize}
              onChatMaximize={handleChatMaximize}
              onStartNewChat={handleStartNewChat}
              onOpenSideNav={handleOpenSideNav}
            />
          </div>
        )}
        {(chatMode === 'normal' || chatMode === 'max') && (
          <ChatScroller
            data={props.data}
            chatMode={chatMode}
            incoming={props.incoming}
            isTyping={props.isTyping}
            isHistoryLoading={props.isHistoryLoading}
            isVentureverse={props.isVentureverse}
            onChatSession={props.onChatSession}
            onIncomingMessage={props.onIncomingMessage}
          >
            {/* <div className="heading-sm">Today</div> */}
            {props.isHistoryLoading ? (
              <div className="spinner">
                <Spinner />
              </div>
            ) : (
              <ChatMessage
                data={props.data}
                isHistoryLoading={props.isHistoryLoading}
                onMessageReact={props.onMessageReact}
              />
            )}
            {props.isTyping && <ChatTyping />}
            {props.isError && <ChatError onChatError={props.onChatError} />}
          </ChatScroller>
        )}
      </div>
    </>
  );
};

export default ChatBox;
