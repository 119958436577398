import create from 'zustand';

import { useGetMentorChat, GetMentorChatPayloadType, GetMentorChatStore } from '@/features/chat';

export const useGetMentorChatStore = () => {
  const { mutateAsync, isLoading, isError, data } = useGetMentorChat();

  return create<GetMentorChatStore>(() => ({
    chatGet: data?.responseObj,
    isLoading,
    isError,
    setGetChatSession: async (payload: GetMentorChatPayloadType) => {
      await mutateAsync({ ...payload });
    },
  }));
};
