import { UseFormReturn } from 'react-hook-form';

import { TextAreaField } from '@/components/Form';
import { QuestionResponseType, QUESTION_LABELS, Scale } from '@/features/assessments';

type QuestionPropsType = {
  children: React.ReactNodeArray;
  errorMessage?: string;
  sectionIndex: number;
  qsIndex: number;
  isBaseline?: boolean;
  question_scale?: { [key: string]: string }[];
  handleBlur: () => void;
  withMembers: boolean;
  readOnly?: boolean;
} & QuestionResponseType &
  UseFormReturn;

export const Question = ({
  children,
  errorMessage,
  question_type,
  question_prompt,
  question_name,
  response,
  formState: { errors },
  sectionIndex,
  qsIndex,
  assessment_question_id,
  question_scale,
  register,
  isBaseline,
  handleBlur,
  withMembers,
  readOnly,
}: QuestionPropsType) => {
  return (
    <div className="question">
      <p className="question__error">{errorMessage}</p>
      <p className="question__text">
        {QUESTION_LABELS.OPEN !== question_type && (
          <span className="category bold">{question_name}:</span>
        )}
        {question_prompt}
      </p>
      {question_type && (
        <>
          {response && question_type === QUESTION_LABELS.TEAM_DYNAMIC && (
            <div className="question__assess">
              {errors.sections &&
                errors.sections[sectionIndex] &&
                errors.sections[sectionIndex].questions[qsIndex] &&
                errors.sections[sectionIndex].questions[qsIndex].response &&
                errors.sections[sectionIndex].questions[qsIndex].response[1] &&
                errors.sections[sectionIndex].questions[qsIndex].response[1].response && (
                  <p className="question__error">
                    {errors.sections[sectionIndex].questions[qsIndex].response[1].response.message}
                  </p>
                )}
              <Scale
                register={register}
                idQuestion={assessment_question_id}
                type={question_type}
                qsIndex={qsIndex}
                sectionIndex={sectionIndex}
                iResponse={1}
                options={question_scale}
                handleBlur={handleBlur}
                readOnly={readOnly}
              />
            </div>
          )}
          {response &&
            question_type !== QUESTION_LABELS.TEAM_DYNAMIC &&
            (withMembers ? response : response.slice(0, 1)).map(
              ({ name, email }, iResponse: number) => {
                return (
                  <div key={name?.length ? name : email} className="question__assess">
                    {errors.sections &&
                      errors.sections[sectionIndex] &&
                      errors.sections[sectionIndex].questions[qsIndex] &&
                      errors.sections[sectionIndex].questions[qsIndex].response &&
                      errors.sections[sectionIndex].questions[qsIndex].response[iResponse] &&
                      errors.sections[sectionIndex].questions[qsIndex].response[iResponse]
                        .response && (
                        <p className="question__error">
                          {
                            errors.sections[sectionIndex].questions[qsIndex].response[iResponse]
                              .response.message
                          }
                        </p>
                      )}
                    <p className="question__assess-name bold">
                      {withMembers ? (name?.length ? name : email) : 'Team member'}
                    </p>
                    {question_type !== QUESTION_LABELS.OPEN && (
                      <Scale
                        register={register}
                        idQuestion={assessment_question_id}
                        type={question_type}
                        qsIndex={qsIndex}
                        sectionIndex={sectionIndex}
                        iResponse={iResponse}
                        options={question_scale}
                        handleBlur={handleBlur}
                        readOnly={readOnly}
                      />
                    )}
                    {!isBaseline && (
                      <TextAreaField
                        readOnly={readOnly}
                        error={
                          errors.sections &&
                          errors.sections[sectionIndex] &&
                          errors.sections[sectionIndex].questions[qsIndex] &&
                          errors.sections[sectionIndex].questions[qsIndex].response &&
                          errors.sections[sectionIndex].questions[qsIndex].response[iResponse] &&
                          errors.sections[sectionIndex].questions[qsIndex].response[iResponse]
                            .comment
                        }
                        placeholder="[OPTIONAL] Can you share some examples of superpowers that help this person shine in this domain? Where could they use a little extra love and attention?"
                        registration={register(
                          `sections.${sectionIndex}.questions.${qsIndex}.response.${iResponse}.comment`,
                          {
                            required:
                              QUESTION_LABELS.OPEN === question_type
                                ? 'This field is required'
                                : false,
                          }
                        )}
                      />
                    )}
                  </div>
                );
              }
            )}
        </>
      )}
      {children}
    </div>
  );
};
