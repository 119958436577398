import { BackArrow } from '@/components/Elements';

import { FormDemo } from './FormDemo';

export const Demo = () => {
  return (
    <div className="demo">
      <BackArrow toUrl={'/'} />
      <div className="demo-header">
        <h3>Request a demo</h3>
        <p className="body-3">
          Thank you for your interest in the GrowthCatalyst tool! Fill in the form below and we will
          reach out to you soon.
        </p>
      </div>
      <div className="demo-content">
        <FormDemo />
      </div>
    </div>
  );
};
