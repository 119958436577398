import { Route, Routes } from 'react-router-dom';

import { MFA } from './MFA';
import { Password } from './Password';
import { Profile } from './Profile';

export const MyProfileRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Profile />} />
      <Route path="change-password" element={<Password />} />
      <Route path="mfa-validation" element={<MFA />} />
    </Routes>
  );
};
