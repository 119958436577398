import create from 'zustand';

import { usePutMentorChat, PutMentorChatPayloadType, PutMentorChatStore } from '@/features/chat';

export const usePutMentorChatStore = () => {
  const { mutateAsync, isLoading, isError, data } = usePutMentorChat();

  // delete message property, since that will be handle on client side.
  // this way also we can persist user message query when there is no response incoming from the API. Let says when no interner connectivity, esc
  // delete data?.responseObj.message;

  return create<PutMentorChatStore>(() => ({
    chatPut: data?.responseObj,
    isLoading,
    isError,
    setPutChatSession: async (payload: PutMentorChatPayloadType) => {
      await mutateAsync({ ...payload });
    },
  }));
};
