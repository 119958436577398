import { ContentLayout } from '@/components/Layout';
import { ChangePassword } from '@/features/my_profile';

export const Password = () => {
  return (
    <ContentLayout title="Change Password" back={'/app/profile'}>
      <ChangePassword />
    </ContentLayout>
  );
};
