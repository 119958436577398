import * as React from 'react';

import { AddButton, BackArrow, MethodDropdown, MethodDropdownOptionsType } from '../Elements';
import { Head } from '../Head';

type ContentLayoutProps = {
  id?: string;
  children:
    | React.ReactChild
    | React.ReactChild[]
    | ((setOpen: () => void) => React.ReactChild)
    | ((setOpen: () => void) => React.ReactChild[]);
  title?: string;
  back?: string | boolean | (() => void);
  addNavigate?: () => void;
  addNavigateLabel?: string;
  customClassName?: string;
  rightWrapper?: React.ReactNode;
} & MethodDropdownOptionsType;

export const ContentLayout = ({
  id,
  children,
  title,
  addNavigate,
  addNavigateLabel,
  back = '',
  options,
  customClassName,
  rightWrapper,
}: ContentLayoutProps) => {
  const baseClassName = 'container-layout';

  const [open, openSet] = React.useState<boolean>(false);

  const handleBackClick = () => {
    if (typeof back === 'function') {
      back();
    }
  };

  const setOpen = React.useCallback(() => {
    openSet(!open);
  }, [open]);

  return (
    <>
      <Head title={title} />
      <div className={`${baseClassName}`}>
        <div className={`${baseClassName}-header`}>
          <h1 className="text-2xl font-semibold text-gray-900">
            {typeof back === 'function' ? (
              <BackArrow onClick={handleBackClick} />
            ) : (
              back && <BackArrow toUrl={back} />
            )}
            {title}
          </h1>
          <span className="right-wrapper">{rightWrapper}</span>
          {addNavigate && (
            <div className="m-0 p-0 flex flex-row gap-4">
              <span className="container-layout-button-label self-center">{addNavigateLabel}</span>
              <AddButton onClick={addNavigate} size="sm" />
            </div>
          )}
          {options && (
            <div className="m-0 p-0 flex flex-row gap-4">
              <span className="container-layout-button-label self-center">{addNavigateLabel}</span>
              <MethodDropdown options={options} setOpen={setOpen} open={open} />
            </div>
          )}
        </div>
        <div
          id={id}
          className={`${baseClassName}-content ${customClassName ? customClassName : ''}`}
        >
          {options && typeof children === 'function' ? children(setOpen) : children}
        </div>
      </div>
    </>
  );
};
