import { useMutation } from 'react-query';

import {
  PUT_MENTOR_CHAT_API,
  PutMentorChatPayloadType,
  PutMentorChatResponseType,
} from '@/features/chat';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { getFineName } from '@/utils/string';

export const putMentorChat = ({
  session_id,
  ...payload
}: PutMentorChatPayloadType): Promise<{ responseObj: PutMentorChatResponseType }> => {
  return axios.put(PUT_MENTOR_CHAT_API.replace('{session_id}', session_id), {
    ...payload,
  });
};

interface PutMentorChatOptions {
  config?: MutationConfig<typeof putMentorChat>;
}

export const usePutMentorChat = ({ config }: PutMentorChatOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: putMentorChat,
    onError: () => {
      localStorage.setItem('chat-error-last-action', 'put');
    },
    onSuccess: (response) => {
      const data = response.responseObj;
      queryClient.invalidateQueries('putMentorChat');
      // store session id to indicate an active session chat. This might be useful to detect that a chat is active or not. only 1 active chat session allowed.
      if (data.session_id) {
        localStorage.setItem('chat-active-session-id', data.session_id);
      }
      if (data.session_name) {
        localStorage.setItem('chat-active-session-name', getFineName(data.session_name));
      }
    },
  });
};
