import clsx from 'clsx';
import React, { useCallback, useContext } from 'react';

import { TeamBaseType, TeamResponseType, useUpdateTeam } from '@/features/teams';
import { useTeamMemberList, useTeamMemberListReturnType } from '@/hooks/useTeamMemberList';
import { useLeavePageStore } from '@/stores/leavePage';
import { formatDateFull } from '@/utils/format';

export const ListContext = React.createContext<{
  editState?: boolean;
  expand?: boolean;
  setEditState?: (edit: boolean) => void;
}>({});

type ListItemProps = {
  editState: boolean;
  children: (
    onSaveEdit: (data: Omit<TeamBaseType, 'members'>) => void,
    memberListMethods: useTeamMemberListReturnType,
    isUpdatingTeam: boolean
  ) => React.ReactNode;
} & TeamResponseType;

//converting the team_type's value to display in UI for example: 'OS_TEAM' to 'Os Team'
const convertTeamType = (value: string) => {
  let team_labels = value.split('_');
  team_labels = team_labels.map(
    (label) => label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()
  );
  return team_labels.join(' ');
};

const TeamCard = ({
  description,
  client,
  team_type,
  stage,
  date_founded,
}: Pick<TeamResponseType, 'description' | 'client' | 'team_type' | 'stage' | 'date_founded'>) => {
  return (
    <div className="team-item__card mb-8">
      <div className="team-item__card-1">
        <p className="bold">Team Description</p>
        <p>{description}</p>
      </div>
      <div className="team-item__card-2">
        <p className="bold grid">
          <span>Client</span>
          <span className="value bold text-left">{client}</span>
        </p>
        <p className="bold grid">
          <span>Team Type</span>
          <span className="value bold text-left">{team_type && convertTeamType(team_type)}</span>
        </p>

        <p className="bold grid">
          <span>Current Stage</span>
          <span className="value bold text-left">{stage}</span>
        </p>
        <p className="bold grid">
          <span>Date Founded</span>
          {typeof date_founded === 'number' && (
            <span className="value bold text-left">{formatDateFull(date_founded)}</span>
          )}
        </p>
      </div>
    </div>
  );
};

export const ListItem = (props: ListItemProps) => {
  const { setEditState } = useContext(ListContext);

  const { children, editState } = props;
  const memberListMethods = useTeamMemberList();
  const { removeBlocker } = useLeavePageStore();
  const { mutateAsync: updateTeam, isLoading: isUpdatingTeam } = useUpdateTeam();

  const onSaveEdit = useCallback(
    (data: any) => {
      data.members = memberListMethods.members;
      updateTeam({
        teamID: data.team_id,
        data,
      }).then(() => {
        removeBlocker();
        if (setEditState) {
          setEditState(false);
        }
      });
    },
    [memberListMethods.members, removeBlocker, updateTeam]
  );

  return (
    <div className={clsx('team-item', editState ? 'team-item__edit' : '')}>
      <div className="team-item__content">
        {!editState && <TeamCard {...props} />}
        {children(onSaveEdit, memberListMethods, isUpdatingTeam)}
      </div>
    </div>
  );
};
