import { useMemo, useState, Fragment } from 'react';

import { ReactComponent as DangerIcon } from '@/assets/icons/dangerIcon.svg';
import { QUESTION_LABELS } from '@/features/assessments';

type Option = {
  label: string;
  value?: number;
  tooltip?: string;
};

type ScaleType = {
  register: any;
  idQuestion: string;
  type:
    | QUESTION_LABELS.SKILL_BUILD
    | QUESTION_LABELS.MINDSET
    | QUESTION_LABELS.TEAM_DYNAMIC
    | QUESTION_LABELS.SKILL_EXEC_SPONSOR
    | QUESTION_LABELS.SKILL_OS_TEAM
    | QUESTION_LABELS.SKILL_VALIDATE;
  qsIndex: number;
  sectionIndex: number;
  iResponse: number;
  options?: Option[];
  handleBlur: () => void;
  readOnly?: boolean;
};

export const Scale = ({
  register,
  idQuestion,
  qsIndex,
  sectionIndex,
  iResponse,
  options,
  handleBlur,
  readOnly,
}: ScaleType) => {
  const errorMessage = (
    <span className="flex">
      <DangerIcon className="mr-2" /> Please make a selection
    </span>
  );

  const [isLevelChecked, setIsLevelChecked] = useState(false);

  const { lonelyOption, usedOptions } = useMemo((): {
    lonelyOption: Option;
    usedOptions: Option[];
  } => {
    const levelOptions: Option[] = [];
    let extraOption: Option = { label: '', value: 0 };
    if (options) {
      options.forEach(({ label, tooltip }, i) => {
        if (i === 0) extraOption = { label, tooltip, value: i };
        else levelOptions.push({ label, tooltip, value: i });
      });
    }
    return { usedOptions: levelOptions, lonelyOption: extraOption };
  }, [options]);

  const handleLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLevelChecked(event.target.checked);
    handleBlur();
  };

  const handleExtraOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLevelChecked(!event.target.checked);
    handleBlur();
  };

  return (
    <div className="radio-level-container">
      <div id="extra-options">
        <input
          {...register(
            `sections.${sectionIndex}.questions.${qsIndex}.response.${iResponse}.response`,
            {
              required: errorMessage,
            }
          )}
          readOnly={readOnly}
          type="radio"
          id={`${idQuestion}-${iResponse}-0`}
          value={lonelyOption.value}
          onInput={handleExtraOptionChange}
        />
        <label
          className={`${readOnly ? 'disabled' : ''}`}
          htmlFor={`${idQuestion}-${iResponse}-0`}
          data-level-label={lonelyOption.label}
        >
          <div className="tooltip scale">
            <span className="label-content"></span>
            <span className="tooltiptext scale">{lonelyOption.tooltip}</span>
          </div>
        </label>
      </div>
      <div className={isLevelChecked ? 'level-slider active' : 'level-slider'}>
        {usedOptions.map(({ label, value, tooltip }, index) => {
          return (
            <Fragment key={`${idQuestion}-${index}`}>
              <input
                type="radio"
                {...register(
                  `sections.${sectionIndex}.questions.${qsIndex}.response.${iResponse}.response`,
                  {
                    required: errorMessage,
                  }
                )}
                id={`${idQuestion}-${iResponse}-${index + 1}`}
                value={value}
                onInput={handleLevelChange}
                readOnly={readOnly}
              />
              <label
                className={`${readOnly ? 'disabled' : ''}`}
                htmlFor={`${idQuestion}-${iResponse}-${index + 1}`}
                data-level-label={label}
              >
                <div className="tooltip scale">
                  {tooltip && <span className="tooltiptext scale">{tooltip}</span>}
                </div>
              </label>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
