import { ChevronDownIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useCallback, useState, useMemo } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { DropdownMenuRole } from '@/components/Elements';
import { FieldWrapper, FieldWrapperPassThroughProps } from '@/components/Form/FieldWrapper';

type DropdownRoleProps = FieldWrapperPassThroughProps & {
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  registration?: Partial<UseFormRegisterReturn>;
  horizontal?: boolean;
  disabled?: boolean;
  grid?: boolean;
  hidden?: boolean;
  value?: string;
  displayName?: string | number;
  onChange?: (option: IOptionRole) => void;
  open?: boolean;
  setOpen?: () => void;
  handleDelete?: (option: IOptionRole) => void;
} & DropdownRoleOptionsType;

export type DropdownRoleOptionsType = {
  options?: IOptionRole[];
  placeholder?: string;
  isSearchable?: boolean;
  selectedValue?: IOptionRole;
};

export type IOptionRole = {
  label: string;
  id?: string;
  onClick?: (x?: IOptionRole) => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  isSelected?: boolean;
  selectedLabel?: string | number;
  system?: boolean;
  team_role_id?: string;
};

export const DropdownRole = ({
  options = [],
  setOpen,
  open,
  placeholder,
  disabled,
  selectedValue,
  isSearchable = false,
  registration,
  handleDelete,
  onChange,
}: DropdownRoleProps) => {
  const [openDefault, openDefaultSet] = useState(false);

  const openDropdown = useCallback(() => {
    setOpen ? setOpen() : openDefaultSet(!openDefault);
  }, [openDefault, setOpen]);

  const openFlag = useMemo(() => {
    return open !== undefined ? open : openDefault;
  }, [open, openDefault]);

  const getDisplay = (selectedValue: any, placeholder?: string) => {
    if (selectedValue) return selectedValue.label;
    if (placeholder) return placeholder;
    return 'Select Role';
  };

  return (
    <FieldWrapper>
      <div className={clsx('dropdown select flex items-center', disabled ? 'disabled-role' : '')}>
        <div className="dropdown-input flex w-full justify-between">
          <div
            onClick={openDropdown}
            onKeyDown={openDropdown}
            role="combobox"
            aria-haspopup="listbox"
            aria-controls="dropdown"
            aria-expanded={openFlag}
            tabIndex={0}
            className={clsx('dropdown-selected-value')}
            style={disabled ? { pointerEvents: 'none' } : {}}
          >
            {getDisplay(selectedValue, placeholder)}
          </div>
          <div className="dropdown-tools">
            <div className="dropdown-tool">
              <ChevronDownIcon className="icon-chevron__down" width={25} />
            </div>
          </div>
        </div>
        <DropdownMenuRole
          options={options}
          open={openFlag}
          setOpen={openDropdown}
          isSearchable={isSearchable}
          selectedValue={selectedValue}
          disabled={disabled}
          registration={registration}
          handleDelete={handleDelete}
          onChange={onChange}
        />
      </div>
    </FieldWrapper>
  );
};
