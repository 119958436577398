import { ReactNode } from 'react';
import Popup from 'reactjs-popup';
import { EventType, PopupPosition } from 'reactjs-popup/dist/types';

interface TooltipProps {
  element: ReactNode;
  toolTipContent: string | ReactNode;
  position?: PopupPosition | PopupPosition[];
  triggerType?: EventType | EventType[];
  customComponent?: boolean;
  arrow?: boolean;
  reportToggle?: (open: boolean) => void;
}

/**
 * Tooltip component
 * @param element ReactNode
 * @param toolTipContent string | ReactNode
 * @param position string | string[] =>
 *   'top left',
 *   'top center',
 *   'top right',
 *   'right top',
 *   'right center',
 *   'right bottom',
 *   'bottom left',
 *   'bottom center',
 *   'bottom right',
 *   'left top',
 *   'left center',
 *   'left bottom',
 *   'center center',
 *   @param triggerType string[] => focus, click, hover
 *   @param customComponent boolean => In case you use a custom component you have to wrap it inside normal HTML Tag
 *   @param arrow boolean
 * @constructor
 */
export const Tooltip = ({
  element,
  toolTipContent,
  position = 'left center',
  triggerType = ['click'],
  customComponent = true,
  arrow = true,
  reportToggle,
}: TooltipProps) => {
  return (
    <div className={'tooltip-container'}>
      <Popup
        trigger={customComponent ? <div>{element}</div> : <>{element}</>}
        arrow={arrow && position !== 'center center'}
        on={triggerType}
        position={position}
        onOpen={() => {
          if (reportToggle) {
            reportToggle(true);
          }
        }}
        onClose={() => {
          if (reportToggle) {
            reportToggle(false);
          }
        }}
      >
        <div className={'tooltip-container--content'}>{toolTipContent}</div>
      </Popup>
    </div>
  );
};
