import { ReactNode, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridContainer, Spinner } from '@/components/Elements';
import {
  useAssessmentTemplatesStore,
  ASSESSMENT_TYPES,
  useSectionResultsStore,
} from '@/features/assessments';
import { ROLES } from '@/lib/authorization';
import { createRegExpMatchFullWord, regexByObjectFields } from '@/utils/regexHelper';

import { NotifyResultType } from '../../assessmentSectionDisplay';

interface ITemplateCard {
  title: string;
  description: string;
  type: string;
  onClick: () => void;
}

export const TemplateCard = ({ title, description, type, onClick }: ITemplateCard) => {
  return (
    <button onClick={onClick} className="template-card">
      <p className="template-card-header-text mb-3">{type}</p>
      <p className="template-card-title mb-3">{title}</p>
      <p className="template-card-description">{description}</p>
    </button>
  );
};

export const TemplatesList = ({
  filterInput,
  notifyResults,
}: {
  filterInput?: string;
  notifyResults?: NotifyResultType;
}) => {
  const navigate = useNavigate();
  const { assessment_templates, templates_loaded, getAssessmentTemplates, selectTemplate } =
    useAssessmentTemplatesStore();
  const { validateResultStatus } = useSectionResultsStore();

  useEffect(() => {
    if (!templates_loaded) {
      getAssessmentTemplates();
    }
  }, [getAssessmentTemplates, templates_loaded]);

  const templates = useMemo(() => {
    const templateOptions: { label: string; system: boolean; button: ReactNode }[] = [];
    assessment_templates.map((template) => {
      const templateData = {
        label: template.assessment_name,
        system: template.client_id === 'ALL',
        button: (
          <TemplateCard
            type={template.assessment_type}
            title={template.assessment_name}
            description={template.assessment_description}
            onClick={() => {
              selectTemplate(template.assessment_id);
              navigate(
                `build?template=${template.assessment_name}&kind=TEMPLATE&type=${ASSESSMENT_TYPES.INDIVIDUAL}&role=${ROLES.COFOUNDER}&template_name=${template.assessment_name}`
              );
            }}
          />
        ),
      };
      if (filterInput) {
        const regex = createRegExpMatchFullWord(filterInput);
        if (regexByObjectFields(regex, false, [template.assessment_name]) !== undefined) {
          templateOptions.push(templateData);
        }
      } else {
        templateOptions.push(templateData);
      }
    });
    return [
      ...templateOptions
        .filter((template) => template.system && template.label.startsWith('Cofounder'))
        .map((template) => template.button),

      ...templateOptions
        .filter((template) => template.system && template.label.startsWith('Executive Sponsor'))
        .map((template) => template.button),

      ...templateOptions
        .filter((template) => template.system && template.label.startsWith('OS Team'))
        .map((template) => template.button),
    ];
  }, [assessment_templates, selectTemplate, navigate, filterInput]);

  useEffect(() => {
    validateResultStatus(!templates_loaded, notifyResults, filterInput, templates);
  }, [templates]);

  if (!templates_loaded) {
    return (
      <div className="w-full flex justify-center mt-6 mb-6">
        <Spinner />
      </div>
    );
  }

  if (!templates || templates.length === 0) return null;

  return (
    <GridContainer
      cards={templates}
      title={filterInput ? `Templates (${templates.length})` : 'Choose a template'}
      maxSizeDisplayed={filterInput ? undefined : 5}
      rowSize={5}
      fadingElements={!!filterInput}
      collapseCards={templates.length > 5}
    />
  );
};
