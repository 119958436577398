import { useMutation } from 'react-query';

import { TeamRoleType } from '@/features/clients';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type addRoleType = {
  data: TeamRoleType;
  client_id: string;
};

export const addRole = ({ data, client_id }: addRoleType): Promise<TeamRoleType> => {
  return axios.post(`/clients/${client_id}/team_roles`, data).then((data: any) => data.responseObj);
};

type UseAddRoleOptions = {
  config?: MutationConfig<typeof addRole>;
};

export const useAddRole = ({ config }: UseAddRoleOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('clients');
      addNotification({
        type: 'success',
        title: 'Role successfully added!',
      });
    },
    ...config,
    mutationFn: addRole,
  });
};
