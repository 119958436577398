import { useMutation } from 'react-query';

import {
  USER_LIKE_CHAT_API,
  UserLikeChatPayloadType,
  UserLikeChatResponseType,
  USER_UNLIKE_CHAT_API,
  UserUnlikeChatPayloadType,
  UserUnlikeChatResponseType,
} from '@/features/chat';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

export const userLikeChat = ({
  session_id,
  message_id,
}: UserLikeChatPayloadType): Promise<{
  responseObj: UserLikeChatResponseType;
}> => {
  return axios.post(
    USER_LIKE_CHAT_API.replace('{session_id}', session_id).replace('{message_id}', message_id)
  );
};

export const userUnlikeChat = ({
  session_id,
  message_id,
}: UserUnlikeChatPayloadType): Promise<{
  responseObj: UserUnlikeChatResponseType;
}> => {
  return axios.post(
    USER_UNLIKE_CHAT_API.replace('{session_id}', session_id).replace('{message_id}', message_id)
  );
};

interface UserLikeChatOptions {
  config?: MutationConfig<typeof userLikeChat>;
}

interface UserUnlikeChatOptions {
  config?: MutationConfig<typeof userUnlikeChat>;
}

export const useUserLikeChat = ({ config }: UserLikeChatOptions = {}) => {
  // const { addNotification } = useNotificationStore();
  return useMutation({
    ...config,
    mutationFn: userLikeChat,
    onError: () => {
      localStorage.setItem('chat-error-last-action', 'post');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('userLikeChat');
      // addNotification({
      //   type: 'success',
      //   title: 'Chat session successfully initiated!',
      // });
    },
  });
};

export const useUserUnlikeChat = ({ config }: UserUnlikeChatOptions = {}) => {
  // const { addNotification } = useNotificationStore();
  return useMutation({
    ...config,
    mutationFn: userUnlikeChat,
    onError: () => {
      localStorage.setItem('chat-error-last-action', 'post');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('userUnlikeChat');
      // addNotification({
      //   type: 'success',
      //   title: 'Chat session successfully initiated!',
      // });
    },
  });
};
