import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GridContainer, Spinner } from '@/components/Elements';
import { useDeleteAssessment, useSectionResultsStore } from '@/features/assessments';
import { TeamResponseType, useTeams } from '@/features/teams';
import { UserResponseType, useUsers } from '@/features/users';
import { useDisclosure } from '@/hooks/useDisclosure';
import { createRegExpMatchFullWord, regexByObjectFields } from '@/utils/regexHelper';

import { useAssessments } from '../../api/getAssessments';
import { DeleteModal } from '../builder/DeleteModal';

import { AssessmentDisplay } from './AssessmentDisplay';
import { SectionDisplayProps } from './types';

interface BuildSectionDisplayProps extends SectionDisplayProps {
  assessmentType?: 'draft' | 'published';
}

export const BuildSectionDisplay = ({
  assessmentType = 'draft',
  title = 'Drafts',
  fadingElements = true,
  fadingPagesToLoad = 3,
  filterInput,
  notifyResults,
}: BuildSectionDisplayProps) => {
  const navigate = useNavigate();
  const {
    isLoading: isLoading,
    data: assessmentsData,
    refetch: refetchAssessments,
  } = useAssessments({
    params: `kind=${assessmentType.toUpperCase()}`,
  });
  const { data: usersData, refetch: refetchUsers } = useUsers();
  const { data: teamsData, refetch: refetchTeams } = useTeams();
  const { close: closeDelete, isOpen: isOpenDelete, open: openDelete } = useDisclosure();
  const { mutateAsync: deleteAssessment, isLoading: loadingDelete } = useDeleteAssessment();
  const [assessmentIdSelected, setAssessmentIdSelected] = useState<string | undefined>(undefined);
  const { validateResultStatus } = useSectionResultsStore();

  const handleRefetch = () => {
    refetchUsers();
    refetchTeams();
    refetchAssessments();
  };

  const usersMap = useMemo(() => {
    const map = new Map<string, UserResponseType>();
    usersData?.map((user) => map.set(user.email, user));
    return map;
  }, [usersData]);

  const teamsMap = useMemo(() => {
    const map = new Map<string, TeamResponseType>();
    teamsData?.map((team) => map.set(team.team_id, team));
    return map;
  }, [teamsData]);

  const handleOpenDeleteModal = (assessmentIdSelected: string) => {
    setAssessmentIdSelected(assessmentIdSelected);
    openDelete();
  };

  const handleDeleteAssessment = useCallback(() => {
    if (assessmentIdSelected) {
      deleteAssessment(assessmentIdSelected).then(() => {
        closeDelete();
        refetchAssessments();
      });
    }
  }, [assessmentIdSelected]);

  const filteredAssessments = useMemo(() => {
    if (assessmentsData && filterInput) {
      return assessmentsData.filter((assessment) => {
        const regex = createRegExpMatchFullWord(filterInput);
        const fieldsToFilter: (string | string[])[] = [assessment.assessment_name];
        if (assessment.assessment_status) {
          fieldsToFilter.push(assessment.assessment_status);
        }
        if (usersMap.has(assessment.modifiedBy)) {
          fieldsToFilter.push(usersMap.get(assessment.modifiedBy)?.name || '');
        }
        if (teamsMap.has(assessment.general_settings.team_id)) {
          fieldsToFilter.push(teamsMap.get(assessment.general_settings.team_id)?.team_name || '');
        } else {
          // Always remove reflections without team assigned
          return false;
        }
        const memberNames: string[] | undefined =
          assessment.general_settings.members_with_roles?.map((memberData) => memberData.name);
        if (memberNames && memberNames.length > 0) {
          fieldsToFilter.push(memberNames);
        }
        //   @TODO FILTER BY CATEGORY (at the moment is only Reflections)
        return regexByObjectFields(regex, false, fieldsToFilter) !== undefined;
      });
    } else if (assessmentsData) {
      return assessmentsData.filter((assessment) => {
        return (
          (assessmentType === 'published' && teamsMap.has(assessment.general_settings.team_id)) ||
          assessmentType === 'draft'
        );
      });
    }
    return assessmentsData;
  }, [filterInput, assessmentsData, teamsMap, usersData]);

  useEffect(() => {
    validateResultStatus(isLoading, notifyResults, filterInput, filteredAssessments);
  }, [filteredAssessments]);

  if (isLoading) {
    return (
      <div className="w-full flex justify-center mt-6 mb-6">
        <Spinner />
      </div>
    );
  }

  if (!filteredAssessments || filteredAssessments.length === 0) return null;
  return (
    <>
      <DeleteModal
        isOpen={isOpenDelete}
        close={closeDelete}
        onClick={handleDeleteAssessment}
        loadingDelete={loadingDelete}
      />
      <GridContainer
        title={`${title} (${filteredAssessments.length})`}
        collapseCards={filteredAssessments.length > 4}
        rowSize={4}
        elementsContainer={false}
        fadingElements={fadingElements}
        fadingPagesToLoad={fadingPagesToLoad}
        cards={filteredAssessments.map((assessment) => (
          <AssessmentDisplay
            key={assessment.assessment_id}
            data={assessment}
            isAssessmentLoading={isLoading}
            type={assessmentType}
            refetchAction={handleRefetch}
            handleOpenDeleteModal={handleOpenDeleteModal}
            navigate={navigate}
            usersMap={usersMap}
            teamsMap={teamsMap}
          />
        ))}
      />
    </>
  );
};
