import { ReactComponent as ArrowRightIcon } from '@/assets/icons/arrow-right-icon.svg';

export interface WelcomeScreenProps {
  onClick: () => void;
}

const WelcomeScreen = (props: WelcomeScreenProps) => {
  return (
    <div className="vv-bg-started">
      <div className="vv-container-1">
        <div>
          <p className="title heading-1">Welcome to</p>
          <p className="title heading-2 mb-8">VentureVerse!</p>
          <p className="title heading-3">Choose Your Path, Build Your Startup Empire</p>
        </div>
        <div className="vv-buttons-container">
          <div>
            <button className="vv-button" onClick={props.onClick}>
              Start New Game
              <ArrowRightIcon />
            </button>
            <button className="vv-button-2">LOAD PREVIOUS GAME</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
