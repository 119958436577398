import { ContentLayout } from '@/components/Layout';
import { useChatMessagesStore } from '@/features/chat';

export const Chat = () => {
  const { invokeNewChatSession } = useChatMessagesStore();

  return (
    <ContentLayout
      title="GrowthMentor"
      addNavigate={() => invokeNewChatSession && invokeNewChatSession()}
      addNavigateLabel="Start a New Chat"
    >
      <div id="chat-portal" />
    </ContentLayout>
  );
};
