import clsx from 'clsx';
import { InputHTMLAttributes, LegacyRef } from 'react';

import { ReactComponent as CircleCancelIcon } from '@/assets/icons/circleCancel.svg';
import { ReactComponent as CircleCheckIcon } from '@/assets/icons/circleCheck.svg';
import { Size } from '@/types';

export interface InlineEditProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: Size;
  inputRef?: LegacyRef<HTMLInputElement>;
  onOk?: () => void;
  onCancel?: () => void;
}

export const InlineEdit = ({
  size = 'md',
  className,
  inputRef,
  onOk,
  ...props
}: InlineEditProps) => {
  const newClassName = clsx(className, size);

  // this sizes thing will be implement in next release along with new theming.
  // style defined below will be remove later
  return (
    <div className="inline-edit-container">
      <input {...props} className={newClassName} ref={inputRef} />
      <CircleCheckIcon onClick={onOk} />
      <CircleCancelIcon onClick={props.onCancel} />
    </div>
  );
};

export default InlineEdit;
