import clsx from 'clsx';
import { useState, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import arrowRightIcon from '@/assets/icons/arrowRight.svg';
import candyBoxMenuIcon from '@/assets/icons/candyBoxMenu.svg';
import empowerLogo from '@/assets/icons/empowerLogo.svg';

import { CANDY_BAR_APPLICATIONS, LogoApplication } from './resources/candyBarApplications';

export interface SideNavigationHeaderProps {
  containerClasses?: string;
  addRule?: boolean;
}

export const SideNavigationHeader = ({ containerClasses, addRule }: SideNavigationHeaderProps) => {
  const [candyBarOpen, setCandyBarOpen] = useState<boolean>(false);
  return (
    <>
      <div className={containerClasses}>
        <button className={'candy-bar-button'} onClick={() => setCandyBarOpen(!candyBarOpen)}>
          <img
            src={candyBoxMenuIcon}
            alt={`${!candyBarOpen ? 'Close' : 'Open'} application selector`}
          />
        </button>
        <span>GrowthOS</span>
        <img src={arrowRightIcon} alt="Arrow Right" />
        <img src={empowerLogo} alt="App Logo" />
      </div>
      {addRule && !candyBarOpen && <hr />}
      {candyBarOpen && <CandyBarContent />}
    </>
  );
};

const CandyBarContent = () => {
  return (
    <div className={'candy-box-container'}>
      {CANDY_BAR_APPLICATIONS.map((application, index) => {
        return (
          <ApplicationLink application={application} key={index}>
            <img src={application.icon} alt={application.title} />
            {application.title} {!application.selected && '>'}
          </ApplicationLink>
        );
      })}
    </div>
  );
};

interface ApplicationLinkProps {
  application: LogoApplication;
  children: ReactNode;
}

const ApplicationLink = ({ application, children }: ApplicationLinkProps) => {
  if (application.external) {
    return (
      <a
        href={application.url ? application.url : '#'}
        target={application.external ? '_blank' : '_self'}
        rel={'noreferrer'}
        className={clsx(
          'item',
          application.selected ? 'item-selected' : '',
          application.disabled ? 'item-disabled' : ''
        )}
        title={application.title}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      to={application.url ? application.url : '#'}
      className={clsx(
        'item',
        application.selected ? 'item-selected' : '',
        application.disabled ? 'item-disabled' : ''
      )}
      title={application.title}
    >
      {children}
    </Link>
  );
};
