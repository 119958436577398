import { Auth } from 'aws-amplify';
import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { InputField } from '@/components/Form';
import { PASSWORD_REGEX } from '@/features/auth';
import { useNotificationStore } from '@/stores/notifications';

export const ChangePassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    watch,
    setError,
    clearErrors,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
  });

  const onSendChangePassword = useCallback(
    ({ current_password, new_password }) => {
      setLoading(true);
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((user) => {
          Auth.changePassword(user, current_password, new_password)
            .then(() => {
              setLoading(false);
              addNotification({
                type: 'success',
                title: 'Password successfully changed!',
              });
              navigate('/app/profile');
            })
            .catch((error) => {
              setLoading(false);
              addNotification({
                type: 'error',
                title: error.message,
              });
            });
        })
        .catch((error) => {
          setLoading(false);
          addNotification({
            type: 'error',
            title: error.message,
          });
        });
    },
    [addNotification, navigate]
  );

  return (
    <div className="container change-password">
      <form onSubmit={handleSubmit(onSendChangePassword)}>
        <InputField
          registration={register('current_password', {
            required: 'This field is required',
          })}
          label="Current Password"
          type="password"
          error={errors.current_password}
        />
        <InputField
          registration={register('new_password', {
            required: 'This field is required',
            validate: (val: string) => {
              if (watch('new_password_confirm') !== val) {
                setError('new_password_confirm', {
                  message: 'Passwords do not match.',
                });
              } else {
                clearErrors('new_password_confirm');
              }
              if (!new RegExp(PASSWORD_REGEX).test(val) || val.length <= 7) {
                return '';
              }
            },
          })}
          error={errors.new_password}
          label="New Password"
          type="password"
        />
        <p className={`${errors.new_password ? 'change-password__error' : ''}`}>
          Must be at least 8 characters, and contain at least 1 lowercase letter, 1 uppercase
          letter, 1 number, and 1 special character. <b>Spaces are not allowed.</b>
        </p>
        <InputField
          registration={register('new_password_confirm', {
            required: 'This field is required',
            validate: (val: string) => {
              if (watch('new_password') !== val) {
                return 'Passwords do not match.';
              }
            },
          })}
          label="Confirm New Password"
          type="password"
          error={errors.new_password_confirm}
        />
        <Button type="submit" isLoading={loading} disabled={!isValid}>
          Change password
        </Button>
      </form>
    </div>
  );
};
