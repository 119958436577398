import { MAX_LENGTH_SESSION_NAME } from '@/features/chat';

export const detectNewLine = (value: string) => {
  if (/\r\n$/.test(value) || /\r$/.test(value) || /\n$/.test(value)) {
    return true;
  }
  // return false;
};

export const getFineName = (source: string, maxLength = MAX_LENGTH_SESSION_NAME) => {
  if (source.length <= maxLength) {
    return source;
  }

  let result = source.substring(0, maxLength);
  const lastSpaceIndex = result.lastIndexOf(' ');

  if (lastSpaceIndex !== -1) {
    result = result.substring(0, maxLength - 3) + '...';
  }

  return result;
};
