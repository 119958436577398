import { useMutation } from 'react-query';

import { ClientResponseType } from '@/features/clients';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type updateClientType = {
  data: ClientResponseType;
  client_id: string;
};

export const updateClient = ({
  data,
  client_id,
}: updateClientType): Promise<ClientResponseType> => {
  return axios.put(`/clients/${client_id}`, data).then((data: any) => data.responseObj);
};

type UseUpdateClientOptions = {
  config?: MutationConfig<typeof updateClient>;
};

export const useUpdateClient = ({ config }: UseUpdateClientOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('clients');
      addNotification({
        type: 'success',
        title: 'Client successfully updated!',
      });
    },
    ...config,
    mutationFn: updateClient,
  });
};
