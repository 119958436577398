import { Button, Dialog, DialogTitle } from '@/components/Elements';

interface IDeleteModal {
  isOpen: boolean;
  onClick: () => void;
  close: () => void;
  loadingDelete: boolean;
}

export const DeleteModal = ({ isOpen, onClick, close, loadingDelete }: IDeleteModal) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Delete Reflection?
          </DialogTitle>
          <div className="modal__info">
            <p>Please confirm that you would like to delete the reflection draft.</p>
            <p>Drafts send to the trash can not be recovered</p>
          </div>
          <div className="builder__publish-modal-buttons">
            <Button type="button" onClick={close} variant="inverse">
              Cancel
            </Button>
            <Button onClick={onClick} isLoading={loadingDelete}>
              Delete reflection
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
