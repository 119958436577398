/* eslint-disable @typescript-eslint/no-unused-vars */
import create from 'zustand';

import { AssessmentBaseType, AssessmentResponseType } from '..';
import {
  getOneAssessmentTemplate,
  getQueryAssessmentTemplate,
  createAssessmentTemplate,
  deleteAssessmentTemplate,
  updateAssessmentTemplate,
} from '../api/assessmentTemplates';

type AssessmentTemplateStore = {
  selected_template: AssessmentResponseType | undefined;
  assessment_templates: AssessmentResponseType[];
  templates_loading: boolean;
  templates_loaded: boolean;

  selectTemplate: (assessment_id: string) => void;

  setSelectTemplate: (template: AssessmentResponseType) => void;

  getAssessmentTemplates: (
    queryString?: string | string[][] | Record<string, string> | URLSearchParams | undefined
  ) => Promise<true | undefined>;

  getOneAssessmentTemplate: (assessment_id: string) => Promise<true | undefined>;

  createAssessmentTemplate: (assessment: AssessmentBaseType) => Promise<true | undefined>;

  updateAssessmentTemplate: (assessment: AssessmentBaseType) => Promise<true | undefined>;

  deleteAssessmentTemplate: (assessment_id: string) => Promise<true | undefined>;

  setSelectedTemplate: (assessment: AssessmentResponseType | undefined) => void;
};

export const useAssessmentTemplatesStore = create<AssessmentTemplateStore>((set, get) => ({
  assessment_templates: [],
  templates_loading: false,
  selected_template: undefined,
  templates_loaded: false,

  selectTemplate: (assessment_id: string) => {
    const state = get();
    set({
      selected_template: state.assessment_templates.find(
        (template) => template.assessment_id === assessment_id
      ),
    });
    return true;
  },

  setSelectTemplate: (assessment: AssessmentResponseType) => {
    set({
      selected_template: assessment,
    });
    return true;
  },

  getAssessmentTemplates: async (queryString?) => {
    set({ templates_loading: true });
    const assessment_templates = await getQueryAssessmentTemplate(queryString);
    set({
      assessment_templates: assessment_templates,
      templates_loading: false,
      templates_loaded: true,
    });
    return true;
  },

  getOneAssessmentTemplate: async (assessment_id) => {
    const selected_template = await getOneAssessmentTemplate(assessment_id);
    set({
      selected_template: selected_template,
    });
    return true;
  },

  createAssessmentTemplate: async (assessment) => {
    const selected_template = await createAssessmentTemplate(assessment);
    set({
      selected_template: selected_template,
    });
    return true;
  },

  updateAssessmentTemplate: async (assessment) => {
    const selected_template = await updateAssessmentTemplate(assessment);
    set({
      selected_template: selected_template,
    });
    return true;
  },

  deleteAssessmentTemplate: async (assessment_id) => {
    await deleteAssessmentTemplate(assessment_id);
    return true;
  },

  setSelectedTemplate: (assessment: AssessmentResponseType | undefined) => {
    set({
      selected_template: assessment,
    });
    return true;
  },
}));
