import { PencilIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { ReactComponent as DragIcon } from '@/assets/icons/dragIcon.svg';
import { MethodDropdown } from '@/components/Elements';
import { InputField, TextAreaField } from '@/components/Form';
import {
  SectionBaseType,
  QuestionBaseType,
  QUESTION_LABELS,
  DRAG_TYPE,
} from '@/features/assessments';

type SectionPropstype = {
  index: number;
  id: string;
  hover: string | undefined;
  dragResult: any;
  addSection: (section: SectionBaseType) => void;
  children: (questions: any) => React.ReactNode;
  removeSection: (index: number) => void;
  setHover: (id: string) => void;
} & SectionBaseType &
  UseFormReturn;

export const Section = ({
  id,
  section_name,
  section_description,
  index,
  formState: { errors },
  hover,
  control,
  dragResult,
  register,
  addSection,
  removeSection,
  setHover,
  children,
  clearErrors,
  getValues,
}: SectionPropstype) => {
  const questionModel = (question_type: QUESTION_LABELS): QuestionBaseType => ({
    question_type,
    category_id: '',
    question_prompt: '',
  });

  const fieldArrayQuestions = useFieldArray({
    control,
    name: `sections[${index}].questions`,
  });

  const { move, append } = fieldArrayQuestions;
  const sectionErrors = errors?.sections && errors?.sections[index];

  const appendQuestion = useCallback(
    (questionType: QUESTION_LABELS) => {
      append(questionModel(questionType));
      const sections = getValues('sections') as SectionBaseType[] | undefined;
      if (!sections?.some((x: SectionBaseType) => !x.questions?.length))
        clearErrors('sections.noQuestions');
    },
    [append, clearErrors, getValues]
  );

  const QuestionOptions = [
    {
      label: 'New Section',
      onClick: () => addSection({ section_name: '', section_description: '', questions: [] }),
    },
    {
      label: `New ${QUESTION_LABELS.MINDSET}`,
      onClick: () => appendQuestion(QUESTION_LABELS.MINDSET),
    },
    {
      label: `New ${QUESTION_LABELS.SKILL_BUILD}`,
      onClick: () => appendQuestion(QUESTION_LABELS.SKILL_BUILD),
    },
    {
      label: `New ${QUESTION_LABELS.SKILL_VALIDATE}`,
      onClick: () => appendQuestion(QUESTION_LABELS.SKILL_VALIDATE),
    },
    {
      label: `New ${QUESTION_LABELS.TEAM_DYNAMIC}`,
      onClick: () => appendQuestion(QUESTION_LABELS.TEAM_DYNAMIC),
    },
    {
      label: `New ${QUESTION_LABELS.OPEN}`,
      onClick: () => appendQuestion(QUESTION_LABELS.OPEN),
    },
  ];

  useEffect(() => {
    if (dragResult) {
      if (!dragResult.destination) return;
      if (dragResult.type === DRAG_TYPE.QUESTION && dragResult.source.droppableId === id) {
        move(dragResult.source.index, dragResult.destination.index);
      }
    }
  }, [dragResult, id, move]);

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          className={clsx('section', id === hover && 'section__hover')}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="section__questions">
            <div
              className="section__drag-icon"
              onMouseEnter={() => setHover(id)}
              onMouseLeave={() => setHover('')}
              {...provided.dragHandleProps}
            >
              <DragIcon />
            </div>
            <InputField
              label="Section title"
              registration={register(`sections.${index}.section_name`, {
                required: 'This field is required',
              })}
              className="section__field-name"
              bold={true}
              error={sectionErrors && sectionErrors.section_name}
              defaultValue={section_name}
              icons={[
                <PencilIcon key={'pencilIcon'} />,
                <div className="tooltip" key={'InformationCircleIcon'}>
                  <InformationCircleIcon />
                  <span className="tooltiptext">
                    What is the capability or skill being assessed? Enter an appropriate title for
                    the capability.
                  </span>
                </div>,
              ]}
            />
            <TextAreaField
              key={`${id}-${index}`}
              registration={register(`sections.${index}.section_description`, {})}
              className="section__field-desc"
              label="Section description"
              placeholder="Describe what this section evaluates in one or two sentences."
              error={sectionErrors && sectionErrors.section_description}
              defaultValue={section_description}
            />
            <Droppable
              droppableId={id}
              isCombineEnabled={false}
              direction="vertical"
              type={DRAG_TYPE.QUESTION}
            >
              {(dropProvided) => (
                <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                  {children(fieldArrayQuestions)}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className="section__actions-container">
            <div className="section__actions">
              <button
                className="section__remove bold"
                type="button"
                onClick={() => {
                  removeSection(index);
                }}
              >
                REMOVE
              </button>
            </div>
            <MethodDropdown className="section-dropdown" options={QuestionOptions} />
          </div>
        </div>
      )}
    </Draggable>
  );
};
