export interface RenameMentorChatPayloadType {
  session_id: string;
  session_name: string;
}

export interface RenameMentorChatStore {
  isLoading?: boolean;
  isError?: boolean;
  setRenameChatSession: (props: RenameMentorChatPayloadType) => void;
}

export const RENAME_MENTOR_CHAT_API = 'mentor/chats/sessions/{session_id}';
