import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as zod from 'zod';

import { Button } from '@/components/Elements/Button';
import { ContentLayout } from '@/components/Layout';

import { useCreateUser } from '../api/createUser';
import { CreateAdmin } from '../components/create/CreateAdmin';
import { useBulkUsersStore } from '../stores/bulkUsers';

const validations = zod.object({
  admins: zod.array(
    zod.object({
      name: zod.string().nonempty('This field is required'),
      email: zod.string().nonempty('This field is required'),
      role: zod.string().nonempty('This field is required'),
    })
  ),
});

export const Create = () => {
  const navigate = useNavigate();
  const { users, setUsers } = useBulkUsersStore();
  const [admins, setAdmins] = useState(() => {
    if (users.length > 0) {
      return Array.from({ length: users.length }, (_, i) => i);
    }
    return [0];
  });
  const { mutateAsync: mutateAsyncUsers } = useCreateUser();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: validations && zodResolver(validations),
  });

  const addAdmin = () => {
    setAdmins((prevAdmins) => [...prevAdmins, prevAdmins.length]);
  };

  const removeAdmin = (indexToRemove: number) => {
    setAdmins((prevAdmins) => prevAdmins.filter((index) => index !== indexToRemove));
  };

  const handleSendInvitations = async (data: any) => {
    const adminsToCreate = data.admins.map((admin: any) => ({
      name: admin.name,
      email: admin.email,
      custom_role: parseInt(admin.role, 10),
    }));

    try {
      await mutateAsyncUsers(adminsToCreate);
      setUsers([]);
      navigate('users');
    } catch (error) {
      console.error('Error creating users:', error);
    }
  };

  return (
    <ContentLayout
      customClassName="add-new-user-layout"
      title="Add New User/Users"
      back={'/app/users'}
    >
      <form onSubmit={handleSubmit(handleSendInvitations)}>
        {admins.map((index) => (
          <CreateAdmin
            key={index}
            index={index}
            userData={users[index]}
            register={register}
            errors={errors}
            onRemove={removeAdmin}
          />
        ))}
        <div className="buttons-wrapper">
          <Button
            type="button"
            variant="inverse"
            size="sm"
            className="add-another-user-button"
            onClick={addAdmin}
          >
            + Add another user
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="send-invitation-button"
            disabled={!isValid}
          >
            Send Invitations
          </Button>
        </div>
      </form>
    </ContentLayout>
  );
};
