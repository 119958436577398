import { useMutation } from 'react-query';

import { AssessmentResponseType, SectionBaseType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
// import { useNotificationStore } from '@/stores/notifications';

type PostAnswers = {
  data: SectionBaseType[];
  assessmentId: string;
};

export const putAssessmentAnswers = ({
  data,
  assessmentId,
}: PostAnswers): Promise<{ responseObj: AssessmentResponseType[] }> => {
  return axios.put(`assessments/take/${assessmentId}`, data);
};

type UsePostAnswersOptions = {
  config?: MutationConfig<typeof putAssessmentAnswers>;
};

export const usePutAssessmentAnswers = ({ config }: UsePostAnswersOptions = {}) => {
  // const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      console.log('context', context);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('takeAssessments');
      // addNotification({
      //   type: 'success',
      //   title: 'Draft successfully saved!',
      // });
    },
    ...config,
    mutationFn: putAssessmentAnswers,
  });
};
