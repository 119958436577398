import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useEffect, useState } from 'react';

// import { ReactComponent as DownloadIcon } from '@/assets/icons/downloadIcon.svg';
import { ReactComponent as Expand } from '@/assets/icons/expand.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/infoIcon.svg';
import { ReactComponent as RobotIcon } from '@/assets/icons/robot.icon.svg';
import { ReactComponent as SideNavMaxIcon } from '@/assets/icons/sidenav.svg';
import { Tooltip } from '@/components/tooltip';
import { ChatMode, HeaderMenu } from '@/features/chat';

export interface ChatHeaderProps {
  title: string;
  maxModeTitle?: string;
  chatMode: ChatMode;
  openSideNav?: boolean;
  isHistoryLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onChatMinimize?: () => void;
  onChatMaximize?: () => void;
  onStartNewChat?: () => void;
  onOpenSideNav?: () => void;
}

export const ChatHeader = (props: ChatHeaderProps) => {
  const [warmUp, setWarmUp] = useState<string>('warm-up');

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (props.chatMode === 'init') {
      timer = setTimeout(() => {
        setWarmUp('');
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleMaximize = () => {
    if (props.onChatMaximize) {
      props.onChatMaximize();
    }
  };

  const handleMinimize = () => {
    if (props.onChatMinimize) {
      props.onChatMinimize();
    }
  };

  const handleOpenSideNav = () => {
    if (props.onOpenSideNav) {
      props.onOpenSideNav();
    }
  };

  return (
    <div
      className={`chat-box-heading ${props.chatMode + ''}-mode ${warmUp} relative${
        props.chatMode === 'max' && props.openSideNav ? ' with-sidenav' : ''
      }`}
    >
      {props.chatMode === 'max' && (
        <div aria-hidden className="side-nav-max-icon" onClick={handleOpenSideNav}>
          <SideNavMaxIcon
            fill="#fff"
            className={`${props.chatMode === 'max' && props.openSideNav ? 'with-sidenav' : ''}`}
          />
        </div>
      )}
      <div className="to-left">
        {props.chatMode !== 'max' && (
          <RobotIcon className={`${props.chatMode === 'init' ? '' : 'small'}`} />
        )}

        {warmUp === '' && props.chatMode !== 'max' && (
          <span>
            {props.chatMode === 'normal' && (
              <span>{`${props.title} ${
                props.maxModeTitle ? ` - ${props.maxModeTitle}` : ''
              }`}</span>
            )}
            {props.chatMode === 'min' && <span className="header-notification">1</span>}
          </span>
        )}
        {props.chatMode === 'max' && !props.isHistoryLoading && (
          <div className="block text-center w-full">{props.maxModeTitle}</div>
        )}
      </div>
      {props.chatMode !== 'init' && (
        <div className="to-right">
          {(props.chatMode === 'max' || props.chatMode === 'normal') && (
            <Tooltip
              text="GrowthMentor leverages GPT 3.5 and 4 and may not contain the most up-to-date
                  information. As with any generative AI model, accuracy may vary. Cross reference
                  with other sources to verify outputs."
            >
              <InfoIcon fill="white" />
            </Tooltip>
          )}
          {props.chatMode !== 'max' && <Expand onClick={handleMaximize} />}
          {props.chatMode !== 'max' && (
            <HeaderMenu onStartNewChat={props.onStartNewChat} onChatMaximize={handleMaximize} />
          )}
          {/* {props.chatMode === 'max' && <DownloadIcon />} */}
          {(props.chatMode === 'normal' || props.chatMode === 'min') && (
            <div aria-hidden onClick={handleMinimize}>
              <FontAwesomeIcon icon={props.chatMode !== 'min' ? faChevronDown : faChevronUp} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatHeader;
