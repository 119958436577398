import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { ASSESSMENT_PHASE, NestedResponseTypePaths } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { getPropertyByPath } from '@/utils/getPropertyByPath';
import { sortWithValidations } from '@/utils/sortWithValidations';

import { AssessmentResponseType } from '../types';

interface IAssessmentTakes {
  responseObj: AssessmentResponseType[];
}

export const getTakeAssessments = (
  assessment_step?: ASSESSMENT_PHASE,
  orderBy?: NestedResponseTypePaths
): Promise<AssessmentResponseType[]> => {
  const searchParams = assessment_step
    ? {
        assessment_take_user_phase: assessment_step,
      }
    : {};
  return axios
    .get(`/assessments/take`, {
      params: searchParams,
    })
    .then((data: AxiosResponse | IAssessmentTakes): AssessmentResponseType[] => {
      const assessments: AssessmentResponseType[] = (data as IAssessmentTakes)?.responseObj || [];
      if (orderBy) {
        return assessments.sort((a, b) =>
          sortWithValidations(getPropertyByPath(a, orderBy), getPropertyByPath(b, orderBy))
        );
      }
      return assessments;
    });
};

type QueryFnType = typeof getTakeAssessments;

type UseSelfAssessmentOptions = {
  config?: QueryConfig<QueryFnType>;
  orderBy?: NestedResponseTypePaths;
};

export const useTakeAssessments = (
  assessment_step?: ASSESSMENT_PHASE,
  config: UseSelfAssessmentOptions = {}
) => {
  if (!config.orderBy) {
    // Set default
    config.orderBy = 'general_settings.due_date';
  }
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['takeAssessments', assessment_step, config.orderBy],
    queryFn: () => getTakeAssessments(assessment_step, config.orderBy),
  });
};
