import { Navigate, Route, Routes } from 'react-router-dom';

import { ROLES, useAuthorization } from '@/lib/authorization';

import { AssessmentPublished } from '../components/builder/AssessmentPublished';

import { Build } from './Build';
import { Main } from './Main';
import { Results } from './Results';
import { TakeAssessment } from './TakeAssessment';
import { TakeAssessmentMain } from './TakeAssessmentMain';

export const AssessmentsRoutes = () => {
  const { checkAccess } = useAuthorization();
  return (
    <Routes>
      <Route path="" element={<Main />} />
      <Route
        path="build"
        element={
          checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) ? (
            <Build />
          ) : (
            <Navigate to="/app/dashboard" />
          )
        }
      />
      <Route
        path="build/:assessmentId"
        element={
          checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) ? (
            <Build />
          ) : (
            <Navigate to="/app/dashboard" />
          )
        }
      />
      <Route path="take/" element={<TakeAssessmentMain />} />
      <Route path="take/:assessmentId" element={<TakeAssessment />} />
      <Route path="take/published" element={<AssessmentPublished />} />

      <Route path="results/" element={<Results />} />
      <Route path="results/:assessmentId" element={<Results />} />

      <Route path="*" element={<Navigate to="/app" />} />
    </Routes>
  );
};
