import create from 'zustand';

import { ClientResponseType } from '@/features/clients';

type ClientStore = {
  clients: ClientResponseType[];
  isWorkspaceChanging: boolean;
  setWorkspaceChanging: (isWorkspaceChanging: boolean) => void;
  selectClient: (client: Omit<ClientResponseType, 'client_id'> & { client_id: string }) => void;
  addClients: (clients: ClientResponseType[]) => void;
  updateClientDetails: (
    clientId: string,
    updatedFields: Partial<
      Pick<
        ClientResponseType,
        'client_name' | 'description' | 'industry_name' | 'role_id' | 'team_roles'
      >
    >
  ) => void;
  // getClients: () => Promise<true | undefined>;
};

// export const useClientStore = create<ClientStore>((set, get) => ({
export const useClientStore = create<ClientStore>((set) => ({
  clients: [],
  isWorkspaceChanging: false,
  addClients: (clients: ClientResponseType[]) =>
    set((state) => {
      //making sure not to add duplicate clients by adding these 3 steps:
      const existingClientIds = state.clients.map((client) => client.client_id);
      const newClients = clients.filter((client) => !existingClientIds.includes(client.client_id));
      const updatedClients = state.clients.filter(
        (client) => !newClients.map((c) => c.client_id).includes(client.client_id)
      );
      return { clients: [...updatedClients, ...newClients] };
    }),
  setWorkspaceChanging: (isWorkspaceChanging: boolean) => {
    set(() => {
      return { isWorkspaceChanging: isWorkspaceChanging };
    });
  },
  selectClient: (client: Omit<ClientResponseType, 'client_id'> & { client_id: string }) => {
    set((state) => {
      const updatedClients = state.clients.map((c) => {
        if (c.client_id === client.client_id) {
          return { ...c, client_selected: true };
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { client_selected, ...updatedClient } = c;
        return updatedClient;
      });

      return { clients: updatedClients };
    });
  },
  updateClientDetails: (clientId, updatedFields) =>
    set((state) => {
      const updatedClients = state.clients.map((client) =>
        client.client_id === clientId ? { ...client, ...updatedFields } : client
      );

      return { clients: updatedClients };
    }),

  // getClients: async () => {
  //   try {
  //     const state = get();
  //     const response = await getClients();
  //     console.log('response', response);
  //     console.log('state', state);

  //     const client = response.filter((client) => client.client_selected)[0];

  //     set({
  //       clients: [...response],
  //       teamRoles2: client.team_roles || state.teamRoles2,
  //       selectedClient2: client,
  //     });

  //     return true;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
}));
