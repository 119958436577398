import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  registration: Partial<UseFormRegisterReturn>;
  value: string;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
};

export const Checkbox = ({
  hidden,
  label,
  registration,
  value,
  disabled,
  className,
}: CheckboxProps) => {
  return (
    <label className={clsx('checkbox', disabled && 'disabled', hidden && 'hide', className)}>
      {label}
      <input
        value={value}
        type="checkbox"
        disabled={disabled}
        hidden={hidden ? true : false}
        {...registration}
      />
      <span className="checkmark"></span>
    </label>
  );
};
