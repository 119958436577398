import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Button, Dialog, DialogTitle } from '@/components/Elements';
import { useUser } from '@/features/users';
import { useDisclosure } from '@/hooks/useDisclosure';

export const BaselineModal = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [searchParams] = useSearchParams();
  const { open, close, isOpen } = useDisclosure();
  const navigate = useNavigate();
  const [assessmentId, setAssessmentId] = useState<string>();
  const baseline_modal = window.localStorage.getItem('baseline_modal');

  const { data: userData } = useUser({
    email: user.attributes?.email as string,
    config: { enabled: !!user.attributes?.email && !!baseline_modal === false },
  });

  useEffect(() => {
    if (userData) {
      const { custom_baseline_assessment } = userData;

      if (custom_baseline_assessment && custom_baseline_assessment !== '' && !baseline_modal) {
        window.localStorage.setItem('baseline_modal', 'true');
        setAssessmentId(custom_baseline_assessment);
        open();
      }
    }
  }, [baseline_modal, open, searchParams, userData]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Welcome to Spark!
          </DialogTitle>
          <div className="modal__info">
            <p>
              To get to know you better, you’ll be required to take a Baseline Reflection (it’ll
              only take 15 minutes!). If you don’t want to take it now, you’ll receive weekly email
              reminders to come back and complete the Baseline Reflection.
            </p>
          </div>
          <div className="baseline-modal__actions">
            <Button
              type="button"
              variant="inverse"
              onClick={() => {
                close();
              }}
            >
              Exit
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                navigate(`assessment/take/${assessmentId}`);
                close();
              }}
            >
              Take reflection
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
