import { Navigate, Route, Routes } from 'react-router-dom';

import { ROLES, useAuthorization } from '@/lib/authorization';

import { Clients } from './Clients';
import { CreateClient } from './CreateClient';

export const ClientsRoutes = () => {
  const { checkAccess } = useAuthorization();
  return (
    <Routes>
      <Route path="" element={<Clients />} />

      <Route
        path="create-client"
        element={
          checkAccess({ allowedRoles: [ROLES.ADMIN] }) ? (
            <CreateClient />
          ) : (
            <Navigate to="/app/dashboard" />
          )
        }
      />

      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
