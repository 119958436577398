import { useMutation } from 'react-query';

import { TeamBaseType, TeamResponseType } from '@/features/teams';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

export const createTeam = (data: TeamBaseType): Promise<TeamResponseType> => {
  return axios.post('/teams', data).then((data: any) => data.responseObj);
};

type UseCreateTeamOptions = {
  config?: MutationConfig<typeof createTeam>;
};

export const useCreateTeam = ({ config }: UseCreateTeamOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      console.log('context', context);
    },
    onSuccess: () => {},
    ...config,
    mutationFn: createTeam,
  });
};
