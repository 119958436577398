import { useState } from 'react';

// 1 lowercase letter
// 1 uppercase letter
// 1 number
// 1 special character
export const PASSWORD_REGEX = '^(?=\\S+$)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$&%()/\\.+])(?=.*[0-9]).*';
export const NAME_REGEX = "^[a-zA-Z.'-]{2,50}(?: [a-zA-Z.'-]{2,50})+$";
export const ONLY_NUMBERS_REGEX = /^[0-9\b]+$/;
const ERRORS = {
  name: 'This field requires first and last name.',
  password: 'Please match the requested format.',
  email: 'Input a valid email.',
  required: 'This field is required.',
  phone_number_length: 'Please enter a valid phone number.',
  sms_code: 'SMS code is invalid.',
};

export const useForceNewPassword = (): Array<any> => {
  const [formErrors, setFormErrors] = useState<{}>({
    name: 'required',
    password: 'required',
    confirm_password: 'required',
    phone_number: 'required',
    'custom:acknowledgement': 'required',
  });

  const modifyFormErrorState = (action: string, attribute: string, message?: string) => {
    if (action === 'add') {
      setFormErrors({
        ...formErrors,
        [attribute]: {
          message,
        },
      });
    } else if (action === 'remove') {
      setFormErrors((prevState: any) => {
        const copy = { ...prevState };
        delete copy[attribute];
        return copy;
      });
    }
  };

  const validateField = (field: string, condition: boolean, message: string) => {
    if (condition) {
      modifyFormErrorState('remove', field);
    } else {
      modifyFormErrorState('add', field, message);
    }
  };

  return [ERRORS, formErrors, validateField, modifyFormErrorState];
};
