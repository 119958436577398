import clsx from 'clsx';
import { useEffect } from 'react';
import { NavLink, useMatch, useResolvedPath, matchPath } from 'react-router-dom';

interface ISibling {
  to: string;
  label: string;
  labelParent?: string;
  allowed?: boolean;
  allowedParent?: boolean;
  isDefault?: boolean;
  active?: string;
  setActive?: (x: string) => void;
  setExpand?: (x: boolean) => void;
}

export interface ISideMenuLink extends ISibling {
  siblings?: Array<ISibling>;
}

export const SideMenuLink = (props: ISideMenuLink) => {
  const { pathname: resolvedPath } = useResolvedPath(props.to);
  const matchCurrent = useMatch({ path: resolvedPath, end: false });

  useEffect(() => {
    if (matchCurrent) {
      props.setActive && props.setActive(props.labelParent ?? '');
      props.setExpand && props.setExpand(true);
    } else {
      props.setActive && props.setActive('');
    }
  }, [props.labelParent, matchCurrent, props.setActive, props.setExpand]);

  const shouldEnd = () => {
    if (props.isDefault) {
      const linksMatched = props.siblings?.filter((sibling: ISibling) => {
        return (
          matchPath({ path: `/app/${sibling.to}`, end: false }, window.location.pathname) &&
          !sibling.isDefault
        );
      });

      if (linksMatched?.length === 0) {
        return false;
      }

      return true;
    }

    return false;
  };

  return (
    <NavLink
      key={props.label}
      end={shouldEnd()}
      to={props.to}
      className={clsx(
        !(props.allowed && props.allowedParent) && 'disabled',
        'menu-item__link child body-4'
      )}
      activeClassName={matchCurrent ? 'active-link child' : 'child'}
    >
      {props.label}
    </NavLink>
  );
};
