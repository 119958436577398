import { Route, Routes } from 'react-router-dom';

import { Ventureverse } from './ventureverse';

export const VentureverseRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Ventureverse />} />
    </Routes>
  );
};
