import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as QuestionsIcon } from '@/assets/icons/questionsIcon.svg';
import { ReactComponent as SettingsIcon } from '@/assets/icons/settingsIcon.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/trashIcon.svg';
import { Button, Tab, Spinner } from '@/components/Elements';
import {
  useSaveAssessment,
  useAssessment,
  AssessmentBaseType,
  useDeleteAssessment,
  SectionBaseType,
  useAssessmentTemplate,
  Take,
  useQuestions,
  SectionResponseType,
  AssessmentResponseType,
  useCloneAssessment,
} from '@/features/assessments';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useLeavePageStore } from '@/stores/leavePage';

import { usePublishAssessment } from '../../api/publishAssessment';
import { useAssessmentTakeStore } from '../../stores/assessmentTakeStore';
import { useAssessmentTemplatesStore } from '../../stores/assessmentTemplateStore';
import { GeneralSettings } from '../generalSettings/GeneralSettings';

import { BuilderContent } from './BuilderContent';
import { DeleteModal } from './DeleteModal';
import { PublishModal } from './PublishModal';

interface BuilderProps {
  published?: boolean;
  displayDrafts?: boolean;
  handleDisplayDrafts: (storeUpdate?: boolean) => void;
  isAssesmentLoadingSet: (x: boolean) => void;
  publishedSet: (x: boolean) => void;
}

enum TabOptions {
  QUESTIONS = 'Questions',
  GENERAL_SETUP = 'General setup',
}

export const Builder = ({
  publishedSet,
  isAssesmentLoadingSet,
  displayDrafts,
  handleDisplayDrafts,
}: BuilderProps) => {
  const { assessmentId, '*': baseUrl } = useParams();
  const { selected_template, setSelectedTemplate } = useAssessmentTemplatesStore();
  const { data: questionsData } = useQuestions();
  const { setSelected } = useAssessmentTakeStore();
  const [searchParams] = useSearchParams();
  const templateParam = searchParams.get('template');
  const templateEditModeParam = searchParams.get('view');
  const previewTypeParam = searchParams.get('preview-type');
  const navigate = useNavigate();
  const { mutateAsync: deleteAssessment, isLoading: loadingDelete } = useDeleteAssessment();
  const { mutateAsync: publishAssessment, isLoading: isLoadingPublishAssessment } =
    usePublishAssessment();
  const { close: closePublish, isOpen: isOpenPublish, open: openPublish } = useDisclosure();
  const { close: closeDelete, isOpen: isOpenDelete, open: openDelete } = useDisclosure();
  const [isSaveOpen, setSaveIsOpen] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [tab, setTab] = useState('');
  const { handleSubmit: handleDelete } = useForm();
  const { removeBlocker } = useLeavePageStore();
  const form = useForm({ mode: 'all' });
  const {
    formState: { isDirty, errors, isValid },
    handleSubmit,
    clearErrors,
    getValues,
    setError,
    reset,
  } = form;

  const { data: dataTemplate, isLoading: isLoadingTemplate } = useAssessmentTemplate(
    templateParam,
    {
      enabled: false,
    }
  );

  const {
    refetch: refetchAssessment,
    data: assessmentData,
    isSuccess: assessmentLoaded,
    isRefetching: assessmentRefetching,
    isLoading: assessmentLoading,
  } = useAssessment(assessmentId, {
    enabled: false,
    cacheTime: 0,
  });

  const {
    mutateAsync: mutateAsyncAssessment,
    isSuccess: isAssessmentSaved,
    isLoading: loadingSave,
  } = useSaveAssessment();

  const { mutateAsync: mutateAsyncCloneAssessment } = useCloneAssessment();

  const disabledPublish = useMemo(() => {
    return !assessmentId || isDirty || (!isValid && !!errors.publish);
  }, [assessmentId, errors.publish, isDirty, isValid]);

  const onCloneAssessment = useCallback(() => {
    const data = getValues() as AssessmentBaseType;
    removeBlocker();
    mutateAsyncCloneAssessment(data).then((data) => {
      if (!assessmentId || data.assessment_id !== assessmentId) {
        navigate(
          assessmentId
            ? `../${data.assessment_id}`
            : baseUrl === 'build'
            ? data.assessment_id
            : `build/${data.assessment_id}`,
          {
            replace: true,
          }
        );
      } else {
        reset(data);
        refetchAssessment();
      }
    });
    setTab(TabOptions.QUESTIONS);
  }, [
    assessmentId,
    baseUrl,
    getValues,
    mutateAsyncAssessment,
    navigate,
    refetchAssessment,
    removeBlocker,
    reset,
  ]);

  const onSaveAssessment = useCallback(() => {
    const data = getValues() as AssessmentBaseType;
    removeBlocker();
    mutateAsyncAssessment(data).then((data) => {
      if (!assessmentId || data.assessment_id !== assessmentId) {
        navigate(
          assessmentId
            ? `../${data.assessment_id}`
            : baseUrl === 'build'
            ? data.assessment_id
            : `build/${data.assessment_id}`,
          {
            replace: true,
          }
        );
      } else {
        reset(data);
        refetchAssessment();
      }
    });
    setTab(TabOptions.QUESTIONS);
  }, [
    assessmentId,
    baseUrl,
    getValues,
    mutateAsyncAssessment,
    navigate,
    refetchAssessment,
    removeBlocker,
    reset,
  ]);

  const onPublishAssessment = useCallback(
    (data: AssessmentBaseType) => {
      if (!data?.sections?.[0]?.questions?.length) {
        setError('sections.noQuestions', {
          message: 'You must add at least one section and one question',
        });
        if (!errors.publish) setError('publish', { message: 'Reflection can not be published' });
      } else {
        if (!Object.keys(errors).length) {
          if (!isOpenPublish) openPublish();
          else {
            publishAssessment(data).then(() => publishedSet(true));
          }
        }
      }
    },
    [isOpenPublish, openPublish, publishAssessment, publishedSet, errors, setError]
  );

  const onPublishError = useCallback(() => {
    // TODO Update sections with qeustions validation
    if (!errors.publish) setError('publish', { message: 'Reflection can not be published' });
    if (!errors.sections?.noQuestions) {
      const sections = getValues('sections') as SectionBaseType[] | undefined;
      if (sections?.some((x: SectionBaseType) => !x.questions?.length))
        setError('sections.noQuestions', {
          message: 'You must add at least one section and one question',
        });
    }
  }, [errors.publish, errors.sections?.noQuestions, setError, getValues]);

  const loadPreview = useCallback(() => {
    const assessment_t = getValues() as AssessmentResponseType | undefined;
    const mockResponse = assessment_t?.recievers?.length
      ? assessment_t.recievers.map((x: string) => ({
          name: assessment_t.general_settings?.members_with_roles?.find((m) => m.email === x)?.name,
          comment: '',
          email: x,
          response: '',
        }))
      : [
          {
            name: 'Team member',
            comment: '',
            email: 'default_user@default.com',
            response: '',
          },
        ];

    assessment_t?.sections?.forEach((section: SectionResponseType) => {
      section.questions.forEach((q) => {
        q.question_scale =
          questionsData?.find((x) => x.question_type === q.question_type)?.question_scale ||
          questionsData?.find((x) => x.question_scale_type === q.question_scale_type)
            ?.question_scale;
        if (!q.response?.length) q.response = mockResponse;
        q.assessment_question_id = `${
          Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000
        }`;
      });
    });
    if (assessment_t && !assessment_t.sections) assessment_t.sections = [];

    setSelected(assessment_t);
    handleDisplayDrafts(false);
    setIsPreview(true);
  }, [getValues, setSelected, questionsData, previewTypeParam]);

  const onDeleteAssessment = useCallback(() => {
    if (!isOpenDelete) openDelete();
    else {
      removeBlocker();
      if (assessmentId)
        deleteAssessment(assessmentId).then(() => {
          navigate('../..', { replace: true });
          closeDelete();
        });
    }
  }, [
    assessmentId,
    closeDelete,
    deleteAssessment,
    isOpenDelete,
    navigate,
    openDelete,
    removeBlocker,
  ]);

  useEffect(() => {
    if (assessmentId) {
      reset({}, { keepErrors: true });
      clearErrors('publish');
      setIsPreview(false);
      refetchAssessment();
    }
    setTab(TabOptions.QUESTIONS);
  }, [assessmentId, clearErrors, refetchAssessment, reset]);

  useEffect(() => {
    if (assessmentData?.length) {
      reset(assessmentData[0], { keepErrors: true });
      clearErrors('publish');
    }
  }, [assessmentData, assessmentLoaded, clearErrors, reset]);

  useEffect(() => {
    if (isAssessmentSaved) {
      removeBlocker();
    }
  }, [isAssessmentSaved, removeBlocker]);

  useEffect(() => {
    if (dataTemplate) {
      reset(dataTemplate[0], { keepErrors: true });
      clearErrors('publish');
    }
  }, [dataTemplate, reset, clearErrors]);

  useEffect(() => {
    if (selected_template) {
      reset(selected_template, { keepErrors: true });
      clearErrors('publish');
    }
  }, [clearErrors, reset, selected_template]);

  useEffect(() => {
    return () => {
      setSelected(undefined);
      setSelectedTemplate(undefined);
      reset(undefined, { keepErrors: true });
    };
  }, [setSelected, assessmentId, setSelectedTemplate, reset]);

  const options = useMemo(() => {
    return [
      {
        label: TabOptions.QUESTIONS,
        selected: TabOptions.QUESTIONS === tab,
        icon: <QuestionsIcon className="baseline" />,
        content: <BuilderContent {...form} />,
        className:
          errors.sections || errors.assessment_instructions || errors.assessment_name
            ? 'tab-error'
            : '',
      },
      {
        label: TabOptions.GENERAL_SETUP,
        selected: TabOptions.GENERAL_SETUP === tab,
        icon: <SettingsIcon />,
        content: (
          <GeneralSettings
            {...form}
            generalSettings={assessmentData?.[0]?.general_settings}
            isBlank={!assessmentId}
          />
        ),
        className: errors?.publish && errors?.general_settings ? 'tab-error' : '',
      },
    ];
  }, [tab, form, previewTypeParam]);

  useEffect(() => {
    if (previewTypeParam && !isPreview && Object.keys(getValues()).length > 0) {
      switch (previewTypeParam) {
        case 'draft':
        case 'published':
          loadPreview();
          break;
        default:
          setIsPreview(false);
          break;
      }
    }
  }, [previewTypeParam, assessmentLoaded, options]);

  useEffect(() => {
    if (isLoadingTemplate || assessmentLoading || assessmentRefetching) {
      isAssesmentLoadingSet(true);
    } else {
      isAssesmentLoadingSet(false);
    }
  }, [assessmentLoading, assessmentRefetching, isAssesmentLoadingSet, isLoadingTemplate]);

  useEffect(() => {
    // Clearing errors when changing the assessment
    clearErrors();
  }, [assessmentId]);

  const saveButtonsValidation = (extraValidation?: boolean) => {
    const { assessment_name, assessment_instructions } = getValues() as AssessmentBaseType;
    return (
      errors?.sections ||
      errors?.assessment_instructions ||
      errors?.assessment_name ||
      !assessment_name ||
      !assessment_instructions ||
      assessmentRefetching ||
      loadingSave ||
      extraValidation
    );
  };

  const handleAssignToTeamButton = () => {
    setTab(TabOptions.GENERAL_SETUP);
  };

  if (isLoadingTemplate || assessmentLoading || assessmentRefetching)
    return (
      <div className="builder__loader">
        <Spinner />
      </div>
    );

  return (
    <div className="w-full m-0 p-0">
      {isPreview && <div className="builder__preview-header">Reflection Preview</div>}
      <div
        className={clsx([
          'builder__main-container',
          isPreview && '!rounded-t-none',
          !displayDrafts && 'full-round',
        ])}
      >
        <form hidden={isPreview}>
          <PublishModal
            isOpen={isOpenPublish}
            close={closePublish}
            onClick={handleSubmit(onPublishAssessment, onPublishError)}
            getValue={getValues}
            isLoadingPublishAssessment={isLoadingPublishAssessment}
          />
          <DeleteModal
            isOpen={isOpenDelete}
            close={closeDelete}
            onClick={handleDelete(onDeleteAssessment)}
            loadingDelete={loadingDelete}
          />
          <Tab
            isLoading={assessmentLoading}
            options={options}
            setParentAttr={setTab}
            displayDrafts={displayDrafts}
            handleDisplayDrafts={handleDisplayDrafts}
          />
          <div className="builder__actions">
            <div className="dropdown-container">
              <Button
                onClick={() => {
                  setSaveIsOpen(!isSaveOpen);
                }}
                variant="inverse"
                isLoading={loadingSave}
              >
                Save
              </Button>
              {isSaveOpen && (
                <ul
                  className="dropdown-menu"
                  onMouseLeave={() => {
                    setTimeout(() => {
                      setSaveIsOpen(!isSaveOpen);
                    }, 1000);
                  }}
                >
                  <li className={saveButtonsValidation(!isDirty) ? 'disabled' : ''}>
                    <button
                      onClick={() => {
                        onSaveAssessment();
                        setSaveIsOpen(!isSaveOpen);
                      }}
                      disabled={saveButtonsValidation(!isDirty)}
                    >
                      Save
                    </button>
                  </li>
                  <li className={saveButtonsValidation(!assessmentId) ? 'disabled' : ''}>
                    <button
                      onClick={() => {
                        onCloneAssessment();
                        setSaveIsOpen(!isSaveOpen);
                      }}
                      disabled={saveButtonsValidation(!assessmentId)}
                    >
                      Clone
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <Button onClick={loadPreview}>Preview</Button>
            {templateEditModeParam !== 'EDIT' && (
              <Button
                disabled={disabledPublish}
                onClick={
                  tab === TabOptions.QUESTIONS
                    ? handleAssignToTeamButton
                    : handleSubmit(onPublishAssessment, onPublishError)
                }
              >
                {tab === TabOptions.QUESTIONS ? 'Assign to Team' : 'Publish'}
              </Button>
            )}
            {!!assessmentId && (
              <button onClick={handleDelete(onDeleteAssessment)}>
                <TrashIcon color="#C80404" />
              </button>
            )}
          </div>
        </form>
        {isPreview && (
          <Take
            previewMode={isPreview}
            withMembers={tab === 'General setup' || previewTypeParam === 'published'}
          />
        )}
      </div>
      {isPreview && (
        <div className="w-100 flex justify-end">
          {!previewTypeParam ? (
            <Button onClick={() => setIsPreview(false)}>Close Preview</Button>
          ) : (
            <Button onClick={() => navigate('/app/assessment')}>Close Preview</Button>
          )}
        </div>
      )}
    </div>
  );
};
