const chartColors = [
  '#AD004E',
  '#025F74',
  '#9600AD',
  '#18671D',
  '#0037FF',
  '#943E00',
  '#6616B6',
  '#066537',
  '#8A4300',
  '#B20101',
];

export default chartColors;
