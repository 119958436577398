import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Spinner } from '@/components/Elements';
import { InputField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import { useSendCode } from '@/features/my_profile';
import { useNotificationStore } from '@/stores/notifications';

export const MFA = () => {
  const { mutateAsync: sendCode, isLoading: loadingSendCode } = useSendCode();
  const [userInfo, setUserInfo] = useState<any>();
  const [isLoadingValidateCode, setLoadingValidateCode] = useState<boolean>(false);
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
      setUserInfo(user);
    });
  }, []);

  const onValidateCode = useCallback(
    (data: any) => {
      setLoadingValidateCode(true);
      Auth.verifyUserAttributeSubmit(user, 'phone_number', data.code)
        .then(() => {
          setLoadingValidateCode(false);
          addNotification({
            type: 'success',
            title: 'Code successfully send!',
          });
          navigate('/app/profile');
        })
        .catch((error) => {
          setLoadingValidateCode(false);
          addNotification({
            type: 'error',
            title: error.message,
          });
        });
    },
    [addNotification, navigate, user]
  );

  return (
    <ContentLayout title="Verify Multi-Factor Authentication" back={'/app/profile'}>
      {userInfo && userInfo.attributes ? (
        <form onSubmit={handleSubmit(onValidateCode)}>
          <div className="mfa container">
            <p>
              An authentication code will be sent to your preferred method of authentication. Please
              input the code in the field below to verify your phone number or secondary email
              address for multi-factor authentication.
            </p>
            <h5 className="bold">Your phone number:</h5>
            <h3 className="bold">{userInfo.attributes.phone_number}</h3>
            <div className="mfa__actions">
              <Button
                onClick={() => sendCode({ email: userInfo.attributes.email })}
                isLoading={loadingSendCode}
              >
                Send authentication code
              </Button>
              <InputField
                label="Confirm Authentication Code"
                registration={register('code', {
                  required: 'This field is required',
                })}
              />
              <Button isLoading={isLoadingValidateCode} type="submit" disabled={!isValid}>
                Verify Multi-Factor Authentication
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <Spinner />
      )}
    </ContentLayout>
  );
};
