/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//   aws_project_region: 'us-east-2',
//   aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
//   aws_user_pools_id: process.env.REACT_APP_POOL_ID,
//   aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID,
//   oauth: {},
//   aws_cognito_username_attributes: ['EMAIL'],
//   aws_cognito_social_providers: [],
//   aws_cognito_signup_attributes: ['EMAIL'],
//   aws_cognito_mfa_configuration: 'ON',
//   aws_cognito_mfa_types: ['SMS'],
//   aws_cognito_password_protection_settings: {
//     passwordPolicyMinLength: 8,
//     passwordPolicyCharacters: [
//       'REQUIRES_LOWERCASE',
//       'REQUIRES_UPPERCASE',
//       'REQUIRES_NUMBERS',
//       'REQUIRES_SYMBOLS',
//     ],
//   },
//   aws_cognito_verification_mechanisms: ['PHONE_NUMBER'],
// };

const awsExports = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    identityPoolId: process.env.REACT_APP_POOL_ID,
    userPoolId: process.env.REACT_APP_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
};

export default awsExports;
