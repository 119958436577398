import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getOrganizations = (): Promise<any[]> => {
  return axios.get('organizations').then((data: any) => data.responseObj);
};

type QueryFnType = typeof getOrganizations;

type UseOrganizationsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useOrganizations = ({ config }: UseOrganizationsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['organizations'],
    queryFn: () => getOrganizations(),
  });
};
