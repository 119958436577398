import { Button, Dialog, DialogTitle } from '@/components/Elements';

interface IConfirmModal {
  isOpen: boolean;
  onClick: () => void;
  close: () => void;
  loadingConfirm: boolean;
}

export const ConfirmModal = ({ isOpen, onClick, close, loadingConfirm }: IConfirmModal) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Leave Page?
          </DialogTitle>
          <div className="modal__info">
            <p>
              Are you sure you want to navigate away from this page? Any changes you have made on
              this page will not be saved.
            </p>
          </div>
          <div className="builder__publish-modal-buttons">
            <Button type="button" onClick={close} variant="inverse">
              Stay
            </Button>
            <Button onClick={onClick} isLoading={loadingConfirm}>
              Leave
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
