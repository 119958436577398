import { ContentLayout } from '@/components/Layout';
import { useTeamMemberList } from '@/hooks/useTeamMemberList';

import { Members } from '../components/members/Members';

export const MemberAdd = () => {
  const memberListMethods = useTeamMemberList();

  return (
    <ContentLayout title="Add team members" back={'teams'}>
      <Members {...memberListMethods} />
    </ContentLayout>
  );
};
