import clsx from 'clsx';
import { forwardRef } from 'react';

import { Spinner } from '@/components/Elements/Spinner';

import './style.scss';

const baseClaseName = 'add-button';

const sizes = {
  sm: `${baseClaseName}-sm`,
  md: `${baseClaseName}-md`,
  lg: `${baseClaseName}-lg`,
};

export type AddButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: keyof typeof sizes;
  isLoading?: boolean;
  label?: string;
};

export const AddButton = forwardRef<HTMLButtonElement, AddButtonProps>(
  ({ label, type = 'button', className = '', size = 'lg', isLoading = false, ...props }, ref) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(`${baseClaseName}`, sizes[size], label && 'add-button-label', className)}
        {...props}
      >
        {label && <span>{label}</span>}
        {isLoading && <Spinner size="sm" className="text-current" />}
      </button>
    );
  }
);

AddButton.displayName = 'AddButton';
