import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { AssessmentResponseType } from '../types';

const adminCloseAssessment = (
  assessment_id: string
): Promise<{ responseObj: AssessmentResponseType[] }> => {
  return axios.post(`assessments/${assessment_id}/publish/admin_close`);
};

type UseAdminCloseAssessmentOptions = {
  config?: MutationConfig<typeof adminCloseAssessment>;
};

export const useAdminCloseAssessment = ({ config }: UseAdminCloseAssessmentOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      addNotification({
        type: 'error',
        title: context,
      });
    },
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Changes successfully saved ',
      });
    },
    ...config,
    mutationFn: adminCloseAssessment,
  });
};
