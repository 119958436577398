import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Spinner } from '@/components/Elements';
import { useCreateUser, useUsers, useResetPassword } from '@/features/users';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useLeavePageStore } from '@/stores/leavePage';
import { useNotificationStore } from '@/stores/notifications';
import { formatDateFull } from '@/utils/format';

import { ResetPasswordModal } from '../ResetPasswordModal';
import { PermissionsHeader } from '../usersTable/PermissionsHeader';
import { UsersTable } from '../usersTable/UsersTable';

export const UsersList = () => {
  const navigate = useNavigate();
  const [selectEmail, selectEmailSet] = useState('');
  const { close: closeReset, isOpen: isOpenReset, open: openReset } = useDisclosure();
  const { addNotification } = useNotificationStore();

  const {
    isLoading: usersLoading,
    data: usersData,
    refetch: refetchUsers,
    isRefetching: refetchingUsers,
  } = useUsers();

  const { removeBlocker } = useLeavePageStore();
  const { mutateAsync: mutateAsyncUser, isLoading: isLoadingUser } = useCreateUser();
  const { mutateAsync: mutateResetPassword, isLoading: isResettingPassword } = useResetPassword();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userList, setUserList] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState('');

  //Defining table columns
  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
      },

      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: PermissionsHeader,
        accessor: 'permissions',
      },
      {
        Header: ' ',
        accessor: 'editUser',
      },
    ],
    []
  );

  //Table data
  useEffect(() => {
    refetchUsers();
  }, [refetchUsers]);

  const filteredUserList = useMemo(() => {
    if (!usersData) return [];

    // Sort the usersData array based on UserLastModifiedDate in descending order
    const sortedUsers = usersData.slice().sort((a, b) => {
      const dateA = new Date(a.UserLastModifiedDate).getTime();
      const dateB = new Date(b.UserLastModifiedDate).getTime();
      return dateB - dateA;
    });

    return sortedUsers
      .filter((user) => {
        const searchString = searchQuery?.toLowerCase();

        return (
          user.name?.toLowerCase().includes(searchString) ||
          user.email?.toLowerCase().includes(searchString)
        );
      })
      .map((user) => ({
        user: (
          <div>
            <p className="user-name">{user.name ? user.name : 'Pending Login'}</p>
            <p className="last-login">
              {user.name && user.phone_number
                ? `Last Login: ${formatDateFull(user.UserLastModifiedDate)}`
                : 'Login Pending'}
            </p>
          </div>
        ),
        email: user.email,
        permissions:
          user.custom_role === '5'
            ? 'Global Admin'
            : user.custom_role === '0'
            ? 'Client Admin'
            : user.custom_role === '8'
            ? 'User'
            : 'Unknown Role',
        editUser:
          user.Enabled === 'true' ? (
            user.name && user.phone_number ? (
              <Button
                className="table-button"
                variant="inverse"
                onClick={() => navigate(`user/${user.email}`, { replace: true })}
              >
                EDIT USER
              </Button>
            ) : (
              <Button
                className="table-button"
                variant="inverse"
                onClick={() => onResetPassword(user.email)}
                isLoading={user.email === selectEmail && isResettingPassword}
              >
                RESET PASSWORD
              </Button>
            )
          ) : (
            <Button
              className="table-button"
              variant="inverse"
              onClick={() => enableUser(user.email)}
              isLoading={user.email === selectEmail && (isLoadingUser || refetchingUsers)}
            >
              REACTIVATE
            </Button>
          ),
      }));
  }, [usersData, searchQuery, selectEmail, isResettingPassword, isLoadingUser, refetchingUsers]);

  const enableUser = useCallback(
    (email: string) => {
      selectEmailSet(email);
      mutateAsyncUser([{ email }]).then(() => {
        refetchUsers();
      });
    },
    [mutateAsyncUser, refetchUsers]
  );

  const onResetPassword = useCallback(
    (email) => {
      selectEmailSet(email);
      if (!isOpenReset) {
        openReset();
      } else {
        removeBlocker();
        mutateResetPassword({ email })
          .then(() => {
            closeReset();
          })
          .catch((error) => {
            console.error('Error sending password reset email:', error);
            closeReset();
            addNotification({
              type: 'error',
              title: 'Password Reset Failed',
            });
          });
      }
    },
    [closeReset, isOpenReset, openReset, removeBlocker, mutateResetPassword]
  );

  return (
    <div className="inline-block w-full users-list">
      {usersLoading ? (
        <div className="users-list-item mb-3">
          <div className="flex justify-center w-full">
            <Spinner size="lg" />
          </div>
        </div>
      ) : (
        <form>
          <div className=" flex page-header">
            <p className="header">All Users</p>
            <input
              className="search"
              type="text"
              placeholder="Search for user by name or email address"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <ResetPasswordModal
            isOpen={isOpenReset}
            close={closeReset}
            onClick={() => onResetPassword(selectEmail)}
          />
          <UsersTable columns={columns} data={filteredUserList} />
        </form>
      )}
    </div>
  );
};
