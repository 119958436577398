import { default as dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { ReactComponent as CircleCheck } from '@/assets/icons/circleCheck.svg';
import { ReactComponent as Clone } from '@/assets/icons/clone.svg';
import { ReactComponent as RobotIcon } from '@/assets/icons/robot.icon.svg';
// import { ReactComponent as PencilIcon } from '@/assets/icons/pencilIcon.svg';
// import { ReactComponent as Refresh } from '@/assets/icons/refresh.svg';
import { ReactComponent as ThumbsDown } from '@/assets/icons/thumbs-down.svg';
import { ReactComponent as ThumbsUp } from '@/assets/icons/thumbs-up.svg';
import {
  ChatModel,
  ChatTyping,
  MessageReact,
  UserReaction,
  grayColor,
  purpleColor,
} from '@/features/chat';
import { toEpoch } from '@/utils/format';

export interface ChatMessageProps {
  data?: ChatModel[];
  isTyping?: boolean;
  isHistoryLoading?: boolean;
  onMessageReact?: (messageReact: MessageReact, mentorMessageId: string) => void;
}

export const ChatMessage = (props: ChatMessageProps) => {
  const [messageCopy, setMessageCopy] = useState<boolean>(false);
  const [userReaction, setUserReaction] = useState<UserReaction[]>([]);

  useEffect(() => {
    if (messageCopy) {
      setTimeout(() => {
        setMessageCopy(false);
      }, 2000);
    }
  }, [messageCopy]);

  useEffect(() => {
    if (props.data) {
      const newUserReaction: UserReaction[] = [];
      props.data.map((item) => {
        newUserReaction.push({ like: item?.like, unlike: item?.unlike });
      });
      setUserReaction(newUserReaction);
    }
  }, [props.data]);

  const handleMessageReact = (
    messageReact: MessageReact,
    mentorMessageId: string,
    index: number
  ) => {
    props.onMessageReact && props.onMessageReact(messageReact, mentorMessageId);
    const newUserReaction = userReaction.map((item, i) => {
      if (i === index) {
        if (messageReact === 'thumbs-up') {
          return { ...item, like: !item.like, unlike: false };
        }
        if (messageReact === 'thumbs-down') {
          return { ...item, like: false, unlike: !item.unlike };
        }
      }
      return item;
    });
    setUserReaction(newUserReaction);
  };

  const handleMessageCopy = (message?: string) => {
    if (message) {
      navigator.clipboard.writeText(message);
      setMessageCopy(true);
    }
  };

  return (
    <>
      {props.data?.map((item, i) => (
        <div className="message" key={i}>
          {/* for user messages */}
          {item.message && (
            <>
              <div className="time-stamp to-right px-3">
                {` You (${
                  item.createdOn
                    ? dayjs.unix(item.createdOn).fromNow()
                    : dayjs.unix(toEpoch()).fromNow()
                })`}
              </div>
              <div className="user">{item.message.trim()}</div>
              <span className="reaction">
                <span className="pencil">
                  {/* <PencilIcon width={12} height={12} />
                  EDIT */}
                </span>
              </span>
            </>
          )}
          {/* for mentor messages */}
          {item.response && (
            <>
              <div className="time-stamp">
                {/* 
                    This manual datetime assignment only works for the first index of the chat history.
                    This is because the first index of the chat history is hardcoded from the client side.
                    This is done to ensure that the chat appears more sensibly timestamped from the user's perspective.
                   */}
                {i === 0 &&
                  props.data &&
                  props.data.length > 1 &&
                  props.data[1]?.createdOn &&
                  `GrowthMentor (${dayjs.unix(props.data[1].createdOn ?? toEpoch()).fromNow()})`}
                {i > 0 &&
                  item.createdOn &&
                  `GrowthMentor (${dayjs.unix(item.createdOn).fromNow()})`}
              </div>
              <div className="wrapper">
                <div className="icon">
                  <RobotIcon />
                </div>
                <div className="mentor">
                  {props.isTyping ? (
                    <ChatTyping />
                  ) : (
                    <>
                      <div>{item.response}</div>
                      {i > 0 && item.suggestions && <hr />}
                      {i > 0 &&
                        (Array.isArray(item.suggestions) ? (
                          item.suggestions.map((suggestion, i) => (
                            <div key={`suggestion_${i}`} className="message-suggestions">
                              {suggestion.toLocaleLowerCase().startsWith(`q${i + 1}`)
                                ? suggestion
                                : `Q${i + 1}. ${suggestion}`}
                            </div>
                          ))
                        ) : (
                          <div className="message-suggestions">{item.suggestions}</div>
                        ))}
                    </>
                  )}
                </div>
              </div>
              {i > 0 && (
                <span className="reaction">
                  <span
                    aria-hidden
                    onClick={() =>
                      item.mentor_message_id &&
                      handleMessageReact('thumbs-up', item.mentor_message_id, i)
                    }
                  >
                    <ThumbsUp
                      className="thumbs-up"
                      fill={userReaction[i]?.like ? purpleColor : grayColor}
                    />
                    LIKE
                  </span>
                  <span
                    aria-hidden
                    onClick={() =>
                      item.mentor_message_id &&
                      handleMessageReact('thumbs-down', item.mentor_message_id, i)
                    }
                  >
                    <ThumbsDown
                      className="thumbs-down"
                      fill={userReaction[i]?.unlike ? purpleColor : grayColor}
                    />
                    DISLIKE
                  </span>
                  {!messageCopy ? (
                    <span aria-hidden onClick={() => handleMessageCopy(item.response)}>
                      <Clone width={17} height={17} />
                      COPY TEXT
                    </span>
                  ) : (
                    <span>
                      <CircleCheck width={17} height={17} />
                      COPIED!
                    </span>
                  )}
                  {/* <span>
                  <Refresh className="refresh" width={17} height={17} />
                  REGENERATE TEXT
                </span> */}
                </span>
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default ChatMessage;
