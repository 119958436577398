import { ContentLayout } from '@/components/Layout';

import { ResultsHub } from '../components/resultsHub/ResultsHub';

export const Results = () => {
  return (
    <ContentLayout title="GrowthCatalyst Results">
      <ResultsHub />
    </ContentLayout>
  );
};
