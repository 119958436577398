import { useAuthenticator } from '@aws-amplify/ui-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Auth } from 'aws-amplify';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as zod from 'zod';

import {
  ConfirmationDialog,
  Button,
  Spinner,
  Indicator,
  ConfirmChangesModal,
} from '@/components/Elements';
import { InputField } from '@/components/Form';
import { useUpdateProfile, useDeleteUser } from '@/features/users';
import { getRoleLabel } from '@/lib/authorization';
import { useLeavePageStore } from '@/stores/leavePage';

import { DialogMFA } from './DialogMFA';

const validations = zod.object({
  custom_alternate_email: zod
    .string()
    .regex(new RegExp('^$|([a-z0-9]+@[a-z]+[.][a-z]{2,3})$'), 'Invalid email address'),
  phone_number: zod
    .string()
    .regex(
      new RegExp('^[+][(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{5,6}$'),
      'Invalid phone number'
    ),
});

export const MyProfile = () => {
  const { setBlocker, removeBlocker } = useLeavePageStore();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [userInfo, setUserInfo] = useState<any>();
  const [edit, setEdit] = useState<boolean>(false);
  const [showMFA, setShowMFA] = useState<boolean>(false);
  const { mutateAsync: deleteUser, isLoading: loadingDeleteUser } = useDeleteUser();
  const {
    mutateAsync: updateProfile,
    isLoading: updateUserLoading,
    isSuccess: userUpdatedSuccess,
  } = useUpdateProfile();

  const userProps = useMemo(() => {
    if (userInfo && userInfo.attributes) {
      const { email, name, phone_number, phone_number_verified } = userInfo.attributes;
      return {
        email,
        name,
        phone_number,
        custom_alternate_email: userInfo.attributes['custom:alternate_email'],
        phone_number_verified,
        'custom:role': userInfo.attributes['custom:role'],
      };
    }
    return null;
  }, [userInfo]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues,
  } = useForm({ mode: 'all', resolver: validations && zodResolver(validations) });

  const onShowMFA = useCallback((show: boolean) => {
    setShowMFA(show);
  }, []);

  const onUpdateUser = useCallback(
    (data) => {
      delete data.email;
      userProps &&
        updateProfile({
          email: userProps.email,
          data,
        })
          .then(() => Auth.currentAuthenticatedUser({ bypassCache: true }))
          .then((user) => {
            Auth.setPreferredMFA(user, 'SMS_MFA');
            setUserInfo(user);
            removeBlocker();
            if (!user.attributes.phone_number_verified) {
              onShowMFA(true);
            }
          });
    },
    [onShowMFA, removeBlocker, updateProfile, userProps]
  );

  const onSetEdit = useCallback(() => {
    setEdit(!edit);
  }, [edit]);

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
      setUserInfo(user);
    });
  }, []);

  useEffect(() => {
    if (userProps) reset(userProps);
    if (userProps && !userProps.phone_number_verified) {
      onShowMFA(true);
    }
  }, [onShowMFA, reset, userProps]);

  useEffect(() => {
    if (isDirty) setBlocker();
  }, [isDirty, setBlocker]);

  const labels: { [x: string]: string } = {
    name: 'Full Name',
    custom_alternate_email: 'Second Email',
    phone_number: 'Phone Number',
  };

  if (!userProps) return <Spinner />;

  return (
    <div className="my-profile container">
      <DialogMFA isOpen={showMFA} show={onShowMFA} />
      <div>
        <div className="user-photo mb-5"></div>
        <ConfirmationDialog
          title="Delete User?"
          cancelButtonText="Go back"
          body="If you proceed with deleting this user profile, all data from this account will be deleted from the GrowthCatalyst database and cannot be restored. You will have 24 hours to contact the system administrator to reactivate this account, if you change your mind. Please confirm you understand and agree to the removal of this account."
          triggerButton={
            <Button type="button" variant="danger">
              Delete user
            </Button>
          }
          confirmButton={
            <Button
              type="button"
              variant="danger"
              onClick={() =>
                deleteUser(userProps.email).then(() => {
                  signOut();
                })
              }
              isLoading={loadingDeleteUser}
            >
              I understand, delete this user
            </Button>
          }
        />
      </div>
      <form onSubmit={onUpdateUser}>
        {edit ? (
          <InputField
            registration={register('name', {
              required: 'Please fill in your full name',
            })}
            error={errors.name}
            label="Full Name"
            horizontal={true}
            bold={true}
          />
        ) : (
          <p className="bold">
            Full Name <span>{userProps.name}</span>
          </p>
        )}
        <p className="bold">
          Email Address <span>{userProps.email}</span>
        </p>
        <p className="bold">
          Password <span>***********</span>
          <Link to="change-password" className="my-profile__change-pass">
            CHANGE PASSWORD
          </Link>
        </p>
        {edit ? (
          <InputField
            label="Second Email"
            registration={register('custom_alternate_email', { required: false })}
            horizontal={true}
            bold={true}
            error={errors.custom_alternate_email}
          />
        ) : (
          <p className="bold">
            Second Email <span>{userProps.custom_alternate_email}</span>
          </p>
        )}
        {edit ? (
          <div className="my-profile_phone-number">
            <InputField
              label="Phone Number"
              registration={register('phone_number', {
                required: 'This is not a valid phone number',
                // pattern: {
                //   value: /^[0-9]+$/,
                //   message: 'This is not a valid phone number',
                // },
              })}
              error={errors.phone_number}
              horizontal={true}
              bold={true}
            />
            <Indicator active={userProps.phone_number_verified} />
            <p className="bold mfa-message">Used for Multi-Factor Authentication.</p>
          </div>
        ) : (
          <p className="bold phone-number">
            Phone Number <span>{userProps.phone_number}</span>
            <Indicator active={userProps.phone_number_verified} />
            <span className="bold mfa-message">Used for Multi-Factor Authentication.</span>
          </p>
        )}
        {/* <div className="last-login">
          <p className="bold">
            Last Login <span>May 9, 2022</span>
          </p>
        </div> */}
        <div className="my-profile__extra-info">
          <p className="bold">
            Role <span>{getRoleLabel && getRoleLabel(+userProps['custom:role'])}</span>
          </p>
        </div>
        <div className="my-profile-actions">
          <>
            {!edit ? (
              <Button onClick={onSetEdit} variant={'inverse'}>
                Edit
              </Button>
            ) : (
              <ConfirmChangesModal
                initValues={userProps}
                currentValues={getValues()}
                dirtyFields={dirtyFields}
                labels={labels}
                triggerDisabled={!isValid || !isDirty}
                confirmOnClick={handleSubmit(onUpdateUser)}
                confirmDisabled={!isValid}
                confirmIsLoading={updateUserLoading}
                isDone={userUpdatedSuccess}
              />
            )}
          </>
        </div>
      </form>
    </div>
  );
};
