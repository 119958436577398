import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { AssessmentResponseType } from '../types';

export const getTakeAssessment = (
  assessment_id?: string
): Promise<{ responseObj: AssessmentResponseType[] }> => {
  return axios.get(`/assessments/take/${assessment_id}`);
};

type QueryFnType = typeof getTakeAssessment;

type UseTakeAssessmentOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useTakeAssessment = (assessment_id = '', config: UseTakeAssessmentOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['takeAssessment'],
    queryFn: () => getTakeAssessment(assessment_id),
  });
};
