import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Category } from '../types';

export const getCategories = (): Promise<{ responseObj: Category[] }> => {
  return axios.get('/categories');
};

type QueryFnType = typeof getCategories;

type UseCategoryOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useCategories = ({ config }: UseCategoryOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['categories'],
    queryFn: () => getCategories(),
  });
};
