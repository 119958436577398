import { useMutation } from 'react-query';

import { RENAME_MENTOR_CHAT_API, RenameMentorChatPayloadType } from '@/features/chat';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export const renameMentorChat = ({
  session_id,
  ...payload
}: RenameMentorChatPayloadType): Promise<void> => {
  return axios.patch(RENAME_MENTOR_CHAT_API.replace('{session_id}', session_id), { ...payload });
};

interface RenameMentorChatOptions {
  config?: MutationConfig<typeof renameMentorChat>;
}

export const useRenameMentorChat = ({ config }: RenameMentorChatOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    ...config,
    mutationFn: renameMentorChat,
    onError: () => {
      localStorage.setItem('chat-error-last-action', 'rename');
      addNotification({
        type: 'error',
        title: 'Chat session renamed failed!',
      });
    },
    onSuccess: () => {
      // addNotification({
      //   type: 'success',
      //   title: 'Chat session successfully renamed!',
      // });
    },
  });
};
