import { useMutation } from 'react-query';

import { AssessmentResponseType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export const deleteAssessment = (
  assessmentID: string
): Promise<{ responseObj: AssessmentResponseType[] }> => {
  return axios.delete(`assessments/${assessmentID}`);
};

type UseDeleteAssessmentOptions = {
  config?: MutationConfig<typeof deleteAssessment>;
};

export const useDeleteAssessment = ({ config }: UseDeleteAssessmentOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      addNotification({
        type: 'error',
        title: context,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries('assessments');
      addNotification({
        type: 'success',
        title: 'Reflection deleted',
      });
    },
    ...config,
    mutationFn: deleteAssessment,
  });
};
