import clsx from 'clsx';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

const variants = {
  primary: 'primary',
  inverse: 'inverse',
  danger: 'danger',
};

export type LinkPropsCustom = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  variant?: keyof typeof variants;
} & LinkProps;

export const Link = ({ className, variant = 'primary', children, ...props }: LinkPropsCustom) => {
  return (
    <RouterLink className={clsx('link-base', className, variants[variant])} {...props}>
      {children}
    </RouterLink>
  );
};
