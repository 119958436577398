import { Navigate, Route, Routes } from 'react-router-dom';

import { ROLES, useAuthorization } from '@/lib/authorization';

import { CreateTeam } from './CreateTeam';
import { EditAssessment } from './EditAssessment';
import { Manage } from './Manage';
import { MemberAdd } from './MemberAdd';
import { Teams } from './Teams';

export const TeamsRoutes = () => {
  const { checkAccess } = useAuthorization();
  return (
    <Routes>
      <Route path="" element={<Teams />} />
      <Route
        path="team-members/:teamID"
        element={
          checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) ? (
            <MemberAdd />
          ) : (
            <Navigate to="/app/dashboard" />
          )
        }
      />
      <Route
        path="create-team"
        element={
          checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) ? (
            <CreateTeam />
          ) : (
            <Navigate to="/app/dashboard" />
          )
        }
      />
      <Route
        path="manage-assessments/:teamID"
        element={
          checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) ? (
            <Manage />
          ) : (
            <Navigate to="/app/dashboard" />
          )
        }
      />
      <Route
        path="edit-assessment/:assessmentId"
        element={
          checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) ? (
            <EditAssessment />
          ) : (
            <Navigate to="/app/dashboard" />
          )
        }
      />
      <Route path="edit-team/:teamID" element={<Teams />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
