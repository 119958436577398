import { PencilIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useEffect, useCallback, useState } from 'react';
import Calendar from 'react-calendar';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as zod from 'zod';

import { ReactComponent as TrashIcon } from '@/assets/icons/trashIcon.svg';
import { Button, Spinner } from '@/components/Elements';
import { InputField, SelectField, TextAreaField } from '@/components/Form';
import {
  useCreateTeam,
  useDeleteTeam,
  TeamBaseType,
  TeamResponseType,
  useTeam,
} from '@/features/teams';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useAuthorization, ROLES } from '@/lib/authorization';
import { useClientStore } from '@/stores/client';
import { useLeavePageStore } from '@/stores/leavePage';

import { DeleteTeamDialog } from './dialogs/DeleteTeamDialog';

const createTeamValidations = zod.object({
  team_name: zod.string().nonempty('Please fill in this field.'),
  description: zod.string().nonempty('Please fill in this field.'),
  team_type: zod.string().nonempty('Select an option.'),
  stage: zod.string().nonempty('Select an option.'),
});

type Option = {
  label: string;
  value: string;
};

type CreateProps = {
  editObj?: TeamResponseType;
  editState?: boolean;
  setEditState?: (edit: boolean) => void;
  onSaveEdit?: (data: Omit<TeamBaseType, 'members'>) => void;
  isUpdatingTeam?: boolean;
  stagesOptions?: Option[];
  clientsOptions?: Option[];
  loading?: boolean;
};

export const Create = ({
  editObj,
  onSaveEdit,
  editState,
  setEditState,
  isUpdatingTeam,
  stagesOptions,
  loading,
}: CreateProps) => {
  const navigate = useNavigate();
  const { open } = useDisclosure();
  const { setBlocker, removeBlocker } = useLeavePageStore();
  const { checkAccess } = useAuthorization();

  const { clients } = useClientStore();
  const selectedClient = clients?.filter((client) => client.client_selected);

  const { teamID } = useParams();
  const { data: team } = useTeam({ teamID: teamID });
  const dateFounded = team?.date_founded;

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (dateFounded !== undefined) {
      setSelectedDate(new Date(dateFounded));
    }
  }, [dateFounded]);

  const handleDateChange = (date: Date | Date[]) => {
    if (showCalendar) {
      setSelectedDate(date instanceof Date ? date : date[0]);
      setShowCalendar(false);
    }
  };

  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };
  const handleIconKeyPress = (event: { key: string; preventDefault: () => void }) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleIconClick();
    }
  };
  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();

  const {
    handleSubmit,
    reset,
    register,
    getValues,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: createTeamValidations && zodResolver(createTeamValidations),
  });

  const {
    mutateAsync: mutateAsyncTeam,
    isSuccess: isSuccessTeam,
    error: errorTeam,
    reset: resetTeam,
    isLoading: isLoadingTeam,
  } = useCreateTeam();

  const { mutateAsync: deleteTeam, isLoading: deleteTeamLoading } = useDeleteTeam();

  const onDeleteTeam = useCallback(() => {
    if (editObj) {
      deleteTeam(editObj.team_id);
    } else {
      closeDeleteModal();
      removeBlocker();
      navigate('teams');
    }
  }, [editObj, deleteTeam, closeDeleteModal, removeBlocker, navigate]);

  useEffect(() => {
    if (editObj) reset(editObj);
  }, [editObj, reset]);

  useEffect(() => {
    if (isSuccessTeam) {
      reset();
      open();
      removeBlocker();
    }
  }, [isSuccessTeam, open, removeBlocker, reset]);

  useEffect(() => {
    if (isDirty) setBlocker();
  }, [isDirty, setBlocker]);

  const onCreateTeam = (data: TeamBaseType) => {
    data.members = [];
    data['date_founded'] = selectedDate.getTime();
    mutateAsyncTeam(data).then((data) => {
      const link = `/app/teams/edit-team/${data.team_id}`;
      resetTeam();
      navigate(link);
    });
  };

  if (loading) return <Spinner />;

  if (errorTeam) return <p>Error while creating a new team!...</p>;

  return (
    <div className={clsx('create-team container', loading && 'flex justify-center')}>
      <form onSubmit={handleSubmit(onCreateTeam)}>
        <DeleteTeamDialog
          isOpen={isOpenDeleteModal}
          close={closeDeleteModal}
          deleteTeam={!!editObj}
          onDelete={onDeleteTeam}
          deleteTeamLoading={deleteTeamLoading}
        />

        {(!editObj || editState) && (
          <>
            <InputField
              registration={register('team_name', { required: true })}
              error={errors.team_name}
              placeholder="Team name"
              className="special-input"
              bold={true}
              horizontal={true}
              icons={[<PencilIcon key={'pencilIcon'} />]}
            />
          </>
        )}
        {(!editObj || editState) && (
          <div className="create-team__info">
            <div className="team-description">
              <TextAreaField
                className="team-description-text"
                label="Team Description"
                registration={register('description')}
                size="md"
                error={errors.description}
                placeholder="Describe the team objectives in two or three sentences"
              />
            </div>
            <div className="create-team__options">
              <div className="client-field-wrapper">
                <p className="client-heading">Client</p>
                <p className="client-name-field">{selectedClient[0]?.client_name}</p>
              </div>
              <SelectField
                label="Team Type"
                registration={register('team_type', { required: true })}
                horizontal={true}
                size="md"
                placeholder="Select team type"
                error={errors.team_type}
                options={[
                  {
                    label: 'OS Team',
                    value: 'OS_TEAM',
                  },
                  {
                    label: 'Cofounder Team',
                    value: 'COFOUNDER_TEAM',
                  },
                ]}
              />
              <SelectField
                label="Current Stage"
                registration={register('stage', { required: true })}
                horizontal={true}
                size="md"
                placeholder="Select stage"
                error={errors.stage}
                options={stagesOptions || []}
              />
            </div>
            <div className="calendar">
              {showCalendar && (
                <Calendar
                  value={selectedDate}
                  calendarType="US"
                  onChange={handleDateChange}
                  nextLabel=">"
                  nextAriaLabel="Go to next month"
                  next2Label={null}
                  prevLabel="<"
                  prevAriaLabel="Go to prev month"
                  prev2Label={null}
                />
              )}
              <p className="date-heading">Date Founded </p>
              <CalendarIcon
                className="w-6 h-6 calendar-icon"
                onClick={handleIconClick}
                onKeyPress={handleIconKeyPress}
                tabIndex={0}
                role="button"
                aria-label="Calendar"
              />
              <p className="selected-date">
                {selectedDate.toLocaleDateString('en-US', {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </p>
            </div>
          </div>
        )}
        <div className="create-team__actions">
          {!editObj && (
            <Button
              className="create-team__button"
              isLoading={isLoadingTeam}
              type="submit"
              disabled={!isValid}
            >
              Create Team
            </Button>
          )}
          {editObj && editState && onSaveEdit && (
            <Button
              disabled={!isValid}
              type="button"
              isLoading={isUpdatingTeam}
              onClick={() => {
                onSaveEdit?.({
                  ...getValues(),
                  date_founded: selectedDate.getTime(),
                } as Omit<TeamBaseType, 'members'>);
                if (setEditState) {
                  setEditState(false);
                }
              }}
            >
              Save
            </Button>
          )}
          {editObj &&
            !editState &&
            setEditState &&
            checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) && (
              <>
                <Button type="button" variant="inverse" onClick={() => setEditState(true)}>
                  Edit Team
                </Button>
                <button type="button" onClick={openDeleteModal}>
                  <TrashIcon className="!mb-[5px]" color="C80404" />
                </button>
              </>
            )}
        </div>
      </form>
    </div>
  );
};
