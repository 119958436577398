import clsx from 'clsx';
import { useEffect, useState } from 'react';

import toggleIcon from '../../../assets/icons/toggleGray.svg';
import { Spinner } from '../Spinner';

type OptionType = {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  content: React.ReactNode;
  className?: string;
  selected?: boolean;
};

type TabProps = {
  footer?: React.ReactNode;
  options: Array<OptionType>;
  isLoading?: boolean;
  displayDrafts?: boolean;
  handleDisplayDrafts: () => void;
  setParentAttr?: (attr: string) => void;
};

export const Tab = ({
  options,
  footer,
  isLoading = true,
  setParentAttr,
  displayDrafts,
  handleDisplayDrafts,
}: TabProps) => {
  const [active, setActive] = useState<string>(options[0].label);

  useEffect(() => {
    // Find the first option that is selected
    const option = options.find((option) => option.selected);
    if (option) {
      setActive(option.label);
    }
  }, [options]);

  const handleSelection = (selectedLabel: string) => {
    options.map((option) => {
      const selected = selectedLabel === option.label;
      option.selected = selected;
      if (selected && setParentAttr) {
        setParentAttr(selectedLabel);
      }
    });
    setActive(selectedLabel);
  };

  return (
    <div className={clsx('tab__container')}>
      {!isLoading ? (
        <>
          <div className="tab__header">
            {!displayDrafts && (
              <button
                className={clsx('bold tab__header-item')}
                onClick={() => handleDisplayDrafts()}
                type="button"
              >
                <img src={toggleIcon} alt="Toggle Sidebar" title="Toggle Sidebar" />
              </button>
            )}
            {options.map(({ label, icon, className }: OptionType) => (
              <button
                key={label}
                className={clsx(
                  'bold tab__header-item',
                  active === label && 'tab__active',
                  className
                )}
                onClick={() => {
                  handleSelection(label);
                }}
                type="button"
              >
                <span>{icon}</span>
                {/* TODO: Check why css did not work here */}
                <p>{label}</p>
              </button>
            ))}
          </div>
          <div className="tab__content">
            {options.map(({ label, content }: OptionType, key: number) => (
              <div
                key={key}
                className={clsx('tab__content-item', active === label && 'tab__active')}
              >
                {content}
              </div>
            ))}
          </div>
          {footer}
        </>
      ) : (
        <div className="spinner">
          <Spinner />
        </div>
      )}
    </div>
  );
};
