import clsx from 'clsx';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

type FieldWrapperProps = {
  label?: string;
  size?: string;
  children: React.ReactNode;
  error?: FieldError | undefined;
  description?: string;
  horizontal?: boolean;
  grid?: boolean;
  hidden?: boolean;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, error, horizontal, children, size, grid, hidden } = props;
  return (
    <div className={clsx('field-wrapper', error?.message ? 'error' : '')} hidden={hidden}>
      <label
        className={clsx(
          'bold',
          horizontal ? (grid ? 'horizontal_field-grid' : 'horizontal_field') : ''
        )}
      >
        <span>{label}</span>
        <div className={clsx('control-content', size === 'full' && 'xs')}>
          {children}
          <span className="error-icon"></span>
          {error?.message && (
            <div role="alert" aria-label={error.message} className="field-error body-4">
              <p>{error.message}</p>
            </div>
          )}
        </div>
      </label>
    </div>
  );
};
