import { useRoutes } from 'react-router-dom';

import { useAuthorization } from '@/lib/authorization';

import { commonRoutes } from './common';
import { protectedRoutes } from './protected';

export const AppRoutes = () => {
  const { checkAccess } = useAuthorization();
  const element = useRoutes([...protectedRoutes(checkAccess), ...commonRoutes]);

  return <>{element}</>;
};
