import { getPropertyByPath } from '@/utils/getPropertyByPath';

type IRegexField = (string | string[])[];

const testRegex = (regex: RegExp, usingPath: boolean, field: string, object?: any) => {
  const fieldValue = usingPath && object ? getPropertyByPath(object, field) : field;
  return regex.test(fieldValue);
};

export const regexByObjectFields = (
  regex: RegExp,
  usingPath = false,
  fields: IRegexField,
  object?: any
) => {
  return fields.find((field) => {
    if (Array.isArray(field)) {
      return field.find((item) => testRegex(regex, usingPath, item, object));
    } else {
      return testRegex(regex, usingPath, field, object);
    }
  });
};

export const createRegExpMatchFullWord = (input: string) => {
  const cleanedValue = input.replaceAll('\\', '');
  return new RegExp(`\\b${cleanedValue}`, 'igm');
};
