import { ChatModel } from './chat.type';

export interface GetMentorChatPayloadType {
  message_id?: string;
  session_id?: string;
}

export type GetMentorChatResponseType = ChatModel;

export interface GetMentorChatStore {
  chatGet?: ChatModel[];
  isLoading?: boolean;
  isError?: boolean;
  setGetChatSession: (props: GetMentorChatPayloadType) => void;
}

export const GET_MENTOR_CHAT_API = 'mentor/chats/sessions/{session_id}';
