import { useMemo } from 'react';

import { ContentLayout } from '@/components/Layout';

import { useClients } from '../api/getClients';
import { useIndustries } from '../api/getIndustries';
import { Create } from '../components/Create';

export const CreateClient = () => {
  const { isLoading: industriesLoading, data: industriesData } = useIndustries();
  const { isLoading: clientsLoading, data: clientsData } = useClients();

  const industries = useMemo(
    () =>
      industriesData?.map((x) => ({
        label: x.industry_name,
        value: x.industry_id,
        subIndustries: x.sub_industries,
      })) || [],
    [industriesData]
  );

  const clients = useMemo(
    () =>
      clientsData?.map((x) => ({
        label: x.client_name || '',
        value: x.client_id || '',
      })) || [],
    [clientsData]
  );

  return (
    <ContentLayout title="Add a New Client" back={'/app/dashboard'}>
      <Create
        loading={industriesLoading || clientsLoading}
        industriesOptions={industries}
        clientsOptions={clients}
      />
    </ContentLayout>
  );
};
