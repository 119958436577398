import { useMutation } from 'react-query';

import { TeamRoleType } from '@/features/clients';
import { TeamResponseType } from '@/features/teams';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type updateUserInTeamType = {
  data: TeamRoleType;
  teamID: string;
  userEmail: string;
};

export const updateUserInTeam = ({
  data,
  teamID,
  userEmail,
}: updateUserInTeamType): Promise<TeamResponseType> => {
  return axios
    .put(`/teams/${teamID}/members/${userEmail}`, data)
    .then((data: any) => data.responseObj);
};

type UseUpdateUserInTeamOptions = {
  config?: MutationConfig<typeof updateUserInTeam>;
};

export const useUpdateUserInTeam = ({ config }: UseUpdateUserInTeamOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('teams');
      addNotification({
        type: 'success',
        title: 'User successfully updated!',
      });
    },
    ...config,
    mutationFn: updateUserInTeam,
  });
};
