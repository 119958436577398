import { PencilIcon } from '@heroicons/react/24/outline';
import { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { MethodDropdown } from '@/components/Elements';
import { InputField, TextAreaField } from '@/components/Form';
import { useCategories, useQuestions, QuestionBaseType, DRAG_TYPE } from '@/features/assessments';

import { Question } from './Question';
import { Section } from './Section';

export const BuilderContent = (props: UseFormReturn) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
    clearErrors,
  } = props;
  const { isLoading: categoriesLoading, data: categoriesData } = useCategories();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get('type');
  const roleToAssesst = searchParams.get('role');
  const templateNameParam = searchParams.get('template_name');
  const { data: questionsData } = useQuestions();
  const categories = useMemo(() => {
    return categoriesData?.responseObj || [];
  }, [categoriesData]);
  const [hover, setHover] = useState<string>();
  const [dragResult, setDragResult] = useState<any>();
  const {
    move: swapSection,
    remove: removeSection,
    append: addSection,
    fields: sections,
  } = useFieldArray({
    control,
    name: 'sections',
  });

  const removeError = useCallback((index: string) => {
    clearErrors(index);
  }, []);

  return (
    <div className="builder">
      {templateNameParam && (
        <input hidden {...register('template_name')} value={templateNameParam} />
      )}
      {typeParam && <input hidden {...register('assessment_type')} value={typeParam} />}
      {roleToAssesst && (
        <input hidden {...register('general_settings.role_to_assesst')} value={roleToAssesst} />
      )}
      <InputField
        label="Reflection title"
        registration={register('assessment_name', {
          required: 'This field is required',
        })}
        bold={true}
        error={errors.assessment_name}
        icons={[<PencilIcon key={'pencilIcon'} />]}
        className="builder__field-assessment-name !w-[95%]"
      />
      <TextAreaField
        label="Instruction text"
        className="section__field-desc assessment-desc"
        containerSize="lg"
        error={errors.assessment_instructions}
        placeholder="Provide instructions for your team members on taking this assessment"
        registration={register('assessment_instructions', { required: 'This field is required' })}
      />
      {errors?.publish && errors?.sections?.noQuestions && (
        <p className="no-questions-error">{errors.sections.noQuestions.message}</p>
      )}
      <DragDropContext
        onDragEnd={(dragResult) => {
          //dropped nowhere
          if (!dragResult.destination) return;

          const { index: sourceIndex, droppableId: sourceDroppableId } = dragResult.source;
          const { index: destinationIndex, droppableId: destinationDroppableId } =
            dragResult.destination;

          if (dragResult.type === DRAG_TYPE.SECTION) swapSection(sourceIndex, destinationIndex);

          // did not move anywhere - can bail early
          if (sourceDroppableId === destinationDroppableId && sourceIndex === destinationIndex)
            return;

          setDragResult(dragResult);
        }}
      >
        <Droppable
          droppableId="board"
          isCombineEnabled={false}
          direction="vertical"
          type={DRAG_TYPE.SECTION}
        >
          {(provided) => (
            <div className="board" ref={provided.innerRef} {...provided.droppableProps}>
              {sections &&
                sections.map((section: any, index: number) => {
                  return (
                    <Section
                      key={section.id}
                      index={index}
                      removeSection={removeSection}
                      addSection={addSection}
                      setHover={setHover}
                      hover={hover}
                      dragResult={dragResult}
                      {...section}
                      {...props}
                    >
                      {(fieldArrayQuestions) => {
                        const { fields: questions } = fieldArrayQuestions;
                        return questions.map((question: QuestionBaseType, indexQs: number) => (
                          <Question
                            key={indexQs}
                            setValue={setValue}
                            question={question}
                            index={index}
                            indexQs={indexQs}
                            sectionId={section.id}
                            register={register}
                            errors={errors}
                            setHover={setHover}
                            hover={hover}
                            watch={watch}
                            categories={categories}
                            questions={questionsData}
                            categoriesLoading={categoriesLoading}
                            clearErrors={removeError}
                            {...fieldArrayQuestions}
                          />
                        ));
                      }}
                    </Section>
                  );
                })}
              {provided.placeholder}
              {sections.length === 0 && (
                <MethodDropdown
                  className="section-dropdown no-sections"
                  options={[
                    {
                      label: 'New Section',
                      onClick: () =>
                        addSection({ section_name: '', section_description: '', questions: [] }),
                    },
                  ]}
                />
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
