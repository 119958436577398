export interface DeleteMentorChatPayloadType {
  session_id: string;
}

export interface DeleteMentorChatStore {
  isLoading?: boolean;
  isError?: boolean;
  setDeleteChatSession: (props: DeleteMentorChatPayloadType) => void;
}

export const DELETE_MENTOR_CHAT_API = 'mentor/chats/sessions/{session_id}';
