import { useAuthenticator } from '@aws-amplify/ui-react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import sparkLogo from '@/assets/Spark_logo_white.svg';
import { TermsConditions } from '@/features/misc/';
import { AppContext } from '@/providers/app.provider';

export const UpdatedTerms = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { setGeneralErrors } = useContext(AppContext);

  const navigate = useNavigate();
  const onAccept = (accept: boolean) => {
    const closeSession = () => {
      signOut();
      setGeneralErrors &&
        setGeneralErrors(['Error: Logged out. User must accept Terms & Conditions']);
    };
    !accept && closeSession();
    navigate('/');
  };
  return (
    <>
      <div className="terms-body !mt-[54px]">
        <img className="auth-image" src={sparkLogo} alt="login page" />
        <TermsConditions
          bArrowProps={{ toUrl: '/' }}
          onAcceptTerms={(accept: boolean) => onAccept(accept)}
        />
      </div>
    </>
  );
};
