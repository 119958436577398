import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { StageResponseType } from '../types';

interface IStages {
  responseObj: StageResponseType[];
}

export const getStages = () => {
  return axios
    .get<IStages>(`/stages`)
    .then((data: AxiosResponse | IStages): StageResponseType[] => {
      return (data as IStages)?.responseObj || [];
    });
};

type QueryFnType = typeof getStages;

type UseStagesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useStages = ({ config }: UseStagesOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['stages'],
    queryFn: () => getStages(),
  });
};
