import { useMutation } from 'react-query';

import { ClientBaseType, ClientResponseType } from '@/features/clients';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

export const createClient = (data: ClientBaseType): Promise<ClientResponseType> => {
  return axios.post('/clients', data).then((data: any) => data.responseObj);
};

type UseCreateClientOptions = {
  config?: MutationConfig<typeof createClient>;
};

export const useCreateClient = ({ config }: UseCreateClientOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      console.log('context', context);
    },
    onSuccess: () => {},
    ...config,
    mutationFn: createClient,
  });
};
