import { ReactNode } from 'react';
import create from 'zustand';

import { NotifyResultType, ResultStatus } from '../components/assessmentSectionDisplay';
type SectionResultsStore = {
  isDisplayingResults: Map<string, Map<string, ResultStatus>>;
  init: (sectionIndex: string, index: string) => void;
  has: (sectionIndex: string, index: string) => boolean;
  add: (sectionIndex: string, index: string, result: ResultStatus) => void;
  getSection: (sectionIndex: string) => Map<string, ResultStatus> | undefined;
  buildResultsMessage: (
    results: ResultStatus[],
    toFind: ResultStatus,
    component?: ReactNode
  ) => false | { component: ReactNode; status: ResultStatus };
  validate: (
    sectionIndex: string,
    validations: { status: ResultStatus; component?: ReactNode }[]
  ) => false | { component?: ReactNode; status: ResultStatus };
  notifyResults: (sectionIndex: string, index: string) => NotifyResultType;
  validateResultStatus: (
    loading: boolean,
    notifyResults?: NotifyResultType,
    filterInput?: string,
    elements?: Array<any>
  ) => void;
};

export const useSectionResultsStore = create<SectionResultsStore>((set, get) => ({
  isDisplayingResults: new Map(),
  init: (sectionIndex: string, index: string) => {
    if (!get().has(sectionIndex, index)) {
      // Init all on true
      const isDisplayingResults = get().isDisplayingResults;
      if (isDisplayingResults.get(sectionIndex) === undefined) {
        isDisplayingResults.set(sectionIndex, new Map());
      }
      isDisplayingResults.get(sectionIndex)?.set(index, ResultStatus.SUCCESS);
      set({
        isDisplayingResults: new Map(isDisplayingResults.entries()),
      });
    }
  },
  has: (sectionIndex: string, index: string): boolean => {
    const isDisplayingResults = get().isDisplayingResults;
    return isDisplayingResults.get(sectionIndex)?.has(index) === true;
  },
  add: (sectionIndex: string, index: string, result: ResultStatus) => {
    const isDisplayingResults = get().isDisplayingResults;
    isDisplayingResults.get(sectionIndex)?.set(index, result);
    set({
      isDisplayingResults: new Map(isDisplayingResults.entries()),
    });
  },
  getSection: (sectionIndex: string) => {
    return get().isDisplayingResults.get(sectionIndex);
  },
  buildResultsMessage: (results: ResultStatus[], toFind: ResultStatus, component?: ReactNode) => {
    if (results.find((result) => toFind === result)) {
      return {
        component: component,
        status: toFind,
      };
    }
    return false;
  },
  validate: (
    sectionIndex: string,
    validations: { status: ResultStatus; component?: ReactNode }[]
  ) => {
    const sectionResults = get().getSection(sectionIndex);
    const values = Array.from(sectionResults ? sectionResults.values() : []);
    const foundValidations = validations.filter((validation) => {
      const found = get().buildResultsMessage(values, validation.status, validation.component);
      if (found) {
        return found;
      }
    });
    return foundValidations.length > 0 ? foundValidations[0] : false;
  },
  notifyResults: (sectionIndex: string, index: string) => {
    get().init(sectionIndex, index);
    return {
      sectionIndex: sectionIndex,
      index: index,
      callback: get().add,
    };
  },
  validateResultStatus: (
    loading: boolean,
    notifyResults?: NotifyResultType,
    filterInput?: string,
    elements?: Array<any>
  ) => {
    if (notifyResults) {
      if (loading) {
        notifyResults.callback(
          notifyResults.sectionIndex,
          notifyResults.index,
          ResultStatus.LOADING
        );
        return;
      }
      if (!loading && elements) {
        if (filterInput && elements.length === 0) {
          notifyResults.callback(
            notifyResults.sectionIndex,
            notifyResults.index,
            ResultStatus.FILTER_NOT_FOUND
          );
          return;
        }
        if (elements.length > 0) {
          notifyResults.callback(
            notifyResults.sectionIndex,
            notifyResults.index,
            ResultStatus.SUCCESS
          );
        } else {
          notifyResults.callback(
            notifyResults.sectionIndex,
            notifyResults.index,
            ResultStatus.NO_RESULTS
          );
        }
      } else if (!elements) {
        notifyResults.callback(notifyResults.sectionIndex, notifyResults.index, ResultStatus.ERROR);
      }
    }
  },
}));
