import { ReactNode } from 'react';

export interface CardContentProps {
  children?: ReactNode;
}

export const CardContent = (props: CardContentProps) => {
  return <div className="content">{props.children}</div>;
};

export default CardContent;
