import { useNavigate, useParams } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { formatDateFromNow } from '@/utils/format';

import toggleIcon from '../../../../assets/icons/toggle.svg';
import { useAssessments } from '../../api/getAssessments';

interface IDraft {
  name?: string;
  modifiedOn: string;
  selected?: boolean;
  isAssesmentLoading?: boolean;
  onClick?: () => void;
}

export const Draft = ({ name, modifiedOn, onClick, selected, isAssesmentLoading }: IDraft) => {
  return (
    <button
      onClick={onClick}
      className={`assessment-draft${selected ? ' selected' : ''}`}
      disabled={isAssesmentLoading}
    >
      <div>
        <h4 className="assessment-section-category">REFLECTION</h4>
        <h3 className="assessment-draft-title">{name}</h3>
      </div>
      <sub className="assessment-draft-sub">Last updated {formatDateFromNow(modifiedOn)}</sub>
    </button>
  );
};

export const DraftsList = ({
  isAssessmentLoading,
  handleDisplayDrafts,
}: {
  isAssessmentLoading?: boolean;
  handleDisplayDrafts: () => void;
}) => {
  const navigate = useNavigate();
  const { assessmentId, '*': baseUrl } = useParams();
  const { isLoading: draftsLoading, data: draftsData } = useAssessments({
    params: 'kind=DRAFT',
  });

  if (draftsLoading) {
    return (
      <div className="w-full flex justify-center">
        <Spinner />
      </div>
    );
  }

  if (!draftsData || draftsData.length === 0) return null;

  return (
    <div className="assessment-draft__main-container">
      <div className="assessment-draft-header-container">
        <span className="assessment-draft-header">{`Drafts (${draftsData.length})`}</span>
        <button className="hover:cursor-pointer" onClick={() => handleDisplayDrafts()}>
          <img src={toggleIcon} alt="Toggle Sidebar" title="Toggle Sidebar" />
        </button>
      </div>
      <div className="assessment-draft-container">
        {draftsData.map(({ assessment_id, assessment_name, modifiedOn, createdOn }) => (
          <Draft
            key={assessment_id}
            selected={assessmentId === assessment_id}
            name={assessment_name}
            modifiedOn={new Date((modifiedOn || createdOn) * 1000).toDateString()}
            isAssesmentLoading={isAssessmentLoading}
            onClick={() =>
              navigate(
                assessmentId
                  ? `../${assessment_id}`
                  : baseUrl === 'build'
                  ? assessment_id
                  : `build/${assessment_id}`,
                {
                  replace: true,
                }
              )
            }
          />
        ))}
      </div>
    </div>
  );
};
