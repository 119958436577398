import { useMutation } from 'react-query';

// import { UserType } from '@/features/users';
import { axios } from '@/lib/axios';
// import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { AssessmentBaseType } from '../types';

const publishAssessment = (data: AssessmentBaseType): Promise<any> => {
  return axios.post(`assessments/${data.assessment_id}/publish`, {
    general_settings: data.general_settings,
  });
};

type UsePublishAssessmentOptions = {
  config?: MutationConfig<typeof publishAssessment>;
};

export const usePublishAssessment = ({ config }: UsePublishAssessmentOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      addNotification({
        type: 'error',
        title: context,
      });
    },
    onSuccess: () => {},
    ...config,
    mutationFn: publishAssessment,
  });
};
