import { ReactNode, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SearchIcon } from '@/assets/icons/searchOutline.svg';
import { InputField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import {
  ASSESSMENT_TEMPLATES,
  ASSESSMENT_TYPES,
  useSectionResultsStore,
} from '@/features/assessments';
import { ROLES } from '@/lib/authorization';

import { ResultStatus, BuildSectionDisplay } from '../components/assessmentSectionDisplay';
import { TemplatesList } from '../components/mainPage/templatesList';

export const Main = () => {
  const navigate = useNavigate();
  const sectionIndex = 'builder';
  const { notifyResults, validate, isDisplayingResults } = useSectionResultsStore();
  const inputName = 'forms_filter';
  const form = useForm({ mode: 'all' });
  const {
    formState: { errors },
    watch,
  } = form;

  const filterInputValue = watch(inputName);

  const createNewTemplate = useCallback(() => {
    navigate(
      `build?template=${ASSESSMENT_TEMPLATES.BLANK}&type=${ASSESSMENT_TYPES.TEAM}&role=${ROLES.COFOUNDER}&template_name=${ASSESSMENT_TEMPLATES.BLANK}`
    );
  }, [navigate]);

  const showNoResults = useMemo(() => {
    const validations: { status: ResultStatus; component?: ReactNode }[] = [
      {
        status: ResultStatus.SUCCESS,
      },
      {
        status: ResultStatus.FILTER_NOT_FOUND,
        component: (
          <>
            <h3>No forms found.</h3>
            <p>Try searching by name, type, status, or team.</p>
          </>
        ),
      },
      {
        status: ResultStatus.NO_RESULTS,
        component: (
          <>
            <h3>No forms found.</h3>
          </>
        ),
      },
    ];
    return validate(sectionIndex, validations);
  }, [isDisplayingResults]);

  return (
    <ContentLayout
      title="GrowthCatalyst"
      addNavigate={createNewTemplate}
      addNavigateLabel="Build custom form"
      customClassName="!flex-col"
    >
      <InputField
        className={'input-form-search'}
        type={'text'}
        placeholder={'Find form...'}
        defaultValue={''}
        registration={form.register(inputName, {})}
        error={errors[inputName]}
        icons={[<SearchIcon key={`search-icon`} />]}
      />
      <TemplatesList
        notifyResults={notifyResults(sectionIndex, 'templates')}
        filterInput={filterInputValue}
      />
      <BuildSectionDisplay
        filterInput={filterInputValue}
        notifyResults={notifyResults(sectionIndex, 'drafts')}
        title={'Drafts'}
        assessmentType={'draft'}
      />
      <BuildSectionDisplay
        filterInput={filterInputValue}
        notifyResults={notifyResults(sectionIndex, 'published')}
        title={'Published Forms'}
        assessmentType={'published'}
      />
      <>
        {showNoResults && showNoResults.component && (
          <div className={'no-results'}>{showNoResults.component}</div>
        )}
      </>
    </ContentLayout>
  );
};
