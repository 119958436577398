import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getChartResults = (
  type: string,
  option1: string,
  option2: string | undefined,
  assessmentId: string | undefined
): Promise<{
  chart: any;
  responseObj: any;
}> => {
  return axios
    .get(
      encodeURI(
        `/stats?type=${type}&option_1=${option1}${option2 ? `&option_2=${option2}` : ''}${
          assessmentId ? `&assessment_id=${assessmentId}` : ''
        }`
      )
    )
    .then((data) => (data as any)?.responseObj);
};

type QueryFnType = typeof getChartResults;

type UseChartResultsOptions = {
  config?: QueryConfig<QueryFnType>;
  type: string;
  option1: string;
  option2?: string;
  assessmentId?: string;
};

export const useChartResults = ({
  config,
  type,
  option1,
  option2,
  assessmentId,
}: UseChartResultsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['chartResults'],
    queryFn: () => getChartResults(type, option1, option2, assessmentId),
    useErrorBoundary: (error: any) => error.response?.status >= 500,
  });
};
