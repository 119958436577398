import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { ReactComponent as CaretIconDown } from '@/assets/icons/iconCaretDown.svg';
import ExitIcon from '@/assets/icons/iconExit.svg';
import GearIcon from '@/assets/icons/iconGear.svg';
import UserIcon from '@/assets/icons/iconUser.svg';
import UserIconCircle from '@/assets/icons/iconUserCircle.svg';
// import searchIcon from '@/assets/icons/search.svg';
import { Button, IconLinkList, IIconListElement, Tooltip } from '@/components/Elements';
import { ROLES, useAuthorization } from '@/lib/authorization';

export const Header = () => {
  const [avatarOptionsOpen, setAvatarOptionsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { checkAccess } = useAuthorization();
  const menuOptions = useMemo(() => {
    const options: IIconListElement[] = [];
    options.push({
      icon: GearIcon,
      title: 'My Profile',
      onClick: () => navigate('/app/profile'),
    });

    if (checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] })) {
      options.push({
        icon: UserIcon,
        title: 'Manage All Users',
        onClick: () => navigate('/app/users'),
      });
    }
    options.push({
      icon: ExitIcon,
      title: 'Sign Out',
      onClick: () => signOut(),
    });
    return options;
  }, [checkAccess]);

  return (
    <header className="h-14 shadow-sm bg-white flex flex-row justify-end items-center">
      <div className="flex flex-row gap-4 header-icons">
        {/*<button className="icon-wrap" tabIndex={0}>*/}
        {/*  <img src={searchIcon} alt="Search Icon" />*/}
        {/*</button>*/}
        <Tooltip
          element={
            <Button size={'sm'} className={`blank ${avatarOptionsOpen ? 'open' : ''}`}>
              {/* @TODO: remove this with real photo*/}
              <img className={'profile'} alt={'Profile'} src={UserIconCircle} />
              <CaretIconDown />
            </Button>
          }
          customComponent={true}
          triggerType={'click'}
          arrow={false}
          toolTipContent={<IconLinkList elements={menuOptions} />}
          position={['bottom left']}
          reportToggle={setAvatarOptionsOpen}
        />
      </div>
    </header>
  );
};
