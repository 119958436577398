import { UseFormReturn } from 'react-hook-form';

import { Button, ConfirmationDialog, Link } from '@/components/Elements';
import { QuestionResponseType, SectionResponseType, SectionBaseType } from '@/features/assessments';
import { useNotificationStore } from '@/stores/notifications';

type QuestionnaireActionsPropsType = {
  preview: boolean;
  step: number;
  totalSections: number;
  isLoading: boolean;
  isLoadingUpdate: boolean;
  sections: SectionResponseType[];
  assessmentId: string;
  onChangeStep: (step: number) => void;
  onSubmit: (data: SectionBaseType[]) => void;
  onSave: () => void;
} & UseFormReturn;

export const QuestionnaireActions = ({
  step,
  totalSections,
  isLoading,
  sections,
  formState,
  onChangeStep,
  handleSubmit,
  onSubmit,
  trigger,
  clearErrors,
  onSave,
  preview,
}: QuestionnaireActionsPropsType) => {
  const { errors, isValid } = formState;
  const { addNotification } = useNotificationStore();

  const hasErrors = () => {
    let errorFound = false;
    const questions = sections[step].questions;

    if (questions)
      questions.map((question: QuestionResponseType, index: number) => {
        if (errors.sections && errors.sections[step]?.questions[index]) {
          errorFound = true;
        }
      });

    return errorFound;
  };

  const onNext = async () => {
    await trigger();
    if (!hasErrors() || preview) {
      onSave();
      onChangeStep(step + 1);
      clearErrors();
      scrollUp();
    } else {
      addNotification({
        type: 'warning',
        title: 'Reflection Incomplete',
        message: 'Please continue completing reflection.',
      });
    }
  };

  const scrollUp = () => {
    document?.querySelector('main')?.scroll(0, 0);
  };

  return (
    <div className="questionnaire__actions">
      {0 !== step && totalSections >= step && (
        <Button
          onClick={() => {
            onChangeStep(step - 1);
            scrollUp();
          }}
        >
          Back
        </Button>
      )}
      {totalSections !== step && totalSections > 0 && <Button onClick={onNext}>Next</Button>}

      {!preview && totalSections === step && !isValid && (
        <Button
          onClick={() => {
            if (hasErrors()) {
              addNotification({
                type: 'warning',
                title: 'Reflection Incomplete',
                message: 'Please continue completing reflection.',
              });
            }
          }}
          type="submit"
        >
          Submit reflection
        </Button>
      )}
      {totalSections === step && isValid && !preview && (
        <ConfirmationDialog
          title="Confirm Submission"
          cancelButtonText="Exit"
          body="Please confirm that you are ready to submit your reflection, as you can not edit your responses once it has been submitted."
          triggerButton={<Button>Submit reflection</Button>}
          confirmButton={
            <Button
              onClick={handleSubmit((data: SectionBaseType[]) => onSubmit(data))}
              isLoading={isLoading}
            >
              Submit
            </Button>
          }
        />
      )}
      {step > totalSections && !preview && <Link to="/app/assessment/take">Close reflection</Link>}
    </div>
  );
};
