import { useQuery } from 'react-query';

import { TeamRoleType } from '@/features/clients';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

type getRoleType = {
  data: TeamRoleType;
  name: string;
  team_role_id: string;
  label: string;
  system: boolean;
};

export const getRoles = ({ client_id }: { client_id: string }): Promise<getRoleType[]> => {
  return axios.get(`/clients/${client_id}/team_roles`).then((data: any) => {
    return data.responseObj;
  });
};

type QueryFnType = typeof getRoles;

type UseRolesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useRoles = ({ config, client_id }: UseRolesOptions & { client_id: string }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['clients'],
    queryFn: () => getRoles({ client_id }),
  });
};
