import { ChevronDownIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useCallback, useState, useMemo } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { DropdownMenu } from '@/components/Elements';
import { FieldWrapper, FieldWrapperPassThroughProps } from '@/components/Form/FieldWrapper';

type DropdownProps = FieldWrapperPassThroughProps & {
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  registration?: Partial<UseFormRegisterReturn>;
  horizontal?: boolean;
  disabled?: boolean;
  grid?: boolean;
  hidden?: boolean;
  value?: string;
  displayName?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  open?: boolean;
  setOpen?: () => void;
} & DropdownOptionsType;

export type DropdownOptionsType = {
  options?: IOption[];
  placeholder?: string;
  isSearchable?: boolean;
  selectedValue?: IOption;
};

export type IOption = {
  label: string;
  id?: string;
  onClick?: (x?: IOption) => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  isSelected?: boolean;
  selectedLabel?: string;

  buttonList?: Array<any>;

  endIcon?: React.ReactNode;
  endOnClick?: (x?: IOption) => void;

  itemClass?: string;
};

export const Dropdown = ({
  options = [],
  setOpen,
  open,
  className,
  // placeholder,
  selectedValue,
  displayName,
  isSearchable = false,
}: DropdownProps) => {
  const [openDefault, openDefaultSet] = useState(false);

  const openDropdown = useCallback(() => {
    setOpen ? setOpen() : openDefaultSet(!openDefault);
  }, [openDefault, setOpen]);

  const openFlag = useMemo(() => {
    return open !== undefined ? open : openDefault;
  }, [open, openDefault]);

  const getDisplay = () => {
    // if (selectedValue) return selectedValue.selectedLabel;
    // if (!placeholder && options[1]) return options[1].selectedLabel;
    return 'Select team/reflection';
  };

  return (
    <FieldWrapper>
      <div className={clsx('dropdown select flex items-center', className)}>
        <div
          className="dropdown-input flex w-full justify-between"
          onClick={openDropdown}
          onKeyDown={openDropdown}
          role="combobox"
          aria-haspopup="listbox"
          aria-controls="dropdown"
          aria-expanded={openFlag}
          tabIndex={0}
        >
          <div className="dropdown-selected-value">{displayName || getDisplay()}</div>
          <div className="dropdown-tools">
            <div className="dropdown-tool">
              <ChevronDownIcon className="icon-chevron__down" width={25} />
            </div>
          </div>
        </div>
        <DropdownMenu
          options={options}
          open={openFlag}
          setOpen={openDropdown}
          isSearchable={isSearchable}
          selectedValue={selectedValue}
        />
      </div>
    </FieldWrapper>
  );
};
