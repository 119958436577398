import create from 'zustand';

import { ChatErrorAction, ChatMessagesStore } from '@/features/chat';
import { toEpoch } from '@/utils/format';

const INITIAL_CHAT_MESSAGE = {
  response:
    "Hi {first_name}, my name is GrowthMentor and I'm here to help you grow. Click a question below to get started or write your own.",
  suggestions: [
    'What can GrowthMentor help me with?',
    'Help me prioritize my ideas for new features.',
    "Give me a case study of a successful food startup's creative marketing strategy.",
  ],
};

export const useChatMessagesStore = create<ChatMessagesStore>((set, get) => ({
  chatMessages: [],
  incoming: false,
  isLoading: false,
  activeChatSession: {
    id: localStorage.getItem('chat-active-session-id'),
    name: localStorage.getItem('chat-active-session-name'),
  },
  chatErrorLastAction: localStorage.getItem('chat-error-last-action') as ChatErrorAction,
  selectedItemMaxMode: undefined,
  getInitialChatMessage: () => {
    const newInitialChatMessage = {
      createdOn: toEpoch(),
      ...INITIAL_CHAT_MESSAGE,
      response: INITIAL_CHAT_MESSAGE.response.replace(
        '{first_name}',
        localStorage.getItem('chat-user-first-name') ?? ''
      ),
    };
    return newInitialChatMessage;
  },
  setInitialChatMessage: () => {
    const newInitialChatMessage = get().getInitialChatMessage();
    set(() => ({
      chatMessages: [{ ...newInitialChatMessage }],
    }));
  },
  setChatMessages: (chatModel, isLoading, isOldChat, isChatHistory) => {
    // this is use for empty the chatMessages and fill with initial chat message.
    if (Array.isArray(chatModel)) {
      get().setInitialChatMessage();
      return;
    }
    set((state) => ({
      chatMessages: !isChatHistory
        ? [
            ...state.chatMessages.filter(
              // we don't want to take items that index more that 1 and without session_id
              (item, index) => (index >= 0 && index <= 1) || (index > 1 && item.session_id)
            ),
            { ...chatModel, createdOn: isOldChat ? chatModel.createdOn : toEpoch() },
          ]
        : [
            { ...get().getInitialChatMessage() },
            ...state.chatMessages.filter(
              (item) =>
                item.session_id === chatModel.session_id &&
                item.mentor_message_id !== chatModel.mentor_message_id
            ),
            { ...chatModel, createdOn: isOldChat ? chatModel.createdOn : toEpoch() },
          ],
      incoming: isOldChat === false ? !!chatModel?.response : false, // to indicate that a message is an incoming or outcoming.
      isLoading,
      activeChatSession: {
        id: localStorage.getItem('chat-active-session-id'),
        name: localStorage.getItem('chat-active-session-name'),
      },
    }));
  },
  setSelectedItemMaxMode: (sessionId) => {
    set(() => ({
      selectedItemMaxMode: sessionId ? { [sessionId]: true } : undefined,
    }));
  },
  setChatHistory: (chatModel) => {
    set(() => ({
      chatHistories: chatModel,
    }));
  },
  setIncoming: (incoming) => {
    set(() => ({
      incoming,
    }));
  },
  setActiveSession(id, name) {
    set(() => ({
      activeChatSession: {
        id,
        name,
      },
    }));
  },
  invokeNewChatSession: () => {
    localStorage.removeItem('chat-active-session-id');
    localStorage.removeItem('chat-active-session-name');
    localStorage.removeItem('chat-error-last-action');

    set(() => ({
      chatMessages: [{ ...get().getInitialChatMessage() }],
      activeChatSession: { id: '', name: '' },
      incoming: false,
    }));
  },
}));
