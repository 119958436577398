import create from 'zustand';

import { usePostMentorChat, PostMentorChatPayloadType, PostMentorChatStore } from '@/features/chat';

export const usePostMentorChatStore = () => {
  const { mutateAsync, isLoading, isError, data } = usePostMentorChat();

  // delete message property, since that will be handle on client side.
  // this way also we can persist user message query when there is no response incoming from the API. Let says when no interner connectivity, esc
  delete data?.responseObj.message;

  return create<PostMentorChatStore>(() => ({
    chatPost: data?.responseObj,
    isLoading,
    isError,
    setPostChatSession: async (payload: PostMentorChatPayloadType) => {
      await mutateAsync({ ...payload });
    },
  }));
};
