import { ReactComponent as TrashIcon } from '@/assets/icons/trashIcon.svg';
import { InputField, SelectField } from '@/components/Form';
import { useClientStore } from '@/stores/client';

type UserData = {
  name: string;
  email: string;
  role: string | number;
  role_value?: string;
};

type CreateAdminProps = {
  index: number;
  register: any;
  errors: any;
  userData?: UserData;
  onRemove: (index: number) => void;
};

export const CreateAdmin = ({ index, userData, register, errors, onRemove }: CreateAdminProps) => {
  const { clients } = useClientStore();
  const currentClient = clients?.filter((client) => client.client_selected);
  const clientName = currentClient[0]?.client_name;

  return (
    <div className="container create-admin">
      <div className="flex">
        <InputField
          registration={register(`admins[${index}].name`, { required: true })}
          className="name-field"
          error={errors.admins && errors.admins[index] && errors.admins[index].name}
          label="Full Name"
          bold={true}
          defaultValue={userData?.name || ''}
        />
        <InputField
          registration={register(`admins[${index}].email`, { required: true })}
          className="name-field"
          error={errors.admins && errors.admins[index] && errors.admins[index].email}
          label="Email Address"
          bold={true}
          defaultValue={userData?.email || ''}
        />
      </div>
      <div className="flex mt-6">
        <SelectField
          className="permissions"
          label="Permissions"
          registration={register(`admins[${index}].role`, { required: true })}
          size="md"
          placeholder="Who is the client?"
          error={errors.admins && errors.admins[index] && errors.admins[index].role}
          defaultValue={userData?.role_value}
          options={[
            {
              label: 'User',
              value: '8',
            },
            {
              label: 'Client Administrator',
              value: '0',
            },
            {
              label: 'Global Administrator',
              value: '5',
            },
          ]}
        />
        <div className="client-wrapper">
          <p className="bold">Client</p>
          <p className="name">{clientName}</p>
        </div>
        <button type="button" onClick={() => onRemove(index)}>
          <TrashIcon color="C80404" />
        </button>
      </div>
    </div>
  );
};
