import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { GridContainer, Spinner } from '@/components/Elements';
import {
  ASSESSMENT_PHASE,
  AssessmentResponseType,
  useAssessmentTakeStore,
  useSectionResultsStore,
} from '@/features/assessments';
import { TeamResponseType, useTeams } from '@/features/teams';
import { createRegExpMatchFullWord, regexByObjectFields } from '@/utils/regexHelper';

import { TakeDisplay } from './TakeDisplay';
import { SectionDisplayProps } from './types';

interface TakeSectionDisplayProps extends SectionDisplayProps {
  phases?: ASSESSMENT_PHASE[];
  completed?: boolean;
  keepDisplay?: boolean;
}
export const TakeSectionDisplay = ({
  phases = [],
  filterInput,
  notifyResults,
  title = 'Assigned to You',
  fadingElements = true,
  fadingPagesToLoad = 3,
  completed = false,
  keepDisplay = true,
}: TakeSectionDisplayProps) => {
  const navigate = useNavigate();
  const { isLoading, takesLoaded, getTakes, filterTakes } = useAssessmentTakeStore();
  const { validateResultStatus } = useSectionResultsStore();
  const { data: teamsData } = useTeams();

  useEffect(() => {
    if (!takesLoaded) {
      getTakes();
    }
  }, [takesLoaded, getTakes]);

  const teamsMap = useMemo(() => {
    const map = new Map<string, TeamResponseType>();
    teamsData?.map((team) => map.set(team.team_id, team));
    return map;
  }, [teamsData]);

  const filteredTakes = useMemo(() => {
    if (!takesLoaded) {
      return [];
    }
    const takesData: AssessmentResponseType[] = filterTakes(phases);
    if (takesData && filterInput) {
      return takesData.filter((assessment) => {
        const regex = createRegExpMatchFullWord(filterInput);
        const fieldsToFilter: (string | string[])[] = [assessment.assessment_name];
        if (assessment.assessment_status) {
          fieldsToFilter.push(assessment.assessment_status);
        }
        if (teamsMap.has(assessment.general_settings.team_id)) {
          fieldsToFilter.push(teamsMap.get(assessment.general_settings.team_id)?.team_name || '');
        }
        const memberNames: string[] | undefined =
          assessment.general_settings.members_with_roles?.map((memberData) => memberData.name);
        if (memberNames && memberNames.length > 0) {
          fieldsToFilter.push(memberNames);
        }
        //  @TODO FILTER BY CATEGORY (at the moment is only Reflections)
        return regexByObjectFields(regex, false, fieldsToFilter) !== undefined;
      });
    }
    return takesData;
  }, [takesLoaded, filterInput, teamsMap]);

  useEffect(() => {
    if (takesLoaded) {
      validateResultStatus(isLoading, notifyResults, filterInput, filteredTakes);
    }
  }, [filteredTakes]);

  if (isLoading) {
    return (
      <div className="w-full flex justify-center mt-6 mb-6">
        <Spinner />
      </div>
    );
  }
  if (!keepDisplay && (!filteredTakes || filteredTakes.length === 0)) return null;
  return (
    <>
      <GridContainer
        title={`${title} (${filteredTakes.length})`}
        collapseCards={filteredTakes.length > 4}
        rowSize={4}
        elementsContainer={false}
        fadingElements={fadingElements}
        fadingPagesToLoad={fadingPagesToLoad}
        cards={filteredTakes.map((assessment, index) => (
          <TakeDisplay
            key={`${assessment.assessment_id}-${index}`}
            data={assessment}
            isAssessmentLoading={isLoading}
            navigate={navigate}
            teamsMap={teamsMap}
            complete={completed}
          />
        ))}
      />
    </>
  );
};
