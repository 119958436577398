import { Link } from 'react-router-dom';

import ConfirmationImage from '@/assets/Confirmation.png';
import { Button } from '@/components/Elements';

export const ConfirmationEmail = () => {
  return (
    <div className="demo confirmation">
      <div className="demo-header">
        <h3>Thank You</h3>
        <p className="body-3">A confirmation email has been send to the address provided.</p>
      </div>
      <div className="demo-content">
        <img className="confirmation-image" src={ConfirmationImage} alt="confirmation page" />
      </div>
      <Link to="/">
        <Button className="form-button">Return to login page</Button>
      </Link>
    </div>
  );
};
