import { useReducer, useCallback } from 'react';

import { MemberType, TeamResponseType } from '@/features/teams';

enum ACTIONS {
  ADD = 'add',
  REMOVE = 'remove',
  RESET = 'reset',
}

export type MemberReducerType = {
  action?: string;
} & MemberType;

export type useTeamMemberListReturnType = {
  teamData?: TeamResponseType;
  teamID?: string;
  members: MemberReducerType[];
  addMember: (member: MemberReducerType) => void;
  deleteMember: (email: string, role: string) => void;
  setMembers: (membersArray: MemberType[]) => void;
  resetList: () => void;
};

const reducer = (state: MemberReducerType[], { action, email, role, name }: MemberReducerType) => {
  let newState = [...state];

  switch (action) {
    case ACTIONS.ADD:
      newState.push({ email, role, name });
      break;
    case ACTIONS.REMOVE:
      newState = newState.filter((member) => !(member.email === email && member.role === role));
      break;
    case ACTIONS.RESET:
      newState = [];
      break;
    default:
  }

  return newState;
};

export const useTeamMemberList = (): useTeamMemberListReturnType => {
  const [members, memberDispatch] = useReducer(reducer, []);

  const addMember = useCallback((member: MemberReducerType): void => {
    memberDispatch({ action: ACTIONS.ADD, ...member });
  }, []);

  const deleteMember = useCallback((email: string, role: string): void => {
    memberDispatch({ action: ACTIONS.REMOVE, email, role });
  }, []);

  const setMembers = useCallback((membersArray: MemberType[]): void => {
    membersArray.length > 0 &&
      membersArray.map((member: MemberType) => {
        const { name, email, team_role } = member;
        team_role &&
          memberDispatch({
            action: ACTIONS.ADD,
            email,
            role: team_role,
            name: name && name !== '' ? name : 'Pending Login',
          });
      });
  }, []);

  const resetList = useCallback((): void => {
    memberDispatch({ action: ACTIONS.RESET, email: '', role: '' });
  }, []);

  return { members, addMember, deleteMember, setMembers, resetList };
};
