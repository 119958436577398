import { useState } from 'react';

import { ReactComponent as CircleDollarIcon } from '@/assets/icons/circle-dollar.icon.svg';
import { ReactComponent as MinusIcon } from '@/assets/icons/minus.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import { ReactComponent as TimeIcon } from '@/assets/icons/time.icon.svg';
import ChatProvider from '@/providers/chat.provider';

export const GameScreen = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="vv-bg-game-screen">
      <div className="vv-container-3">
        <div className="game-board">
          <div className="info">
            <div className="bg-white py-2 px-4 drop-shadow-xl rounded-lg">
              <div className="p-2 hover:bg-gray-50">
                <div className="flex justify-between">
                  <h1 className="text-base">Your Unique Game Premise</h1>
                  <button onClick={() => handleClick()}>
                    {!open ? <PlusIcon className="w-4 h-4" /> : <MinusIcon className="w-4 h-4" />}
                  </button>
                </div>
                {open && (
                  <p className="text-xs text-gray-500 py-2">
                    {`In a world increasingly conscious about mental well-being, there's a growing,
                    unmet need for innovative stress-relief solutions. Your hypothesis is that
                    people are seeking new, immersive ways to destress, beyond traditional methods
                    like meditation apps or therapy.`}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="status">
            <table>
              <tr>
                <td>
                  <TimeIcon />
                </td>
                <td className="font-semibold">Time Elapsed</td>
                <td />
                <td>1 week out of 12 weeks</td>
              </tr>
              <tr>
                <td>
                  <CircleDollarIcon />
                </td>
                <td className="font-semibold">Bank Balanced</td>
                <td />
                <td>$10.000</td>
              </tr>
            </table>
          </div>
        </div>
        <ChatProvider isVentureverse />
      </div>
    </div>
  );
};

export default GameScreen;
