/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';

export const PermissionsHeader = ({ column: { filterValue, setFilter } }: { column: any }) => (
  <div>
    <select
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Permissions (All) </option>
      <option value="Global Admin">Global Admin</option>
      <option value="Client Admin">Client Admin</option>
      <option value="User">User </option>
    </select>
  </div>
);
