import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export type DeleteUserProps = {
  emails: string[] | string;
};

export const deleteUser = (email: string[] | string) => {
  if (typeof email === 'string') {
    return axios.delete(`/users/${email}`);
  } else {
    return axios.delete('/users', { data: email });
  }
};

type UseDeleteUserOptions = {
  config?: MutationConfig<typeof deleteUser>;
};

export const useDeleteUser = ({ config }: UseDeleteUserOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('users');
    },
    onError: (_, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData('users', context.previousUsers);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      addNotification({
        type: 'success',
        title: 'Profile successfully deleted',
      });
    },
    ...config,
    mutationFn: deleteUser,
  });
};
