import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type ResetPassword = {
  email: string;
};

export const resetPassword = ({ email }: ResetPassword) => {
  return axios.post(`/users/${email}/forgotpassword`);
};

type UseResetPasswordOptions = {
  config?: MutationConfig<typeof resetPassword>;
};

export const useResetPassword = ({ config }: UseResetPasswordOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Password Reset sent',
      });
    },
    ...config,
    mutationFn: resetPassword,
  });
};
