import { MemberType } from '@/features/teams';
import { Paths } from '@/utils/Paths';

// Bases
export type AssessmentBaseType = {
  assessment_name: string;
  assessment_instructions: string;
  general_settings: GeneralSettingsType;
  sections: SectionResponseType[];
  client_name?: string;
  assessment_id?: string;
  createdOn?: number;
  modifiedOn?: number;
  kind: string;
};

export type AssessmentTakeBaseType = {
  assigned_user_id: string;
  assessment_take_user_phase: string;
};
export type SectionBaseType = {
  section_name: string;
  section_description: string;
  questions: QuestionBaseType[];
};

export type QuestionBaseType = {
  id?: string;
  category_id?: string;
  category_name?: string;
  phase_tag?: string;
  question_name?: string;
  question_prompt?: string;
  question_scale?: {
    tooltip: string;
    label: string;
  }[];
  scale?: string;
  question_scale_type?: string;
  question_type:
    | QUESTION_LABELS.OPEN
    | QUESTION_LABELS.MINDSET
    | QUESTION_LABELS.SKILL_BUILD
    | QUESTION_LABELS.SKILL_VALIDATE
    | QUESTION_LABELS.SKILL_EXEC_SPONSOR
    | QUESTION_LABELS.SKILL_OS_TEAM
    | QUESTION_LABELS.TEAM_DYNAMIC;
  role?: string[];
  section_name?: string;
  section_description?: string;
};

export type AnswerBaseType = {
  assessment_question_id: string;
  prompt: string;
  response: number;
  comment: string;
};

// Responses
export type AssessmentResponseType = {
  assessment_id: string;
  client_id?: string;
  createdBy: string;
  createdOn: number;
  kind: string;
  modifiedBy: string;
  modifiedOn: number;
  assessment_takes: AssessmentTakeBaseType[];
  assessment_type: string;
  assessment_description: string;
  template_name: string;
  observers?: any;
  recievers?: any;
  general_settings?: GeneralSettingsType;
  assessment_status?: string;
  assessment_take_user_phase?: string;
  questions?: QuestionResponseType[];
  admin_closed?: boolean;
  completedOn?: number;
} & AssessmentBaseType;

export type AssessmentBaseline = {
  assessment_take_user_phase: string;
  assigned_user_id: string;
  members?: MemberType[];
  UserCreateDate: string;
} & AssessmentResponseType;

export type QuestionResponseType = {
  assessment_question_id: string;
  response?: { email: string; response: string; name: string }[];
  comment?: string;
  category_name?: string;
} & QuestionBaseType;

export type SectionResponseType = {
  questions: QuestionResponseType[];
} & SectionBaseType;

// General

export type Category = {
  category_id: string;
  category_name: string;
  category_types: string[];
};

export type GeneralSettingsType = {
  due_date: string;
  launch_date: string;
  team_id: string;
  members: Array<string>;
  partner?: string;
  team_name?: string;
  assessment_take_user_phase?: string;
  stage_id?: string;
  role_to_assesst: string;
  members_with_roles?: Array<any>;
};

//ENUMS
export enum QUESTION_LABELS {
  TEAM_DYNAMIC = 'Team Dynamics Question',
  SKILL_BUILD = 'Skills - Build Question',
  SKILL_VALIDATE = 'Skills - Validate Question',
  SKILL_EXEC_SPONSOR = 'Skills - Exec Sponsor',
  SKILL_OS_TEAM = 'Skills - OS Team',
  MINDSET = 'Mindsets Question',
  OPEN = 'Open-ended Question',
}

export enum DRAG_TYPE {
  SECTION = 'section',
  QUESTION = 'question',
}

export enum ASSESSMENT_TEMPLATES {
  BLANK = 'blank',
  BASELINE = 'Cofounder Assessment - Baseline',
  COFOUNDER_VALIDATE = 'Cofounder Assessment - Validate',
  COFOUNDER_BUILD = 'Cofounder Assessment - Build',
  SPONSOR_BASELINE = 'Executive Sponsor GrowthCatalyst - Baseline',
  SPONSOR_FOLLOW_UP = 'Executive Sponsor GrowthCatalyst - Follow-up',
  OS_BASELINE = 'OS Team GrowthCatalyst - Baseline',
  OS_FOLLOW_UP = 'OS Team GrowthCatalyst - Follow-up',
}

export enum ASSESSMENT_TYPES {
  INDIVIDUAL = '1',
  TEAM = '2',
}

export enum ASSESSMENT_ACTIONS {
  SUBMIT = 'submit',
  DRAFT = 'draft',
}

export enum CALENDAR_TYPES {
  DUE_DATE = 'due_date',
  LAUNCH_DATE = 'launch_date',
}

export enum ASSESSMENT_PHASE {
  UPCOMING = 'UPCOMING',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  CLOSED = 'CLOSED',
  SUBMITTED = 'SUBMITTED',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  PAST_DUE = 'PAST_DUE',
  ADMIN_CLOSED = 'ADMIN_CLOSED',
  OVERDUE = 'OVERDUE',
  DUE_SOON = 'DUE_SOON',
  UNDEFINED = 'UNDEFINED',
}

export const PHASE_LABELS: { [key: string]: string } = {
  [ASSESSMENT_PHASE.UPCOMING]: 'Upcoming',
  [ASSESSMENT_PHASE.IN_PROGRESS]: 'In Progress',
  [ASSESSMENT_PHASE.CLOSED]: 'Complete',
  [ASSESSMENT_PHASE.SUBMITTED]: 'Submitted',
  [ASSESSMENT_PHASE.NOT_SUBMITTED]: 'Not Submitted',
  [ASSESSMENT_PHASE.PAST_DUE]: 'Past Due',
  [ASSESSMENT_PHASE.COMPLETED]: 'Completed',
  [ASSESSMENT_PHASE.UNDEFINED]: 'Unknown',
};

export interface AssessmentDropdownType {
  assessment_name: string;
  assessment_id: string;
  due_date: number;
  is_reciever: boolean;

  team_name?: string;
  team_id?: string;
}

export interface TeamAssessmentType {
  team_name: string;
  team_id: string;
  assessments: AssessmentDropdownType[];
}

export interface ITeamAssessments {
  responseObj: TeamAssessmentType[];
}

export interface IQuestions {
  responseObj: QuestionResponseType[];
}

export type NestedResponseTypePaths = Paths<AssessmentResponseType>;
