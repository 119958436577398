import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { AssessmentResponseType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

interface IAssessment {
  responseObj: AssessmentResponseType[];
}

interface IEditAssessment {
  responseObj: AssessmentResponseType;
}

export const getAssessment = (assessmentID: string) => {
  return axios
    .get<AssessmentResponseType[]>(`assessments?assessment_id=${assessmentID}`)
    .then((data: AxiosResponse | IAssessment): AssessmentResponseType[] => {
      const assessments = (data as IAssessment)?.responseObj || [];
      return assessments.map((assessment: any) => {
        assessment.observers = assessment?.general_settings?.members_with_roles
          ?.filter((m: any) => m.observer)
          .map((m: any) => m.email);
        assessment.recievers = assessment?.general_settings?.members_with_roles
          ?.filter((m: any) => m.reciever)
          .map((m: any) => m.email);
        assessment.general_settings.members = assessment?.general_settings?.members_with_roles?.map(
          (m: any) => m.email
        );
        return assessment;
      });
    });
};

export const getEditAssessment = (assessmentID: string) => {
  return axios
    .get<AssessmentResponseType[]>(`assessments/${assessmentID}`)
    .then((data: AxiosResponse | IEditAssessment): AssessmentResponseType => {
      const assessment: any = { ...(data as IEditAssessment).responseObj };
      assessment.observers = assessment?.general_settings?.members_with_roles
        ?.filter((m: any) => m.observer)
        .map((m: any) => m.email);
      assessment.recievers = assessment?.general_settings?.members_with_roles
        ?.filter((m: any) => m.reciever)
        .map((m: any) => m.email);
      assessment.general_settings.members = assessment?.general_settings?.members_with_roles?.map(
        (m: any) => m.email
      );
      return assessment;
    });
};

type QueryFnType = typeof getAssessment;
type QueryFnType2 = typeof getEditAssessment;

export const useAssessment = (assessmentID: string, config?: QueryConfig<QueryFnType>) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['assessment'],
    queryFn: () => getAssessment(assessmentID),
  });
};

export const useEditAssessment = (assessmentID: string, config?: QueryConfig<QueryFnType2>) => {
  return useQuery<ExtractFnReturnType<QueryFnType2>>({
    ...config,
    queryKey: ['editAssessment', assessmentID],
    queryFn: () => getEditAssessment(assessmentID),
  });
};
