import clsx from 'clsx';
import { useCallback } from 'react';

import { IOption } from './Dropdown';

interface IDropdown {
  options: IOption[];
  open: boolean;
  setOpen?: () => void;
  isSearchable?: boolean;
  selectedValue?: IOption;
}

export const DropdownMenu = ({ options = [], open, setOpen, selectedValue }: IDropdown) => {
  const onClickOption = useCallback(
    (option: IOption) => {
      if (setOpen) setOpen();
      if (option.onClick) option.onClick(option);
    },
    [setOpen]
  );

  const isSelected = (option: IOption) => {
    if (!selectedValue) {
      return false;
    }
    return selectedValue.label === option.label;
  };

  return (
    <div id="dropdown" className={clsx('dropdown-content', open ? 'dropdown-show' : '')}>
      {options.map((option, i) => (
        <button
          type="button"
          onClick={() => onClickOption(option)}
          key={i}
          className={
            `dropdown-item${isSelected(option) ? ' selected' : ''}` +
            `${option.itemClass ? ' ' + option.itemClass : ''}`
          }
          disabled={option.disabled}
        >
          {option.icon && <i className="dropdown-item-img">{option.icon}</i>}
          <span className="dropdown-item-span">{option.label}</span>
          {option.buttonList &&
            option.buttonList.map((IconComponent, index) => <IconComponent key={index} />)}
        </button>
      ))}
    </div>
  );
};
