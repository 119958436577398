import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { AssessmentBaseType, AssessmentResponseType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

interface ISaveAssessment {
  responseObj: AssessmentResponseType;
}

const saveAssessment = (data: AssessmentBaseType): Promise<AssessmentResponseType> => {
  return (
    data.assessment_id && data.kind !== 'TEMPLATE'
      ? axios.put<AssessmentResponseType>(`/assessments/${data.assessment_id}`, data)
      : axios.post<AssessmentResponseType>('/assessments', data)
  ).then((data: AxiosResponse | ISaveAssessment): AssessmentResponseType => {
    return (data as ISaveAssessment)?.responseObj;
  });
};

type UseSaveAssessmentOptions = {
  config?: MutationConfig<typeof saveAssessment>;
};

export const useSaveAssessment = ({ config }: UseSaveAssessmentOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onError: (_, __, context: any) => {
      addNotification({
        type: 'error',
        title: context,
      });
    },
    onSuccess: () => {
      const title = `Assessment successfully saved!`;
      queryClient.invalidateQueries('assessments');
      addNotification({
        type: 'success',
        title: title,
      });
    },
    ...config,
    mutationFn: saveAssessment,
  });
};
