import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { ReactComponent as PencilIcon } from '@/assets/icons/pencilIcon.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/trashIcon.svg';
import { Button, ConfirmationDialog, Spinner } from '@/components/Elements';
import { ChatModel, GroupedChatHistory, MAX_LENGTH_SESSION_NAME } from '@/features/chat';
import { useNotificationStore } from '@/stores/notifications';
import { ActionMode } from '@/types';
import { getFineName } from '@/utils/string';

import { InlineEdit } from '../inline-edit';

export interface SideNavMaxProps {
  open?: boolean;
  data?: ChatModel[];
  chatMessages?: ChatModel[];
  isLoading?: boolean;
  isDeleting?: boolean;
  selectedItemMaxMode?: Record<string, boolean>;
  setSelectedItemMaxMode?: (sessionId?: string) => void;
  onLoadDataBySession?: (data?: ChatModel[], selectedName?: string) => void;
  onChatDelete?: (sessionId: string) => void;
  onChatRename?: (sessionId: string, sessionName: string) => void;
}

export const SideNavMax = (props: SideNavMaxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { addNotification } = useNotificationStore();

  const [actionMode, setActionMode] = useState<ActionMode>('none');
  const [newData, setNewData] = useState<GroupedChatHistory[]>();
  const [deletionDone, setDeletionDone] = useState<boolean>(false);

  useEffect(() => {
    const activeSessionId = localStorage.getItem('chat-active-session-id');
    if (activeSessionId) {
      props.setSelectedItemMaxMode && props.setSelectedItemMaxMode(activeSessionId);
    }
  }, []);

  useEffect(() => {
    if (props.data) {
      const data = props.data
        .sort((a, b) => {
          if (!a.createdOn) return 1;
          if (!b.createdOn) return -1;
          return b.createdOn - a.createdOn;
        })
        .reduce((groupedData: GroupedChatHistory[], { session_id, ...restItem }) => {
          const record = groupedData.find((item) => item.sessionId === session_id);
          if (!record) {
            groupedData.push({
              sessionId: session_id,
              sessionName: '',
              createdOn: restItem.createdOn,
              messages: [{ ...restItem }],
            });
            return groupedData;
          }
          record.messages?.push({ ...restItem });
          return groupedData;
        }, [])
        .map((item) => {
          if (item.messages) {
            const messages = item.messages.sort((a, b) => {
              if (!a.createdOn) return 1;
              if (!b.createdOn) return -1;
              return a.createdOn - b.createdOn;
            });
            const sessionName = !messages[0].session_name
              ? messages[1]?.message
              : messages[0].session_name;
            return {
              ...item,
              messages,
              sessionName: sessionName ? getFineName(sessionName) : '',
            };
          }
          return item;
        });
      setNewData(data);
    }
  }, [props.data]);

  useEffect(() => {
    if (actionMode === 'edit') {
      inputRef.current?.focus();
    }
  }, [actionMode]);

  const handleItemSelected = (sessionId?: string, selectedName?: string) => {
    if (sessionId && actionMode === 'none') {
      props.setSelectedItemMaxMode && props.setSelectedItemMaxMode(sessionId);
      localStorage.setItem('chat-active-session-id', sessionId);
      if (selectedName) {
        localStorage.setItem('chat-active-session-name', selectedName);
      }
      const filtered = props.data?.filter((item) => item.session_id === sessionId);
      props.onLoadDataBySession && props.onLoadDataBySession(filtered, selectedName);
    }
  };

  const handleEditChat = () => {
    setActionMode('edit');
  };

  const handleInlineEditChange = (e: ChangeEvent<HTMLInputElement>, sessionId?: string) => {
    const { value } = e.target;
    if (newData) {
      const data = newData.map((item) => {
        if (item.sessionId === sessionId) {
          return {
            ...item,
            sessionName: value,
          };
        }
        return item;
      });
      setNewData(data);
    }
  };

  const handleInlineEditOk = (sessionId?: string, sessionName?: string) => {
    if (!sessionName) {
      addNotification({
        type: 'error',
        title: 'Chat name can not be empty, please provide a name.',
      });
      inputRef.current?.focus();
      return;
    }
    setActionMode('none');
    if (props.onChatRename && sessionId && sessionName) {
      props.onChatRename(sessionId, sessionName);
    }
  };

  const handleInlineEditCancel = (sessionId?: string) => {
    setActionMode('none');
    const oldSessionName = localStorage.getItem('chat-active-session-name');
    if (oldSessionName && newData) {
      const data = newData.map((item) => {
        if (item.sessionId === sessionId) {
          return {
            ...item,
            sessionName: oldSessionName,
          };
        }
        return item;
      });
      setNewData(data);
    }
  };

  const handleChatDelete = (sessionId?: string) => {
    if (props.onChatDelete && sessionId) {
      setDeletionDone(true);
      props.onChatDelete(sessionId);
    }
  };

  return (
    <div className={`side-nav-max${!props.open ? ' collapsed' : ''}`}>
      <div className="chat-history">
        <div className="header">Chat history</div>
        {props.isLoading && (
          <div className="spinner">
            <Spinner />
          </div>
        )}
        <ul className={`${!props.open ? ' collapsed' : ''}`}>
          {newData &&
            newData.map((item) => (
              <li
                aria-hidden
                key={item.sessionId}
                onClick={() => handleItemSelected(item.sessionId, item.sessionName)}
                className={`${
                  props.selectedItemMaxMode &&
                  item.sessionId &&
                  props.selectedItemMaxMode[item.sessionId]
                    ? 'active'
                    : ''
                }`}
              >
                {(actionMode === 'none' ||
                  !props.selectedItemMaxMode ||
                  (item.sessionId && !props.selectedItemMaxMode[item.sessionId])) && (
                  <>
                    <span>{item.sessionName}</span>
                    <PencilIcon width={20.5} height={20.5} onClick={handleEditChat} />
                    <ConfirmationDialog
                      title="Delete chat?"
                      body="Please confirm that you would like to delete this chat history. Chats sent to the trash cannot be recovered."
                      cancelButtonText="Cancel"
                      triggerButton={<TrashIcon width={18} color="rgba(92, 92, 92, 0.6)" />}
                      confirmButton={
                        <Button
                          type="button"
                          isLoading={props.isDeleting}
                          onClick={() => handleChatDelete(item.sessionId)}
                        >
                          Delete chat
                        </Button>
                      }
                      isDone={deletionDone}
                    />
                  </>
                )}
                {actionMode === 'edit' &&
                  props.selectedItemMaxMode &&
                  item.sessionId &&
                  props.selectedItemMaxMode[item.sessionId] && (
                    <InlineEdit
                      inputRef={inputRef}
                      className="show"
                      value={item.sessionName}
                      maxLength={MAX_LENGTH_SESSION_NAME}
                      onChange={(e) => handleInlineEditChange(e, item.sessionId)}
                      onOk={() => handleInlineEditOk(item.sessionId, item.sessionName)}
                      onCancel={() => handleInlineEditCancel(item.sessionId)}
                    />
                  )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SideNavMax;
