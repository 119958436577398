import { useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { ReactComponent as TrashIcon } from '@/assets/icons/trashIcon.svg';
import { Button, Spinner } from '@/components/Elements';
import {
  MemberList,
  useUpdateTeam,
  useTeam,
  TeamResponseType,
  MemberType,
  useDeleteTeam,
} from '@/features/teams';
import { useDisclosure } from '@/hooks/useDisclosure';
import { MemberReducerType, useTeamMemberListReturnType } from '@/hooks/useTeamMemberList';
import { useLeavePageStore } from '@/stores/leavePage';

import { DeleteTeamDialog } from '../dialogs/DeleteTeamDialog';

type MembersProps = {
  editObj?: TeamResponseType;
} & useTeamMemberListReturnType;

export const Members = ({
  editObj,
  members,
  addMember,
  deleteMember,
  setMembers,
  resetList,
}: MembersProps) => {
  const { teamID } = useParams();
  const { removeBlocker } = useLeavePageStore();
  const { mutateAsync: updateTeam, isLoading: isUpdatingTeam } = useUpdateTeam();
  const { mutateAsync: deleteTeam, isLoading: deleteTeamLoading } = useDeleteTeam();

  const navigate = useNavigate();
  const {
    data: teamData,
    refetch,
    isLoading,
  } = useTeam({
    teamID,
    config: {
      enabled: false,
    },
  });

  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();

  const enabledUsers = useMemo(() => {
    const membersResult: MemberReducerType[] = [];
    const objMembers = editObj?.members || [];
    members.forEach((x) => {
      const tempMember = objMembers.find((y) => y.email === x.email);
      if (!tempMember || tempMember.Enabled === 'true') {
        membersResult.push(x);
      }
    });
    return membersResult;
  }, [editObj?.members, members]);

  useEffect(() => {
    if (teamID) {
      refetch();
    }
  }, [refetch, teamID]);

  useEffect(() => {
    if (editObj && editObj.members) {
      resetList();
      setMembers(editObj.members);
    }
  }, [editObj, resetList, setMembers]);

  const onSaveTeam = () => {
    if (teamData) {
      updateTeam({
        teamID,
        data: {
          ...teamData,
          members: members.map(({ email, role }: MemberType) => {
            return { email, role };
          }),
        },
      }).then(() => {
        removeBlocker().then(() => navigate('teams'));
      });
    }
  };

  const onDeleteTeam = useCallback(() => {
    if (teamID) {
      deleteTeam(teamID).then(() => {
        removeBlocker().then(() => navigate('teams'));
      });
    }
  }, [deleteTeam, navigate, removeBlocker, teamID]);

  if (isLoading) return <Spinner />;

  return (
    <div className="team-members container">
      <DeleteTeamDialog
        isOpen={isOpenDeleteModal}
        close={closeDeleteModal}
        deleteTeam={!!editObj}
        onDelete={onDeleteTeam}
        deleteTeamLoading={deleteTeamLoading}
      />

      {!editObj && teamData && <h4>{'Add New or Existing Users'}</h4>}
      <p className="mt-4 body-4">
        Your teammates will get an email that gives them access to your team.
      </p>

      <MemberList
        teamID={teamID}
        teamData={teamData}
        members={enabledUsers}
        addMember={addMember}
        deleteMember={deleteMember}
        showHeading={false}
      />
      {!editObj && (
        <div className="team-members__options">
          <Button
            type="button"
            variant="primary"
            disabled={members.length === 0}
            isLoading={isUpdatingTeam ? true : false}
            onClick={() => {
              onSaveTeam();
            }}
          >
            Save team
          </Button>
          <button type="button" onClick={openDeleteModal} className="mt-1">
            <TrashIcon color="C80404" />
          </button>
        </div>
      )}
    </div>
  );
};
