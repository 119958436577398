import deRiskIcon from '@/assets/applicationIcons/deRisk.svg';
import discoverIcon from '@/assets/applicationIcons/discover.svg';
import empowerIcon from '@/assets/applicationIcons/empower.svg';
import growthOSIcon from '@/assets/applicationIcons/growthOS.svg';
import learnIcon from '@/assets/applicationIcons/learn.svg';
import makeIcon from '@/assets/applicationIcons/make.svg';
import validateIcon from '@/assets/applicationIcons/validate.svg';

export interface LogoApplication {
  title: string;
  icon: string;
  disabled?: boolean;
  selected?: boolean;
  url?: string;
  external?: boolean;
}

export const CANDY_BAR_APPLICATIONS: LogoApplication[] = [
  {
    title: 'GrowthOS',
    icon: growthOSIcon,
    disabled: true,
    external: true,
  },
  {
    title: 'Blimp', // Validate
    icon: validateIcon,
    url: 'https://blimp.rocks/',
    external: true,
  },
  {
    title: 'Spark', // Empower
    icon: empowerIcon,
    selected: true,
    url: '/',
  },
  {
    title: 'Learn',
    icon: learnIcon,
    url: 'https://accenture-song.storied.co/gpi-methods-toolkit/',
    external: true,
  },
  {
    title: 'Discover',
    icon: discoverIcon,
    disabled: true,
    external: true,
  },
  {
    title: 'De-Risk',
    icon: deRiskIcon,
    disabled: true,
    external: true,
  },
  {
    title: 'Make',
    icon: makeIcon,
    disabled: true,
    external: true,
  },
];
