import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { AssessmentResponseType } from '../types';

interface IAssessments {
  responseObj: AssessmentResponseType[];
}

export const getAssessments = (params?: string, orderBy?: keyof AssessmentResponseType) => {
  return axios
    .get<IAssessments>(`/assessments${params ? `?${params}` : ''}`)
    .then((data: AxiosResponse | IAssessments): AssessmentResponseType[] => {
      const assessments: AssessmentResponseType[] = (data as IAssessments)?.responseObj || [];
      const response: AssessmentResponseType[] = assessments.map(
        (assessment: AssessmentResponseType) => {
          assessment.observers = assessment?.general_settings?.members_with_roles
            ?.filter((m: any) => m.observer)
            .map((m: any) => m.email);
          assessment.recievers = assessment?.general_settings?.members_with_roles
            ?.filter((m: any) => m.reciever)
            .map((m: any) => m.email);
          assessment.general_settings.members =
            assessment?.general_settings?.members_with_roles?.map((m: any) => m.email) || [];
          return assessment;
        }
      );
      if (orderBy) {
        return response.sort((a, b) => b[orderBy] - a[orderBy]);
      }
      return response;
    });
};

type QueryFnType = typeof getAssessments;

type UseAssessmentOptions = {
  config?: QueryConfig<QueryFnType>;
  params?: string;
  orderBy?: keyof AssessmentResponseType;
};

export const useAssessments = ({ config, params, orderBy }: UseAssessmentOptions = {}) => {
  if (!orderBy) {
    // Set default
    orderBy = 'modifiedOn';
  }
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['assessments', params, orderBy],
    queryFn: () => getAssessments(params, orderBy),
  });
};
