import dayjs from 'dayjs';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { Button, Spinner } from '@/components/Elements';
import {
  useTakeAssessment,
  AssessmentHeader,
  Questionnaire,
  ASSESSMENT_PHASE,
} from '@/features/assessments';
import { useTeams } from '@/features/teams';

import { useAssessmentTakeStore } from '../../../stores/assessmentTakeStore';

type AssessmentPropsType = {
  assessmentId: string;
  isPreview: boolean;
  withMembers: boolean;
};

export const Assessment = ({
  assessmentId,
  isPreview = false,
  withMembers = true,
}: AssessmentPropsType) => {
  const [startAssessment, setStartAssessment] = useState<boolean>(false);
  const { selected } = useAssessmentTakeStore();
  const [step, setStep] = useState<number>(0);
  const {
    data: assessmentData,
    isLoading: assesmentIsLoading,
    refetch: assessmentRefetch,
    isFetching: assessmentIsFetching,
  } = useTakeAssessment(assessmentId);

  const { data: teamsData, isLoading: teamsLoading } = useTeams();

  const team_name = useMemo(() => {
    return teamsData?.filter(
      (team) => team.team_id === assessmentData?.responseObj?.[0]?.general_settings?.team_id
    )[0]?.team_name;
  }, [assessmentData?.responseObj, teamsData]);

  useEffect(() => {
    assessmentRefetch();
    setStartAssessment(isPreview);
    setStep(0);
  }, [assessmentId, isPreview, assessmentRefetch, setStartAssessment]);

  const onChangeStep = useCallback((step: number) => {
    setStep(step);
  }, []);

  if (assesmentIsLoading || assessmentIsFetching || teamsLoading)
    return (
      <div className="container assessment">
        <Spinner />
      </div>
    );

  if (assessmentData?.responseObj?.[0] || selected) {
    const assessment = !isPreview ? assessmentData?.responseObj?.[0] : selected;
    const {
      assessment_id = '',
      sections = [],
      assessment_name = '',
      assessment_type = '',
      general_settings = { due_date: '' },
      assessment_instructions = '',
      template_name = '',
      assessment_take_user_phase,
      assessment_status,
    } = assessment || {};

    return (
      <div className="container assessment">
        <>
          <AssessmentHeader
            sectionsLength={sections.length}
            step={step}
            assessment_name={assessment_name}
            dueDate={general_settings.due_date}
          />
          {startAssessment && (
            <Questionnaire
              withMembers={withMembers}
              preview={
                isPreview ||
                assessment_status === ASSESSMENT_PHASE.ADMIN_CLOSED ||
                assessment_take_user_phase === ASSESSMENT_PHASE.COMPLETED
              }
              sections={sections}
              step={step}
              onChangeStep={onChangeStep}
              assessmentId={assessment_id}
              assessment_type={assessment_type}
            />
          )}
          {!startAssessment && (
            <>
              <p className="bold">
                Welcome to the {template_name} {team_name ? `for ${team_name}` : ''}
              </p>
              <p>{assessment_instructions}</p>
            </>
          )}
          {!startAssessment &&
            (assessmentId || selected) &&
            assessmentData?.responseObj[0].general_settings.launch_date && (
              <div className="questionnaire__actions">
                <Button
                  onClick={() =>
                    dayjs(
                      new Date(
                        parseInt(assessmentData?.responseObj[0].general_settings.launch_date) * 1000
                      )
                    ).diff(new Date(), 'day') >= 0
                      ? null
                      : setStartAssessment(true)
                  }
                  className={
                    dayjs(
                      new Date(
                        parseInt(assessmentData?.responseObj[0].general_settings.launch_date) * 1000
                      )
                    ).diff(new Date(), 'day') >= 0
                      ? 'disabled'
                      : ''
                  }
                >
                  {assessment_status === ASSESSMENT_PHASE.ADMIN_CLOSED ||
                  assessment_take_user_phase === ASSESSMENT_PHASE.COMPLETED
                    ? 'See responses'
                    : 'Begin reflection'}
                </Button>
              </div>
            )}
        </>
      </div>
    );
  }

  return null;
};
