import { isDate } from 'lodash';

type ComparatorType = string | Date | number;

const validateAsDate = (value1: ComparatorType, value2: ComparatorType) => {
  // Validate as Date
  const date1 = new Date(value1);
  const date2 = new Date(value2);
  if (isDate(date1) && isDate(date2)) {
    return date2.getTime() - date1.getTime();
  }
  return false;
};

export const sortWithValidations = (value1: ComparatorType, value2: ComparatorType) => {
  const typeOfValue = typeof value1;
  if (typeOfValue === 'number') {
    return Number(value2) - Number(value1);
  } else {
    const dateValidation = validateAsDate(value1, value2);
    if (dateValidation !== false) {
      return dateValidation;
    }
    return value2 > value1 ? 1 : -1;
  }
};
