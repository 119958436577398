import React from 'react';

interface IResetPasswordFooter {
  children: React.ReactElement;
}
export const ResetPasswordHeader = () => (
  <div className="auth-header">
    <h3 className="auth-title">Forgot your password?</h3>
    <p>
      Enter your email address to locate your account. You will receive an SMS code in order to
      change your password.{' '}
    </p>
  </div>
);

export const ResetPasswordFooter = ({ children }: IResetPasswordFooter) => (
  <div className="auth-footer">{children}</div>
);
