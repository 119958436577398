import { ChatModel } from '@/features/chat';

export interface UserLikeChatPayloadType {
  session_id: string;
  message_id: string;
}

export interface UserUnlikeChatPayloadType {
  session_id: string;
  message_id: string;
}

export type UserLikeChatResponseType = ChatModel;
export type UserUnlikeChatResponseType = ChatModel;

export interface UserLikeChatStore {
  chatUserLike?: ChatModel;
  isLoading?: boolean;
  isError?: boolean;
  setUserLikeChatSession: (props: UserLikeChatPayloadType) => void;
}

export interface UserUnlikeChatStore {
  chatUserUnlike?: ChatModel;
  isLoading?: boolean;
  isError?: boolean;
  setUserUnlikeChatSession: (props: UserUnlikeChatPayloadType) => void;
}

export const USER_LIKE_CHAT_API = '/mentor/chats/sessions/{session_id}/messages/{message_id}/like';
export const USER_UNLIKE_CHAT_API =
  '/mentor/chats/sessions/{session_id}/messages/{message_id}/unlike';
