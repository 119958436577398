import { ContentLayout } from '@/components/Layout';

import { Take } from '../components/take/Take';

interface TakeAssessmentProps {
  previewMode?: boolean;
}

export const TakeAssessment = ({ previewMode = false }: TakeAssessmentProps) => {
  return (
    <ContentLayout title="GrowthCatalyst Inbox">
      <Take previewMode={previewMode} />
    </ContentLayout>
  );
};
