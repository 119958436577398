import { Button, Dialog, DialogTitle } from '@/components/Elements';

interface IBulkUploadModal {
  isOpen: boolean;
  onClick: () => void;
  close: () => void;
  loadingUpload?: boolean;
}

export const BulkUploadModal = ({ isOpen, onClick, close, loadingUpload }: IBulkUploadModal) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Upload Users from CSV file
          </DialogTitle>
          <div className="modal__info">
            <h5>Please upload a CSV file with the following headings:</h5>
            <p className="mt-4">Name | Email | Permission (Client Administrator, User)</p>
          </div>
          <div className="builder__publish-modal-buttons">
            <Button type="button" onClick={close} variant="inverse">
              Go Back
            </Button>
            <Button onClick={onClick} isLoading={loadingUpload}>
              I understand, upload users
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
