import { useEffect, useState, useMemo, useCallback } from 'react';

import { Button, Dialog, DialogTitle, DropdownRole, IOptionRole } from '@/components/Elements';
import { TeamRoleType } from '@/features/clients';
import { useTeams, useTeamRolesStore } from '@/features/teams';

type DeleteRoleDialogProps = {
  isOpen: boolean;
  close: () => void;
  onDelete: (roleToAssign: string) => void;
  roleToDelete?: string;
  teamRoles?: TeamRoleType[];
};

export const DeleteRoleDialog = ({
  isOpen,
  close,
  onDelete,
  roleToDelete,
}: DeleteRoleDialogProps) => {
  const [openDefault, openDefaultSet] = useState(true);
  const [open, openSet] = useState(false);
  const [selectedValue, setSelectedValue] = useState<IOptionRole | undefined>(undefined);
  const [roleToAssign, setRoleToAssign] = useState('User');
  const { data: teamsData } = useTeams();
  const { teamRoles } = useTeamRolesStore();
  const [dropdownOptions, setDropdownOptions] = useState<IOptionRole[]>([]);
  const [teamNames, setTeamNames] = useState('None');

  useEffect(() => {
    if (!isOpen) {
      setDropdownOptions([]);
      setSelectedValue(undefined);
      setRoleToAssign('User');
    }
  }, [isOpen]);

  useEffect(() => {
    if (teamsData) {
      const teamsWithRole = teamsData.filter((team) =>
        team?.members?.some((member) => member.team_role === roleToDelete)
      );

      const newTeamNames = teamsWithRole.map((team) => team.team_name).join(', ');
      setTeamNames(newTeamNames);
    }
  }, [teamsData, roleToDelete]);

  const openFlag = useMemo(() => {
    return open !== undefined ? open : openDefault;
  }, [open, openDefault]);

  const setOpen = useCallback(() => {
    openSet(!open);
  }, [open]);

  const openDropdown = useCallback(() => {
    setOpen ? setOpen() : openDefaultSet(!openDefault);
  }, [openDefault, setOpen]);

  useEffect(() => {
    const newDropdownOptions = [...dropdownOptions];

    teamRoles.forEach((role) => {
      const exists = newDropdownOptions.find((option) => option.selectedLabel === role.value);
      if (!exists) {
        const label = role.name || '';
        newDropdownOptions.push({
          label,
          selectedLabel: role.value,
          //system set to true for all roles in DeleteRole, so the delete icon won't show
          system: true,
          team_role_id: role.team_role_id,
          onClick: () => {
            if (role.team_role_id) {
              setRoleToAssign(role.team_role_id);
            }
            setSelectedValue({ label });
          },
        });
      }
    });

    setDropdownOptions(newDropdownOptions);
  }, [teamRoles]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal delete-role-modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Delete Role
          </DialogTitle>
          <div className="modal__info">
            <p>
              The teams listed below are using this role. Please bulk reassign these individuals to
              a new role. Roles in closed reflections will not be modified.
            </p>
            <div className="role-container flex">
              <span className="role-heading">Teams:</span>
              <span className="role-content ml-6">{teamNames || 'None'}</span>
            </div>
            <div className="role-container flex">
              <span className="role-heading">Role to be deleted:</span>
              <span className="role-content ml-6">{roleToDelete}</span>
            </div>
            <div className="role-container flex">
              <span className="role-heading">Replace role with:</span>
              <span className="role-content ml-6">
                <DropdownRole
                  options={dropdownOptions}
                  open={openFlag}
                  setOpen={openDropdown}
                  selectedValue={selectedValue}
                  placeholder="Role"
                  className="dropdown-menu"
                />
              </span>
            </div>
          </div>
          <div className="modal__footer modal-row justify-end">
            <Button type="button" variant="inverse" onClick={close}>
              Go Back
            </Button>
            <Button onClick={() => onDelete(roleToAssign)} variant="primary">
              Confirm Deletion
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
