import { Navigate, Route, Routes } from 'react-router-dom';

import { Create } from './Create';
import { User } from './User';
import { Users } from './Users';

export const UserRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Users />} />
      <Route path="user/:userEmail" element={<User />} />
      <Route path="/create-admin" element={<Create />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
