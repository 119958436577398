import loginImage from '@/assets/Spark_logo_white.svg';

export const AlreadyHaveAccount = (toSignIn: () => void) => (
  <div className="auth-already-account">
    <p>
      Already have an account?
      <button type="button" onClick={toSignIn}>
        Log In
      </button>
    </p>
  </div>
);

export const TopBanner = () => (
  <div className="header-login !pl-0">
    <p className="header-login__title">
      <img className="auth-image" src={loginImage} alt="login page" />
    </p>
  </div>
);
