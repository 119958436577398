import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { AssessmentResponseType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

interface IAssessment {
  responseObj: AssessmentResponseType[];
}

export const getAssessmentTemplate = (template: string | null) => {
  return axios
    .get<AssessmentResponseType[]>(`/assessments?kind=TEMPLATE&assessment_name=${template}`)
    .then((data: AxiosResponse | IAssessment): AssessmentResponseType[] => {
      return (data as IAssessment)?.responseObj || [];
    });
};

type QueryFnType = typeof getAssessmentTemplate;

export const useAssessmentTemplate = (
  template: string | null,
  config?: QueryConfig<QueryFnType>
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    cacheTime: 0,
    queryKey: ['assessment_template', template],
    queryFn: () => getAssessmentTemplate(template),
  });
};
