// import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState, useEffect, useCallback, UIEvent, ReactNode, ChangeEvent } from 'react';

import { ReactComponent as CircularArrow } from '@/assets/icons/circularArrow.svg';
import { ReactComponent as PaperPlane } from '@/assets/icons/paperPlane.svg';
import { TextArea } from '@/components/textarea';
import { ChatMode, BotSuggestion, MessageType, ChatModel } from '@/features/chat';
import { detectNewLine } from '@/utils/string';

export interface ChatScrollerProps {
  children: ReactNode;
  data?: ChatModel[];
  chatMode: ChatMode;
  incoming?: boolean;
  isTyping?: boolean;
  isHistoryLoading?: boolean;
  isVentureverse?: boolean;
  onChatSession?: (message: string, type: MessageType, scroller?: () => void) => void;
  onIncomingMessage?: () => void;
}

export const ChatScroller = (props: ChatScrollerProps) => {
  const outerDiv = useRef<HTMLDivElement>(null);
  const innerDiv = useRef<HTMLDivElement>(null);

  const prevInnerDivHeight = useRef<any>(null);

  const [showScrollButton, setShowScrollButton] = useState(false);
  const [userMessage, setUserMessage] = useState<string>('');

  useEffect(() => {
    const outerDivHeight = outerDiv?.current?.clientHeight ?? 0;
    const innerDivHeight = innerDiv?.current?.clientHeight;
    const outerDivScrollTop = outerDiv?.current?.scrollTop;
    const scrollValue = prevInnerDivHeight.current - outerDivHeight;

    if (!prevInnerDivHeight.current || outerDivScrollTop === scrollValue || scrollValue < 0) {
      outerDiv?.current?.scrollTo({
        top: innerDivHeight! - outerDivHeight! + 10,
        left: 0,
        behavior: prevInnerDivHeight.current ? 'smooth' : 'auto',
      });
    } else {
      setShowScrollButton(true);
    }

    prevInnerDivHeight.current = innerDivHeight;
  }, [props.children]);

  const handleScrollButtonClick = useCallback(() => {
    const outerDivHeight = outerDiv?.current?.clientHeight;
    const innerDivHeight = innerDiv?.current?.clientHeight;

    outerDiv?.current?.scrollTo({
      top: innerDivHeight! - outerDivHeight! + 10,
      left: 0,
      behavior: 'smooth',
    });

    setShowScrollButton(false);
    props.onIncomingMessage && props.onIncomingMessage();
  }, []);

  const handleOuterScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollPosition =
      Math.floor(e.currentTarget.scrollHeight) - Math.floor(e.currentTarget.scrollTop) - 1;

    if (scrollPosition !== e.currentTarget.clientHeight) {
      return;
    }
    if (showScrollButton) {
      setShowScrollButton(false);
      props.onIncomingMessage && props.onIncomingMessage();
    }
  };

  const handleSendMessage = (message: string) => {
    props.onChatSession && props.onChatSession(message.trim(), 'user', handleScrollButtonClick);
    setUserMessage('');
  };

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserMessage(e.target.value);
  };

  const handleMessageKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      const { value } = e.currentTarget;
      if (value && !detectNewLine(value)) {
        props.onChatSession && props.onChatSession(value, 'user', handleScrollButtonClick);
        setUserMessage('');
      }
    }
  };

  const handleBotSuggestionClick = (message: string, type: MessageType) => {
    props.onChatSession && props.onChatSession(message, type, handleScrollButtonClick);
  };

  return (
    <div
      className={`scroll-container ${props.chatMode}-mode${
        props.isVentureverse ? ' ventureverse' : ''
      }`}
    >
      <div
        ref={outerDiv}
        className={`outer${props.data?.length === 1 ? ' full-height' : ''}`}
        onScroll={handleOuterScroll}
      >
        <div ref={innerDiv} className="inner">
          {props.children}
        </div>
      </div>
      {props.data && props.data?.length === 1 && !props.isHistoryLoading && (
        <BotSuggestion
          data={
            props.data && Array.isArray(props.data[0].suggestions) ? props.data[0].suggestions : []
          } // suggestion will only at first index!
          onBotSuggestionClick={handleBotSuggestionClick}
        />
      )}
      <div className="footer">
        <div className="relative flex flex-nowrap items-stretch">
          <TextArea
            value={userMessage}
            placeholder="Type your message here"
            // PreIcon={() => <FontAwesomeIcon icon={faPaperclip} />}
            PostIcon={() => (
              <PaperPlane
                onClick={() => {
                  handleSendMessage(userMessage);
                }}
              />
            )}
            onChange={handleMessageChange}
            onKeyDown={handleMessageKeyDown}
          />
        </div>
        {!props.isTyping && props.incoming && (
          <button
            style={{
              opacity: showScrollButton ? 1 : 0,
              pointerEvents: showScrollButton ? 'auto' : 'none',
            }}
            className="new-message"
            onClick={handleScrollButtonClick}
          >
            <span>
              New Message <CircularArrow />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatScroller;
