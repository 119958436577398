import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Link, Spinner } from '@/components/Elements';
import { Create, ListItem, Members, useStages, useTeams, ListContext } from '@/features/teams';
import { ROLES, useAuthorization } from '@/lib/authorization';
import { formatSelectData } from '@/utils/format';

import { useClients } from '../../clients/api/getClients';
import { useIndustries } from '../../clients/api/getIndustries';

export const TeamsList = () => {
  const [expand, setExpand] = useState(true);
  const [editState, setEditState] = useState(false);
  const { data: teamsData, isLoading: teamsLoading } = useTeams();
  const { checkAccess } = useAuthorization();
  const { teamID } = useParams();
  const { data: industriesData } = useIndustries();
  const { data: stagesData } = useStages();
  const { data: clientsData } = useClients();

  const clients = useMemo(
    () => formatSelectData(clientsData, 'client_name', 'client_id'),
    [clientsData]
  );

  const stages = useMemo(
    () => formatSelectData(stagesData, 'stage_name', 'stage_id'),
    [stagesData]
  );

  const industries = useMemo(
    () => formatSelectData(industriesData, 'industry_name', 'industry_id'),
    [industriesData]
  );

  const getLabel = (obj: any[], key: string): string | void => {
    const item = obj.find((item) => item.value === key);
    if (item && item.label) return item.label;
  };

  useEffect(() => {
    setExpand(true);
    setEditState(false);
  }, [teamID]);

  useEffect(() => {
    if (expand === false) setEditState(false);
  }, [expand]);

  if (teamsLoading) {
    return (
      <div className="w-full flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="team-list">
      {!teamsData ? (
        <div className="team-list container">
          <p>No teams found!</p>
        </div>
      ) : (
        teamsData.map((team) => {
          const { client_id, stage, industry, team_id, team_name } = team;
          const clientLabel = teamID || team_id ? getLabel(clients, client_id) : false;
          const stageLabel = teamID ? getLabel(stages, stage) : false;
          const industryLabel = teamID ? getLabel(industries, industry) : false;

          return (
            <div
              key={team_id}
              className={clsx(
                'team-item container',
                editState && teamID === team_id ? 'team-item__edit' : '',
                expand && teamID === team_id ? 'team-item__expand' : ''
              )}
            >
              <div className="team-item__header">
                {!editState ? (
                  <h3>
                    {team_name}
                    {clientLabel && <span> - {clientLabel}</span>}
                  </h3>
                ) : teamID === team_id ? null : (
                  <h3>{team_name}</h3>
                )}
                <div className="team-item__header-actions">
                  {checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) && (
                    <Link to={`/app/teams/manage-assessments/${team_id}`} variant="inverse">
                      Manage Reflections
                    </Link>
                  )}
                  {teamID !== team_id ? (
                    <Link
                      className="item-arrow"
                      to={`/app/teams/edit-team/${team_id}`}
                      variant="inverse"
                    ></Link>
                  ) : (
                    <button onClick={() => setExpand(!expand)} className="item-arrow"></button>
                  )}
                </div>
              </div>
              {teamID === team_id && (
                <div className={clsx(expand ? 'team-item__show' : 'hide')}>
                  <ListItem
                    key={team_id}
                    {...team}
                    industry={industryLabel ? industryLabel : ''}
                    stage={stageLabel ? stageLabel : ''}
                    client={clientLabel ? clientLabel : ''}
                    editState={editState}
                  >
                    {(onSaveEdit, memberListMethods, isUpdatingTeam) => {
                      return (
                        <>
                          <Create
                            editObj={team}
                            onSaveEdit={onSaveEdit}
                            editState={editState}
                            setEditState={setEditState}
                            isUpdatingTeam={isUpdatingTeam}
                            stagesOptions={stages}
                            clientsOptions={clients}
                          />
                          <div className="team-list__divider"></div>
                          <ListContext.Provider value={{ editState, expand, setEditState }}>
                            <Members editObj={team} {...memberListMethods} />
                          </ListContext.Provider>
                        </>
                      );
                    }}
                  </ListItem>
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};
