import { useMutation } from 'react-query';

import { TeamRoleType } from '@/features/clients';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type deleteRoleType = {
  client_id: string;
  team_role_id: string;
  new_team_role_id?: string;
};

export const deleteRole = ({ client_id, team_role_id }: deleteRoleType): Promise<TeamRoleType> => {
  return axios
    .delete(`/clients/${client_id}/team_roles/${team_role_id}`)
    .then((data: any) => data.responseObj);
};

type UseDeleteRoleOptions = {
  config?: MutationConfig<typeof deleteRole>;
};

export const useDeleteRole = ({ config }: UseDeleteRoleOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('clients');
      addNotification({
        type: 'success',
        title: 'Role successfully deleted!',
      });
    },
    ...config,
    mutationFn: deleteRole,
  });
};
