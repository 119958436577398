import { useMutation } from 'react-query';

import { TeamRoleType } from '@/features/clients';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type replaceRoleType = {
  client_id: string;
  team_role_id: string;
  new_team_role_id: string;
};

export const replaceRole = ({
  client_id,
  team_role_id,
  new_team_role_id,
}: replaceRoleType): Promise<TeamRoleType> => {
  return axios
    .put(`/clients/${client_id}/team_roles/${team_role_id}/replace`, {
      team_role_id: new_team_role_id,
    })
    .then((data: any) => data.responseObj);
};

type UseReplaceRoleOptions = {
  config?: MutationConfig<typeof replaceRole>;
};

export const useReplaceRole = ({ config }: UseReplaceRoleOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('clients');
      addNotification({
        type: 'success',
        title: 'Role successfully reassigned!',
      });
    },
    ...config,
    mutationFn: replaceRole,
  });
};
