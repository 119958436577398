import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/Elements';

interface IGridContainer {
  cards: ReactNode[];
  title: string;
  maxSizeDisplayed?: number;
  rowSize?: 4 | 5;
  collapseCards?: boolean;
  elementsContainer?: boolean;
  fadingElements?: boolean;
  fadingPagesToLoad?: number;
}

export const GridContainer = ({
  cards,
  title,
  maxSizeDisplayed,
  rowSize = 5,
  collapseCards,
  elementsContainer = true,
  fadingElements,
  fadingPagesToLoad = 3,
}: IGridContainer) => {
  const [usedSize, setUsedSize] = useState(maxSizeDisplayed);
  const [collapse, setCollapse] = useState(false);
  const [page, setPage] = useState<number>(2);

  const displayedChilds = useMemo(() => {
    const newCards = [...cards];
    return usedSize && newCards.length > usedSize ? newCards.splice(0, usedSize) : newCards;
  }, [usedSize, cards]);

  useEffect(() => {
    setPage(2);
    setUsedSize(rowSize);
    setCollapse(false);
  }, [cards, rowSize]);

  useEffect(() => {
    if (collapse) {
      setPage(2);
      setUsedSize(rowSize);
    }
  }, [collapse]);

  const elementsToFade: Map<number, ReactNode[]> = useMemo(() => {
    const map = new Map<number, ReactNode[]>();
    if (fadingElements) {
      if (cards.length > rowSize) {
        let page = 2;
        cards.slice(rowSize).forEach((card, index) => {
          if (!map.has(page)) {
            map.set(page, []); // Init all available pages
          }
          map.get(page)?.push(card);
          if ((index + 1) % rowSize === 0) {
            page++;
          }
        });
      }
    }
    return map;
  }, [fadingElements, cards, rowSize]);

  const changeSize = useCallback(() => {
    setUsedSize(
      usedSize && maxSizeDisplayed && usedSize > maxSizeDisplayed ? maxSizeDisplayed : cards.length
    );
  }, [cards.length, maxSizeDisplayed, usedSize]);

  const handleLoadMore = () => {
    const newPage = page + fadingPagesToLoad;
    setPage(newPage);
    setUsedSize(rowSize * (newPage - 1));
  };

  return (
    <div className="w-full flex flex-col mt-6 mb-6">
      <div className="flex justify-between items-baseline">
        <span className="grid-container-title mb-5 justify-start">{title}</span>
        {collapseCards && (
          <button
            className="grid-container-view-less-button"
            onClick={() => setCollapse(!collapse)}
          >
            {!collapse ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </button>
        )}
      </div>
      {!collapse && (
        <>
          <div className={`grid ${rowSize === 5 ? 'grid-cols-5' : 'grid-cols-4'} gap-4`}>
            {displayedChilds.map((child, i) => {
              if (elementsContainer) {
                return <div key={`card-${i}`}>{child}</div>;
              }
              return child;
            })}
          </div>
          {fadingElements && elementsToFade.has(page) && (
            <div
              className={`grid ${
                rowSize === 5 ? 'grid-cols-5' : 'grid-cols-4'
              } gap-4 grid-container-fade`}
            >
              {elementsToFade.get(page)?.map((child, i) => {
                if (elementsContainer) {
                  return <div key={`card-${i}`}>{child}</div>;
                }
                return child;
              })}
            </div>
          )}

          {maxSizeDisplayed && (
            <Button
              className="table-button grid-container-view-more-button w-fit self-end mt-4"
              variant="inverse"
              onClick={changeSize}
            >
              {maxSizeDisplayed < cards.length && usedSize && cards.length > usedSize
                ? 'SEE ALL +'
                : 'SEE LESS -'}
            </Button>
          )}

          {fadingElements && elementsToFade.has(page) && (
            <div className={'grid-container-load-more-container'}>
              <Button
                className="table-button grid-container-load-more-button w-fit self-end mt-4"
                variant="inverse"
                onClick={() => handleLoadMore()}
              >
                Load More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
