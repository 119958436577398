/* eslint-disable @typescript-eslint/no-unused-vars */
import { response } from 'msw';
import create from 'zustand';

import { getClients, ClientResponseType, TeamRoleType } from '@/features/clients';
import { getRoles, addRole, deleteRole, replaceRole } from '@/features/teams';

type TeamRolesStore = {
  clients: ClientResponseType[];
  selectedClient: ClientResponseType[];
  teamRoles: TeamRoleType[];
  formattedRoles: TeamRoleType[];
  getRoles: () => Promise<true | undefined>;
  getClients: () => Promise<true | undefined>;
  addRole: (role: TeamRoleType) => void;
  deleteRole: (role: TeamRoleType) => void;
  replaceRole: (role: TeamRoleType, role_to_assign: string) => void;
};

export const useTeamRolesStore = create<TeamRolesStore>((set, get) => ({
  clients: [],
  selectedClient: [],
  teamRoles: [],
  formattedRoles: [],

  getClients: async () => {
    try {
      const state = get();
      const response = await getClients();

      const client = response.filter((client) => client.client_selected)[0];

      set({
        clients: [...response],
        selectedClient: [client],
      });

      return true;
    } catch (error) {
      console.error(error);
    }
  },

  getRoles: async () => {
    try {
      await get().getClients();
      const state = get();
      const response = await getRoles({ client_id: state.selectedClient[0].client_id });

      const formattedRoles = response.map((role) => ({
        label: role.label || '',
        role_id: role.team_role_id,
        system: role.system,
      }));

      set({
        teamRoles: response,
        formattedRoles: formattedRoles,
      });

      return true;
    } catch (error) {
      console.error(error);
    }
  },

  addRole: async (role) => {
    try {
      const state = get();
      await get().getClients();

      const roleExists = state.teamRoles.some((existingRole) => existingRole.value === role.value);

      if (!roleExists) {
        const response = await addRole({
          data: role,
          client_id: state.selectedClient[0].client_id,
        });
      }

      get().getRoles();
    } catch (error) {
      console.error(error);
    }
  },
  replaceRole: async (role, role_to_assign) => {
    try {
      await get().getClients();
      const state = get();

      if (role && role.team_role_id) {
        const response = await replaceRole({
          team_role_id: role.team_role_id,
          client_id: state.selectedClient[0].client_id,
          new_team_role_id: role_to_assign,
        });
      }

      get().getRoles();
      // return response;
    } catch (error) {
      console.error(error);
    }
  },

  deleteRole: async (role) => {
    try {
      await get().getClients();
      const state = get();

      if (role && role.team_role_id) {
        const response = await deleteRole({
          team_role_id: role.team_role_id,
          client_id: state.selectedClient[0].client_id,
        });
      }

      get().getRoles();
    } catch (error) {
      console.error(error);
    }
  },
}));
