import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { ClientResponseType } from '../types';

interface IClients {
  responseObj: ClientResponseType[];
}

export const getClients = () => {
  return axios
    .get<IClients>(`/clients`)
    .then((data: AxiosResponse | IClients): ClientResponseType[] => {
      return (data as IClients)?.responseObj || [];
    });
};

type QueryFnType = typeof getClients;

type UseClientsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useClients = ({ config }: UseClientsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['clients'],
    queryFn: () => getClients(),
  });
};
