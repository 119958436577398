import { ReactNode, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { ReactComponent as SearchIcon } from '@/assets/icons/searchOutline.svg';
import { InputField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import {
  ASSESSMENT_PHASE,
  useAssessmentTakeStore,
  useSectionResultsStore,
} from '@/features/assessments';

import { ResultStatus, TakeSectionDisplay } from '../components/assessmentSectionDisplay';

enum TAKE_SECTION {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export const TakeAssessmentMain = () => {
  const sectionIndex = 'takes';
  const { notifyResults, validate, isDisplayingResults, getSection } = useSectionResultsStore();
  const inputName = 'forms_filter';
  const form = useForm({ mode: 'all' });
  const {
    formState: { errors },
    watch,
  } = form;

  const { setTakesLoaded, takesLoaded } = useAssessmentTakeStore();

  const filterInputValue = watch(inputName);

  useEffect(() => {
    setTakesLoaded(false);
  }, []);

  const showNoResults = useMemo(() => {
    const validations: { status: ResultStatus; component?: ReactNode }[] = [
      {
        status: ResultStatus.SUCCESS,
      },
      {
        status: ResultStatus.FILTER_NOT_FOUND,
        component: (
          <>
            <h3>No forms found.</h3>
            <p>Try searching by name, type, status, or team.</p>
          </>
        ),
      },
    ];
    return validate(sectionIndex, validations);
  }, [isDisplayingResults]);

  const pendingSectionMessages: ReactNode | undefined = useMemo(() => {
    if (takesLoaded) {
      const map = getSection(sectionIndex);
      const completedStatus = map?.get(TAKE_SECTION.COMPLETED);
      const pendingStatus = map?.get(TAKE_SECTION.PENDING);
      if (pendingStatus === ResultStatus.NO_RESULTS) {
        if (completedStatus === ResultStatus.SUCCESS) {
          return (
            <>
              <h3>Hooray! You have no pending forms.</h3>
              <p>Feel free to relax and check back later!</p>
            </>
          );
        }
        if (completedStatus === ResultStatus.NO_RESULTS) {
          return (
            <>
              <h3>Welcome to your GrowthCatalyst Inbox!</h3>
              <p>Forms awaiting your response will magically appear here. Stay tuned!</p>
            </>
          );
        }
      }
    }
    return undefined;
  }, [isDisplayingResults, takesLoaded]);

  return (
    <ContentLayout title="GrowthCatalyst Inbox" customClassName="!flex-col">
      <InputField
        className={'input-form-search'}
        type={'text'}
        placeholder={'Find form...'}
        defaultValue={''}
        registration={form.register(inputName, {})}
        error={errors[inputName]}
        icons={[<SearchIcon key={`search-icon`} />]}
      />
      <TakeSectionDisplay
        phases={[ASSESSMENT_PHASE.NEW, ASSESSMENT_PHASE.IN_PROGRESS]}
        title={'Pending forms'}
        filterInput={filterInputValue}
        notifyResults={notifyResults(sectionIndex, TAKE_SECTION.PENDING)}
        keepDisplay={!(showNoResults && showNoResults.status === ResultStatus.FILTER_NOT_FOUND)}
      />
      <>
        {pendingSectionMessages && (
          <div className={'no-results sub-content'}>{pendingSectionMessages}</div>
        )}
      </>
      <TakeSectionDisplay
        phases={[ASSESSMENT_PHASE.COMPLETED]}
        title={'Completed forms'}
        filterInput={filterInputValue}
        notifyResults={notifyResults(sectionIndex, TAKE_SECTION.COMPLETED)}
        completed={true}
        keepDisplay={!(showNoResults && showNoResults.status === ResultStatus.FILTER_NOT_FOUND)}
      />
      <>
        {showNoResults && showNoResults.component && (
          <div className={'no-results'}>{showNoResults.component}</div>
        )}
      </>
    </ContentLayout>
  );
};
