import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type TextAreaFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  placeholder?: string;
  size?: keyof typeof sizes;
  containerSize?: keyof typeof sizes;
  defaultValue?: string;
  maxLength?: number;
  readOnly?: boolean;
};

const baseClaseName = 'text-area';

const sizes = {
  sm: `${baseClaseName}-sm`,
  md: `${baseClaseName}-md`,
  lg: `${baseClaseName}-lg`,
};

export const TextAreaField = (props: TextAreaFieldProps) => {
  const {
    label,
    className,
    registration,
    error,
    placeholder,
    size = 'sm',
    containerSize,
    defaultValue,
    maxLength = 9000,
    readOnly = false,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <div className={clsx('text-area-container', containerSize ? sizes[containerSize] : '')}>
        <textarea
          readOnly={readOnly}
          defaultValue={defaultValue}
          placeholder={placeholder}
          maxLength={maxLength}
          className={clsx(
            sizes[size],
            'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm',
            className,
            baseClaseName
          )}
          {...registration}
        />
        <span className="error-icon"></span>
      </div>
    </FieldWrapper>
  );
};
