import create from 'zustand';

type LeavePageStoreType = {
  open: () => void;
  close: () => void;
  setBlocker: () => void;
  removeBlocker: () => any;
  setURL: (url: string) => void;
  isOpen: boolean;
  URL: string;
  isBlocker: boolean;
};

export const useLeavePageStore = create<LeavePageStoreType>((set) => ({
  isBlocker: false,
  isOpen: false,
  URL: '',
  open: () =>
    set(() => ({
      isOpen: true,
    })),
  close: () =>
    set(() => ({
      isOpen: false,
    })),
  removeBlocker: async () => {
    const returnFalse = async () => false;
    set({
      isBlocker: await returnFalse(),
    });
  },
  setBlocker: () =>
    set(() => ({
      isBlocker: true,
    })),
  setURL: (URL) =>
    set(() => ({
      URL,
    })),
}));
