/* eslint-disable @typescript-eslint/no-unused-vars */
import create from 'zustand';

const roleToValueMap: Record<string, string> = {
  'Client Administrator': '0',
  'Global Administrator': '0',
  User: '8',
};

type UsersType = {
  name: string;
  email: string;
  role: string;
  role_value?: string;
};

type BulkUsersStore = {
  users: UsersType[];
  setUsers: (users: UsersType[]) => void;
};

export const useBulkUsersStore = create<BulkUsersStore>((set) => ({
  users: [],

  setUsers: (users: UsersType[]) =>
    set((state) => {
      const usersWithRoleValue = users.map((user) => ({
        ...user,
        role_value: roleToValueMap[user.role] || undefined,
      }));
      return { users: usersWithRoleValue };
    }),
}));
