import { ReactComponent as RefreshSingleArrowIcon } from '@/assets/icons/refresh-single-arrow.svg';
import { ChatErrorAction } from '@/features/chat';

export interface ChatErrorProps {
  onChatError?: (lastAction: ChatErrorAction) => void;
}

export const ChatError = (props: ChatErrorProps) => {
  const handleChatError = () => {
    props.onChatError &&
      localStorage.getItem('chat-error-last-action') &&
      props.onChatError(localStorage.getItem('chat-error-last-action') as ChatErrorAction);
  };

  return (
    <div className="chat-error-container">
      <span role="img" aria-label="">
        Server failed us. So sad 😞
      </span>
      <RefreshSingleArrowIcon />
      <div aria-hidden className="try-again" onClick={handleChatError}>
        Try again?
      </div>
    </div>
  );
};

export default ChatError;
