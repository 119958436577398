import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { TeamResponseType } from '../types';

export type GetTeam = {
  teamID?: string;
};

export const getTeam = (teamID: string | undefined): Promise<TeamResponseType> => {
  return axios.get(`/teams/${teamID}`).then((data: any) => data.responseObj[0]);
};

type QueryFnType = typeof getTeam;

type useGetTeamOptions = {
  config?: QueryConfig<QueryFnType>;
  teamID?: string;
};

export const useTeam = ({ config, teamID }: useGetTeamOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['getTeam', teamID],
    queryFn: () => getTeam(teamID),
  });
};
