import { useMutation } from 'react-query';

import { TeamResponseType } from '@/features/teams';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type updateTeamType = {
  data: TeamResponseType;
  teamID: string;
};

export const updateTeam = ({ data, teamID }: updateTeamType): Promise<TeamResponseType> => {
  return axios.put(`/teams/${teamID}`, data).then((data: any) => data.responseObj);
};

type UseUpdateTeamOptions = {
  config?: MutationConfig<typeof updateTeam>;
};

export const useUpdateTeam = ({ config }: UseUpdateTeamOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('teams');
      addNotification({
        type: 'success',
        title: 'Team successfully updated!',
      });
    },
    ...config,
    mutationFn: updateTeam,
  });
};
