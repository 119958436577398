import { useParams } from 'react-router-dom';

import { Assessment, AssessmentList, ASSESSMENT_PHASE } from '@/features/assessments';

export const Take = ({ previewMode = false, withMembers = true }) => {
  const { assessmentId } = useParams();
  return (
    <div className="take">
      {!previewMode && (
        <div className="take__lists">
          <AssessmentList label="Assigned to me" step={ASSESSMENT_PHASE.NEW} />
          <AssessmentList label="In progress" step={ASSESSMENT_PHASE.IN_PROGRESS} />
        </div>
      )}

      {(assessmentId || previewMode) && (
        <Assessment assessmentId={assessmentId} isPreview={previewMode} withMembers={withMembers} />
      )}
      {!assessmentId && !previewMode && (
        <div className="container">
          <h1 className="take__title">
            Welcome to your GrowthCatalyst inbox!
            <span role="img" aria-label="emjoi">
              📫
            </span>
          </h1>
          <p className="take__desc bold">
            Please remember to complete your reflections before they’re due!
          </p>
        </div>
      )}
    </div>
  );
};
