/* eslint-disable @typescript-eslint/no-unused-vars */
import create from 'zustand';

import { ASSESSMENT_PHASE } from '@/features/assessments';
import { getClients, ClientResponseType } from '@/features/clients';

import { getTakeAssessments } from '../api';
import { AssessmentResponseType } from '../types';

type AssessmentsStore = {
  clients: ClientResponseType[];
  selectedClient2: ClientResponseType[];
  assessments: AssessmentResponseType[];
  getClients: () => Promise<true | undefined>;
  getAssessments: (assessment_steps?: ASSESSMENT_PHASE[]) => Promise<true | undefined>;
};

export const useAssessmentsStore = create<AssessmentsStore>((set, get) => ({
  clients: [],
  assessments: [],
  selectedClient2: [],

  getClients: async () => {
    try {
      const state = get();
      const response = await getClients();

      const client = response.filter((client) => client.client_selected)[0] || null;

      set({
        clients: [...response],
        selectedClient2: [client],
      });

      return true;
    } catch (error) {
      console.error(error);
    }
  },
  getAssessments: async (assessment_steps: ASSESSMENT_PHASE[] = []) => {
    try {
      await get().getClients();
      const state = get();
      let allAssessments: AssessmentResponseType[] = [];
      for (const step of assessment_steps) {
        const response = await getTakeAssessments(step);
        allAssessments = [...allAssessments, ...response];
      }

      set({
        assessments: allAssessments,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
  },
}));
