import { ContentLayout } from '@/components/Layout';
import { MyProfile } from '@/features/my_profile';

export const Profile = () => {
  return (
    <ContentLayout title="My Profile">
      <MyProfile />
    </ContentLayout>
  );
};
