import { useMutation } from 'react-query';

// import { useAuth } from '@/lib/auth';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type UpdateUser = {
  custom_role?: number;
  team_role?: string | number;
  custom_organization?: string;
  name?: string;
  teams?: string[];
  email: string;
  custom_client_id?: string;
};

export const updateUser = (data: UpdateUser) => {
  return axios.put(`/users/${data.email}`, data);
};

type UseUpdateUserOptions = {
  config?: MutationConfig<typeof updateUser>;
};

export const useUpdateUser = ({ config }: UseUpdateUserOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'User Updated',
      });
    },
    onError: (error) => {
      console.error('Update User Error:', error);
    },
    ...config,
    mutationFn: updateUser,
  });
};
