import { MessageType } from '@/features/chat';

export interface BotSuggestionProps {
  data?: string[];
  onBotSuggestionClick?: (message: string, type: MessageType) => void;
}

export const BotSuggestion = (props: BotSuggestionProps) => {
  return (
    <div className={`bot-suggestion`}>
      <div className="title">Suggested Replies</div>
      <ul>
        {props.data &&
          props.data.map((item, j) => (
            <li
              key={`suggestion-item-${j}`}
              className="item text-sm"
              aria-hidden="true"
              onClick={() => props.onBotSuggestionClick && props.onBotSuggestionClick(item, 'user')}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default BotSuggestion;
