import { Button, Dialog, DialogTitle } from '@/components/Elements';

type AdminCloseDialogProps = {
  isOpen: boolean;
  close: () => void;
  adminClose: boolean;
  onAdminClose: () => void;
  adminCloseLoading?: boolean;
};

export const AdminCloseDialog = ({
  isOpen,
  close,
  onAdminClose,
  adminCloseLoading,
}: AdminCloseDialogProps) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Confirm assessment completion
          </DialogTitle>
          <div className="modal__info">
            <p>
              Are you sure you would like to mark the assessment as complete before all members have
              finished?
            </p>
          </div>
          <div className="modal__footer modal-row justify-end">
            <Button type="button" variant="inverse" onClick={close}>
              Cancel
            </Button>
            <Button onClick={onAdminClose} isLoading={adminCloseLoading} variant="danger">
              Mark as complete
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
