import clsx from 'clsx';
import React from 'react';

import { ASSESSMENT_PHASE } from '@/features/assessments';

type LabelStateProps = {
  phase: ASSESSMENT_PHASE;
  adminClosed?: boolean;
  noMargin?: boolean;
};

const returnPhase = (phase: ASSESSMENT_PHASE, admin_closed: boolean | undefined) => {
  let phaseText = '';
  let phaseClass = '';

  switch (phase) {
    case ASSESSMENT_PHASE.COMPLETED:
      phaseText = 'Complete';
      phaseClass = 'closed';
      break;
    case ASSESSMENT_PHASE.UPCOMING:
      phaseText = 'Upcoming';
      phaseClass = 'upcoming';
      break;
    case ASSESSMENT_PHASE.ADMIN_CLOSED:
      phaseText = 'Admin Closed';
      phaseClass = 'admin-closed';
      break;
    case ASSESSMENT_PHASE.CLOSED:
      if (!admin_closed) {
        phaseText = 'Complete';
        phaseClass = 'closed';
      } else if (admin_closed) {
        phaseText = 'Admin Closed';
        phaseClass = 'admin-closed';
      }
      break;
    case ASSESSMENT_PHASE.IN_PROGRESS:
      phaseText = 'In Progress';
      phaseClass = 'in-progress';
      break;
    case ASSESSMENT_PHASE.PAST_DUE:
      phaseText = 'Past Due';
      phaseClass = 'past-due';
      break;
    case ASSESSMENT_PHASE.OVERDUE:
      phaseText = 'Overdue';
      phaseClass = 'over-due';
      break;
    case ASSESSMENT_PHASE.DUE_SOON:
      phaseText = 'Due Soon';
      phaseClass = 'due-soon';
      break;
    case ASSESSMENT_PHASE.NEW:
      phaseText = 'New';
      phaseClass = 'new';
      break;
    case ASSESSMENT_PHASE.UNDEFINED:
      phaseText = 'Unknown';
      phaseClass = 'unknown';
      break;
  }

  return <span className={`bold phase ${phaseClass}`}>{phaseText}</span>;
};

export const LabelState = ({ phase, adminClosed = false, noMargin = false }: LabelStateProps) => {
  return (
    <div className={clsx('label-state', `${noMargin ? 'no-margin' : ''}`)}>
      {returnPhase(phase, adminClosed)}
    </div>
  );
};
