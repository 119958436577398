import { find, forEach } from 'lodash';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { AssessmentDropdownType, TeamAssessmentType } from '@/features/assessments';
/* eslint-disable no-restricted-imports */
import { getTeamAssessments } from '@/features/assessments/api/getTeamAssessments';
import chartColors from '@/features/assessments/components/resultsHub/colors';
import { formatDateMMDDYY } from '@/utils/format';

import { getChartResults } from '../api/getChartResults';

const INITIAL_OPTIONS = [
  {
    label: 'My Self-Assessment',
    value: 'SELF_ASSESSMENT',
    is_reciever: true,
  },
  {
    label: 'My Results',
    value: 'MY_RESULTS',
    is_reciever: true,
  },
  {
    label: 'Team Self-Assessment',
    value: 'MY_TEAM_SELF_ASSESSMENT',
    is_reciever: false,
  },
  {
    label: 'Team Results',
    value: 'MY_TEAM_MEMBERS',
    is_reciever: false,
  },
  {
    label: 'Team Max',
    value: 'MY_TEAM_MAX_SCORE',
    is_reciever: false,
  },
];

const INITIAL_TEAM_DYNAMIC_OPTIONS = [
  {
    label: 'Team Results',
    value: 'MY_TEAM_AVERAGE',
    is_reciever: false,
  },
  {
    label: 'Team Self-Assessment',
    value: 'MY_TEAM_SELF_ASSESSMENT',
    is_reciever: false,
  },
];

// Define a type for the option
type Option = {
  label: string;
  value: string;

  is_reciever: boolean;
};

// Define a type for the chart data
type Chart = {
  // Add fields based on the shape of your chart data
};

type ApiResponse = {
  chart: any[];
};

// Define a type for the store
type StatHubStore = {
  charts: Chart[];
  options: Option[];

  assessmentName: string | undefined;

  isCompleted: boolean;

  assessmentDate: string | undefined;

  teamDynamicOptions: Option[];

  option1?: string;
  option2?: string;

  cType?: string;

  chartResults?: any;

  chartType: Option[];
  setCharts: (charts: Chart[]) => void;
  setOptions: (options: Option[]) => void;
  setChartType: (chartType: Option[]) => void;
  fetchChart: (
    type: string,
    option1: string,
    option2: string | undefined,
    assessmentId: string | undefined
  ) => Promise<true | undefined>;
  fetchTeamAssessmentDropdown: () => Promise<void>;
  teamAssessmentDropdown: TeamAssessmentType[] | undefined;

  teamAssessmentDropdownGroup: () => AssessmentDropdownType[];
  teamAssessmentDropdownSelectedID: string | undefined;
  setTeamAssessmentDropdownSelectedID: (assessmentId: string) => void;

  mappedChartData: any[];
  dataKeys: any[];

  categories: string[];

  colors: string[];

  colorsData: { [x: string]: string };

  chartResultsData: ApiResponse | undefined;
};

// Create the store
export const useStatHubStore = create<StatHubStore>(
  devtools(
    (set, get) => ({
      assessmentName: undefined,
      isCompleted: false,
      assessmentDate: undefined,
      charts: [],
      mappedChartData: [],
      dataKeys: [],
      categories: [],
      colors: [],
      colorsData: {},
      chartResultsData: undefined,
      options: INITIAL_OPTIONS,
      teamDynamicOptions: INITIAL_TEAM_DYNAMIC_OPTIONS,
      chartType: [],
      teamAssessmentDropdown: undefined,
      teamAssessmentDropdownSelectedID: undefined,
      setCharts: (charts) => set({ charts }),
      setOptions: (options) => set({ options }),
      setChartType: (chartType) => set({ chartType }),
      fetchChart: async (type, option1, option2, assessmentId) => {
        try {
          const response = await getChartResults(type, option1, option2, assessmentId);
          const DUP_OPTIONS = ['MY_TEAM_MEMBERS', 'MY_TEAM_SELF_ASSESSMENT'];
          const chartData = response?.chart.map((question: any) => {
            if (DUP_OPTIONS.includes(option1) && option2 && DUP_OPTIONS.includes(option2)) {
              question.data = question.data.map((data: any) => {
                if (
                  data.dials &&
                  data.dials?.length === 1 &&
                  data.dials[0]?.assigned_user_id === data.email
                ) {
                  data.name = data.name + ' (SA)';
                }
                return data;
              });
            }
            return question;
          });
          const dataKeysResult: string[] = [];
          const resultData: any[] = [];
          const colors: string[] = [];
          const colorsData: { [x: string]: string } = {};
          chartData.forEach((x: any) => {
            const category = { label: x.question_name };
            const dotsData: { [x: string]: number } = {};

            x.data.forEach((y: any, i: number) => {
              if (y?.name) {
                if (!dataKeysResult.some((k) => k === y.name)) {
                  colors.push(chartColors[i]);
                  dataKeysResult.push(y.name);
                }
                dotsData[y.name] = y.value;
                colorsData[y.name] = chartColors[i];
              }
            });
            resultData.push({ ...category, ...dotsData });
          });
          set({
            chartResultsData: response,
            assessmentName: chartData.length ? chartData[0].question_assessment_name : '',
            isCompleted: chartData[0]?.question_assessment_status === 'CLOSED',
            assessmentDate: chartData.length
              ? formatDateMMDDYY(chartData[0].question_assessment_due_date)
              : '',
            categories: chartData.map((question: any) => {
              return question.question_category;
            }),
            mappedChartData: resultData,
            dataKeys: dataKeysResult,
            colors: colors,
            colorsData: colorsData,
          });
          return true;
        } catch (error) {
          console.error(error);
        }
      },
      fetchTeamAssessmentDropdown: async () => {
        try {
          const response = await getTeamAssessments();
          set({ teamAssessmentDropdown: response });
        } catch (error) {
          console.error(error);
        }
      },
      setTeamAssessmentDropdownSelectedID: (assessmentId: string) => {
        set((state) => {
          const assessment = find(state.teamAssessmentDropdownGroup(), {
            assessment_id: assessmentId,
          });
          const options = INITIAL_OPTIONS.filter((option) => {
            return assessment?.is_reciever ? option : !option.is_reciever;
          });
          return { teamAssessmentDropdownSelectedID: assessmentId, options: options };
        });
      },
      teamAssessmentDropdownGroup: () => {
        const state = get();
        const assessments: AssessmentDropdownType[] = [];
        forEach(state.teamAssessmentDropdown, (item) => {
          forEach(item.assessments, (assess) => {
            assess.team_id = item.team_id;
            assess.team_name = item.team_name;
            assessments.push(assess);
          });
        });
        return assessments;
      },
    }),
    'StatHubStore'
  )
);
