import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ReactComponent as CircleCancel } from '@/assets/icons/circleCancel.svg';
import { ReactComponent as CircleCheck } from '@/assets/icons/circleCheck.svg';
import { LabelState } from '@/components/Elements';
import { InputField } from '@/components/Form';
import {
  ASSESSMENT_PHASE,
  AssessmentBaseType,
  useCloneAssessment,
  useRenamePublishedAssessment,
  useSaveAssessment,
} from '@/features/assessments';
import { formatDateMMMMDDYYYYHHmmA } from '@/utils/format';

import { DisplayOption, InformationProps } from './types';

interface AssessmentDisplayProps extends InformationProps {
  type?: string;
  refetchAction: () => void;
  handleOpenDeleteModal: (assessmentId: string) => void;
}

export const AssessmentDisplay = ({
  data,
  selected,
  isAssessmentLoading,
  type,
  refetchAction,
  handleOpenDeleteModal,
  navigate,
  usersMap,
  teamsMap,
}: AssessmentDisplayProps) => {
  const inputName = 'assessment_name';
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [options, setOptions] = useState<DisplayOption[]>([]);
  const form = useForm({ mode: 'all' });
  const {
    formState: { errors, isValid },
    reset,
    getValues,
    setValue,
  } = form;

  const { mutateAsync: mutateAsyncAssessment } = useSaveAssessment();
  const { mutateAsync: mutateAsyncCloneAssessment } = useCloneAssessment();
  const { mutateAsync: mutateAsyncPublishedAssessmentRename } = useRenamePublishedAssessment();

  const onSaveAssessment = useCallback(() => {
    const newName = getValues()[inputName];
    const sendData: AssessmentBaseType = {
      ...data,
      ...(newName ? { assessment_name: newName } : {}),
    };
    mutateAsyncAssessment(sendData).then((response) => {
      if (response) {
        refetchAction();
      }
    });
  }, []);

  const onCloneAssessment = useCallback(() => {
    const sendData: AssessmentBaseType = {
      ...data,
    };
    mutateAsyncCloneAssessment(sendData).then((response) => {
      if (response) {
        refetchAction();
      }
    });
  }, []);

  const onRenamePublishedAssessment = useCallback(() => {
    const newName = getValues()[inputName];
    mutateAsyncPublishedAssessmentRename({
      assessmentId: data.assessment_id,
      assessment_name: newName,
    }).then((response) => {
      if (response) {
        refetchAction();
      }
    });
  }, []);

  const handleClickCard = () => {
    if (type === 'draft') {
      navigate(`build/${data.assessment_id}`, {
        replace: true,
      });
    } else {
      navigate(`/app/teams/edit-assessment/${data.assessment_id}?phase=${data.assessment_status}`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    const options: DisplayOption[] = [];
    const invalidPhasesToRename = [
      ASSESSMENT_PHASE.COMPLETED,
      ASSESSMENT_PHASE.CLOSED,
      ASSESSMENT_PHASE.ADMIN_CLOSED,
    ];
    switch (type) {
      case 'published':
        options.push({
          title: 'Manage Form',
          onClick: () => {
            handleClickCard();
          },
        });
        if (
          !data.assessment_status ||
          !invalidPhasesToRename.includes(data.assessment_status as ASSESSMENT_PHASE)
        ) {
          options.push({
            title: 'Rename',
            onClick: () => {
              setValue(inputName, data.assessment_name);
              setShowInput(true);
            },
          });
        }
        options.push({
          title: 'Clone as Draft',
          onClick: () => {
            onCloneAssessment();
          },
        });
        break;
      case 'draft':
      default:
        options.push(
          {
            title: 'Rename',
            onClick: () => {
              setValue(inputName, data.assessment_name);
              setShowInput(true);
            },
          },
          {
            title: 'Clone',
            onClick: () => {
              onCloneAssessment();
            },
          },
          {
            title: 'Delete',
            onClick: () => {
              handleOpenDeleteModal(data.assessment_id);
            },
          },
          {
            title: 'Preview',
            onClick: () => {
              navigate(`build/${data.assessment_id}/?preview-type=${type}`, {
                replace: true,
              });
            },
          }
        );
        break;
    }
    setOptions(options);
  }, [type, data]);

  const handleIconAction = (actionType: 'cancel' | 'send') => {
    switch (actionType) {
      case 'send':
        if (isValid) {
          // Send data
          if (type === 'published') {
            onRenamePublishedAssessment();
          } else {
            onSaveAssessment();
          }
          setShowInput(false);
        }
        break;
      case 'cancel':
      default:
        setShowInput(false);
        reset();
        break;
    }
  };

  return (
    <div className={`assessment-section ${selected ? 'selected' : ''}`}>
      <button
        onClick={() => {
          if (!showInput) {
            handleClickCard();
          }
        }}
        className={`assessment-section-button`}
        disabled={isAssessmentLoading}
      >
        <div className={'assessment-section-content'}>
          {/*@TODO Then change this to assessment category*/}
          <h4 className="assessment-section-category">REFLECTION</h4>
          {!showInput ? (
            <h3 className="assessment-section-title">{data.assessment_name}</h3>
          ) : (
            <InputField
              className={'assessment-section-input'}
              type={'text'}
              defaultValue={data.assessment_name}
              registration={form.register(inputName, {
                required: 'This field is required',
              })}
              error={errors[inputName]}
              icons={[
                <CircleCheck
                  key={`check-${data.assessment_id}`}
                  onClick={() => handleIconAction('send')}
                />,
                <CircleCancel
                  key={`cancel-${data.assessment_id}`}
                  onClick={() => handleIconAction('cancel')}
                />,
              ]}
            />
          )}
        </div>
        <div className={'assessment-section-content'}>
          {type === 'published' && teamsMap.has(data.general_settings.team_id) && (
            <sub className="assessment-section-sub">
              Assigned to {teamsMap.get(data.general_settings.team_id)?.team_name}
            </sub>
          )}
          <sub className="assessment-section-sub">
            Last updated by {usersMap?.get(data.modifiedBy)?.name || data.modifiedBy}
          </sub>
          <sub className="assessment-section-sub">
            {formatDateMMMMDDYYYYHHmmA(new Date((data.modifiedOn || data.createdOn) * 1000))}
          </sub>
          {type === 'published' && data.assessment_status !== undefined && (
            <LabelState phase={data.assessment_status as ASSESSMENT_PHASE} noMargin={true} />
          )}
        </div>
      </button>
      {options && options.length > 0 && (
        <button
          onClick={() => setDisplayOptions(!displayOptions)}
          className={'assessment-section-options-button'}
        >
          ...
        </button>
      )}
      {displayOptions && (
        <ul className={'assessment-section-options-list'}>
          {options?.map((opt, index) => {
            return (
              <li key={index}>
                <button
                  onClick={() => {
                    opt.onClick();
                    setDisplayOptions(false);
                  }}
                >
                  {opt.title}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
