import clsx from 'clsx';
import { useEffect, useState, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FieldValues, UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { ASSESSMENT_PHASE, CALENDAR_TYPES } from '@/features/assessments';
const options: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};
type CaledarSettingsType = {
  label?: string;
  name: string;
  registration: Partial<UseFormRegisterReturn>;
  errorMessage?: string;
  minDate?: Date;
  value?: Date;
  setValue: UseFormSetValue<FieldValues>;
  disabled?: boolean;
};

export const getNextDay = (date: Date) => {
  return new Date(date.getTime() + 24 * 60 * 60 * 1000);
};

export const CalendarSettings = ({
  label,
  name,
  registration,
  errorMessage,
  minDate = new Date(),
  value,
  setValue,
}: CaledarSettingsType) => {
  const [date, setDate] = useState<Date>(new Date());
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const phaseParam = searchParams.get('phase') as ASSESSMENT_PHASE;
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (rootRef.current && !rootRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const onChangeCalendar = (date: Date) => {
    setDate(date);
    setOpen(false);
    setValue(`general_settings.${name}`, new Date(date.toLocaleDateString('en-US', options)), {
      shouldDirty: true,
    });
  };

  const checkDisableCalendar = (): boolean => {
    if (phaseParam === ASSESSMENT_PHASE.CLOSED) return true;
    if (
      (phaseParam === ASSESSMENT_PHASE.IN_PROGRESS || phaseParam === ASSESSMENT_PHASE.PAST_DUE) &&
      name === CALENDAR_TYPES.LAUNCH_DATE
    )
      return true;
    return false;
  };

  return (
    <div className="calendar" ref={rootRef}>
      {errorMessage?.length && <p className="error">{errorMessage}</p>}
      {label && <p className="title">{label}</p>}
      <input hidden value={new Date(date.toString()).toDateString()} {...registration} />
      <button
        className={clsx('date-prompt', open ? 'active' : '')}
        onClick={() => setOpen(!open)}
        type="button"
        disabled={checkDisableCalendar()}
      >
        {new Date(date.toString()).toLocaleDateString('en-US', options)}
      </button>
      {open && (
        <Calendar
          className="absolute z-50 custom"
          onChange={onChangeCalendar}
          value={date}
          selectRange={false}
          calendarType={'US'}
          minDate={minDate}
          tileDisabled={checkDisableCalendar}
          nextLabel=">"
          nextAriaLabel="Go to next month"
          next2Label={null}
          prevLabel="<"
          prevAriaLabel="Go to prev month"
          prev2Label={null}
        />
      )}
    </div>
  );
};
