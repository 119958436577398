import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { DeepMap, FieldError, FieldValues, UseFormReturn } from 'react-hook-form';

import { Spinner } from '@/components/Elements';
import { TextAreaField, SelectField } from '@/components/Form';
import { Category, QuestionBaseType, QUESTION_LABELS } from '@/features/assessments';
import { formatSelectData } from '@/utils/format';

type QuestionPropsType = {
  question: QuestionBaseType;
  index: number;
  indexQs: number;
  errors: DeepMap<FieldValues, FieldError>;
  hover: string | undefined;
  categories: Category[];
  categoriesLoading: boolean;
  questions: QuestionBaseType[];
  remove: (index: number) => void;
  setValue: (name: string, value: string | number, config?: { [key: string]: boolean }) => void;
  setHover: (id: string) => void;
  clearErrors: (index: string) => void;
} & UseFormReturn;

export const Question = ({
  question,
  index,
  indexQs,
  errors,
  hover,
  categories,
  categoriesLoading,
  questions,
  remove,
  register,
  watch,
  setValue,
  setHover,
  clearErrors,
}: QuestionPropsType) => {
  const { id, question_type: questionType, question_prompt, category_id, question_name } = question;
  const questionRoleKey = `sections.${index}.questions.${indexQs}.question_role`;
  const questionTypeKey = `sections.${index}.questions.${indexQs}.question_type`;
  const categoryKey = `sections.${index}.questions.${indexQs}.category_id`;
  const questionKey = `sections.${index}.questions.${indexQs}.question_name`;
  const promptKey = `sections.${index}.questions.${indexQs}.question_prompt`;
  const questionRoleWatch = watch(questionRoleKey);
  const questionTypeWatch = watch(questionTypeKey);
  const categoryWatch = watch(categoryKey);
  const questionWatch = watch(questionKey);
  const questionErrors =
    errors?.sections && errors?.sections[index] && errors?.sections[index].questions;
  const [rolesHistory] = useState<string[]>([]);

  const questionRoleMatch = (roleLabel: string | undefined, questionRole?: string[]) => {
    if (roleLabel === 'default' || roleLabel === undefined) {
      return true;
    }
    if (questionRole) {
      return (
        questionRole?.filter((role) => {
          return roleLabel.startsWith(role);
        }).length > 0
      );
    }
    return false;
  };

  const validateRolesHistoryChanged = () => {
    return rolesHistory[0] != rolesHistory[1];
  };

  // Load all existent roles that came from questions list
  const rolesFiltered: {
    selectOptions: { label: string; value: string }[];
    metaInformation: Map<string, { type: string; category: string }[]>;
  } = useMemo(() => {
    const rolesMap = new Map<string, { label: string; value: string }>();
    const metaMap = new Map<string, any[]>();
    if (questions && questions.length > 0) {
      questions.map((questionItem: QuestionBaseType) => {
        questionItem.role?.map((role: string) => {
          if (!rolesMap.has(role)) {
            rolesMap.set(role, {
              label: role,
              value: role,
            });
            metaMap.set(role, []);
          }
          metaMap.get(role)?.push({
            type: questionItem.question_type,
            category: questionItem.category_name,
          });
        });
      });
    }
    return { selectOptions: Array.from(rolesMap.values()), metaInformation: metaMap };
  }, [questions]);

  // we split the data since all the categories come together
  const categoriesByType: Category[] = useMemo(() => {
    const questionTypeSelected = questionTypeWatch ? questionTypeWatch : questionType;
    return categories && questionTypeSelected
      ? categories.filter(
          (category: Category) =>
            category.category_types?.includes(questionTypeSelected) &&
            (questionRoleWatch == 'default' ||
              questionRoleWatch == undefined ||
              rolesFiltered.metaInformation
                .get(questionRoleWatch)
                ?.find((meta) => meta.category === category.category_name))
        )
      : [];
  }, [categories, questionType, questionTypeWatch, rolesFiltered]);

  // we split the data since all the questions come together
  const questionsFiltered: QuestionBaseType[] = useMemo(() => {
    let questionsTemp: QuestionBaseType[] = [];
    if (questions && questions.length > 0) {
      if (categoriesByType && categoriesByType.length > 0) {
        const categorySelected = categoryWatch ? categoryWatch : category_id;
        questionsTemp = questions.filter(
          ({ category_id, role }: QuestionBaseType) =>
            category_id === categorySelected && questionRoleMatch(questionRoleWatch, role)
        );
      } else {
        questionsTemp = questions.filter(
          ({ question_type, role }: QuestionBaseType) =>
            question_type === questionTypeWatch && questionRoleMatch(questionRoleWatch, role)
        );
      }
    }

    return questionsTemp;
  }, [
    questions,
    categoriesByType,
    categoryWatch,
    category_id,
    questionTypeWatch,
    questionRoleWatch,
  ]);

  const typesOptionsFiltered: { label: QUESTION_LABELS; value: QUESTION_LABELS }[] = useMemo(() => {
    const defaultOptions = [
      { label: QUESTION_LABELS.MINDSET, value: QUESTION_LABELS.MINDSET },
      { label: QUESTION_LABELS.SKILL_BUILD, value: QUESTION_LABELS.SKILL_BUILD },
      { label: QUESTION_LABELS.SKILL_VALIDATE, value: QUESTION_LABELS.SKILL_VALIDATE },
      {
        label: QUESTION_LABELS.SKILL_EXEC_SPONSOR,
        value: QUESTION_LABELS.SKILL_EXEC_SPONSOR,
      },
      { label: QUESTION_LABELS.SKILL_OS_TEAM, value: QUESTION_LABELS.SKILL_OS_TEAM },
      { label: QUESTION_LABELS.TEAM_DYNAMIC, value: QUESTION_LABELS.TEAM_DYNAMIC },
      { label: QUESTION_LABELS.OPEN, value: QUESTION_LABELS.OPEN },
    ];
    if (questionRoleWatch === 'default') {
      return defaultOptions;
    }
    return defaultOptions.filter((option) => {
      return rolesFiltered.metaInformation
        .get(questionRoleWatch)
        ?.find((meta) => meta.type === option.value);
    });
  }, [questionsFiltered]);

  const questionSelected = useMemo(() => {
    return questionsFiltered.filter((question: QuestionBaseType) => {
      return questionWatch === question.question_name;
    });
  }, [questionWatch, questionsFiltered]);

  useEffect(() => {
    // Leaving preview of role select input changes - In case input is going to be hydrated from API
    if (rolesHistory.length == 2) {
      rolesHistory[0] = rolesHistory[1];
      rolesHistory[1] = questionRoleWatch;
      if (validateRolesHistoryChanged()) {
        setValue(questionTypeKey, typesOptionsFiltered[0] ? typesOptionsFiltered[0].value : '');
      }
    } else if (questionRoleWatch) {
      rolesHistory.push(questionRoleWatch);
      rolesHistory.push(questionRoleWatch);
    }
  }, [questionRoleWatch]);

  //set values for category
  useEffect(() => {
    if (category_id && questionType === questionTypeWatch) {
      setValue(categoryKey, category_id);
    } else if (categoriesByType.length === 0) {
      setValue(categoryKey, '');
    } else {
      categoriesByType[0] && setValue(categoryKey, categoriesByType[0].category_id);
    }
  }, [categoriesByType, categoryKey, category_id, questionType, questionTypeWatch, setValue]);

  //set inital value questions
  useEffect(() => {
    if (questionsFiltered.length === 0) {
      setValue(questionKey, '');
    } else {
      if (
        questionsFiltered.length > 0 &&
        (!questionsFiltered.find(({ question_name }) => question_name === questionWatch) ||
          questionWatch !== question_name ||
          validateRolesHistoryChanged())
      ) {
        setValue(questionKey, questionsFiltered[0].question_name);
      } else {
        setValue(questionKey, question_name);
      }
    }
  }, [
    categoriesByType.length,
    categoryWatch,
    category_id,
    questionKey,
    questionType,
    questionTypeWatch,
    question_name,
    questionsFiltered,
    setValue,
  ]);

  const validateQuestionPrompt = (prompt: string | undefined) => {
    if (prompt) {
      clearErrors(promptKey);
    }
  };

  //set question prompt
  useEffect(() => {
    if (questionWatch && questionsFiltered && questionsFiltered.length > 0) {
      if (questionSelected && questionSelected.length > 0) {
        if (questionWatch === question_name) {
          setValue(promptKey, question_prompt);
          validateQuestionPrompt(question_prompt);
        } else {
          setValue(promptKey, questionSelected[0].question_prompt);
          validateQuestionPrompt(questionSelected[0].question_prompt);
        }
      }
    } else {
      if (questionType === QUESTION_LABELS.OPEN) {
        setValue(promptKey, question_prompt);
        validateQuestionPrompt(question_prompt);
      } else {
        setValue(promptKey, '');
      }
    }
  }, [
    promptKey,
    questionSelected,
    questionType,
    questionWatch,
    question_name,
    question_prompt,
    questionsFiltered,
    setValue,
  ]);

  if (!id) return null;

  return (
    <Draggable key={id} draggableId={id} index={indexQs}>
      {(dragProvided) => (
        <div
          className={clsx('question', hover === id && 'question__hover')}
          ref={dragProvided.innerRef}
          {...dragProvided.dragHandleProps}
          {...dragProvided.draggableProps}
        >
          <div
            className="question__drag-icon"
            onMouseEnter={() => setHover(id)}
            onMouseLeave={() => setHover('')}
          >
            <ArrowUpIcon />
            <ArrowDownIcon />
          </div>
          <SelectField
            clearable={true}
            defaultValue={'default'}
            label="Filter questions by role"
            size="md"
            placeholder="Please select a role"
            className="question__field-category"
            options={rolesFiltered.selectOptions}
            registration={register(questionRoleKey, {})}
            error={questionErrors && questionErrors[indexQs] && questionErrors[indexQs].category_id}
          />
          <SelectField
            defaultValue={questionType}
            label="Question type"
            size="md"
            placeholder="Question type"
            className="question__field-category"
            options={typesOptionsFiltered}
            registration={register(questionTypeKey, {
              required: 'This field is required',
            })}
            error={questionErrors && questionErrors[indexQs] && questionErrors[indexQs].category_id}
          />
          {categoriesLoading ? (
            <Spinner />
          ) : (
            categoriesByType &&
            categoriesByType.length > 0 && (
              <SelectField
                label="Question category"
                defaultValue={category_id}
                size="md"
                placeholder="Question category"
                className="question__field-category"
                options={formatSelectData(categoriesByType, 'category_name', 'category_id')}
                registration={register(categoryKey, {
                  required: 'This field is required',
                })}
                error={
                  questionErrors && questionErrors[indexQs] && questionErrors[indexQs].category_id
                }
              />
            )
          )}
          {questionsFiltered && questionsFiltered.length > 0 && (
            <SelectField
              label="Question selection"
              defaultValue={question_name}
              size="md"
              placeholder="Question selection"
              className="question__field-category"
              options={formatSelectData(questionsFiltered, 'question_name', 'question_name')}
              registration={register(questionKey, {
                required: 'This field is required',
              })}
              error={
                questionErrors && questionErrors[indexQs] && questionErrors[indexQs].category_id
              }
            />
          )}
          <TextAreaField
            registration={register(promptKey, {
              required: 'This field is required',
            })}
            label="Question phrasing"
            error={
              questionErrors && questionErrors[indexQs] && questionErrors[indexQs].question_prompt
            }
            className="question__field-desc"
            defaultValue={question_prompt}
          />
          <div className="question__actions">
            <button className="bold" type="button" onClick={() => remove(indexQs)}>
              REMOVE
            </button>
          </div>
        </div>
      )}
    </Draggable>
  );
};
