import create from 'zustand';

import {
  useRenameMentorChat,
  RenameMentorChatPayloadType,
  RenameMentorChatStore,
  useChatMessagesStore,
} from '@/features/chat';

export const useRenameMentorChatStore = () => {
  const { mutateAsync, isLoading, isError } = useRenameMentorChat();
  const { setActiveSession } = useChatMessagesStore();
  return create<RenameMentorChatStore>(() => ({
    isLoading,
    isError,
    setRenameChatSession: async (payload: RenameMentorChatPayloadType) => {
      await mutateAsync({ ...payload });
      localStorage.setItem('chat-active-session-name', payload.session_name);
      setActiveSession && setActiveSession(payload.session_id, payload.session_name);
    },
  }));
};
