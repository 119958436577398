import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { UserResponseType } from '../types';

export const getUserByEmail = (email: string): Promise<UserResponseType> => {
  return axios.get(`/users/${email}`).then((data: any) => data.responseObj[0]);
};

type QueryFnType = typeof getUserByEmail;

type useUserOptions = {
  config?: QueryConfig<QueryFnType>;
  email: string;
  status?: string;
};

export const useUser = ({ config, email }: useUserOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['user', email],
    queryFn: () => getUserByEmail(email),
  });
};
