import clsx from 'clsx';

export const Slider = ({
  labelActive,
  labelColor,
  desc_2,
  quotes,
  onArrowClick,
  chartType,
  question_definition,
  question_detailed_definition,
  className,
}: any) => {
  return (
    <div
      className="slider-container"
      style={{
        fontFamily: 'Graphik',
        fontStyle: 'normal,',
        fontSize: '14px',
        lineHeight: '17px',
        letterSpacing: '-0.05em',
      }}
    >
      <div className="slider-header mb-3">
        <button
          onClick={() => onArrowClick('back')}
          style={{
            content: '',
            width: '15px',
            height: '15px',
            display: 'block',
            borderBottom: `solid 4px ${labelColor}`,
            borderLeft: `solid 4px ${labelColor}`,
            transform: 'rotate(45deg)',
            padding: '5px',
            borderRadius: 0,
          }}
        />
        <div className="slider-header__title">
          <h4 style={{ color: labelColor }}>{labelActive}</h4>
          {<p>{desc_2}</p>}
        </div>
        <button
          onClick={() => onArrowClick('next')}
          style={{
            content: '',
            width: '15px',
            height: '15px',
            display: 'block',
            borderBottom: `solid 4px ${labelColor}`,
            borderLeft: `solid 4px ${labelColor}`,
            transform: 'rotate(225deg)',
            padding: '5px',
            borderRadius: 0,
          }}
        />
      </div>
      <div className={clsx('flex gap-4', className)}>
        <div className="slider-info" style={{ width: '48%' }}>
          {quotes && (
            <>
              <h5 style={{ marginBottom: '15px' }}>Individual Comments</h5>
              <ul className="slider-list quotes">
                {quotes.individual_comments.length ? (
                  quotes.individual_comments.map(({ text }: any, index: number) => (
                    <li key={index}>
                      <p>&quot;{text}&quot;</p>
                    </li>
                  ))
                ) : (
                  <li>
                    <p>No quotes</p>
                  </li>
                )}
              </ul>
              <h5 style={{ marginBottom: '15px' }}>Team Comments</h5>
              <ul className="slider-list quotes">
                {quotes.team_comments.length ? (
                  quotes.team_comments.map(({ text }: any, index: number) => (
                    <li key={index}>
                      <p>&quot;{text}&quot;</p>
                    </li>
                  ))
                ) : (
                  <li>
                    <p>No quotes</p>
                  </li>
                )}
              </ul>
            </>
          )}
        </div>
        <div style={{ height: 'inherit', width: '1px', border: '0.5px solid #CECECE' }}></div>
        <div style={{ width: '48%' }}>
          <h5 style={{ marginBottom: '15px' }}>{chartType} Definition</h5>
          <p style={{ marginBottom: '15px' }}>{question_definition}</p>
          {question_detailed_definition && question_detailed_definition.length > 0 && (
            <>
              <h5 style={{ marginBottom: '15px' }}>{chartType} Breakdown</h5>
              <ul className="slider-list">
                {question_detailed_definition.map((definition: string, index: number) => (
                  <li key={index}>
                    <p>{definition}</p>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
