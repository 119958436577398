import { useEffect, useMemo } from 'react';

import { Button, Dialog, DialogTitle } from '@/components/Elements';
import { useMembersByTeam, useTeam } from '@/features/teams';
import { ROLES } from '@/lib/authorization';

interface IFormObject {
  [key: string]: string | boolean | number;
}

interface IPublishModal {
  isOpen: boolean;
  onClick: () => void;
  close: () => void;
  getValue: (key: string) => string | IFormObject | IFormObject[] | string[] | undefined;
  isLoadingPublishAssessment: boolean;
}

export const PublishModal = ({
  isOpen,
  onClick,
  close,
  getValue,
  isLoadingPublishAssessment,
}: IPublishModal) => {
  const teamID = getValue('general_settings.team_id') as string;

  const {
    data: teamData,
    isLoading: isLoadingTeam,
    refetch: refetchTeam,
    isRefetching: isRefetchingTeam,
  } = useTeam({ teamID, config: { enabled: false } });
  const {
    data: membersData,
    refetch: refetchMembers,
    isLoading: isLoadingMembers,
    isRefetching: isRefetchingMembers,
  } = useMembersByTeam({ teamID, config: { enabled: false } });

  const selectedTeam = useMemo(() => {
    if (Array.isArray(teamData) && teamData.length === 1) {
      return teamData[0].team_name;
    }
    return teamData?.team_name || '';
  }, [teamData]);

  useEffect(() => {
    if (teamID && isOpen) {
      if (!teamData && !isRefetchingTeam && !isLoadingTeam) refetchTeam();
      if (!membersData && !isRefetchingMembers && !isLoadingMembers) refetchMembers();
    }
  }, [
    teamID,
    teamData,
    refetchTeam,
    isOpen,
    membersData,
    refetchMembers,
    isRefetchingTeam,
    isRefetchingMembers,
    isLoadingTeam,
    isLoadingMembers,
  ]);

  const membersSelected = useMemo(() => {
    if (isOpen && membersData) {
      const selectedMembers: any = getValue('general_settings.members_with_roles') || [];
      return selectedMembers.filter((sm: any) => {
        return sm.observer || sm.reciever;
      });
    }
    return [];
  }, [getValue, isOpen, membersData]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        close();
      }}
    >
      <div className="modal overflow-hidden transform transition-all">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Ready to publish?
          </DialogTitle>
          <div className="modal__info">
            <p>
              Once you have confirmed that your reflection is ready to be published, an email will
              be sent to assigned team members and the reflection will be activated in their
              inboxes.
            </p>
          </div>
          <div className="publish-info">
            {/* <p>
              <span className="body-4 bold">Partner</span>
              <span className="body-4 bold value">Jhon Doe</span>
            </p> */}
            <span className="body-4 bold">Team Name</span>
            <span className="body-4 bold value">{selectedTeam}</span>
            <span className="body-4 bold">Asignees</span>
            <ul>
              {membersSelected.map((x: any, i: any) => (
                <li key={i}>
                  <span className="body-4 bold value">{x.name || x.email}</span>{' '}
                  <span>{ROLES[x.team_role as number]}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="builder__publish-modal-buttons">
            <Button type="button" onClick={close} variant="inverse">
              Cancel
            </Button>
            <Button
              disabled={!selectedTeam?.length && !membersSelected.length}
              onClick={onClick}
              isLoading={isLoadingPublishAssessment}
            >
              Publish reflection
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
