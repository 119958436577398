import { useEffect, useRef, useState } from 'react';

import { ReactComponent as HorizontalElipsis } from '@/assets/icons/horizontalElipsis.svg';

export interface HeaderMenuProps {
  onStartNewChat?: () => void;
  onChatMaximize?: () => void;
}

export const HeaderMenu = (props: HeaderMenuProps) => {
  const [showItems, setShowItems] = useState<boolean>(false);

  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (childrenRef.current && !childrenRef.current.contains((event?.target as Node) || null)) {
        setShowItems(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [childrenRef]);

  const handleShowItems = () => {
    setShowItems(!showItems);
  };

  const handleStartNewChat = () => {
    setShowItems(false);
    props.onStartNewChat && props.onStartNewChat();
  };

  return (
    <div className="header-menu">
      <HorizontalElipsis onClick={handleShowItems} />
      {showItems && (
        <div className="children" ref={childrenRef}>
          <div aria-hidden className="menu-item" onClick={handleStartNewChat}>
            Start a new chat
          </div>
          <div aria-hidden className="menu-item" onClick={props.onChatMaximize}>
            Chat history
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMenu;
