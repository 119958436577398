import { Button } from '@/components/Elements';
import { Form, InputField, TextAreaField } from '@/components/Form';

import { validations } from './validations';

export const FormDemo = () => {
  const onSubmit = () => {};

  return (
    <Form onSubmit={onSubmit} schema={validations}>
      {(data) => {
        const {
          register,
          formState: { errors },
        } = data;
        return (
          <>
            <InputField
              label="Full Name"
              registration={register('fullname')}
              error={errors.fullname}
            />
            <InputField label="Email" registration={register('email')} error={errors.email} />
            <InputField label="Company" registration={register('company')} error={errors.email} />
            <TextAreaField
              label="Reason for interest (optional)"
              registration={register('reason')}
              error={errors.reason}
            />
            <Button className="form-button" type="submit">
              Submit
            </Button>
          </>
        );
      }}
    </Form>
  );
};
