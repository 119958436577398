// import { Button } from '@aws-amplify/ui-react';

export const ConfirmSignInHeader = ({ userInfo }: any) => {
  return (
    <div className="auth-header">
      <h1 className="auth-title">Verify Phone Number</h1>
      <p>
        An authentication code will be sent to your phone number. Please input the code in the field
        below to verify your phone number for multi-factor authentication.
      </p>
      <div className="auth-phone-container">
        {userInfo?.challengeParam?.CODE_DELIVERY_DESTINATION && (
          <>
            <p className="auth-phone-label">Your phone number:</p>
            <p className="auth-phone-number">{userInfo.challengeParam.CODE_DELIVERY_DESTINATION}</p>
          </>
        )}
        {/* <Button
          type="button"
          variation="primary"
          onClick={() => {
            userInfo?.resendConfirmationCode();
          }}
        >
          Send authentication code
        </Button> */}
      </div>
    </div>
  );
};
