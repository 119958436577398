import clsx from 'clsx';
import { ReactNode, SVGProps } from 'react';

import { Position } from '@/types';

export interface CardProps {
  innerHeader?: ReactNode;
  outerHeader?: ReactNode;
  children?: ReactNode;
  className?: string;
  image?: {
    file?: JSX.Element;
    position?: Position;
  };
  icon?: SVGProps<SVGSVGElement>;
  actions?: ReactNode;
}

const defaultProps: CardProps = {
  image: {
    position: 'top', // as off now, only this position will be functioning
  },
};

export const Card = (props: CardProps) => {
  const newClassName = clsx(
    `card${props.outerHeader !== undefined ? ' with-outer-header' : ''}`,
    `${props.image?.file ? ' with-card-image' : ''}`,
    `${props.icon ? ' with-card-icon' : ''}`,
    props.className
  );
  return (
    <div className={newClassName}>
      {props.outerHeader && <div className="outer-header">{props.outerHeader}</div>}
      {props.innerHeader && <div className="inner-header">{props.innerHeader}</div>}
      {props.image?.file && <div className="card-image">{props.image.file}</div>}
      {props.icon ? (
        <div className="card-icon">
          {props.icon}
          {props.children}
        </div>
      ) : (
        props.children
      )}
      {props.actions && <div className="card-action">{props.actions}</div>}
    </div>
  );
};

Card.defaultProps = defaultProps;

export default Card;
