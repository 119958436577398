import { useNavigate } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { ClientDetails } from '@/features/clients';
import { useAuthorization, ROLES } from '@/lib/authorization';

import { TeamsList } from '../components/List';

export const Teams = () => {
  const navigate = useNavigate();
  const { checkAccess } = useAuthorization();

  const createNewTeam = () => {
    navigate('create-team');
  };

  return (
    <ContentLayout
      title="My Teams"
      addNavigateLabel="Create new team"
      addNavigate={
        checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.CLIENT_ADMIN] }) ? createNewTeam : undefined
      }
    >
      <div className="flex-direction-column">
        <ClientDetails />
        <TeamsList />
      </div>
    </ContentLayout>
  );
};
