import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { Dropdown, IOption } from '@/components/Elements';
import { TeamAssessmentType } from '@/features/assessments';
import { formatDateMMDDYYUnix } from '@/utils/format';

import { useStatHubStore } from '../../stores/resultsHubTeamDropdown';

type IHistoricalDropdown = {
  setHistoricalSelect: Dispatch<SetStateAction<string>>;
  historicalSelect: string;
  historicalOptions: TeamAssessmentType[] | undefined;
};

const HistoricalDropdown = ({
  setHistoricalSelect,
  historicalSelect,
  historicalOptions,
}: IHistoricalDropdown) => {
  //const { data: historicalOptions } = useTeamAssessments();
  const { setTeamAssessmentDropdownSelectedID } = useStatHubStore();

  const [openDefault, openDefaultSet] = useState(false);
  const [open, openSet] = useState(false);
  const [selectedValue, setSelectedValue] = useState<IOption | undefined>();
  const [displayName, setDisplayName] = useState('');

  const dropdownOptions: IOption[] = useMemo(() => [], []);

  const dropdownClick = useCallback(
    (id: string) => {
      setHistoricalSelect(id);
      setTeamAssessmentDropdownSelectedID(id);
      const selectedOption = dropdownOptions.filter((option) => option.id === id);
      setSelectedValue(selectedOption[0]);
    },
    [setHistoricalSelect, dropdownOptions, setTeamAssessmentDropdownSelectedID]
  );

  const setOpen = useCallback(() => {
    openSet(!open);
  }, [open]);

  const openDropdown = useCallback(() => {
    setOpen ? setOpen() : openDefaultSet(!openDefault);
  }, [openDefault, setOpen]);

  const openFlag = useMemo(() => {
    return open !== undefined ? open : openDefault;
  }, [open, openDefault]);

  useEffect(() => {
    if (historicalOptions) {
      historicalOptions.map((team) => {
        dropdownOptions.push({ label: team.team_name, disabled: true });
        team.assessments
          .sort((a, b) => b.due_date - a.due_date)
          .map((assessment) => {
            const { assessment_name: name, assessment_id: id, due_date: date } = assessment;
            const assessmentLabel = `(${formatDateMMDDYYUnix(date)}) ${name}`;
            const selectedValueLabel = `${team.team_name} ${assessmentLabel};`;
            dropdownOptions.push({
              id: id,
              label: assessmentLabel,
              selectedLabel: selectedValueLabel,
              onClick: () => {
                dropdownClick(id);
              },
            });
          });
      });
    }
  }, [dropdownClick, dropdownOptions, historicalOptions]);

  useEffect(() => {
    if (historicalOptions?.length === 1 && !historicalSelect) {
      const tempSelection = dropdownOptions[1].id || '';
      dropdownClick(tempSelection);
    }
  }, [dropdownClick, dropdownOptions, historicalOptions?.length, historicalSelect, selectedValue]);

  useEffect(() => {
    setDisplayName(
      dropdownOptions.filter((option) => option.id === historicalSelect)[0]?.selectedLabel || ''
    );
  }, [dropdownOptions, historicalSelect]);

  return (
    <Dropdown
      options={dropdownOptions}
      open={openFlag}
      setOpen={openDropdown}
      className="historical-results ml-auto"
      selectedValue={selectedValue}
      displayName={displayName}
    />
  );
};

export default HistoricalDropdown;
