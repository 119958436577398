import { ChatModel } from './chat.type';

export interface PutMentorChatPayloadType {
  session_id: string;
  message: string;
}

export type PutMentorChatResponseType = ChatModel;

export interface PutMentorChatStore {
  chatPut?: ChatModel;
  isLoading?: boolean;
  isError?: boolean;
  setPutChatSession: (props: PutMentorChatPayloadType) => void;
}

export const PUT_MENTOR_CHAT_API = 'mentor/chats/sessions/{session_id}';
