import { useAuthenticator } from '@aws-amplify/ui-react';
import clsx from 'clsx';
import { useCallback, useEffect, useState, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { ReactComponent as TrashIcon } from '@/assets/icons/trashIcon.svg';

import { IOptionRole } from './DropdownRole';

interface IDropdownRole {
  options: IOptionRole[];
  open: boolean;
  setOpen?: () => void;
  isSearchable?: boolean;
  selectedValue?: IOptionRole;
  registration?: Partial<UseFormRegisterReturn>;
  disabled?: boolean;
  handleDelete?: (option: IOptionRole) => void;
  onChange?: (option: IOptionRole) => void;
}

export const DropdownMenuRole = ({
  options = [],
  open,
  setOpen,
  selectedValue,
  disabled,
  registration,
  handleDelete,
  onChange,
}: IDropdownRole) => {
  const onClickOption = useCallback(
    (option: IOptionRole) => {
      if (setOpen) setOpen();
      if (option.onClick) option.onClick(option);
      if (onChange) {
        onChange(option);
      }
    },
    [setOpen, onChange]
  );
  const { user } = useAuthenticator((context) => [context.user]);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState<boolean>();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        if (open && setOpen) {
          setOpen();
        }
      }
    },
    [open, setOpen]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (user && user.attributes) {
      if (user?.attributes['custom:role'] === '5') {
        setIsGlobalAdmin(true);
      }
    }
  }, [user]);

  const isSelected = (option: IOptionRole) => {
    if (!selectedValue) {
      return false;
    }
    return selectedValue.label === option.label;
  };

  return (
    <div
      id="dropdown"
      ref={dropdownRef}
      className={clsx(
        'dropdown-content',
        open ? 'dropdown-show' : '',
        'dropdown-content-scrollable'
      )}
    >
      {options.map((option, i) => (
        <button
          type="button"
          onClick={() => onClickOption(option)}
          key={i}
          className={`dropdown-item${isSelected(option) ? ' selected' : ''}`}
          disabled={disabled}
          {...registration}
        >
          {option.icon && <i className="dropdown-item-img">{option.icon}</i>}
          <span className="dropdown-item-span">{option.label}</span>
          {!option.system && isGlobalAdmin && (
            <button
              className="dropdown-item-delete-button"
              onClick={() => {
                if (handleDelete) {
                  handleDelete(option);
                }
              }}
            >
              <TrashIcon color="#C80404" />
            </button>
          )}
        </button>
      ))}
    </div>
  );
};
