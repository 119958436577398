import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ReactComponent as PencilIcon } from '@/assets/icons/pencilIcon.svg';
import { Button } from '@/components/Elements';
import { SelectField } from '@/components/Form';
import { useUpdateClient } from '@/features/clients';
import { Authorization, ROLES } from '@/lib/authorization';
import { useClientStore } from '@/stores/client';
import { formatSelectData } from '@/utils/format';

import { useIndustries } from '../../clients/api/getIndustries';

export const ClientDetails = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { clients, updateClientDetails } = useClientStore();
  const { data: industriesData } = useIndustries();
  const { mutateAsync: updateClient } = useUpdateClient();

  const selectedClient = clients.filter((client) => client.client_selected);

  const [clientName, setClientName] = useState(selectedClient[0]?.client_name || '');
  const [clientDescription, setClientDescription] = useState(selectedClient[0]?.description || '');
  const [clientIndustry, setClientIndustry] = useState(selectedClient[0]?.industry_name);
  const [clientIndustryID, setClientIndustryID] = useState(selectedClient[0]?.industry_id);
  const [clientID, setClientID] = useState(selectedClient[0]?.client_id);
  const { industries } = useMemo(() => {
    const newClients = (formatSelectData(clients) as any) || [];
    const industries =
      industriesData?.map((x) => ({
        label: x.industry_name,
        value: x.industry_id,
        subIndustries: x.sub_industries,
      })) || [];
    const client = newClients?.filter((client: any) => client.client_selected)[0];

    if (client) {
      setClientName(client.client_name);
      setClientDescription(client.description);
      setClientIndustry(client.industry_name);
      setClientID(client.client_id);
    }
    return { newClients, industries };
  }, [clients, industriesData]);

  const [editMode, setEditMode] = useState(false);
  const {
    formState: { errors },
  } = useForm();

  const handleIndustryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndustry = industries.find((industry) => industry.value === event.target.value);
    setClientIndustry(selectedIndustry?.label || '');
    setClientIndustryID(event.target.value);
  };

  const handleSave = () => {
    //send Client updates to API
    updateClient({
      client_id: clientID,
      data: {
        client_id: clientID,
        client_name: clientName,
        description: clientDescription,
        industry_id: clientIndustryID,
      },
    });
    //send Client updates to Redux state as well to avoid delays in UI
    const updatedClientData = {
      client_name: clientName,
      description: clientDescription,
      industry_name: clientIndustry,
    };
    updateClientDetails(clientID, updatedClientData);

    setEditMode(false);
  };

  if (!user?.attributes) return null;

  return (
    <div className="container client-details">
      <div className="button-container">
        <Authorization allowedRoles={[ROLES.CLIENT_ADMIN, ROLES.ADMIN]}>
          {editMode ? (
            <Button className="save-button" variant="primary" size="sm" onClick={handleSave}>
              Save
            </Button>
          ) : (
            <Button
              className="edit-button"
              variant="inverse"
              size="sm"
              onClick={() => setEditMode(true)}
            >
              Edit Details
            </Button>
          )}
        </Authorization>
      </div>
      <p className="organization">Organization</p>
      <h3 className="text-xl client-name">
        {editMode ? (
          <div className="input-icon-container">
            <input
              type="text"
              className="client-name-field"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              maxLength={10000}
            />
            <PencilIcon className="input-icon" />
          </div>
        ) : (
          <b>{clientName}</b>
        )}
      </h3>
      <div className="flex mt-6">
        {editMode ? (
          <div className="description-container">
            <h6>Description</h6>
            <textarea
              className="client-description textarea editmode"
              value={clientDescription}
              onChange={(e) => setClientDescription(e.target.value)}
              maxLength={10000}
            />
          </div>
        ) : (
          <div className="client-description">
            <h6>Description</h6>
            <p className="mt-6 body-4">{clientDescription}</p>
          </div>
        )}
        <div className="flex ml-6">
          <h6 className="mr-2 industry-heading">Client Industry</h6>
          {editMode ? (
            <SelectField
              label=""
              className="select-field"
              horizontal={true}
              size="md"
              placeholder="Select industry"
              error={errors.industry}
              options={industries || []}
              value={clientIndustryID || ''}
              onChange={handleIndustryChange}
            />
          ) : (
            <h6 className="client-industry">{clientIndustry}</h6>
          )}
        </div>
      </div>
    </div>
  );
};
