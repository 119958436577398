import { Link, Button, Dialog, DialogTitle } from '@/components/Elements';

type DialogMFAProps = {
  isOpen: boolean;
  show: (show: boolean) => void;
};

export const DialogMFA = ({ isOpen, show }: DialogMFAProps) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        show(false);
      }}
    >
      <div className="modal overflow-hidden transform transition-all dialog-mfa">
        <div className="modal-container">
          <DialogTitle as="h3" className="modal__title">
            Verify Multi-Factor Authentication
          </DialogTitle>
          <div className="modal__info">
            <p>
              To confirm the selected change in multi-factor authentication, you will need to verify
              your new method of authentication with a verification code.
            </p>
          </div>
          <div className="modal__footer modal-row">
            <Button type="button" variant="inverse" onClick={() => show(false)}>
              Go back
            </Button>
            <Link to="mfa-validation" variant="primary">
              Continue
            </Link>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
