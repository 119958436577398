import { ContentLayout } from '@/components/Layout';
import { ManageAssessment } from '@/features/teams';

export const Manage = () => {
  return (
    <ContentLayout title="Manage Reflections" back={'teams'}>
      <ManageAssessment />
    </ContentLayout>
  );
};
