import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { TextAreaField } from '@/components/Form';
import {
  ASSESSMENT_TYPES,
  QuestionResponseType,
  SectionResponseType,
  SectionBaseType,
  useSubmitAssessment,
  usePutAssessmentAnswers,
} from '@/features/assessments';
import { useUser } from '@/features/users';
import { useLeavePageStore } from '@/stores/leavePage';

import { Question } from './Question';
import { QuestionnaireActions } from './QuestionnaireActions';
import { Section } from './Section';

type QuestionnaireType = {
  sections: SectionResponseType[];
  step: number;
  assessmentId: string;
  assessment_type: string;
  onChangeStep: (step: number) => void;
  withMembers: boolean;
  preview: boolean;
};

export const Questionnaire = ({
  sections,
  step,
  onChangeStep,
  assessmentId,
  assessment_type,
  preview,
  withMembers,
}: QuestionnaireType) => {
  const { setBlocker, removeBlocker } = useLeavePageStore();
  const { user } = useAuthenticator((context) => [context.user]);
  const { data: userData } = useUser({
    email: user.attributes?.email as string,
    config: { enabled: !!user.attributes?.email },
  });

  const isBaseline = useMemo(() => {
    if (userData) {
      const { custom_baseline_assessment } = userData;

      if (custom_baseline_assessment?.length && custom_baseline_assessment === assessmentId) {
        return true;
      }
    }
    return false;
  }, [assessmentId, userData]);

  const totalSections = sections.length - 1;
  const navigate = useNavigate();
  const { mutateAsync: saveDraft, isLoading: isLoadingDraft } = usePutAssessmentAnswers();
  const {
    isLoading,
    isSuccess: isSuccessSubmit,
    mutateAsync: assessmentSubmit,
  } = useSubmitAssessment();

  const form = useForm({
    mode: 'all',
  });

  const {
    handleSubmit,
    register,
    getValues,
    formState: { isDirty },
    reset: resetForm,
  } = form;

  useEffect(() => {
    resetForm({ sections });
  }, [resetForm, sections]);

  useEffect(() => {
    if (isSuccessSubmit) {
      removeBlocker().then(() => {
        navigate('/app/assessment/take');
      });
    }
  }, [isSuccessSubmit, navigate, removeBlocker]);

  useEffect(() => {
    if (isDirty) setBlocker();
  }, [isDirty, setBlocker]);

  const onSave = useCallback(() => {
    const data = getValues() as SectionBaseType[];
    if (!preview) {
      saveDraft({ data, assessmentId });
    }
    removeBlocker();
  }, [assessmentId, getValues, removeBlocker, saveDraft, preview]);

  const handleBlur = () => {
    onSave();
  };

  const onSubmit = useCallback(
    (data: SectionBaseType[]) => {
      assessmentSubmit({ data, assessmentId });
    },
    [assessmentId, assessmentSubmit]
  );

  return (
    <div className="questionnaire">
      <form onSubmit={handleSubmit((data: SectionBaseType[]) => onSubmit(data))}>
        {sections.map((section: SectionResponseType, index: number) => {
          return (
            <Section
              key={`${index}-${section.section_name}`}
              index={index}
              step={step}
              {...section}
            >
              {section.questions &&
                section.questions.map((question: QuestionResponseType, qsIndex: number) => {
                  const { assessment_question_id } = question;
                  return (
                    <Question
                      readOnly={preview}
                      withMembers={withMembers}
                      sectionIndex={index}
                      qsIndex={qsIndex}
                      key={assessment_question_id}
                      isBaseline={isBaseline}
                      handleBlur={handleBlur}
                      {...form}
                      {...question}
                    >
                      <input
                        hidden
                        value={assessment_question_id}
                        {...register(
                          `sections.${index}.questions.${qsIndex}.assessment_question_id`
                        )}
                      />
                      {!isBaseline && assessment_type !== ASSESSMENT_TYPES.INDIVIDUAL && (
                        <TextAreaField
                          readOnly={preview}
                          label={'Team Comments (optional)'}
                          placeholder="Leave comments for the entire team"
                          registration={register(
                            `sections.${index}.questions.${qsIndex}.team_comments`
                          )}
                        />
                      )}
                    </Question>
                  );
                })}
            </Section>
          );
        })}
        <QuestionnaireActions
          preview={preview}
          assessmentId={assessmentId}
          step={step}
          totalSections={totalSections}
          isLoading={isLoading}
          isLoadingUpdate={isLoadingDraft}
          onChangeStep={onChangeStep}
          onSave={onSave}
          onSubmit={onSubmit}
          sections={sections}
          {...form}
        />
      </form>
    </div>
  );
};
