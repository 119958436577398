import { StepProgressBar } from '@/components/Elements';
import { formatDateFull } from '@/utils/format';

type AssessmentHeaderPropsType = {
  sectionsLength: number;
  step: number;
  assessment_name: string;
  dueDate: string;
};

export const AssessmentHeader = ({
  sectionsLength,
  step,
  assessment_name,
  dueDate,
}: AssessmentHeaderPropsType) => {
  return (
    <div className="assessment-header">
      <div className="assessment-header__info">
        <div>
          <p className="bold assessment-header__name">{assessment_name}</p>
          <p className="assessment-header__date">
            <span className="bold">Due Date:</span>
            {formatDateFull(dueDate)}
          </p>
        </div>
      </div>
      <StepProgressBar stepsTotal={sectionsLength} selectedTotal={step + 1} />
    </div>
  );
};
