import create from 'zustand';

import { ASSESSMENT_PHASE, AssessmentResponseType, getTakeAssessments } from '..';

type AssessmentTakeStore = {
  selected: AssessmentResponseType | undefined;
  takes: AssessmentResponseType[];
  isLoading: boolean;
  takesLoaded: boolean;
  setSelected: (assessment: AssessmentResponseType | undefined) => void;
  getTakes: () => Promise<void>;
  filterTakes: (phases: ASSESSMENT_PHASE[]) => AssessmentResponseType[];
  setTakesLoaded: (value: boolean) => void;
};

export const useAssessmentTakeStore = create<AssessmentTakeStore>((set, get) => ({
  selected: undefined,
  takes: [],
  isLoading: false,
  takesLoaded: false,
  setSelected: (assessment: AssessmentResponseType | undefined) => {
    set({
      selected: assessment,
    });
    return true;
  },
  getTakes: async () => {
    set({
      isLoading: true,
    });
    const takes = await getTakeAssessments(undefined, 'general_settings.due_date');
    set({
      takes: takes,
      isLoading: false,
      takesLoaded: true,
    });
  },
  filterTakes: (phases: ASSESSMENT_PHASE[]) => {
    return get().takes.filter((take) =>
      phases.includes(take.assessment_take_user_phase as ASSESSMENT_PHASE)
    );
  },
  setTakesLoaded: (value: boolean) => {
    set({
      takesLoaded: value,
    });
  },
}));
