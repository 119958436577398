import { Dispatch, SetStateAction } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { Button } from '@/components/Elements';
import { SelectField } from '@/components/Form';
import { TeamAssessmentType } from '@/features/assessments';

import { useStatHubStore } from '../../stores/resultsHubTeamDropdown';

import HistoricalDropdown from './HistoricalDropdown';

type NavInterface = {
  chartTypes: { onclick: () => void; label: string; selected: boolean }[];
  chartTypeSelected: string;
  register: UseFormRegister<{
    main: string;
    second: string;
    teamMain: string;
    teamSecond: string;
  }>;
  disabled?: boolean;
  mainSelect: string;
  teamMainSelect: string;
  setHistoricalSelect: Dispatch<SetStateAction<string>>;
  historicalSelect: string;
  historicalOptions: TeamAssessmentType[] | undefined;
};

const Nav = ({
  chartTypes,
  chartTypeSelected,
  register,
  disabled = false,
  mainSelect,
  teamMainSelect,
  setHistoricalSelect,
  historicalSelect,
  historicalOptions,
}: NavInterface) => {
  const { options, teamDynamicOptions } = useStatHubStore();
  const noComparisonOption = [{ label: 'Choose a Comparison', value: 'NO_COMPARISON' }];
  const chartOptions = chartTypeSelected === 'Team Dynamics' ? teamDynamicOptions : options;

  const register1 = chartTypeSelected === 'Team Dynamics' ? register('teamMain') : register('main');
  const register2 =
    chartTypeSelected === 'Team Dynamics' ? register('teamSecond') : register('second');

  const selectedMain = chartTypeSelected === 'Team Dynamics' ? teamMainSelect : mainSelect;

  const filteredOptions = chartOptions.filter((n) => n.value !== selectedMain);

  return (
    <div className="flex flex-col">
      <div className="flex gap-4">
        {chartTypes.map((x) => (
          <Button
            key={x.label}
            disabled={disabled}
            onClick={x.onclick}
            variant={x.selected ? 'primary' : 'inverse'}
            style={{ whiteSpace: 'pre' }}
          >
            {x.label}
          </Button>
        ))}
      </div>
      <div className="team-list__divider" style={{ marginTop: '15px', marginBottom: '15px' }} />
      <div className="flex justify-between">
        <div className="flex gap-4 w-1/2 items-center">
          <SelectField
            size="md"
            options={chartOptions}
            registration={register1}
            disabled={disabled}
          />
          <span style={{ marginBottom: '15px' }}>versus</span>
          <SelectField
            defaultValue="none"
            size="md"
            options={[...noComparisonOption, ...filteredOptions]}
            registration={register2}
            disabled={disabled}
          />
        </div>
        <div className="flex w-1/2">
          <HistoricalDropdown
            setHistoricalSelect={setHistoricalSelect}
            historicalSelect={historicalSelect}
            historicalOptions={historicalOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default Nav;
