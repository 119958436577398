import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { Button, Dialog, DialogTitle } from '@/components/Elements/';
import {
  AlreadyHaveAccount,
  SignInHeader,
  SignInFooter,
  ResetPasswordHeader,
  ConfirmSignInHeader,
  formFields,
  ForceNewPasswordView,
  ResetPasswordFooter,
  TopBanner,
} from '@/features/auth';
import { useDisclosure } from '@/hooks/useDisclosure';
import { AppContext } from '@/providers/app.provider';

const AccountSuspendedModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  return (
    <Dialog isOpen={isOpen} onClose={close}>
      <div className={clsx('modal overflow-hidden transform transition-all')}>
        <div className="modal-container">
          <div className="">
            <DialogTitle as="h3" className="modal__title">
              Account temporarily suspended
            </DialogTitle>

            <div className="modal__info">
              <div className="inline-block">
                You have exceeded the number of attempts to log into your account. Details to reset
                your password and unlock your account has been sent to your email.
              </div>
            </div>
          </div>
        </div>
        <div className="modal__btn">
          <Button type="button" className="back-btn" variant="primary" onClick={close}>
            Got it
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export function Login() {
  const navigate = useNavigate();
  const [authState, setAuthState] = useState<string>();
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const stateLocation: any = location.state;
  const from = stateLocation?.from?.pathname || '/';
  const { close, open, isOpen } = useDisclosure();
  const { loginInfo, setLoginInfo, generalErrors } = useContext(AppContext);

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <div className={`!mb-4 auth-content ${authState}`}>
      <TopBanner />
      <AccountSuspendedModal isOpen={isOpen} close={close} />
      <div className="auth-form-container">
        <Authenticator
          formFields={formFields}
          components={{
            SignIn: {
              Header() {
                useEffect(() => {
                  setAuthState('auth-sign-in');
                });
                return <SignInHeader errors={generalErrors} />;
              },
              Footer() {
                const { toSignUp, toResetPassword } = useAuthenticator();
                return <SignInFooter toSignUp={toSignUp} toResetPassword={toResetPassword} />;
              },
            },
            ConfirmSignIn: {
              Header() {
                useEffect(() => {
                  setAuthState('auth-confirm-sign-in');
                });
                return <ConfirmSignInHeader userInfo={loginInfo} />;
              },
            },
            ForceNewPassword: {
              FormFields() {
                const { isPending, validationErrors, toSignIn } = useAuthenticator();

                useEffect(() => {
                  setAuthState('auth-force-new-password');
                });
                return (
                  <ForceNewPasswordView
                    isPending={isPending}
                    errorsObj={validationErrors}
                    userInfo={loginInfo}
                    AlreadyHaveAccount={AlreadyHaveAccount(toSignIn)}
                  />
                );
              },
            },
            ResetPassword: {
              Header() {
                useEffect(() => {
                  setAuthState('auth-reset-password');
                });
                return <ResetPasswordHeader />;
              },
              Footer() {
                const { toSignIn } = useAuthenticator();
                return <ResetPasswordFooter>{AlreadyHaveAccount(toSignIn)}</ResetPasswordFooter>;
              },
            },
          }}
          services={{
            async handleSignIn({ username, password }) {
              const user = new Promise((resolve: any, reject: any) => {
                password = password.replace(/\s/g, '');
                username = username.replace(/\s/g, '');
                Auth.signIn(username, password)
                  .then((user) => {
                    setLoginInfo && setLoginInfo(user);
                    resolve(user);
                  })
                  .catch((error: any) => {
                    if (error.message === 'User is disabled.') open();
                    reject(error);
                  });
              });

              return user;
            },

            async handleConfirmSignIn({ user, code, mfaType }: any) {
              return await Auth.confirmSignIn(user, code, mfaType);
            },
          }}
        ></Authenticator>
      </div>
    </div>
  );
}
