import { useState } from 'react';

type chartInfoType = {
  title: string;
  text?: string;
  bullets?: string[];
  list?: any;
};

export type chartDataType = {
  name: string;
  value: number;
  score?: string;
  info: chartInfoType[];
};

export type chartType = {
  question_name: string;
  question_definition: string;
  question_detailed_definition: string;
  desc?: string;
  desc_2?: string;
  data: chartDataType[];
  quotes: {
    individual_comments: { [key: string]: any }[];
    team_comments: { [key: string]: any }[];
  };
};

type useSliderReturnType = {
  setLabel: (direction: 'next' | 'back') => void;
  setChartActive: (chart: string) => void;
  setLabelActive: (label: string) => void;
  labelActive: string;
  chartActive: string;
  dataFiltered: {
    question_name: string;
    question_definition: string;
    question_detailed_definition: string;
    desc_2?: string;
  } & chartDataType;
};

export const useSlider = (chart: chartType[]): useSliderReturnType => {
  const [labelActive, setLabelActive] = useState<string>('');
  const [chartActive, setChartActive] = useState<string>('');
  const {
    question_name,
    desc_2,
    data,
    question_definition,
    question_detailed_definition,
    quotes = { individual_comments: [], team_comments: [] },
  } = chart?.filter(({ question_name }: chartType) => question_name === labelActive)[0] || {};
  const filteredByName = data?.filter((x: chartDataType) => x?.name === chartActive)[0];

  const setLabel = (direction: 'next' | 'back') => {
    chart.map(({ question_name }: chartType, index: number) => {
      if (question_name === labelActive) {
        if (direction === 'next') {
          if (!chart[index + 1]) {
            setLabelActive(chart[0].question_name);
            return false;
          }

          setLabelActive(chart[index + 1].question_name);
          return false;
        } else {
          if (index - 1 < 0) {
            setLabelActive(chart[chart.length - 1].question_name);
            return false;
          }

          setLabelActive(chart[index - 1].question_name);
          return false;
        }
      }
    });
  };

  const dataFiltered = {
    question_name,
    question_definition,
    desc_2,
    question_detailed_definition,
    ...(filteredByName || { name: labelActive, value: 0, score: 'No scored', info: [] }),
    quotes,
    ...(filteredByName && {
      ...filteredByName,
      ...{ info: filteredByName?.info.map((info) => ({ ...info, bullets: info?.bullets || [] })) },
    }),
  };

  return { setLabel, setChartActive, setLabelActive, dataFiltered, labelActive, chartActive };
};
