import create from 'zustand';

import {
  useUserLikeChat,
  UserLikeChatPayloadType,
  UserLikeChatStore,
  useUserUnlikeChat,
  UserUnlikeChatPayloadType,
  UserUnlikeChatStore,
} from '@/features/chat';

export const useUserLikeChatStore = () => {
  const { mutateAsync, isLoading, isError, data } = useUserLikeChat();

  // delete message property, since that will be handle on client side.
  delete data?.responseObj.message;

  return create<UserLikeChatStore>(() => ({
    chatUserLike: data?.responseObj,
    isLoading,
    isError,
    setUserLikeChatSession: async (payload: UserLikeChatPayloadType) => {
      await mutateAsync({ ...payload });
    },
  }));
};

export const useUserUnlikeChatStore = () => {
  const { mutateAsync, isLoading, isError, data } = useUserUnlikeChat();

  // delete message property, since that will be handle on client side.
  delete data?.responseObj.message;

  return create<UserUnlikeChatStore>(() => ({
    chatUserUnlike: data?.responseObj,
    isLoading,
    isError,
    setUserUnlikeChatSession: async (payload: UserUnlikeChatPayloadType) => {
      await mutateAsync({ ...payload });
    },
  }));
};
