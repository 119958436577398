import { useMutation } from 'react-query';

import { SectionBaseType, AssessmentResponseType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

type PostAnswers = {
  data: SectionBaseType[];
  assessmentId: string;
};

export const postAnswers = ({
  data,
  assessmentId,
}: PostAnswers): Promise<{ responseObj: AssessmentResponseType[] }> => {
  return axios.post(`assessments/take/${assessmentId}/complete`, data);
};

type UsePostAnswersOptions = {
  config?: MutationConfig<typeof postAnswers>;
};

export const useSubmitAssessment = ({ config }: UsePostAnswersOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onError: (_, __, context: any) => {
      console.log('context', context);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('takeAssessments');
      addNotification({
        type: 'success',
        title: 'Reflection successfully submited!',
      });
    },
    ...config,
    mutationFn: postAnswers,
  });
};
