import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { AssessmentBaseType, AssessmentResponseType } from '@/features/assessments';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { formatDateIsoString } from '@/utils/format';

interface ICloneAssessment {
  responseObj: AssessmentResponseType;
}

const cloneAssessment = (data: AssessmentBaseType): Promise<AssessmentResponseType> => {
  return axios
    .post<AssessmentResponseType>(`assessments/${data.assessment_id}/copy`, {
      assessment_name: `${data.assessment_name} ${formatDateIsoString(new Date())}`,
    })
    .then((data: AxiosResponse | ICloneAssessment): AssessmentResponseType => {
      return (data as ICloneAssessment)?.responseObj;
    });
};

type UseCloneAssessmentOptions = {
  config?: MutationConfig<typeof cloneAssessment>;
};

export const useCloneAssessment = ({ config }: UseCloneAssessmentOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onError: (_, __, context: any) => {
      addNotification({
        type: 'error',
        title: context,
      });
    },
    onSuccess: () => {
      const title = `Assessment successfully cloned!`;
      queryClient.invalidateQueries('assessments');
      addNotification({
        type: 'success',
        title: title,
      });
    },
    ...config,
    mutationFn: cloneAssessment,
  });
};
