import { useMutation } from 'react-query';

import {
  GET_MENTOR_CHAT_API,
  GetMentorChatPayloadType,
  GetMentorChatResponseType,
} from '@/features/chat';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
// import { useNotificationStore } from '@/stores/notifications';

export const getMentorChat = ({
  session_id,
}: GetMentorChatPayloadType): Promise<{ responseObj: GetMentorChatResponseType[] }> => {
  return axios.get(GET_MENTOR_CHAT_API.replace('{session_id}', session_id ?? ''));
};

interface GetMentorChatOptions {
  config?: MutationConfig<typeof getMentorChat>;
}

export const useGetMentorChat = ({ config }: GetMentorChatOptions = {}) => {
  // const { addNotification } = useNotificationStore();
  return useMutation({
    ...config,
    mutationFn: getMentorChat,
    onError: () => {
      localStorage.setItem('chat-error-last-action', 'get');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('getMentorChat');
      // store session id to indicate an active session chat. This might be useful to detect that a chat is active or not. only 1 active chat session allowed.

      // addNotification({
      //   type: 'success',
      //   title: 'Chat session successfully initiated!',
      // });
    },
  });
};
