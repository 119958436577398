import { ConfirmationDialog, Button, BackArrow } from '@/components/Elements';
import { IBackArrow } from '@/components/Elements/BackArrow';

interface ITermsConditions {
  onAcceptTerms?: (accept: boolean, open: boolean) => void;
  bArrowProps?: IBackArrow;
}

export const TermsConditions = ({ onAcceptTerms, bArrowProps }: ITermsConditions) => {
  return (
    <div className="terms-container">
      {bArrowProps && <BackArrow {...bArrowProps} />}
      <h1>Terms and Conditions</h1>
      <div className="terms-content">
        <h6>Last updated March 7, 2022</h6>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          nulla pariatur.
        </p>
        <h6>Governing Law</h6>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        <h6>Using our Services</h6>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
          voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </p>
        <h6>Last updated March 7, 2022</h6>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          nulla pariatur.
        </p>
        <h6>Governing Law</h6>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        <h6>Using our Services</h6>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
          voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </p>
      </div>
      <div className="terms-actions">
        {onAcceptTerms && (
          <>
            <ConfirmationDialog
              title="Are you sure?"
              body="By declining the terms and conditions, you will be unable to register or view the GrowthCatalyst application."
              cancelButtonText="Go back"
              triggerButton={
                <Button variant="inverse" type="button">
                  Decline
                </Button>
              }
              confirmButton={
                <Button
                  type="button"
                  onClick={() => {
                    onAcceptTerms(false, false);
                  }}
                >
                  I understand, continue
                </Button>
              }
            />
            <Button
              type="button"
              onClick={() => {
                onAcceptTerms(true, false);
              }}
            >
              Accept
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
