import alert from '@/assets/icons/alert.svg';
import { ReactComponent as ArrowRightIcon } from '@/assets/icons/arrow-right-icon.svg';
import mission from '@/assets/ventureverse/mission.svg';
import rocket from '@/assets/ventureverse/rocket-gray.svg';
import { Button } from '@/components/Elements';

export interface HowToPlayScreenProps {
  onClick: () => void;
}

const HowToPlayScreen = (props: HowToPlayScreenProps) => {
  return (
    <div className="vv-bg-how-to-play">
      <div className="vv-container-2 how-to-play-content">
        <div className="flex gap-4">
          <div>
            <p className="title-1 mb-4">How to Play</p>
            <p className="text-1 mb-4">
              VentureVerse is the ultimate entrepreneurship simulation that challenges you to master
              the art of experimentation in order to reach startup glory: product-market fit.
            </p>
            <p className="text-1">You&apos;re starting with $10,000 and 12 weeks.</p>
          </div>
          <img src={rocket} alt="rocket" className="vv-img-rocket" />
        </div>
        <div className="vv-separator"></div>
        <div className="flex gap-8 mb-3">
          <img src={mission} alt="mission" className="vv-img-mission" />
          <div>
            <p className="title-2 mr-3 mb-4 ml-3">Your Mission</p>
            <p className="text-2 mr-3 mb-4 ml-3">
              Validate or invalidate hypotheses about unmet customer needs and potential solutions.
            </p>
            <div className="marked-text">
              <img src={alert} alt="alert" />
              <p>
                Remember, time and money are your critical constraints, and your best moves are
                those that quickly and cost-effectively gather evidence of demand for your solution.
              </p>
            </div>
          </div>
        </div>
        <div className="lets-play-button">
          <Button
            type="button"
            variant="primary"
            endIcon={<ArrowRightIcon />}
            onClick={props.onClick}
          >
            {`Let's Play!`}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HowToPlayScreen;
