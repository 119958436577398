import { ReactNode } from 'react';

import { TooltipPosition } from './tooltip.type';

export interface TooltipProps {
  text?: string;
  children?: ReactNode;
  position?: TooltipPosition;
}

const defaultProps: TooltipProps = {
  position: 'top-left',
};

export const Tooltip = (props: TooltipProps) => {
  return (
    <div className="tooltip-container">
      <span className={`text-${props.position}`}>{props.text}</span>
      {props.children}
    </div>
  );
};

Tooltip.defaultProps = defaultProps;

export default Tooltip;
