import clsx from 'clsx';
import { groupBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import { Spinner, Button } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import {
  usePublishAssessment,
  GeneralSettings,
  useEditAssessment,
  useDeleteAssessment,
  useAssessmentTakeStore,
  Take,
  AssessmentResponseType,
  SectionResponseType,
  GeneralSettingsType,
} from '@/features/assessments';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useLeavePageStore } from '@/stores/leavePage';
import { useNotificationStore } from '@/stores/notifications';

import { DeleteModal } from '../../assessments/components/builder/DeleteModal';

export const EditAssessment = () => {
  const { setBlocker, removeBlocker } = useLeavePageStore();
  const navigate = useNavigate();
  const { mutateAsync: deleteAssessment, isLoading: loadingDelete } = useDeleteAssessment();
  const { mutateAsync: publishAssessment, isLoading } = usePublishAssessment();
  const { assessmentId } = useParams();
  const { close: closeDelete, isOpen: isOpenDelete, open: openDelete } = useDisclosure();
  const { handleSubmit: handleDelete } = useForm();
  const form = useForm({ mode: 'all' });
  const { reset, getValues } = form;
  const { data: assessmentData, isLoading: loadingAssessment } = useEditAssessment(assessmentId);
  const { addNotification } = useNotificationStore();
  const { setSelected } = useAssessmentTakeStore();
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (assessmentData) {
      reset(assessmentData);
    }
  }, [assessmentData, reset]);

  useEffect(() => {
    if (form.formState.isDirty) {
      setBlocker();
    } else {
      removeBlocker();
    }
  }, [form.formState.isDirty, removeBlocker, setBlocker]);

  const onEditAssessment = useCallback(
    (data) => {
      let newMembers: string[] = [];
      if (assessmentData) {
        if (data?.general_settings?.members?.length > 0) {
          newMembers = [
            ...data.general_settings.members,
            ...assessmentData.general_settings.members,
          ];
        } else {
          newMembers = [...assessmentData.general_settings.members];
        }

        const uniqueChars = newMembers.filter((c: string, index: number) => {
          return newMembers.indexOf(c) === index;
        });

        data.general_settings.members = uniqueChars;

        delete data.observers;
        delete data.recievers;

        publishAssessment(data).then((data) => {
          form.reset(data.general_settings);
          navigate(-1);

          addNotification({
            type: 'success',
            title: 'Changes successfully saved!',
          });
        });
      }
    },
    [addNotification, assessmentData, form, navigate, publishAssessment]
  );
  const onDeleteAssessment = useCallback(() => {
    if (!isOpenDelete) openDelete();
    else {
      removeBlocker();
      if (assessmentId)
        deleteAssessment(assessmentId).then(() => {
          navigate('../..', { replace: true });
          closeDelete();
        });
    }
  }, [
    assessmentId,
    closeDelete,
    deleteAssessment,
    isOpenDelete,
    navigate,
    openDelete,
    removeBlocker,
  ]);

  const loadPreview = useCallback(() => {
    const assessment_t = { ...assessmentData } as AssessmentResponseType;
    const sectionsTemp = groupBy(assessment_t.questions, ({ section_name }) => section_name);
    const values = getValues();
    const response: { name: string; email: string; response: string }[] = [];
    (values?.general_settings as GeneralSettingsType)?.members_with_roles?.forEach((member) => {
      member.reciever &&
        response.push({
          email: member.email,
          name: member.name,
          response: '',
        });
    });
    !response.length &&
      response.push({
        email: '',
        name: 'Team member',
        response: '',
      });
    const sections = Object.keys(sectionsTemp).map((section_name) => {
      sectionsTemp[section_name].forEach((question) => {
        question.response = response;
      });
      return {
        section_name,
        questions: sectionsTemp[section_name],
      };
    });
    if (sections?.length) assessment_t.sections = sections as SectionResponseType[];

    setSelected(assessment_t);
    setIsPreview(true);
  }, [assessmentData, getValues, setSelected]);

  return (
    <ContentLayout title="Edit Reflection" back={true}>
      {(() => {
        if (loadingAssessment) {
          return <Spinner />;
        }

        if (!assessmentData) return <></>;
        const {
          general_settings,
          assessment_name,
          assessment_takes,
          assessment_id,
          admin_closed = false,
        } = assessmentData;

        return (
          <div className="p-0 m-0 w-full">
            {isPreview && <div className="builder__preview-header">Reflection Preview</div>}
            <div className={clsx(['container', isPreview && '!rounded-t-none'])}>
              {isPreview && <Take previewMode={isPreview} withMembers={true} />}
              <form onSubmit={form.handleSubmit(onEditAssessment)}>
                <div hidden={isPreview}>
                  <DeleteModal
                    isOpen={isOpenDelete}
                    close={closeDelete}
                    onClick={handleDelete(onDeleteAssessment)}
                    loadingDelete={loadingDelete}
                  />
                  <GeneralSettings
                    editAssessmentPublished={true}
                    assessmentName={assessment_name}
                    assessmentID={assessment_id}
                    generalSettings={general_settings}
                    assessmentTakes={assessment_takes}
                    adminClosed={admin_closed}
                    {...form}
                  />
                </div>
                <div className="edit-assessment__actions">
                  {!isPreview ? (
                    <>
                      <Button onClick={loadPreview}>Preview</Button>
                      <Button
                        disabled={!form.formState.isDirty}
                        isLoading={isLoading}
                        type="submit"
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button onClick={() => setIsPreview(false)} className="mt-20">
                      Close Preview
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        );
      })()}
    </ContentLayout>
  );
};
