import React from 'react';
import { useTable, useFilters, useGlobalFilter } from 'react-table';

interface Column {
  Header: any;
  accessor: string;
  Filter?: any;
}

interface Row {
  [key: string]: any;
}

interface UsersTableProps {
  columns: Column[];
  data: Row[];
}
export const UsersTable: React.FC<UsersTableProps> = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <div>
      <table {...getTableProps()} className="userTable">
        <thead className="tableHead">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="tableBody">
          {rows.map((row) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps();
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cellProps}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
